import { trans, transChoice } from '../common';

$(document).ready(function()
{
    $('.js-dropdown-checkbox').each(function()
    {
        updateCheckboxDropdown($(this));
    });
});

/************************************** Dropdown with checkbox **********************************/
// Stop checkbox clicking closing dropdown menu
$(document).on('click', '.js-dropdown-checkbox .dropdown-menu', function(e)
{
    e.stopPropagation();
});

// Count number of checked checkboxes and update counter in button
$(document).on('change', '.js-dropdown-checkbox .custom-checkbox input', function(event)
{
    updateCheckboxDropdown($(this));
});

/**
 * Custom dropdown select handler
 */
$(document).on('click', '.js_dropdown-select .dropdown-item', function(e)
{
    $(this).addClass('active');
    $(this)
        .siblings()
        .removeClass('active');

    let dropdownBtn = $(this).closest('.dropdown-menu').siblings('.btn-dropdown');
    if ($(this).parents('.js_dropdown-select').hasClass('dropdown-search'))
    {
        dropdownBtn.find('input').val($(this).clone().find('span').remove().end().text().trim());
    }
    else if (dropdownBtn.hasClass('with-icon'))
    {
        dropdownBtn.find('.inner').html($(this).html());
    }
    else if ($(this).closest('.dropdown-menu').hasClass('invites'))
    {
        let selectedValue = $(this).find('label').text();
        dropdownBtn.text(selectedValue);
    }
    else
    {
        dropdownBtn.text($(this).text());
    }

    //trigger change event, so this dropdown can be used like a normal select dropdown
    let element = $(this).parents('.js_dropdown-select').find('.js_dropdown-select-input');
    element.val($(this).attr('data-bs-target'));
    element.trigger('change');
    dropdownBtn.find('input').blur();
});

/**
 * Filter dropdown menu with items matching search input
 */
$(document).on('keyup paste', '.dropdown-search .input-wrapper input', function(e)
{
    let searchVal = $(this).val().toLowerCase();
    let itemsCount = 0;
    const dropdownParent = $(this).parents('.dropdown-search');
    const dropdownMenu = dropdownParent.find('.dropdown-menu');
    dropdownMenu.find('.empty-state-item').remove();
    dropdownMenu.find('.dropdown-item').each(function()
    {
        if ($(this).clone().find('span').remove().end().text().toLowerCase().includes(searchVal))
        {
            $(this).show();
            itemsCount++;
        }
        else
        {
            $(this).hide();
        }
    });
    dropdownMenu.find('.dropdown-item').removeClass('active');
    dropdownParent.find('.js_dropdown-select-input').val(searchVal ? 'custom' : '').change();
    if (itemsCount === 0)
    {
        const emptyStateMsg = dropdownMenu.attr('data-empty-state-msg');
        if (emptyStateMsg)
        {
            dropdownMenu.find('.inner-scroll').append(`<div class="dropdown-item empty-state-item">${emptyStateMsg}</div>`);
        }
        else
        {
            dropdownMenu.hide();
        }
    }
    else
    {
        dropdownMenu.attr('style', '');
        dropdownParent.find('.btn-dropdown').dropdown('toggle');
    }
});

/**
 * Prevent dropdown menu from closing on dropdown search input click
 */
$(document).on('hide.bs.dropdown', '.dropdown-search', function(e)
{
    if (!e.clickEvent || $(this).find('.btn-dropdown').is(e.clickEvent.target))
    {
        return false;
    }
});

/**
 * Prevent dropdown menu from closing on dropdown checkbox click
 */
$(document).on('hide.bs.dropdown', '.dropdown-checkbox', function(e)
{
    if (!e.clickEvent || $(this).find('.dropdown-item').is(e.clickEvent.target) || $(this).find('.dropdown-item').is($(e.clickEvent.target).parents('.dropdown-item')))
    {
        return false;
    }
});

/**
 * Increase hit box for checkbox in dropdown item
 */
$(document).on('click', '.dropdown-checkbox .dropdown-item', function(e)
{
    // toggle checkbox if label or checkbox not clicked
    if (!$(this).find('input, label, div:not(.custom-checkbox)').is(e.target) && $(this).find('input').attr('id') !== e.target.id)
    {
        $(this).find('input').prop('checked', !$(this).find('input').prop('checked')).change();
    }
});

/**
 * Auto-scroll dropdown menu to top on open
 */
$(document).on('show.bs.dropdown', function(e)
{
    setTimeout(function()
    {
        $(e.target).find('.dropdown-menu .inner-scroll').scrollTop(0);
    }, 0);
});

/**
 * Prevent dropdown menu from closing on header click
 */
$(document).on('click', '.dropdown-menu .dropdown-header', function(e)
{
    e.stopPropagation();
});

/**
 * Prevent show-all-button closing the dropdown
 */
$(document).on('hide.bs.dropdown', function(e)
{
    if (e.clickEvent && e.clickEvent.target && $(e.clickEvent.target).hasClass('js-show-all-toggle'))
    {
        e.preventDefault();
    }
});

/**
 * Toggle showing of sometimes hidden items (e.g. archived classes)
 */
$(document).on('click', '.js-show-all-toggle', function(e)
{
    let targetSelector = $(this).data('bs-target-selector');
    let type = $(this).attr('data-bs-type');

    let isVisible = $(targetSelector).is(':visible');

    if (isVisible)
    {
        $(targetSelector).hide();
        $(this).text(trans('base.showAll')).attr('data-bs-type', 'show');
    }
    else
    {
        $(targetSelector).show();
        $(this).text(trans('base.showLess')).attr('data-bs-type', 'hide');
    }
});

$(document).on('mouseover', '.dropdown-menu a.dropdown-toggle', function(e)
{
    const nextDropdownMenu = $(this).next('.dropdown-menu');
    nextDropdownMenu.toggleClass('show');

    const submenu = $(this).parents('.dropdown-submenu');
    submenu.on('mouseout', function(e)
    {
        const boundingRect = nextDropdownMenu.get(0).getBoundingClientRect();
        const mouseX = e.pageX - $(document).scrollLeft();
        const mouseY = e.pageY - $(document).scrollTop();

        if (mouseX < boundingRect.left || mouseX > boundingRect.right || mouseY < boundingRect.top || mouseY > boundingRect.bottom)
        {
            nextDropdownMenu.removeClass('show');
            submenu.off('mouseover');
        }
    });

    $(this).parents('.dropdown').on('hidden.bs.dropdown', function(e)
    {
        nextDropdownMenu.removeClass('show');
    });

    if (nextDropdownMenu.hasClass('show'))
    {
        $(this).trigger('submenu:shown');
    }
    else
    {
        $(this).trigger('submenu:hidden');
    }

    return false;
});


export function updateCheckboxDropdown(dropdownEle)
{
    let currentDropdown = dropdownEle.closest('.js-dropdown-checkbox');
    let count = currentDropdown.find('input[type=checkbox]:not(.js-canvas-section-select-all):checked').length;

    // Update pluralisation of button text
    let title = currentDropdown.attr('data-title');
    if (title && count !== 1)
    {
        currentDropdown.find('button').text(transChoice(title, count));
    }
    else
    {
        let inputId = currentDropdown.find('input[type=checkbox]:checked').attr('id');
        currentDropdown.find('button').text(
            currentDropdown.find(`[for=${inputId}`).text());
    }

    if (count > 1)
    {
        currentDropdown.addClass('checkbox-count');
        if (currentDropdown.find('button .count').length > 0)
        {
            currentDropdown.find('button .count').text(count);
        }
        else
        {
            currentDropdown.find('button').append(`<span class="count">${count}</span>`);
        }
    }
    else
    {
        currentDropdown.removeClass('checkbox-count');
        currentDropdown.find('span.count').remove();
    }
}

export function selectDropdownItem(dropdown, value, withChange = false)
{
    let item = dropdown.find(`.dropdown-item[data-bs-target="${value}"]`);
    if (item.length)
    {
        item.addClass('active');
        item.siblings().removeClass('active');
        let dropdownBtn = item.closest('.dropdown-menu').siblings('.btn-dropdown');
        if (dropdownBtn.hasClass('with-icon'))
        {
            dropdownBtn.find('.inner').html(item.html());
        }
        else if (dropdownBtn.hasClass('input-wrapper'))
        {
            dropdownBtn.find('input').val(item.clone().find('span').remove().end().text().trim());
        }
        else
        {
            dropdownBtn.text(item.text());
        }
        dropdown.find('.js_dropdown-select-input').val(value);
        if (withChange)
        {
            dropdown.find('.js_dropdown-select-input').change();
        }
    }
}