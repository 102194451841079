import { Quitch } from '../quitch';

$(document).on('click', '.js-show-test-snackbar', function()
{
    Quitch.showSnackbar('Test Snackbar');
});

$(document).on('click', '.js-show-test-snackbar-dismiss', function()
{
    Quitch.showSnackbar('Test Snackbar 2', true);
});

$(document).on('click', '.js-show-test-modal-dialog', function()
{
    Quitch.modal(
    {
        message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        title: 'Modal dialog'
    });
});

$(document).on('click', '#show-add-img-modal-btn', function()
{
    $('#add-image-modal').modal('show');
});

function handleSampleError(error)
{
    console.log('Balloon editor handleSampleError: ', error);
}