import { Quitch } from '../quitch';
import { telemetryTrackAndPerformUrlChange, telemetryTrackEvent } from './mixpanel';

$(document).on('click', '.js-reset-user-password', function(e)
{
    e.preventDefault();
    let el = $(this);
    let targetUrl = el.attr('href');
    Quitch.modal({ title: 'Reset User Password', message: el.data('title'), type: 'confirm' }, function(response)
    {
        if (response === true)
        {
            $.get(targetUrl, function(res)
            {
                if (res.updated)
                {
                    let response = '<p>User password has been reset:<br/><span>' + res.updated + '</span></p>';
                    Quitch.modal({ message: response, title: 'User Password Updated' });
                }
                else
                {
                    let response = '<p>Unable to update user password</p>';
                    Quitch.modal({ message: response, title: 'Update Password' });
                }
            });
        }
    });
});

$(document).on('click', '.js-force-logout', function(e)
{
    e.preventDefault();
    let el = $(this);
    let targetUrl = el.attr('href');
    Quitch.modal({ title: 'Force Logout', message: el.data('title'), type: 'confirm' }, function(response)
    {
        if (response === true)
        {
            $.get(targetUrl, function(res)
            {
                if (res.updated)
                {
                    let response = '<p>User session has been reset</p>';
                    Quitch.modal({ message: response, title: 'User Session Reset' });
                }
                else
                {
                    Quitch.modal({ message: res.message, title: 'User Session Reset' });
                }
            });
        }
    });
});

$(document).on('click', '.js-regenerate-name', function(e)
{
    e.preventDefault();
    let el = $(this);
    let targetUrl = el.attr('href');
    Quitch.modal({ title: 'Regenerate Screen Name', message: el.data('title'), type: 'confirm' }, function(response)
    {
        if (response === true)
        {
            $.get(targetUrl, function(res)
            {
                if (res.updated)
                {
                    let response = '<p>Screen name regenerated</p>';
                    Quitch.modal({ message: response, title: 'Regenerate Screen Name' });
                    window.location.reload();
                }
                else
                {
                    let response = '<p>Failed to regenerated screen name</p>';
                    Quitch.modal({ message: response, title: 'Regenerate Screen Name' });
                }
            });
        }
    });
});

$(document).on('click', '.js-unlock-user', function(e)
{
    e.preventDefault();
    let el = $(this);
    let targetUrl = el.attr('href');
    Quitch.modal({ title: 'Unlock User', message: el.data('title'), type: 'confirm' }, function(response)
    {
        if (response === true)
        {
            $.get(targetUrl, function(res)
            {
                if (res.updated)
                {
                    let response = '<p>User Unlocked</p>';
                    Quitch.modal({ message: response, title: 'Unlock User' });
                    window.location.reload();
                }
                else
                {
                    let response = '<p>User is already unlocked</p>';
                    Quitch.modal({ message: response, title: 'Unlock User' });
                }
            });
        }
    });
});

$(document).on('click', '.js-toggle-super-user', function(e)
{
    e.preventDefault();
    let el = $(this);
    let targetUrl = el.attr('href');
    Quitch.modal({ title: 'Toggle Super user', message: el.data('title'), type: 'confirm' }, function(response)
    {
        if (response === true)
        {
            $.get(targetUrl, function(res)
            {
                if (res.updated)
                {
                    if (res.delete)
                    {
                        let response = '<p>User demoted</p>';
                        Quitch.modal({ message: response, title: 'Demoted user' });
                    }
                    else
                    {
                        let response = '<p>User promoted to Super User</p>';
                        Quitch.modal({ message: response, title: 'Promoted user' });
                    }
                    window.location.reload();
                }
                else
                {
                    let response = '<p>Failed to update user roles</p>';
                    Quitch.modal({ message: response, title: 'Toggle Super user' });
                }
            });
        }
    });
});

$(document).on('click', '.js-toggle-user-is-developer', function(e)
{
    e.preventDefault();
    let el = $(this);
    let targetUrl = el.attr('href');
    let target = e.currentTarget;
    Quitch.modal({ title: 'Toggle user is developer', message: el.data('title'), type: 'confirm' }, function(response)
    {
        if (response === true)
        {
            let dev = $(target).data('dev-value');
            let token = $('meta[name="csrf-token"]').attr('content');
            $.post(targetUrl, { dev: dev, _token: token }, function(res)
            {
                if (res.updated)
                {
                    if (dev != 1)
                    {
                        let response = '<p>User demoted, not a developer</p>';
                        Quitch.modal({ message: response, title: 'Demoted user' });
                    }
                    else
                    {
                        let response = '<p>User promoted to Developer</p>';
                        Quitch.modal({ message: response, title: 'Promoted user' });
                    }
                    window.location.reload();
                }
                else
                {
                    let response = '<p>Failed to update user developer status</p>';
                    Quitch.modal({ message: response, title: 'Toggle developer' });
                }
            });
        }
    });
});

$(document).on('click', '.js-delete-confirm', function(e)
{
    e.preventDefault();
    let el = $(this);
    let targetUrl = el.attr('href');
    Quitch.modal(
        {
            title: el.attr('modal-title'),
            message: el.data('message') || 'This process is not reversible. Do you wish to continue?',
            type: 'confirm',
        },
        function(response)
        {
            if (response === true)
            {
                telemetryTrackAndPerformUrlChange(targetUrl, 'ep-delete-confirm-true');
            }
        }
    );
});

$(document).on('click', '.js-delete-user-completely', function(e)
{
    $('#delete-user-completely-modal').modal('show');
});

/**
 * Profile reassign user search
 */
$(document).on('keyup', '.js_autocomplete input', e =>
{
    const input = $(e.target);
    const list = input.parent().find('ul');
    const value = input.val();

    // list missing or no value do not fire
    if (list.length === 0 || value.length === 0)
    {
        return;
    }

    // clear request
    if (input.attr('timer'))
    {
        clearInterval(input.attr('timer'));
    }

    const token = $('meta[name="csrf-token"]').attr('content');
    const timer = setTimeout(() =>
    {
        $.ajax(
        {
            url: '/manage/users/search',
            type: 'post',
            data:
            {
                search: encodeURI(input.val()),
                _token: token,
            },
            dataType: 'json',
            success: users =>
            {
                list.empty();
                $.each(users, (key, user) =>
                {
                    const link = $('<a/>').text(`${user.first_name || '-'} ${user.last_name || '-'} (${user.email})`);
                    list.append(
                        $('<li/>')
                        .data('user', user)
                        .append(link)
                    );
                });
            },
        });
    }, 400);

    input.attr('timer', timer);
});

$(document).on('click', '.js_autocomplete a', e =>
{
    const listItem = $(e.target).parent();
    const list = listItem.parent();
    const user = listItem.data('user');
    const target = list.parent().data('bs-target');
    $(`.${target}`)
        .data('user', user)
        .trigger('user-selected');
});

// custom event handler for user-selected
$(document).on('user-selected', '.js_targetUser', () =>
{
    reassignProfile(true);
});

// perform re-assign on click
$(document).on('click', '.js_reassignUser', () =>
{
    if ($('.js_reassignUser').hasClass('disabled'))
    {
        return;
    }
    reassignProfile(false);
});

// go back to the search form
$(document).on('click', '.js_targetUser a.change-user', () =>
{
    const targetEl = $('.js_targetUser');
    targetEl.find('.alert').remove();
    targetEl.hide();
    targetEl
        .parent()
        .find('.js_autocomplete')
        .show();
});

function reassignProfile(dryRun)
{
    const targetEl = $('.js_targetUser');
    const user = targetEl.data('user');

    // update ui with user that was selected
    $('ul.js_autocomplete')
        .find('li')
        .removeClass('selected');
    targetEl
        .parent()
        .find('.js_autocomplete')
        .hide();
    targetEl
        .show()
        .find('p')
        .text(`${user.first_name || '-'} ${user.last_name || '-'} (${user.email})`);

    // fire request to check if we can reassign
    const orgUserId = $('.js_owner').data('org-user-id');
    const token = $('meta[name="csrf-token"]').attr('content');
    $.ajax(
    {
        url: '/manage/profile/reassign',
        type: 'post',
        data:
        {
            dryRun: dryRun,
            orgUserId: orgUserId,
            targetUserId: user.id,
            _token: token,
        },
        dataType: 'json',
        success: (response, statusText, xhr) =>
        {
            if (xhr.status === 202)
            {
                reassignProfileMessage(targetEl, response.message, 'info');
            }
            else if (xhr.status === 200)
            {
                reassignProfileMessage(targetEl, response.message, 'success');

                telemetryTrackAndPerformUrlChange(response.redirect, 'ep-reassign-profile-success');
            }
        },
        error: xhr =>
        {
            const response = xhr.responseJSON;
            reassignProfileMessage(targetEl, response.message, 'danger');

            telemetryTrackEvent('ep-reassign-profile-error', { error: response.message });
        },
    });
}

function reassignProfileMessage(targetEl, message, type)
{
    const alertClass = `alert-${type}`;
    let reassignBtn = $('.js_reassignUser');
    targetEl.find('.alert').remove();

    // create new message and update button state
    let changeUser = $('<a/>')
        .addClass('change-user float-right')
        .text('change user');
    let messageDiv = $('<div/>')
        .addClass('alert')
        .text(message)
        .addClass(alertClass);
    if (type === 'success')
    {
        reassignBtn.addClass('disabled');
    }
    else if (type === 'info')
    {
        reassignBtn.removeClass('disabled btn-light').addClass('btn-success');
        messageDiv.append(changeUser);
    }
    else
    {
        reassignBtn.addClass('btn-light disabled').removeClass('btn-success');
        messageDiv.append(changeUser);
    }
    targetEl.append(messageDiv);
}