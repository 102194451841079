import { trans } from '../../common';
import { Quitch } from '../../quitch';
import { getSpinnerHtml, getUpdateMessage } from '../../../system';
import { telemetryTrackAndPerformUrlChange, telemetryTrackEvent } from '../../superuser/mixpanel';
import Cookies from 'js-cookie';

/**
 * Import file validation
 */
$(document).on('change', '.import-file', function()
{
    $(this)
        .parent()
        .find('.btn-import-submit')
        .attr('disabled', 'disabled');
    $(this)
        .parent()
        .find('.file-view')
        .empty();

    // no file selected
    if (!this.files || this.files.length === 0)
    {
        return false;
    }

    const file = this.files[0];
    let ext = file.name.split('.').pop();
    if (!/(zip|xls|xlsx|doc|docx)/g.test(ext))
    {
        error(trans('import.unsupportedFileType'), 'Error', 'form-errors');
        return false;
    }

    const fileSize = file.size / 1024 / 1024; // in MB
    if (5 <= fileSize)
    {
        error(trans('import.fileTooLarge'), 'Error', 'form-errors');
        return false;
    }

    const filename = this.value.replace(/C:\\fakepath\\/i, '');
    $(this)
        .parent()
        .find('.file-view')
        .html(filename);
    $(this)
        .parent()
        .find('.btn-import-submit')
        .removeAttr('disabled');
});

// enable #chatgpt-import-form submit button if [name="chat-gpt-prompt"] is not empty
$(document).on('keyup', 'input[name="chat-gpt-prompt"]', function()
{
    if ($(this).val().length > 0)
    {
        $('#chatgpt-import-form button').removeAttr('disabled');
    }
    else
    {
        $('#chatgpt-import-form button').attr('disabled', 'disabled');
    }
});

$(document).on('submit', '#chatgpt-import-form', function(e)
{
    e.preventDefault();
    let values = {};
    values['_token'] = $('meta[name="csrf-token"]').attr('content');
    values['prompt'] = $('[name="chat-gpt-prompt"]').val();
    values['question-count'] = $('[name="chat-gpt-count"]').val();
    const url = $(this).attr('action');

    let chatGPTForm = $('#chatgpt-import-form .callout.callout-info');
    chatGPTForm.find('div,button').hide();
    chatGPTForm.find('p').html('Generating questions, the page will reload once completed ☕')
        .after(getSpinnerHtml());

    // displays the time elapsed since the form was submitted
    let time = new Date();
    let timeElapsed = time.getTime();
    let timeElapsedP = $('<p></p>');
    chatGPTForm.append(timeElapsedP);
    setInterval(function()
    {
        let time = new Date();
        let timeNow = time.getTime();
        let timeDiff = timeNow - timeElapsed;
        let minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
        let seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);
        timeElapsedP.html('Time elapsed: ' + minutes + 'm ' + seconds + 's');
    }, 1000);

    $.ajax(
    {
        url: url,
        type: 'POST',
        dataType: 'json',
        data: values,
        success: function(response)
        {
            if (response.redirectTo)
            {
                telemetryTrackAndPerformUrlChange(response.redirectTo, 'ep-chatgpt-import-form-success');
            }
            else
            {
                window.location.reload();
            }
        },
        error: function(response, status, error) {},
    });
});

$(document).on('submit', '#import-content-form', function(e)
{
    e.preventDefault();
    const url = $(this).attr('action');
    const fileInput = $(this).find('.import-file');
    const classId = $('.js_class-manager').data('class-id');

    if (fileInput.length === 0 || fileInput[0].files.length === 0)
    {
        $(this)
            .parent()
            .find('#form-errors')
            .html(getUpdateMessage('import.generalError', 'danger', { error: trans('import.importRequired') }))
            .show()
            .delay(5000)
            .slideUp(500);
        return false;
    }

    if (fileInput.length > 0 && fileInput[0].files[0]['name'].toLowerCase().endsWith('.docx'))
    {
        const chatGptActive = $('.chatGptActiveDataHolder').data('chat-gpt-active');
        if (chatGptActive)
        {
            Quitch.modal(
                {
                    title: `<div class="d-inline-flex">${trans('import.wordImportWithAI')}<span class="lozenge rectangle ms-2">${trans('content.betaLozenge')}</span></div>`,
                    message: `<p class="wrap">${trans('import.wordImportWithAIWarning1')}</p><br>` +
                        `<p class="wrap">${trans('import.wordImportWithAIWarning2')}</p>`,
                    type: 'confirm_custom',
                    cssClass: 'modal-dialog-centered modal-dialog-sm',
                    okText: 'base.continue',
                    cancelText: 'base.cancel',
                },
                function(response)
                {
                    if (response === true)
                    {
                        importAjax(url, fileInput, classId);
                    }
                    else
                    {
                        telemetryTrackEvent('wordImportWithAi-modalCancel');
                    }
                });
        }
        else
        {
            Quitch.modal(
            {
                title: `<div class="d-inline-flex">${trans('import.wordImportWithAIDisabled')}</div>`,
                message: `<div class="call-to-action caution mb-2"><div class="icon"></div><div class="text"><div class="description">${trans('import.wordImportWithAIDisabledMsg1')}</div></div></div><br>` +
                    `<p class="wrap">${trans('import.wordImportWithAIDisabledMsg2')}</p>`,
                type: 'alert-no-button',
                cssClass: 'modal-dialog-centered modal-dialog-sm',
                okText: 'base.ok',
                cancelText: 'base.cancel',
                showClose: true,
            });
        }
    }
    else
    {
        importAjax(url, fileInput, classId);
    }
});

function importAjax(url, fileInput, classId)
{
    let importLoader = $('.import-loader').html(getSpinnerHtml());
    const importBtn = $('.btn-import-submit')
        .prop('disabled', true)
        .html('<span class="me-2"><i class="fa-solid fa-circle-notch fa-pulse"></i></span>' + trans('reporting.processing'));

    const file = fileInput[0].files[0];
    const formData = new FormData();
    formData.append('_token', $('meta[name="csrf-token"]').attr('content'));
    formData.append('class_id', classId);
    formData.append('import_file', file);
    formData.append('file_name', file['name']);

    $.ajax(
    {
        url: url,
        type: 'POST',
        enctype: 'multipart/form-data',
        data: formData,
        cache: false,
        contentType: false,
        processData: false,
        success: function(response)
        {
            telemetryTrackEvent('ep-import-content-form-success');
            refreshOnJobCreated(response.batch);
        },
        error: function(response, status, error)
        {
            importBtn.prop('disabled', false).html('Upload');
            importLoader.html('');
            if (status === 422)
            {
                let errors = response;
                let errorsHtml = '<div class="alert alert-danger"><ul>';
                $.each(errors, function(key, value)
                {
                    errorsHtml += '<li>' + value[0] + '</li>'; //showing only the first error.
                });
                errorsHtml += '</ul></di>';
                $(this)
                    .parent()
                    .find('#form-errors')
                    .html(errorsHtml)
                    .show()
                    .delay(5000)
                    .slideUp(500);
            }
            else
            {
                // Error
                // Incorrect credentials
                $('#form-errors')
                    .html(
                        `
                    <div class="alert alert-danger">
                        ${trans('import.generalError', { error: response.responseJSON.error })}
                    <div>
                `
                    )
                    .show()
                    .delay(5000)
                    .slideUp(500);
            }
        },
    });
}

function refreshOnJobCreated(jobId, retryDelay = 500)
{
    $.ajax(
    {
        url: `/jobs/get-status/${jobId}`,
        type: 'GET',
        dataType: 'json',
        success: function(response)
        {
            if (response.job.status === 'running')
            {
                window.setTimeout(function()
                {
                    refreshOnJobCreated(jobId, Math.ceil(retryDelay * 1.5));
                }, retryDelay);
            }
            else if (response.job.status === 'created' || response.job.status === 'failed')
            {
                window.location.reload();
            }
        },
        error: function(xhr, status, error)
        {
            window.location.reload();
        }
    });
}


$(document).on('click', '.js-complete-import-btn', function(e)
{
    e.preventDefault();

    let action = $(this).val();
    let classId = $('.js-import-confirm-form').data('class-id');
    let batchId = $('.js-import-confirm-form').data('batch-id');
    const importType = $('.js-import-confirm-form').data('import-type');
    let sectionErrCount = $('.js-section-error').length;
    let topicErrCount = $('.js-topic-error').length;
    let questionErrCount = $('.js-question-error').length;
    let questionTotal = $('.card.card-import').length;
    let values = {};
    values['_token'] = $('meta[name="csrf-token"]').attr('content');
    values['action'] = action;

    let questionErrorList = $('.js-question-error-type').map(function()
    {
        let val = $(this).val();
        try
        {
            const jsonObj = JSON.parse(val); // Sometimes the error type is a value in a JSON key pair
            return jsonObj['i18nKey'];
        }
        catch (e)
        {
            return val;
        }
    }).get();

    let imageErrCount = 0;
    for (let i = 0; i < questionErrorList.length; ++i)
    {
        if (questionErrorList[i] === 'import.invalidImage' ||
            questionErrorList[i] === 'import.tooLargeImage' ||
            questionErrorList[i] === 'import.unsupportedImage')
        {
            ++imageErrCount;
        }
    }

    questionErrCount -= imageErrCount; // Image errors should not be counted as they don't block importing a question

    if (action === 'submit')
    {
        if (questionErrCount + topicErrCount + sectionErrCount + imageErrCount > 0)
        {
            let errorMsg = '<ul>';

            errorMsg +=
                (topicErrCount + sectionErrCount > 0 ?
                    `<li>${trans('import.missingSectionTopic')}</li>` :
                    '') +
                (questionErrCount > 0 ?
                    `<li><b>${trans('import.questionsImported', { questions: questionTotal - questionErrCount, questionTotal: questionTotal })}</b></li>` :
                    '');

            if (imageErrCount > 0)
            {
                errorMsg += `<li><b>${trans('import.imageErrors', { imageErrorCount: imageErrCount })}</b></li>`;
            }

            errorMsg += '</ul>';
            errorMsg += `<p>${trans('import.completeAnyway')}</p>`;

            Quitch.modal(
                {
                    title: trans('import.containErrors'),
                    message: errorMsg,
                    type: 'confirm',
                },
                function(response)
                {
                    if (response === true)
                    {
                        telemetryTrackEvent('contentImport-submitWithErrors', { importType: importType });
                        submitContentImport(classId, batchId, values, importType);
                    }
                    else
                    {
                        telemetryTrackEvent('contentImport-modalCancelSubmitWithErrors', { importType: importType });
                    }
                }
            );
        }
        else
        {
            telemetryTrackEvent('contentImport-submit', { importType: importType });
            submitContentImport(classId, batchId, values, importType);
        }
    }
    else if (action === 'cancel')
    {
        telemetryTrackEvent('contentImport-cancel', { importType: importType });
        submitContentImport(classId, batchId, values, importType, true);
    }
});

$(document).on('click', '.js_validation_text, .js-import-overview-link', function()
{
    let id = $(this)
        .children('input')
        .val();
    $('.import-questions .card').each(function()
    {
        if ($(this).attr('data-question-id') === id || $(this).attr('data-topic-id') === id || $(this).attr('data-section-id') === id)
        {
            $(this)[0].scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
            return false;
        }
    });
});

/**
 * QTI content import
 */
$(document).on('submit', '#qti-form', function(e)
{
    e.preventDefault();
    $('#prop-section option[value=""]').attr('selected', true);

    $.ajax(
    {
        url: $(this).attr('action'),
        type: 'POST',
        data: $(this).serialize(),
        success: function(response)
        {
            // enable complete import button if all questions are assign topics
            $('.js-qti-import').prop('disabled', !response.completed);
            Quitch.showSnackbar(response.message);
        },
        error: function(e, t, s)
        {
            Quitch.showSnackbar(e.responseJSON.message);
        },
    });
});

/**
 * Handle assigning topic to all questions on page
 */
$(document).on('change', '#prop-section', function()
{
    let selected = $('#prop-section').val();
    $('.js-topic-select')
        .find('option:selected')
        .removeAttr('selected');
    $('.js-topic-select option[value="' + selected + '"]').prop('selected', true);
    $('#qti-form').submit();
});

$('.js-topic-select').change(function()
{
    if ($(this).val())
    {
        $('#qti-form').submit();
    }
});

/**
 * Handle adding and removing questions from staging
 */
$('.js-qti-update').on('click', function()
{
    let el = $(this);
    let importId = el.attr('data-import-id');
    let questionId = el.attr('data-question-id');
    let action = el.attr('data-action');
    let row = el.closest('tr');
    $.ajax(
    {
        url: '/admin/content/import/' + importId + '/' + action + '/' + questionId,
        type: 'get',
        dataType: 'json',
        success: function(response)
        {
            row.toggleClass('soft-delete');
            row.find('.assign-topic select').toggle();
            row.find('.js-qti-update i').toggleClass('fa-plus fa-trash');
            if (action === 'restore')
            {
                row.find('.assign-topic select').prop('disabled', false);
                el.attr('data-action', 'remove');
            }
            else if (action === 'remove')
            {
                row.find('.assign-topic select').prop('disabled', true);
                el.attr('data-action', 'restore');
            }
            if (response.staged)
            {
                $('.questions-staged').text(trans('import.importedRecords', { imported: response.staged }));
            }
            Quitch.showSnackbar(response.message);
        },
        error: function(e, t, s)
        {
            Quitch.showSnackbar(e.responseJSON.message);
        },
    });
});

// Import Content Upload Process
const error = function(msg, status = 'Error', div = 'errors')
{
    $(`#${div}`)
        .html(`<div class="alert alert-danger"><strong>${status}!</strong> ${msg}<div>`)
        .show()
        .delay(5000)
        .slideUp(500);
};

function submitContentImport(classId, batchId, values, importType, back = false)
{
    $.ajax(
    {
        type: 'POST',
        url: `/admin/class/${classId}/import/${batchId}`,
        data: values,
        success: function()
        {
            let feedback = '';
            if (importType === 'word-import')
            {
                feedback = '?feedback=wordImportWithAi';
            }
            else if (importType === 'excel-import')
            {
                feedback = '?feedback=excelImport';
            }

            if (back)
            {
                window.location = `/admin/class/${classId}/import${feedback}`;
            }
            else
            {
                if (Cookies.get('last-content-authoring-visited') === 'new')
                {
                    const classInstanceId = $('input[name=classInstanceId]').val();
                    window.location = `/content-authoring/${classInstanceId}${feedback}`;
                }
                else
                {
                    window.location = `/admin/class/${classId}/content${feedback}`;
                }
            }
        },
    });
}