/**
 * Educator portal javascript Components
 */
require('./system');

require('./components/class-manager/content-authoring');
require('./components/class-manager/content-authoring/batch-actions');
require('./components/class-manager/content-authoring/common');
require('./components/class-manager/content-authoring/content-details');
require('./components/class-manager/content-authoring/move');
require('./components/class-manager/content-authoring/onboarding');
require('./components/class-manager/content-authoring/preview');
require('./components/class-manager/content-authoring/question');
require('./components/class-manager/content-authoring/topic');
require('./components/class-manager/content-authoring/resource');
require('./components/class-manager/content-authoring/schedule-publish');
require('./components/class-manager/wysiwyg-editor');

require('./components/class-manager/content-authoring-old');
require('./components/class-manager/content-authoring-old/content-import');
require('./components/class-manager/content-authoring-old/formulas');
require('./components/class-manager/content-authoring-old/questions');
require('./components/class-manager/content-authoring-old/resources');
require('./components/class-manager/content-authoring-old/scheduling');
require('./components/class-manager/content-authoring-old/section-topic');
require('./components/class-manager/content-authoring-old/ai-content');

require('./components/class-manager/announcements');
require('./components/class-manager/assess-answers');
require('./components/class-manager/badges');
require('./components/class-manager/content-analytics');
require('./components/class-manager/dashboard');
require('./components/class-manager/invitations');
require('./components/class-manager/learners');
require('./components/class-manager/whats-new');
require('./components/class-manager/tutorials');
require('./components/class-manager/feedback-modal');
require('./components/class-manager/css-style-lib');
require('./components/class-manager/integrations/canvas');
require('./components/class-manager/settings');

require('./components/admin/class-edit');
require('./components/admin/org-settings');
require('./components/admin/reporting');
require('./components/admin/user-manager');
require('./components/admin/integrations/canvas');
require('./components/admin/integrations/teams');
require('./components/admin/integrations/chatGpt');

require('./components/home');
require('./components/login');

require('./components/superuser/mixpanel');
require('./components/superuser/marketplace');
require('./components/superuser/org-management');
require('./components/superuser/user-management');

require('./components/form/dropdown');
require('./components/form/file-upload');
require('./components/form/input');