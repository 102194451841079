import { Quitch } from '../quitch';
import { trans, transChoice } from '../common';
import { mixpanelEventCounter, telemetryTrackEvent } from '../superuser/mixpanel';
import { updateProfileImagePlaceholder } from '../form/file-upload';

// Scroll to top when switching org settings tab
$(document).on('click', '.js-org-settings-nav .nav-link', function()
{
    window.scrollTo(0, 0);
});

$(document).ready(function()
{
    if ($('#org-settings').length > 0)
    {
        updateOrgInitials();
    }
});

/**
 * Update organisation logo with org initial on input change
 */
function updateOrgInitials()
{
    let orgName = $('.org-settings-input').val();
    let initial = '';
    if (orgName && orgName.trim().length > 0)
    {
        initial = orgName.substring(0, 1);
    }

    updateProfileImagePlaceholder('org-logo-upload-widget', initial);
    $('.workspace-section .org-details .rounded-circle').text(initial);
}

/**
 * Update organisation settings on input change
 */
$(document).on('change', '.org-settings-input', function()
{
    let inputName = $(this).attr('name');
    updateOrgInitials();
    updateOrgSetting(
    {
        [inputName]: $(this).val()
    }, () =>
    {
        let transKey = 'orgManager.' + inputName.replace('_', '') + 'Updated';
        Quitch.showSnackbar(trans(transKey));
        if (inputName === 'organization_name')
        {
            $('.workspace-section .org-details label').text($(this).val());
        }
    });
});

$(document).on('change', '#certifying-body-toggle', function()
{
    updateOrgSetting({ certifyingBody: $(this).is(':checked') ? 1 : 0 }, () =>
    {
        Quitch.showSnackbar(trans('orgManager.certifyingBodyUpdated'));
    });
});

/**
 * Update org logo on file input change
 */
$(document).on('change', '#org-logo-upload-widget [name=original-filename]', function()
{
    const orgLogo = $('#org-logo-upload-widget [name=org-logo-path]').val();
    updateOrgSetting(
    {
        organization_logo: orgLogo,
        originalLogoName: $('#org-logo-upload-widget [name=original-filename]').val()
    }, () =>
    {
        if (orgLogo)
        {
            $('.workspace-section .org-details .rounded-circle').replaceWith(`<img class="rounded-circle" src="${$('#org-logo-upload-widget [name=asset-url]').val()}" alt="organization_logo" onerror="imageErrorHandler(this)">`);
        }
        else
        {
            const orgColour = $('#org-logo-upload-widget .image-preview').attr('data-profile-colour');
            const orgName = $('input[name=organization_name]').val();
            $('.workspace-section .org-details .rounded-circle').replaceWith(`<div class="rounded-circle" style="background-color: ${orgColour}">${orgName ? orgName.substring(0, 1) : ''}</div>`);
        }

        Quitch.showSnackbar(trans('orgManager.logoUpdated'));
    });
});

/**
 * Update org banner on file input change
 */
$(document).on('change', '#org-banner-upload-widget [name=original-filename]', function()
{
    updateOrgSetting(
    {
        organisationBanner: $('#org-banner-upload-widget [name=organisationBanner]').val(),
        originalBannerName: $('#org-banner-upload-widget [name=original-filename]').val()
    }, () =>
    {
        Quitch.showSnackbar(trans('orgManager.logoUpdated'));
    });
});

/**
 * Show modal that warns users that learners are pending deletion
 * @param deleteCount
 * @param togglePressed
 * @param callBack
 */
function showEmailEnforcementModal(deleteCount, togglePressed, callBack)
{
    Quitch.modal(
        {
            title: trans('orgManager.deleteNonlistedUsers'),
            message: `
                    <p class="wrap">${trans('orgManager.deleteNonlistedUsersDesc')}</p>
                    <p class="error-text mt-4" >${transChoice('orgManager.usersPendingDeletion', deleteCount)}</p>`,
            type: 'confirm_custom',
            cssClass: 'modal-dialog-centered',
            okText: 'orgManager.delete',
            cancelText: 'base.cancel',
        },
        function(response)
        {
            if (response === true)
            {
                callBack();
            }
            else
            {
                if (togglePressed)
                {
                    $('#allowlist-toggle').prop('checked', false);
                }
            }
        }
    );
}

/**
 * Add email domain to allow list
 */
$(document).on('click', '.js-add-email-domain-btn', function(e)
{
    e.preventDefault();
    let values = {};
    values['_token'] = $('meta[name="csrf-token"]').attr('content');
    values['orgId'] = $('.org-manager').attr('data-org-id');
    values['emailDomain'] = $('input[name="add-email-domain"]').val();

    $.ajax(
    {
        url: '/organisation/email-allowlist/add',
        type: 'POST',
        data: values,
        success: response =>
        {
            $('input[name="add-email-domain"]').val('');
            let allowlistItem = `<li class="input-group text-input-with-button">
                                                <input type="text" name="emailDomains[]" class="form-control" value="${response.emailDomain}" disabled/>
                                                <span class="input-group-append">
                                                    <button class="btn btn-secondary js-remove-email-domain-btn" type="button">
                                                        ${trans('orgManager.remove')}
                                                    </button>
                                                </span>
                                            </li>`;
            $('.email-domains').append(allowlistItem);

            // re-enable allowlist toggle
            $('#allowlist-toggle').prop('disabled', false);
            $('.allowlist-toggle-label').removeClass('disabled');
            telemetryTrackEvent('ep-security-added-email-domain-success',
            {
                'addedEmailDomain': 'success'
            });
            mixpanelEventCounter('customDomain');
            Quitch.showSnackbar(trans('orgManager.invitationPolicyUpdated'));
        },
        error: function(xhr, status, error)
        {
            if (xhr.responseJSON.message)
            {
                $('.add-email-domain input').addClass('error-input');
                $('.add-email-domain').after(`<p class="error-msg">${xhr.responseJSON.message}</p>`);
            }
            else
            {
                Quitch.showSnackbar(trans('orgManager.errorOccurred'));
            }
        },
    });
});

/**
 * Remove email domain from allow list
 */
$(document).on('click', '.js-remove-email-domain-btn', function(e)
{
    e.preventDefault();

    let values = {};
    values['_token'] = $('meta[name="csrf-token"]').attr('content');
    values['orgId'] = $('.org-manager').attr('data-org-id');
    values['emailDomain'] = $(this).closest('.input-group').find('[name="emailDomains[]"]').val();

    if ($('#allowlist-toggle').is(':checked'))
    {
        $.ajax(
        {
            url: '/organisation/pending-delete',
            type: 'POST',
            data: values,
            success: response =>
            {
                if (response.deleteCount > 0)
                {
                    values['deleteUsers'] = 1;
                    showEmailEnforcementModal(response.deleteCount, false, () => deleteEmailDomain(this, values));
                }
                else
                {
                    deleteEmailDomain(this, values);
                }
            },
            error: function(xhr, status, error)
            {
                Quitch.showSnackbar(trans('orgManager.errorOccurred'));
            },
        });
    }
    else
    {
        deleteEmailDomain(this, values);
    }
});

/**
 * Update email enforcement on toggle change. Show modal if change will result in learners deleted
 */
$(document).on('change', '#allowlist-toggle', function()
{
    let emailEnforcement = $(this).is(':checked') ? 1 : 0;

    if ($(this).is(':checked'))
    {
        let values = {};
        values['_token'] = $('meta[name="csrf-token"]').attr('content');
        values['orgId'] = $('.org-manager').attr('data-org-id');

        $.ajax(
        {
            url: '/organisation/pending-delete',
            type: 'POST',
            data: values,
            success: response =>
            {
                if (response.deleteCount > 0)
                {
                    showEmailEnforcementModal(response.deleteCount, true, () => updateEmailEnforcement(emailEnforcement));
                }
                else
                {
                    updateEmailEnforcement(emailEnforcement);
                }
            },
            error: function(xhr, status, error)
            {
                Quitch.showSnackbar(trans('orgManager.errorOccurred'));
            },
        });
    }
    else
    {
        updateEmailEnforcement(emailEnforcement);
    }
});

$('#organization_logo').on('change', function()
{
    if (this.files && this.files[0])
    {
        let reader = new FileReader();

        reader.onload = function(e)
        {
            $('#organization_logo_preview')
                .attr('src', e.target.result)
                .show();
        };
        reader.readAsDataURL(this.files[0]);
    }
});

// Remove error message on input on change
$(document).on('keyup', '.org-settings-input, .add-email-domain input', function()
{
    $(this).removeClass('error-input').closest('.form-group-depreciated').find('.error-msg').remove();
});

/**
 * When toggling on sso, default to enabling it only for educators. When toggling off, disable radio options.
 */
$('#sso-toggle').change(function()
{
    if ($(this).is(':checked'))
    {
        $('[name="ssoEnforcement"]').prop('disabled', false);
        $('[name="ssoEnforcement"][value="ep"]').prop('checked', true);
    }
    else
    {
        $('[name="ssoEnforcement"][type="radio"]').prop('checked', false);
        $('[name="ssoEnforcement"][type="radio"]').prop('disabled', true);
    }
});

/**
 * Update sso enforcement setting on toggle or radio change
 */
$(document).on('change', '[name="ssoEnforcement"]', function()
{
    if ($(this).attr('id') === 'sso-toggle')
    {
        if ($(this).is(':checked'))
        {
            let ssoProvider = $('#sso-provider').find(':selected').val();
            Quitch.modal(
                {
                    title: trans('orgManager.enforceSso'),
                    message: `<p class="wrap">${trans('orgManager.enforceSsoDesc', {ssoProvider: ssoProvider})}</p>`,
                    type: 'confirm_custom',
                    cssClass: 'modal-dialog-centered',
                    okText: 'base.continue',
                    cancelText: 'base.cancel',
                },
                function(response)
                {
                    if (response === true)
                    {
                        let ssoProvider = $('#sso-provider').find(':selected').val();
                        updateOrgSetting({ ssoEnforcement: 'ep', ssoProvider: ssoProvider }, () =>
                        {
                            Quitch.showSnackbar(trans('orgManager.ssoUpdated'));
                        });
                    }
                    else
                    {
                        // revert if users cancels sso enforcement
                        $('#sso-toggle').prop('checked', false);
                        $('[name="ssoEnforcement"][type="radio"]').prop('checked', false);
                        $('[name="ssoEnforcement"][type="radio"]').prop('disabled', true);
                    }
                }
            );
        }
        else
        {
            updateOrgSetting({ ssoEnforcement: 'none' }, () =>
            {
                Quitch.showSnackbar(trans('orgManager.ssoUpdated'));
            });
        }
    }
    else
    {
        updateOrgSetting({ ssoEnforcement: $(this).val() }, () =>
        {
            Quitch.showSnackbar(trans('orgManager.ssoUpdated'));
        });
    }
});

/**
 * Ajax to update organisation settings
 * @param formData
 * @param callback
 */
function updateOrgSetting(formData, callback)
{
    let values = {};
    values['_token'] = $('meta[name="csrf-token"]').attr('content');
    values['orgId'] = $('.org-manager').attr('data-org-id');
    values = $.extend({}, values, formData);

    $.ajax(
    {
        url: '/organisation/update',
        type: 'POST',
        dataType: 'json',
        data: values,
        success: response =>
        {
            callback();
        },
        error: function(xhr, status, error)
        {
            if (xhr.responseJSON.errors)
            {
                // show error message below input
                $.each(xhr.responseJSON.errors, function(inputName, errMsg)
                {
                    $(`input[name="${inputName}"]`).addClass('error-input').after(`<p class="error-msg">${errMsg}</p>`);
                });
            }
            else
            {
                Quitch.showSnackbar(trans('orgManager.errorOccurred'));
            }
        },
    });
}

function deleteEmailDomain(currentInput, values)
{
    $.ajax(
    {
        url: '/organisation/email-allowlist/remove',
        type: 'POST',
        data: values,
        success: response =>
        {
            $(currentInput).closest('.input-group').remove();

            // disable email enforcement if no domains found
            let allowlistToggle = $('#allowlist-toggle');
            if ($('input[name="emailDomains[]"]').length === 0)
            {
                allowlistToggle.prop('checked', false).change();
                allowlistToggle.prop('disabled', true);
                $('.allowlist-toggle-label').addClass('disabled');
            }
            else
            {
                Quitch.showSnackbar(trans('orgManager.invitationPolicyUpdated'));
            }
        },
        error: function(xhr, status, error)
        {
            Quitch.showSnackbar(trans('orgManager.errorOccurred'));
        },
    });
}

/**
 * Ajax to update email enforcement org setting and enable/disable sso enforcement based on its new state
 * @param emailEnforcement
 */
function updateEmailEnforcement(emailEnforcement)
{
    updateOrgSetting({ emailEnforcement: emailEnforcement }, () =>
    {
        let ssoToggle = $('#sso-toggle');
        if (emailEnforcement === 1)
        {
            ssoToggle.prop('disabled', false);
            $('.sso-toggle-label').removeClass('disabled');
        }
        else
        {
            if (ssoToggle.is(':checked'))
            {
                $('#sso-toggle').prop('checked', false).change();
            }
            ssoToggle.prop('disabled', true);
            $('.sso-toggle-label').addClass('disabled');
        }
        Quitch.showSnackbar(trans('orgManager.invitationPolicyUpdated'));
    });
}

$(document).on('change keyup paste', '#create-org-workspace-name', function(e)
{
    let input = $(e.currentTarget);
    let button = $('#create-org-submit-btn');
    let initial = '';

    if (input.val().trim().length > 0)
    {
        initial = input.val().substring(0, 1);
        button.attr('disabled', false);
    }
    else
    {
        button.attr('disabled', true);
    }

    updateProfileImagePlaceholder('org-logo-upload-widget', initial);
});

$(document).on('submit', '#create-workspace-form', function(e)
{
    $('#create-org-submit-btn').attr('disabled', true);
});