import { Quitch } from '../../quitch';
import { hideModalPopBox, trans, transChoice } from '../../common';
import { updateClassPublishStatus } from './index';
import { telemetryTrackEvent } from '../../superuser/mixpanel';

$(document).ready(function()
{
    updateTopicScheduleStatus();
});

/**
 * Prepare bulk schedule modal on schedule button click
 */
$(document).on('click', '.js_schedule-btn', function(event)
{
    event.preventDefault();

    let dataSection = $('.section-list-item.active').data('section');

    let dropdownListHtml = `<a href="#" class="dropdown-item" data-bs-target="class">${trans('content.allTopics')}</a>`;
    dropdownListHtml += `<div class="dropdown-header">${trans('content.dropSections')}</div>`;

    $('.js-section-list-item').each(function()
    {
        dropdownListHtml += `<a href="#" id="section-item" class="dropdown-item" data-bs-target="section"
                                    data-section-id="${$(this).attr('data-section')}">${$(this).attr('data-title')}</a>`;
    });

    dropdownListHtml += `<div class="dropdown-header">${trans('content.dropTopics')}</div>`;
    $('.js-topic-list-item').each(function()
    {
        dropdownListHtml += `<a href="#" id="topic-item-dynamic" class="dropdown-item" data-bs-target="topic"
                                    data-topic-id="${$(this).attr('topic-id')}">${$(this).attr('data-title')}</a>`;
    });

    $('.js_schedule-target .dropdown-menu .inner-scroll').html(dropdownListHtml);

    let selectedItem = $('#schedule-modal .dropdown-item[data-section-id=' + dataSection + '], .dropdown-item[data-topic-id=' + dataSection + ']');
    if (selectedItem.length === 0)
    {
        $('#schedule-modal .dropdown-item:first-child').addClass('active');
        $('#schedule-modal .btn-dropdown').text($('#schedule-modal .dropdown-item:first-child').text());
    }
    else
    {
        selectedItem.addClass('active');
        $('#schedule-modal .btn-dropdown').text(selectedItem.text());
    }

    $('.js_schedule-daily').hide();
    $('#schedule-all-at-once').trigger('click');
    window.scrollTo({ top: 0, behavior: 'smooth' });
    $('#schedule-modal').modal('toggle');

    $('#schedule-modal #js-pop-box-outer').attr('hidden', true);

    $('#js-filters-btn').removeClass('js-btn-popup-highlight');
});

$(document).on('click', '#js-choice-all-btn', function()
{
    $('#js-questions-btn').attr('hidden', true);
    $('#js-resources-btn').attr('hidden', true);
    hideModalPopBox(this);
    highlightScheduleModalPopBoxBtn(this);
});

$(document).on('click', '#js-choice-qn-btn', function()
{
    $('#js-questions-btn').attr('hidden', false);
    $('#js-resources-btn').attr('hidden', true);
    hideModalPopBox(this);
    highlightScheduleModalPopBoxBtn(this);
});

$(document).on('click', '#js-choice-res-btn', function()
{
    $('#js-questions-btn').attr('hidden', true);
    $('#js-resources-btn').attr('hidden', false);
    hideModalPopBox(this);
    highlightScheduleModalPopBoxBtn(this);
});

$(document).on('click', '#schedule-modal .js-modal-sect input[type="radio"]', function()
{
    $('#schedule-modal .js-modal-sect').removeClass('active');
    $(this)
        .parentsUntil(null, '.js-modal-sect')
        .addClass('active');
});

$(document).on('click', '#schedule-modal .js-btn-transient', function()
{
    $(this).attr('hidden', true);

    $('#schedule-modal .js-btn-choice').removeClass('js-btn-choice-highlight');
    $('#schedule-modal #js-choice-all-btn').addClass('js-btn-choice-highlight');
});

/**
 * Handles showing of date/time inputs when switching radio in schedule modal
 */
$(document).on('change', 'input[name=schedule-method]', function()
{
    let scheduleType = $('input[name=schedule-method]:checked').val();

    $('.js_schedule-daily input[name=send-time]').prop('disabled', scheduleType !== 'daily');
    $('.js_schedule-daily input[name=send-date]').prop('disabled', scheduleType !== 'daily');
    $('.js_schedule-daily').toggle(scheduleType === 'daily');

    $('.js_schedule-all input[name=send-time]').prop('disabled', scheduleType !== 'all');
    $('.js_schedule-all input[name=send-date]').prop('disabled', scheduleType !== 'all');
    $('.js_schedule-all').toggle(scheduleType === 'all');

    if (scheduleType === 'clear')
    {
        $('#js-scheduleBtn').text('Clear');
    }
    else
    {
        $('#js-scheduleBtn').text('Schedule');
    }
});

/**
 * Form responsible for updating schedules for all topics, a section or a topic.
 */
$(document).on('submit', '#class-schedule-form', function(e)
{
    e.preventDefault();
    let values = {};
    const targetEle = $('.js_schedule-target .dropdown-item.active');
    const currentSection = $('.section-list-item.active').data('section');

    values['_token'] = $('meta[name="csrf-token"]').attr('content');
    values['classId'] = $('.js-class-id').data('class-id');
    values['target'] = targetEle.data('bs-target');
    values['scheduleType'] = $('[name="schedule-method"]:checked').val();
    values['showFlash'] = 1;

    if (values['target'] === 'section')
    {
        values['sectionId'] = targetEle.data('section-id');
    }
    else if (values['target'] === 'topic')
    {
        values['topicId'] = targetEle.data('topic-id');
    }

    if (values['scheduleType'] !== 'clear')
    {
        let inputSelector = '';
        if (values['scheduleType'] === 'all')
        {
            inputSelector = '.js_schedule-all';
        }
        else if (values['scheduleType'] === 'daily')
        {
            inputSelector = '.js_schedule-daily';
        }

        const timezone = $('.js_class-timezone').attr('data-class-timezone');
        let now = new Date(new Date().toLocaleString('sv-SE', { timeZone: timezone }));
        let scheduleDate = $(`${inputSelector} [name="send-date"]`).val();
        let scheduleTime = $(`${inputSelector} [name="send-time"]`).val();

        const today = now.getFullYear() + '-' + String(now.getMonth() + 1).padStart(2, '0') + '-' + String(now.getDate()).padStart(2, '0');
        if ((scheduleDate != '' && scheduleDate < today) || scheduleDate.length !== 10)
        {
            Quitch.showMessage('warning', 'base.backdateError');
            scheduleDate = today;
        }
        if (scheduleTime.length !== 5)
        {
            Quitch.showMessage('warning', 'content.scheduleTimeError');
            scheduleTime = '08:00';
        }
        values['scheduledAt'] = scheduleDate + ' ' + scheduleTime;

        if (values['scheduleType'] === 'daily')
        {
            if (!$(`${inputSelector} [name="include-weekends"]`)[0].checked)
            {
                values['include-weekends'] = 1;
            }
            else
            {
                values['include-weekends'] = 0;
            }
        }
    }

    values['bScheduleQuestions'] = $('#schedule-modal #js-questions-btn').attr('hidden') === undefined ? 1 : 0;
    values['bScheduleResources'] = $('#schedule-modal #js-resources-btn').attr('hidden') === undefined ? 1 : 0;

    bulkScheduleContent(currentSection, values);
});

/**
 * Form responsible for updating schedule for a single question/resource
 */
$(document).on('submit', '#content-schedule-form', function(e)
{
    e.preventDefault();
    let values = {};
    const timezone = $('.js_class-timezone').attr('data-class-timezone');
    let now = new Date(new Date().toLocaleString('sv-SE', { timeZone: timezone }));
    const contentId = $('.manual-content-id').val();

    values['scheduleType'] = $('input[name=schedule-type]:checked').val();
    values['contentId'] = contentId;

    if (values['scheduleType'] === 'set')
    {
        const today = now.getFullYear() + '-' + String(now.getMonth() + 1).padStart(2, '0') + '-' + String(now.getDate()).padStart(2, '0');
        let scheduleDate = $('[name="individual-send-date"]').val();
        let scheduleTime = $('[name="individual-send-time"]').val();

        if (scheduleDate != '' && scheduleDate < today)
        {
            Quitch.showMessage('warning', 'base.backdateError');
            values['scheduledAt'] = today + ' ' + scheduleTime;
        }
        else
        {
            values['scheduledAt'] = scheduleDate + ' ' + scheduleTime;
        }
    }

    scheduleContent(values, resp =>
    {
        // Update content schedule status
        let inputWrappers = '.js_content-dates-' + contentId;

        if (values['scheduleType'] === 'set')
        {
            $(inputWrappers + ' .js_date-time').text(resp.localeDateTime);
            $(inputWrappers).data('date-time', values['scheduledAt']);

            let contentDateTime = new Date(values['scheduledAt']);

            if (contentDateTime <= now)
            {
                $(`ul[data-content-id="${contentId}"]`)
                    .parent()
                    .removeClass('content-unscheduled content-pending')
                    .addClass('content-sent');
                $(`ul[data-content-id="${contentId}"] li i`)
                    .removeClass('fa-calendar')
                    .addClass('fa-check');
            }
            else
            {
                $(`ul[data-content-id="${contentId}"]`)
                    .parent()
                    .removeClass('content-unscheduled content-sent')
                    .addClass('content-pending');
                $(`ul[data-content-id="${contentId}"] li i`)
                    .removeClass('fa-check')
                    .addClass('fa-calendar');
            }
        }
        else
        {
            $(inputWrappers + ' .js_date-time').text(trans('content.unscheduled'));
            $(inputWrappers + ' li:first-of-type i')
                .removeClass('fa-check')
                .addClass('fa-calendar');
            $(inputWrappers).attr('data-date-time', null);

            $(`ul[data-content-id="${contentId}"]`)
                .parent()
                .removeClass('content-pending content-sent')
                .addClass('content-unscheduled');
        }

        let schedule = $(`ul[data-content-id="${contentId}"]`);
        let contentParent = schedule.parents('.js_content-list-item');

        // copy schedule UI from resource to attached question
        if (contentParent.find('ol.js_content-list li').length > 0)
        {
            let scheduleState = schedule.parent('.accordion-parent').attr('class');

            contentParent.find('ol.js_content-list .question .accordion-parent').removeClass().addClass(scheduleState);
            contentParent.find('ol.js_content-list .question .js_update-content-date')
                .attr('data-date-time', schedule.attr('data-date-time'))
                .html(schedule.html());
        }

        Quitch.showSnackbar(transChoice('content.scheduledQuestionResource', resp.rowsUpdated), true);
        updateTopicScheduleStatus();
        $('#content-schedule-modal').modal('toggle');
    });
});

$(document).on('click', '.js_update-content-date', function(event)
{
    event.preventDefault();
    let el = $(this);
    // Open individual schedule modal if not nested
    if (el.parents('.accordion-child.js_content-list').length === 0)
    {
        $('[name="individual-send-date"]').val(el.data('date-time').split(' ')[0]);
        $('[name="individual-send-time"]').val(
            el
            .data('date-time')
            .split(' ')[1]
            .substring(0, 5)
        );
        $('.manual-content-id').val(el.data('content-id'));
        $('#set-schedule').trigger('click');
        $('#content-schedule-modal').modal('toggle');
    }
});

/**
 * Handles disabling of date time input when switching radio in question/resource schedule modal
 */
$('#content-schedule-form input[name=schedule-type]').change(function()
{
    let disabled = $('input[name=schedule-type]:checked').val() === 'clear';
    $('input[name=individual-send-time]').prop('disabled', disabled);
    $('input[name=individual-send-date]').prop('disabled', disabled);
});

/**
 * Ajax for bulk scheduling content
 */
function bulkScheduleContent(currentSection, values)
{
    $.ajax(
    {
        url: '/schedule',
        type: 'POST',
        dataType: 'json',
        data: values,
        success: function(response)
        {
            $('#schedule-modal').modal('toggle');
            // Stay in current section/topic after refresh
            Quitch.updateUrl({ section: currentSection }, 'ep-bulk-schedule-content-success');
            updateTopicScheduleStatus();
            updateClassPublishStatus('modified');
        },
        error: function()
        {
            $('#schedule-modal').modal('toggle');
            Quitch.showSnackbar(trans('content.bulkScheduleError'));
            telemetryTrackEvent('ep-bulk-schedule-content-error');
        },
    });
}

function highlightScheduleModalPopBoxBtn(highlightBtn)
{
    if (highlightBtn !== undefined && highlightBtn !== null)
    {
        $('#schedule-modal .js-btn-choice').removeClass('js-btn-choice-highlight');
        $(highlightBtn).addClass('js-btn-choice-highlight');
    }
}

/**
 * Ajax for scheduling individual content
 */
function scheduleContent(values, successCallback)
{
    values['_token'] = $('meta[name="csrf-token"]').attr('content');
    values['classId'] = $('.js-class-id').data('class-id');
    values['target'] = 'content';
    values['showFlash'] = 1;
    $.ajax(
    {
        url: '/schedule',
        type: 'POST',
        dataType: 'json',
        data: values,
        success: function(response)
        {
            successCallback(response);
            updateTopicScheduleStatus();
            updateClassPublishStatus('modified');
        },
        error: () =>
        {
            Quitch.showSnackbar(trans('content.scheduleError'), true);
        },
    });
}

export function toggleScheduleBtn()
{
    if ($('.js_section-content-display[data-type="topic"]').length > 0)
    {
        $('.js_schedule-btn').prop('disabled', false);
    }
    else
    {
        $('.js_schedule-btn').prop('disabled', true);
    }
}

function updateTopicScheduleStatus()
{
    const topicUnscheduledFlag = $('.topic-unscheduled-flag');
    if (topicUnscheduledFlag.length > 0)
    {
        $('.section-row').each(function()
        {
            let topicId = $(this).attr('topic-id');
            // show unscheduled flag if topic contains any unscheduled content
            if ($('ol.js_content-for-' + topicId).find('.content-unscheduled').length > 0)
            {
                $($(this).children('.topic-unscheduled-flag')[0]).show();
            }
            else
            {
                $($(this).children('.topic-unscheduled-flag')[0]).hide();
            }
        });
    }
}