import { trans, transChoice, updateGlobalMessageState } from '../../common';
import { Quitch } from '../../quitch';
import { updateInvitesCallToAction } from '../invitations';
import { updateCheckboxDropdown } from '../../form/dropdown';

/**
 * Initialise Canvas integration token edit modal
 */
$(document).on('click', '#js-canvas-integration-btn', function(e)
{
    Quitch.modal(
    {
        title: '',
        message: `
                          <div class="w-100 d-inline-flex justify-content-center align-items-center">
                            <img src="/img/canvas-icon-rounded.svg" class="me-2"/>
                            <i class="fa-solid fa-right-left"></i>
                            <img src="/img/quitch-icon-rounded.svg" class="ms-2"/>
                            </div>
                          <h1>${trans('dashboard.connectCanvas')}</h1>
                          <p class="body-medium wrap">${trans('dashboard.addCanvasToken')}</p>
                          <div id="js-canvas-token-edit" class="js-canvas-token-edit w-100 d-inline-flex flex-wrap justify-content-center align-items-center">
                            <i class="fa-solid fa-circle-notch fa-pulse"></i>
                          </div>
                          <label class="label-small wrap">${trans('dashboard.canvasHelp', {url: 'https://quitch.atlassian.net/servicedesk/customer/portal/1/article/1605533697'})}</label>`,
        type: 'alert-no-button',
        cssClass: 'modal-sm modal-dialog-centered js-canvas-token-modal',
        showClose: true
    });
});

/**
 * Check status of user's canvas token and update token input and button state on modal load
 */
$(document).on('shown.bs.modal', '#js_dialog-box:has(.js-canvas-token-modal)', function()
{
    $.ajax(
    {
        url: '/canvas/token/status',
        type: 'GET',
        dataType: 'json',
        success: function(response)
        {
            $('#js-canvas-token-edit').html(`
                                          <div class="js-canvas-token-input">
                                            <input class="form-control" type="text" placeholder="${trans('dashboard.canvasTokenPlaceHolder')}">
                                            <span class="input-group-append" style="display:none">
                                                <div class="input-group-text border-start-0 bg-transparent"></div>
                                            </span>
                                          </div>
                                         <button type="button" id="js-add-canvas-token-btn" class="btn btn-primary" data-telemetry-name="add-canvas-token-btn" disabled>${trans('dashboard.connect')}</button>`);
            if (response.token)
            {
                $('.js-canvas-token-input input').val(response.token);
                if (response.valid)
                {
                    updateCanvasTokenInputState('valid', 'remove');
                }
                else
                {
                    updateCanvasTokenInputState('error', 'remove');
                }
            }
        },
    });
});

$(document).on('change keyup paste', '#js-canvas-token-edit input', function()
{
    updateCanvasTokenInputState('initial', 'add');
    $('#js-add-canvas-token-btn,#js-remove-canvas-token-btn').prop('disabled', $(this).val().length < 1);
});

$(document).on('click', '#js-add-canvas-token-btn', function(e)
{
    e.preventDefault();
    updateCanvasTokenInputState('loading');
    $.ajax(
    {
        url: '/canvas/token/add',
        type: 'POST',
        dataType: 'json',
        data: { _token: $('meta[name="csrf-token"]').attr('content'), canvasToken: $('.js-canvas-token-input input').val() },
        success: function(response)
        {
            updateCanvasTokenInputState('valid', 'remove');
            $('#js_dialog-box').modal('hide');
            Quitch.showSnackbar(trans('dashboard.canvasConnected'));
        },
        error: function(e, t, s)
        {
            updateCanvasTokenInputState('error', 'add');
        },
    });
});

$(document).on('click', '#js-remove-canvas-token-btn', function(e)
{
    e.preventDefault();
    updateCanvasTokenInputState('loading');
    $.ajax(
    {
        url: '/canvas/token/remove',
        type: 'POST',
        dataType: 'json',
        data: { _token: $('meta[name="csrf-token"]').attr('content') },
        success: function(response)
        {
            $('.js-canvas-token-input input').val('');
            updateCanvasTokenInputState('initial', 'add');
        },
    });
});

/**
 * Initialise Canvas invite import modal
 */
$(document).on('shown.bs.modal', '#canvas-invite-import-modal', function()
{
    toggleCanvasLoader(true);
    $('#canvas-import-error').hide();
    $('#js-canvas-invites-table-container').html(`<table id="js-canvas-invites-table" class="table table-hover w-100"></table>`);
    $('.js-courses-dropdown button').text(trans('invites.course'));
    $('.js-sections-dropdown button').text(trans('invites.section'));
    $('.js-sections-dropdown button').prop('disabled', true);
    $('.js-courses-dropdown input').val('');
    $('.js-courses-dropdown .dropdown-menu').html('<div class="inner-scroll"></div>');
    $('.js-sections-dropdown .dropdown-menu .inner-scroll').empty();
    $('.learners-selected').text(transChoice('invites.learnersSelected', 0));
    $('#js-canvas-invite-import-btn').prop('disabled', true);

    $.ajax(
    {
        url: '/canvas/courses',
        type: 'GET',
        dataType: 'json',
        data: { classId: $('.js_class-manager').data('class-id') },
        success: function(response)
        {
            let className = $('.topnav-class-title').text().trim();
            let dropdownMenu = $('.js-courses-dropdown .dropdown-menu .inner-scroll');
            let dropdownListHtml = '';
            let recommendedCourses = [];
            let otherCourses = [];
            let hideCourses = false;

            if (response.recommended.id)
            {
                recommendedCourses.push(response.recommended);
            }

            $.each(response.courses, function(key, course)
            {
                if (!recommendedCourses.some(x => x.id === course.id))
                {
                    if (course.name.toLowerCase().indexOf(className.toLowerCase()) !== -1)
                    {
                        recommendedCourses.push(course);
                    }
                    else
                    {
                        otherCourses.push(course);
                    }
                }
            });

            if (recommendedCourses.length > 0)
            {
                dropdownListHtml += `<div class="dropdown-header text-capitalize label-medium">${trans('invites.recommended')}</div>`;

                $.each(recommendedCourses, function(i, course)
                {
                    dropdownListHtml += `<a data-bs-target="#" class="dropdown-item" data-canvas-course-id="${course.id}"
                                    data-bs-toggle="tooltip" title="${course.name}">${course.name}</a>`;
                });

                if (otherCourses.length > 0)
                {
                    hideCourses = true;
                    dropdownListHtml += `<div class="dropdown-divider" style="display: none;"></div>`;
                }
            }

            $.each(otherCourses, function(i, course)
            {
                dropdownListHtml += `<a data-bs-target="#" class="dropdown-item js-other-courses" data-canvas-course-id="${course.id}"
                                    data-bs-toggle="tooltip" data-bs-placement="right" title="${course.name}"
                                    ${hideCourses ? 'style="display: none;"' : ''}>${course.name}</a>`;
            });

            if (hideCourses)
            {
                // TODO migrate to use js-show-all-toggle instead of js-show-courses-toggle
                $(`<a class="dropdown-header dropdown-header-btn js-show-courses-toggle" data-type="show">${trans('invites.showAll')}</a>`).insertAfter(dropdownMenu);
            }

            dropdownMenu.html(dropdownListHtml);
            $('#canvas-invite-import-modal .toolbar').show();
            toggleCanvasLoader(false);
        },
        error: function(xhr, status, error)
        {
            toggleCanvasLoader(false);
            Quitch.showCallToAction('#canvas-import-error', 'canvas-course-error',
            {
                type: 'error',
                title: xhr.responseJSON.title,
                description: xhr.responseJSON.message,
                replace: true,
                showDismiss: false,
                dismissCallback: false,
                actionText: xhr.responseJSON.action ? xhr.responseJSON.action : false
            });
            $('#canvas-import-error').show();
        }
    });
});

/**
 * Prevent Canvas courses dropdown menu from closing on header click
 */
$(document).on('click', '.js-courses-dropdown .dropdown-menu .dropdown-header', function(e)
{
    e.stopPropagation();
});

/**
 * Toggle showing of non-recommended Canvas courses
 */
$(document).on('click', '.js-show-courses-toggle', function(e)
{
    $('.js-other-courses').toggle($(this).attr('data-type') === 'show');
    if ($(this).attr('data-type') === 'show')
    {
        $(this).text(trans('invites.showLess')).attr('data-type', 'hide');
        $('.js-other-courses').show();
        $('.js-courses-dropdown .dropdown-divider').show();
    }
    else
    {
        $(this).text(trans('invites.showAll')).attr('data-type', 'show');
        $('.js-other-courses').hide();
        $('.js-courses-dropdown .dropdown-divider').hide();
    }
});

function clearSectionsDropdown()
{
    $('#canvas-import-error').hide();
    $('#js-canvas-invites-table-container').hide();
    $('.js-sections-dropdown button').text(trans('invites.section')).removeClass('empty');
    $('.js-sections-dropdown').removeClass('checkbox-count');
    $('.js-sections-dropdown .dropdown-menu .inner-scroll').empty();
}

/**
 * Clear sections dropdown on focusout
 */
$(document).on('change', '.js-courses-dropdown input', function()
{
    clearSectionsDropdown();
    $('.js-sections-dropdown button').attr('disabled', true);
});

/**
 * Scroll cursor to end of input on focus
 */
$(document).on('focus', '.js-courses-dropdown input', function()
{
    let self = this;
    setTimeout(function()
    {
        self.selectionStart = self.selectionEnd = self.value.length;
        self.scrollLeft = self.scrollWidth;
    }, 0);
});

/**
 * Update sections in dropdown on course select
 */
$(document).on('click', '.js-courses-dropdown .dropdown-item:not(.active)', function()
{
    clearSectionsDropdown();
    toggleCanvasLoader(true, trans('invites.fetchingCanvasLearners'));
    let token = $('meta[name="csrf-token"]').attr('content');
    let classId = $('.classId').attr('class-id');
    let courseId = $(this).attr('data-canvas-course-id');

    let values = {
        _token: token,
        classId: classId,
        canvasCourseId: courseId,
    };
    $.ajax(
    {
        url: '/canvas/stage-invites',
        type: 'POST',
        dataType: 'json',
        data: values,
        success: function(response)
        {
            if (response.canvasSections)
            {
                let dropdownListHtml = '';
                for (const [id, name] of Object.entries(response.canvasSections))
                {
                    dropdownListHtml += `<div class="dropdown-item" data-bs-toggle="tooltip" data-bs-placement="right" title="${name}">
                                        <div class="form-check custom-checkbox">
                                            <input id="canvas-section-chk${id}" class="form-check-input js-canvas-section-checkbox" type="checkbox"
                                            data-canvas-section-id="${id}" data-canvas-section-name="${name}">
                                            <label class="form-check-label custom-input-label" for="canvas-section-chk${id}">
                                                <div class="ellipsis">${name}</div>
                                            </label>
                                        </div>
                                    </div>`;
                }
                $('.js-sections-dropdown .dropdown-menu .inner-scroll').html(dropdownListHtml);
            }
            $('#js-canvas-invite-import-btn').attr('data-job-id', response.jobId);
            updateCanvasTableOnJobComplete(response.jobId, classId, token, 500);
        },
        error: function(xhr, status, error)
        {
            toggleCanvasLoader(false);
            Quitch.showCallToAction('#canvas-import-error', 'canvas-course-error',
            {
                type: 'error',
                title: xhr.responseJSON.title,
                description: xhr.responseJSON.message,
                replace: true,
                showDismiss: false,
                dismissCallback: false,
                actionText: xhr.responseJSON.action ? xhr.responseJSON.action : false
            });
            $('#canvas-import-error').show();
        }
    });
});

/**
 * Update table to list learners to import from Canvas sections
 */
$(document).on('hidden.bs.dropdown', '.js-sections-dropdown', function()
{
    $('#js-canvas-invites-table-container').hide();
    $('#js-canvas-invite-import-btn').prop('disabled', true);
    $('#dropdown-sections-btn').attr('disabled', true).attr('data-reset-selection', true);

    toggleCanvasLoader(true);
    loadCanvasImportTable('/canvas/list-staged-invites');
});

function updateCanvasTableOnJobComplete(jobId, classId, token, timeout = 500)
{
    // check modal is still open
    if ($('#canvas-invite-import-modal.show').length !== 1)
    {
        return;
    }
    $.ajax(
    {
        url: '/jobs/get-status/' + jobId,
        type: 'GET',
        dataType: 'json',
        success: function(response)
        {
            if (response.job.status === 'created' || response.job.status === 'running')
            {
                window.setTimeout(function()
                {
                    if (response.job.meta && response.job.meta.staged !== '-' && response.job.meta.staged > 0)
                    {
                        toggleCanvasLoader(true, trans('invites.fetchedCanvasLearners', { count: response.job.meta.staged }));
                    }
                    updateCanvasTableOnJobComplete(jobId, classId, token, Math.min(timeout * 1.5, 4000));
                }, timeout);
            }
            else if (response.job.status === 'completed')
            {
                if (response.job.meta.sections && response.job.meta.sections.length > 0)
                {
                    $('.js-sections-dropdown .dropdown-item:not(.select-all-item)').hide();
                    for (const sectionId of response.job.meta.sections)
                    {
                        $(`#canvas-section-chk${sectionId}`).attr('checked', 'checked').parents('.dropdown-item').show();
                    }

                    updateCheckboxDropdown($('.js-sections-dropdown'));
                }
                else
                {
                    $('#dropdown-sections-btn').text('No sections').prop('disabled', true).addClass('empty');
                }
                loadCanvasImportTable('/canvas/list-staged-invites');
            }
            else if (response.job.status === 'failed')
            {
                showCanvasGenericError();
            }
        },
        error: function(error)
        {
            if (timeout >= 1000)
            {
                window.setTimeout(function()
                {
                    updateCanvasTableOnJobComplete(jobId, classId, token, timeout * 1.5);
                }, timeout);
            }
            else
            {
                showCanvasGenericError();
            }
        },
    });
}

function toggleCanvasLoader(show = true, msg = '')
{
    const loader = $('#canvas-invite-import-modal .loader');
    loader.find('.msg').text(msg);
    loader.toggle(show);
}

function showCanvasGenericError()
{
    $('#canvas-invite-import-modal .toolbar').hide();
    toggleCanvasLoader(false);
    Quitch.showCallToAction('#canvas-import-error', 'canvas-staged-error',
    {
        type: 'error',
        title: 'invites.somethingWentWrong',
        description: 'invites.canvasGenericError',
    });
    $('#canvas-import-error').show();
}

function loadCanvasImportTable(url)
{
    if ($.fn.dataTable.isDataTable('#js-canvas-invites-table'))
    {
        // just refresh data if table exists
        $('#js-canvas-invites-table')
            .DataTable()
            .ajax.reload(null, false);
        return;
    }

    let jsTable = $('#js-canvas-invites-table').DataTable(
    {
        ajax:
        {
            url: url,
            type: 'POST',
            data: function(d)
            {
                let values = {
                    _token: $('meta[name="csrf-token"]').attr('content'),
                    classId: $('.classId').attr('class-id'),
                    start: d['start'],
                    length: d['length'],
                    search: d['search'],
                };

                let sections = [];
                $('.js-sections-dropdown').find('input[type=checkbox]:checked').each(function()
                {
                    sections.push($(this).attr('data-canvas-section-name'));
                });
                if (sections.length > 0)
                {
                    values.sections = sections;
                }

                values.resetSelection = $('#dropdown-sections-btn').attr('data-reset-selection');
                $('#dropdown-sections-btn').removeAttr('data-reset-selection');

                return values;
            }
        },
        drawCallback: function(data)
        {
            toggleCanvasLoader(false);
            let learnerCount = parseInt(data.json.learnersSelected) || 0;
            $('#js-canvas-invite-import-btn').prop('disabled', learnerCount < 1);

            if ($('.learners-selected').length == 1)
            {
                $('.learners-selected').text(transChoice('invites.learnersSelected', learnerCount));
            }
            else
            {
                $('#js-canvas-invites-table_filter').prepend(`<div class="my-auto">
                            <p class="learners-selected">${transChoice('invites.learnersSelected', learnerCount)}</p>
                        </div>
                        <div class="ms-3">
                            <button type="button" class="btn btn-secondary js-toggle-canvas-invites" data-option="deselect-all">${trans('invites.deselectAll')}</button>
                        </div>`);
            }
            $('#js-canvas-invites-table-container').show();
            $('#dropdown-sections-btn:not(.empty)').prop('disabled', false);
        },
        language:
        {
            info: trans('invites.tableEntries', { start: '_START_', end: '_END_', total: '_TOTAL_' }),
            infoFiltered: '',
            search: '',
            searchPlaceholder: trans('base.search'),
            lengthMenu: trans('reporting.lengthMenuInvites', { menu: '_MENU_' }),
            zeroRecords: trans('invites.tableEmpty'),
            infoEmpty: '',
            paginate:
            {
                next: '<i class="fa fa-angle-right"></i>',
                previous: '<i class="fa fa-angle-left"></i>',
            },
        },
        serverSide: true,
        searching: true,
        dom: 'frt<"col-lg-8"i><"col-lg-4"p>',
        pagingType: 'simple',
        targets: 'no-sort',
        order: [],
        pageLength: 10,
        autoWidth: false,
        columnDefs: [
            { targets: [0], width: '10px' },
            { targets: [1], width: '30%' },
            { targets: [2], width: '35%' },
            { targets: [3], width: '15%' },
            { targets: [4], width: '20%' },
            { targets: '_all', orderable: false, visible: true },
        ],
        columns: [
            {
                data: 'actions',
                title: `
                            <div class="dropdown">
                            <i class="fas fa-chevron-down" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                                <div class="dropdown-menu float-right custom-dropdown-list">
                                    <a href="#" class="js-toggle-canvas-invites dropdown-item" data-option="select-visible">${trans('invites.selectVisible')}</a>
                                    <a href="#" class="js-toggle-canvas-invites dropdown-item" data-option="select-all">${trans('invites.selectAll')}</a>
                                    <a href="#" class="js-toggle-canvas-invites dropdown-item" data-option="deselect-all">${trans('invites.deselectAll')}</a>
                                </div>
                            </div>`,
                render: function(data, type, row)
                {
                    return `<div class="form-check custom-checkbox">
                                        <input id="${row.id}inviteChk" class="js-toggle-canvas-invites js-canvas-invite-checkbox form-check-input" data-id="${row.id}" data-option="toggle" type="checkbox" ${row.checked ? 'checked' : ''}>
                                        <label class="form-check-label" for="${row.id}inviteChk"></label>
                                    </div>`;
                },
            },
            { data: 'name', title: trans('invites.name') },
            { data: 'email', title: trans('invites.tableEmail') },
            { data: 'externalReference', title: trans('invites.externalId') },
            { data: 'externalGroup', title: trans('invites.section') },
        ],
    });
}

// Canvas invite import modal cleanup
$(document).on('hidden.bs.modal', '#canvas-invite-import-modal', function()
{
    $('#canvas-invite-import-modal .toolbar').hide();
    $('#js-canvas-invites-table-container').hide();
    $('#canvas-import-error').hide();
});

/**
 * Handling the selecting and deselecting of Canvas learners to import
 */
$(document).on('click', '.js-toggle-canvas-invites', function()
{
    let token = $('meta[name="csrf-token"]').attr('content');
    let classId = $('.classId').attr('class-id');
    let option = $(this).attr('data-option');
    let type = '';
    let checked = 0;
    let ids = [];
    switch (option)
    {
        case 'deselect-all':
            type = 'all';
            checked = 0;
            break;
        case 'select-all':
            type = 'all';
            checked = 1;
            break;
        case 'select-visible':
            type = 'selection';
            $('.js-canvas-invite-checkbox').each(function(i, el)
            {
                ids.push($(el).attr('data-id'));
            });
            checked = 1;
            break;
        case 'toggle':
            type = 'selection';
            ids = [$(this).attr('data-id')];
            checked = $(this).attr('checked') ? 0 : 1;
            break;
        default:
            return;
    }
    let values = {
        _token: token,
        classId: classId,
        type: type,
        ids: ids,
        checked: checked
    };
    $.ajax(
    {
        url: '/canvas/toggle-invites',
        type: 'POST',
        dataType: 'json',
        data: values,
        success: function(response)
        {
            $('#js-canvas-invites-table')
                .DataTable()
                .ajax.reload(null, false);
        },
    });
});

/**
 * Prompt to send email invitations for Canvas learner import then start bulk import job
 */
$(document).on('click', '#js-canvas-invite-import-btn', function()
{
    $('#canvas-invite-import-modal').modal('hide');

    let values = {
        _token: $('meta[name="csrf-token"]').attr('content'),
        classId: $('.classId').attr('class-id'),
        jobId: $(this).attr('data-job-id')
    };

    $.ajax(
    {
        url: '/canvas/import-invites',
        type: 'POST',
        dataType: 'json',
        data: values,
        success: function(response)
        {
            Quitch.showCallToAction('#js-invites-cta', 'import-in-progress',
            {
                type: 'info',
                title: 'invites.importInProgress',
                description: 'invites.importInProgressDesc',
            });
            updateInvitesCallToAction(response.jobId, 500);
        },
    });
});

$(document).on('click', '.js-canvas-section-select-all', function()
{
    let isChecked = $(this).prop('checked');

    // Toggle all visible section checkboxes based on the "Select All" state
    $('.js-canvas-section-checkbox').each(function()
    {
        if ($(this).closest('.dropdown-item').css('display') !== 'none')
        {
            $(this).prop('checked', isChecked);
        }
    });

    // Update the dropdown button text and state
    updateCheckboxDropdown($('.js-sections-dropdown'));
});

$(document).on('click', '.js-canvas-section-checkbox', function()
{
    // Update the "Select All" checkbox state based on the checked state of visible checkboxes
    let allVisibleChecked = true; // Assume all visible checkboxes are checked

    $('.js-canvas-section-checkbox').each(function()
    {
        if ($(this).closest('.dropdown-item').css('display') !== 'none' && !$(this).prop('checked'))
        {
            allVisibleChecked = false; // Found an unchecked checkbox
        }
    });

    // Set the "Select All" checkbox state
    $('.js-canvas-section-select-all').prop('checked', allVisibleChecked);

    // Update the dropdown button text and state
    updateCheckboxDropdown($('.js-sections-dropdown'));
});


export function initialiseCanvas()
{
    if ($('#js-canvas-cta').length)
    {
        Quitch.showCallToAction('#js-canvas-cta', 'js-canvas-cta-info',
        {
            type: 'info',
            title: '',
            description: 'invites.canvasCta',
            showDismiss: true,
            dismissCallback: hideTryCanvasGlobalMessage,
            actionText: 'invites.learnMore',
            actionCallback: () => { $('#js-canvas-integration-btn').trigger('click'); }
        });
    }
}


function hideTryCanvasGlobalMessage()
{
    updateGlobalMessageState('tryCanvas', 'hide');
}

/**
 * Update Canvas token input and button styling based on state and type
 * @param state
 * @param btnType
 */
export function updateCanvasTokenInputState(state, btnType = null)
{
    let tokenInputEl = $('.js-canvas-token-input');
    let btnDisabled = false;
    $('.js-canvas-token-modal .error-msg').remove();
    if (!$('#js-canvas-invite-import-modal-btn').attr('data-org-has-token'))
    {
        $('#js-canvas-invite-import-modal-btn').toggle(state === 'valid');
    }
    switch (state)
    {
        case 'valid':
            tokenInputEl.find('.input-group-text').html(`<i class="fa-solid fa-circle-check"></i>`);
            tokenInputEl.removeClass('loading').addClass('input-group valid');
            tokenInputEl.find('.input-group-append').show();
            btnDisabled = false;
            break;
        case 'error':
            tokenInputEl.find('.input-group-text').html(`<i class="fa-solid fa-circle-xmark"></i>`);
            tokenInputEl.removeClass('loading').addClass('input-group error');
            tokenInputEl.find('.input-group-append').show();
            $(`<small class="error-msg">${trans('dashboard.canvasTokenError')}</small>`).insertAfter('.js-canvas-token-edit');
            btnDisabled = false;
            break;
        case 'loading':
            tokenInputEl.find('.input-group-text').html(`<i class="fa-solid fa-circle-notch fa-pulse"></i>`);
            tokenInputEl.removeClass('valid error');
            tokenInputEl.addClass('input-group loading');
            tokenInputEl.find('.input-group-append').show();
            btnDisabled = true;
            break;
        default:
            tokenInputEl.removeClass('input-group valid error loading');
            tokenInputEl.find('.input-group-append').hide();
            btnDisabled = false;
    }

    if (btnType === 'add')
    {
        $('#js-remove-canvas-token-btn').removeClass('btn-secondary').addClass('btn-primary')
            .attr('id', 'js-add-canvas-token-btn').text(trans('dashboard.connect'));
    }
    else if (btnType === 'remove')
    {
        $('#js-add-canvas-token-btn').removeClass('btn-primary').addClass('btn-secondary')
            .attr('id', 'js-remove-canvas-token-btn').text(trans('dashboard.remove'));
    }
    else if (btnType === 'disabled')
    {
        btnDisabled = true;
    }

    $('#js-add-canvas-token-btn,#js-remove-canvas-token-btn,.js-canvas-connect-btn').prop('disabled', btnDisabled);
}