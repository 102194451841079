import Cookies from 'js-cookie';
import { showContentAuthoringFirstTimeModal } from './onboarding';
import { getQueryParameter, trans } from '../../common';
import { Quitch } from '../../quitch';
import
{
    selectContent,
    scrollToContent,
    validateAllContent,
    getPrevContentId,
    validateAndUpdateQuestionIncompleteFlag,
    validateAndUpdateResourceIncompleteFlag,
    updateTopicIncompleteCounter,
    addCharacterLimitCounter,
    trimWhitespaceAndBreaks
}
from './common';
import { createPlaceholders, updatePlaceholders, validateQuestion } from './question';
import { telemetryTrackAndPerformUrlChange } from '../../superuser/mixpanel';
import { updateQuestionFromAnswers } from '../content-authoring-old/questions';
import { loadPreview } from './preview';
import { initialiseSchedulePublish, setPublishBtnState, updatePublishBtn } from './schedule-publish';
import { showFeedbackModal } from '../feedback-modal';
import { createTopic } from './topic';
import { initialiseTooltip } from '../../../system';
import { ajaxSetDragAndDropQuestion } from './drag-drop';

export function initialiseContentAuthoring()
{
    if ($('.content-authoring-wrapper .content-authoring').length === 0)
    {
        //not on content authoring page
        if ($('#class_content').length > 0) //TODO remove old content authoring check when it is phased out
        {
            //on old content authoring page
            showContentAuthoringFirstTimeModal();
            rememberContentAuthoring();
        }
        return;
    }

    rememberContentAuthoring();
    showContentAuthoringFirstTimeModal();
    initialiseSchedulePublish();
    validateAllContent();

    // Select first content in topic/section if query parameter set
    const selectedTopicId = getQueryParameter('section');
    if (selectedTopicId)
    {
        // Remove query parameter so it doesn't re-select the content on refresh
        const url = new URL(window.location.href);
        history.pushState({}, null, url.pathname);

        const firstTopicContent = $(`.content-authoring-topic[data-id=${selectedTopicId}] .content-authoring-content,
                                         .content-authoring-section[data-id=${selectedTopicId}] .content-authoring-content`).first();
        if (firstTopicContent.length > 0)
        {
            selectContent(firstTopicContent, true, true);
        }
    }
    else
    {
        if ($('.content-authoring-content.selected').length > 0)
        {
            selectContent($('.content-authoring-content.selected'));
        }
        else if ($('.content-authoring-topic .content-authoring-content').length > 0)
        {
            selectContent($('.content-authoring-topic .content-authoring-content').first());
        }
    }

    initialiseDragDropQuestions();
    updateTopicChevronStyling();
    updatePublishBtn();
}

function rememberContentAuthoring()
{
    let version = 'classic';
    if ($('.content-authoring-wrapper .content-authoring').length > 0)
    {
        version = 'new';
    }

    Cookies.set('last-content-authoring-visited', version);
}

export function updateTopicChevronStyling()
{
    if ($('.js-expand-topic').length < 2)
    {
        $('.js-expand-topic').addClass('disabled');
    }
    else
    {
        $('.js-expand-topic').removeClass('disabled');
    }
}

function initialiseDragDropQuestions()
{
    const dragDropQuestions = $('.content-authoring-content.question[data-content-type="fill-in-the-blanks"]');
    dragDropQuestions.each(function(i, item)
    {
        const questionInput = $(item).find('.content-title .input-field')[0];

        const answerInputs = $(item).find('.option-item .title.input-field');
        updateQuestionFromAnswers(questionInput, answerInputs, null, $(item).attr('data-schedule-status') !== 'draft');
    });

    loadPreview();
}

$(document).on('change', '#content-ui-toggle', function(e)
{
    window.location = $(this).data('redirect-url');
});

$(document).on('click', '.content-authoring-content.collapsed:not(.edit)', function()
{
    if ($(event.target).parents('.batch-checkbox, .content-dropdown-menu').length > 0)
    {
        return;
    }

    selectContent($(this));
});

$(document).on('show.bs.dropdown', '.js-toolbar-dropdown', function(e)
{
    const contentEle = $(this).parents('.content-authoring-content');
    selectContent(contentEle);

    if (contentEle.hasClass('newly-added'))
    {
        contentEle.removeClass('newly-added');
        contentEle.find('.newly-added').removeClass('newly-added');

        if (contentEle.hasClass('question'))
        {
            validateAndUpdateQuestionIncompleteFlag(contentEle);
        }
        else
        {
            validateAndUpdateResourceIncompleteFlag(contentEle);
        }

        updateTopicIncompleteCounter(contentEle.parents('.content-authoring-topic'));
    }

    const schedulePublishBtn = contentEle.find('.js-schedule-publish.dropdown-item');

    if (contentEle.attr('data-schedule-status') === 'draft')
    {
        schedulePublishBtn.show();
        const hasIncomplete = contentEle.find('.incomplete.active').length > 0;
        if (hasIncomplete)
        {
            schedulePublishBtn.attr('data-bs-toggle', 'tooltip').attr('data-bs-placement', 'right').attr('title', trans('content.cantPublishIncomplete'));
            initialiseTooltip(schedulePublishBtn);
            schedulePublishBtn.addClass('disabled');
        }
        else
        {
            schedulePublishBtn.removeAttr('data-bs-toggle').removeAttr('data-bs-placement').removeAttr('title');
            schedulePublishBtn.removeClass('disabled');
        }
    }
    else
    {
        schedulePublishBtn.hide();
    }
});

$(document).on('focusin', '.content-authoring-content .content-title .input-field', function(e)
{
    $(this).parents('.content-title').addClass('active');
});

$(document).on('click', '.content-authoring-content .content-title', function(e)
{
    $(this).find('.input-field').focus();
});

$(document).on('focusout', '.content-authoring-content.question .content-title .input-field', function(e)
{
    $(this).parents('.content-title').removeClass('active');
    const questionEle = $(this).parents('.content-authoring-content');
    const dataContentType = questionEle.attr('data-content-type');
    const isDragDrop = dataContentType === 'fill-in-the-blanks';

    if (isDragDrop)
    {
        ajaxSetDragAndDropQuestion(questionEle);
    }
    else
    {
        let title = $(this).html().trim();
        const oldTitle = title;
        const titleEle = questionEle.find('.content-title');
        const currentValue = $(this).attr('data-current-val');
        if (currentValue === title)
        {
            // Put error back if input didn't change
            if ($(this).hasClass('had-error') || $(this).parents('.content-title').hasClass('had-error'))
            {
                validateQuestion(questionEle);
            }
            return;
        }
        else if (titleEle.text().trim().length === 0)
        {
            title = '';
            titleEle.find('.input-field').html('').attr('data-current-val', '');
        }
        else
        {
            title = trimWhitespaceAndBreaks($(this));
        }

        if (title !== oldTitle)
        {
            loadPreview(questionEle);
        }

        let values = {
            _token: $('meta[name="csrf-token"]').attr('content'),
            assessmentId: questionEle.attr('data-content-id'),
            classId: $('[name=classId]').val(),
            title: title,
        };

        $.ajax(
        {
            url: '/content/authoring/question/edit',
            type: 'POST',
            dataType: 'json',
            data: values,
            success: function(response)
            {
                const inputField = titleEle.find('.input-field');
                inputField.attr('data-current-val', response.question.title);
            },
        });
    }

    validateAndUpdateQuestionIncompleteFlag(questionEle);
    updateTopicIncompleteCounter(questionEle.parents('.content-authoring-topic'));
});

$(document).on('change', '.content-authoring-content .js-toggle-correct', function(e)
{
    const optionEle = $(this).parents('.option-item');
    if (optionEle.attr('data-option-id') === 'no-id')
    {
        let isCorrect = $(this).is(':checked') ? 1 : 0;
        let questionEle = optionEle.parents('.content-authoring-content');
        updateToggles(optionEle, questionEle, isCorrect);
        addNewOptionAjax(optionEle);
    }
    else
    {
        updateOption(optionEle);
    }
});

$(document).on('click', '.content-authoring-content .js-delete-option', function(e)
{
    let tooltip = window.bootstrap.Tooltip.getInstance(this);
    if (tooltip)
    {
        tooltip.dispose();
    }
    const optionEle = $(this).parents('.option-item');
    const questionEle = optionEle.parents('.content-authoring-content.question');
    if (optionEle.attr('data-option-id') === 'no-id')
    {
        optionEle.remove();
        return;
    }
    const optionId = optionEle.attr('data-option-id');
    let values = {
        _token: $('meta[name="csrf-token"]').attr('content'),
        optionId: optionId,
        classId: $('[name=classId]').val()
    };

    if (optionEle.siblings('.option-item').length === 0)
    {
        return;
    }

    $.ajax(
    {
        url: '/content/authoring/option/delete',
        type: 'POST',
        dataType: 'json',
        data: values,
        success: function(response)
        {
            // hide the delete button if there is only one option left
            if (optionEle.siblings('.option-item').length === 1)
            {
                let lastOption = optionEle.siblings('.option-item').first();
                optionEle.remove();
                lastOption.find('.js-delete-option').hide();
            }
            else
            {
                optionEle.next('.error-msg').remove();
                optionEle.remove();
            }

            validateAndUpdateQuestionIncompleteFlag(questionEle);
            updateTopicIncompleteCounter(questionEle.parents('.content-authoring-topic'));
            updatePlaceholders(questionEle);
            loadPreview();
            setPublishBtnState('modified');
        },
    });
});

$(document).on('click', '.content-authoring-content .option-container .option-control span', function(e)
{
    // prevent the clicking of the toggle from triggering option text focus
    e.stopImmediatePropagation();
});

$(document).on('focusin', '.content-authoring-content .option-container .title', function(e)
{
    $(this).parents('.option-item').addClass('active');
});

$(document).on('click', '.content-authoring-content .option-container', function(e)
{
    $(this).find('.title').focus();
});

$(document).on('focusout', '.content-authoring-content .option-container .title', function(e)
{
    const optionTitle = $(this);
    const optionEle = optionTitle.parents('.option-item');
    setTimeout(function()
    {
        if (optionEle.find('.js-delete-option').is(':focus')) // Clicked on delete btn
        {
            return;
        }

        if (optionEle.find('.toggle-button').is(':focus')) // Clicked on toggle btn which will cause the answer to be saved anyway
        {
            return;
        }

        optionEle.removeClass('active');

        if (optionTitle.parents('.option-item').attr('data-option-id') === 'no-id')
        {
            addNewOptionAjax(optionEle);
            return;
        }

        if (optionTitle.attr('data-current-val') === optionTitle.html() && !optionEle.hasClass('error') && !optionEle.hasClass('had-error'))
        {
            return;
        }

        const contentContainer = optionTitle.parents('.content-authoring-content');
        const questionInput = contentContainer.first().find('.content-title .input-field')[0];

        const answerInputs = optionTitle.parents('.content-authoring-content').first().find('.option-item .title.input-field');

        if (contentContainer.data('content-type') === 'fill-in-the-blanks')
        {
            updateQuestionFromAnswers(questionInput, answerInputs, null);
            if (optionEle.hasClass('correct-option'))
            {
                ajaxSetDragAndDropQuestion(contentContainer);
            }
            else
            {
                updateOption(optionEle);
            }
        }
        else
        {
            updateOption(optionEle);
        }
    }, 10);
});

$(document).on('keyup', '.content-authoring-section-heading .content-input:not(.error), .topic-status-container .content-input:not(.error)', function(e)
{
    addCharacterLimitCounter($(this), 150);
});

$(document).on('keyup', '.content-authoring-content.question .content-title.content-input:not(.error)', function(e)
{
    addCharacterLimitCounter($(this), 500);
});

$(document).on('keyup', '.content-authoring-content.resource .content-title.content-input:not(.error)', function(e)
{
    addCharacterLimitCounter($(this), 150);
});

$(document).on('keyup', '.content-authoring-content.resource .resource-description:not(.error)', function(e)
{
    addCharacterLimitCounter($(this), 2000);
});

$(document).on('keyup', '.content-authoring-content .option-item .option-input:not(.error)', function(e)
{
    addCharacterLimitCounter($(this), 300);
});

/**
 * Update existing character counter
 */
$(document).on('keyup', '.content-authoring-content .content-input.error, .content-authoring-content .option-input.error, ' +
    '.content-authoring-section-heading .content-input.error, .topic-status-container .content-input.error',
    function(e)
    {
        if ($(this).next().is('.error-msg.has-counter'))
        {
            const counter = $(this).next().find('.counter');
            const length = $(this).find('.input-field').text().trim().length;
            if (length > counter.attr('data-max-length'))
            {
                counter.find('.count').text(length);
            }
            else
            {
                $(this).addClass('had-error');
                $(this).removeClass('error');
                $(this).next().remove();
            }
        }
    });

function addNewOptionAjax(optionEle)
{
    const questionEle = optionEle.parents('.content-authoring-content');
    const title = optionEle.find('.title.input-field').html().trim();
    let values = {
        _token: $('meta[name="csrf-token"]').attr('content'),
        assessmentId: questionEle.attr('data-content-id'),
        classId: $('[name=classId]').val(),
        title: title,
        isCorrect: optionEle.find('.js-toggle-correct').is(':checked') ? 1 : 0
    };

    $.ajax(
    {
        url: '/content/authoring/option/edit',
        type: 'POST',
        dataType: 'json',
        data: values,
        success: function(response)
        {
            optionEle.replaceWith(response.optionHtml);
            updatePlaceholders(questionEle);

            initialiseSchedulePublish();

            // prevent reshuffling
            const renderedShuffledAnswers = $('.question-preview').attr('data-rendered-shuffled-answers');
            $('.question-preview').attr('data-rendered-shuffled-answers', renderedShuffledAnswers.replace('no-id', response.option.id));

            loadPreview();
        },
    });
}

export function addNewOptionUi(questionEle)
{
    const html = $('.option-template-holder').first().html();
    if ($(questionEle).find('.option-list .correct-option').length)
    {
        $(questionEle).find('.option-list .correct-option').last().after(html);
    }
    else
    {
        $(questionEle).find('.option-list').prepend(html);
    }

    const newElem = $(questionEle).find('.option-item.option-template');
    newElem.removeClass('hide option-template');
    newElem.addClass('correct-option');
    newElem.find('.toggle-slide').addClass('disabled');
    newElem.find('.js-toggle-correct').attr('id', 'optionToggle-idMissing');
    newElem.find('.js-delete-option').remove();

    $(questionEle).find('.option-list .correct-option').find('.title-group').addClass('correct');
}

$(document).on('click', '.js-add-question', function(e)
{
    const parentContent = $(this).closest('.content-authoring-content');
    const prevContentId = getPrevContentId(parentContent, this);
    const questionType = $(this).attr('data-type');
    const selectedTopicId = $('.content-authoring-topic.expanded').first().attr('data-id');
    const classId = $('[name=classId]').val();

    if (selectedTopicId)
    {
        const addFromDivider = $(this).closest('.add-divider-container').length > 0;

        addQuestion(classId, selectedTopicId, questionType, prevContentId, !addFromDivider);
    }
    else
    {
        const classInstanceId = $('input[name=classInstanceId]').val();
        const token = $('meta[name="csrf-token"]').attr('content');

        const firstSection = $(this).closest('.content-authoring-wrapper').find('.content-authoring-section');
        if (firstSection.length)
        {
            const sectionId = firstSection.first().data('id');
            const sectionName = firstSection.first().find('.js-edit-section-topic-name').data('current-val');

            createTopic(trans('base.emptyTopicNamePlaceholder'), sectionId, sectionName, classInstanceId, token, null, questionType);
        }
        else
        {
            createTopic(trans('base.emptyTopicNamePlaceholder'), null, trans('base.defaultSectionName'), classInstanceId, token, null, questionType);
        }
    }
});

export function addQuestion(classId, topicId, questionType = 'multi-choice', prevContentId = null, addToBottom = true)
{
    const values = {
        _token: $('meta[name="csrf-token"]').attr('content'),
        type: questionType,
        topicId: topicId,
        classId: classId,
        prevContentId: prevContentId,
        addToBottom: addToBottom
    };

    $.ajax(
    {
        url: '/content/authoring/question/add',
        type: 'POST',
        dataType: 'json',
        data: values,
        success: function(response)
        {
            const topic = $(`.content-authoring-topic[data-id="${topicId}"]`);

            const newQuestion = $(response.questionHtml);
            if (prevContentId)
            {
                topic.find(`.content-authoring-content[data-content-id='${prevContentId}']`).after(newQuestion);
            }
            else
            {
                if (addToBottom)
                {
                    topic.find('.content-authoring-topic-footer').before(newQuestion);
                }
                else
                {
                    topic.find('.content-authoring-topic-body').prepend(newQuestion);
                }
            }

            if (questionType === 'multi-choice' || questionType === 'select-all-that-apply')
            {
                createPlaceholders(newQuestion);
            }

            newQuestion.find('.dropdown-item.js-schedule-publish').show();
            newQuestion.addClass('newly-added');
            newQuestion.find('.option-list').addClass('newly-added');
            topic.find('.content-authoring-topic-heading .js-schedule-publish').show();
            const questionTitle = newQuestion.find('.content-title .input-field');
            newQuestion.click();
            questionTitle.focus();
            topic.find('.js-delete-question').show();
            updatePublishBtn();

            const footer = topic.find('.content-authoring-topic-footer');
            if (footer.length && footer.hasClass('empty-topic'))
            {
                footer.removeClass('empty-topic');
            }
        }
    });
}

$(document).on('click', '.js-duplicate-question', function(e)
{
    const questionEle = $(this).parents('.content-authoring-content');
    let values = {
        _token: $('meta[name="csrf-token"]').attr('content'),
        assessmentId: questionEle.attr('data-content-id'),
        classId: $('[name=classId]').val(),
    };

    $.ajax(
    {
        url: '/content/authoring/question/duplicate',
        type: 'POST',
        dataType: 'json',
        data: values,
        success: function(response)
        {
            const newQuestion = $(response.questionHtml);
            questionEle.after(newQuestion);
            if (questionEle.attr('data-content-type') === 'fill-in-the-blanks')
            {
                const questionInput = newQuestion.find('.content-title .input-field')[0];
                const answerInputs = newQuestion.find('.option-item .title.input-field');
                updateQuestionFromAnswers(questionInput, answerInputs, null);
            }
            selectContent(newQuestion);
            scrollToContent(newQuestion);
            questionEle.find('.js-delete-question').show();
            updatePublishBtn();
        }
    });
});

function updateOption(optionEle)
{
    let optionId = optionEle.attr('data-option-id');
    const titleEle = optionEle.find('.title');
    let title = titleEle.html().trim();
    const oldTitle = title;
    let isCorrect = $(`#optionToggle${optionId}`).is(':checked') ? 1 : 0;
    let questionEle = optionEle.parents('.content-authoring-content');

    if (titleEle.text().trim().length === 0)
    {
        title = '';
        titleEle.html('').attr('data-current-val', '');
    }
    else
    {
        title = trimWhitespaceAndBreaks(titleEle);
    }

    if (title !== oldTitle)
    {
        loadPreview(questionEle);
    }

    let values = {
        _token: $('meta[name="csrf-token"]').attr('content'),
        assessmentId: questionEle.attr('data-content-id'),
        classId: $('[name=classId]').val(),
        optionId: optionId,
        title: title,
        isCorrect: isCorrect
    };

    updateToggles(optionEle, questionEle, isCorrect);
    validateAndUpdateQuestionIncompleteFlag(questionEle);
    updateTopicIncompleteCounter(questionEle.parents('.content-authoring-topic'));

    $.ajax(
    {
        url: '/content/authoring/option/edit',
        type: 'POST',
        dataType: 'json',
        data: values,
        success: function(response)
        {
            optionEle.find('.title').attr('data-current-val', response.option.title);
            initialiseSchedulePublish();
            loadPreview();
        },
    });
}

function updateToggles(optionEle, questionEle, isCorrect)
{
    const title = optionEle.find('.title-group');
    const otherOptions = optionEle.siblings('.option-item');
    const questionType = questionEle.attr('data-content-type');

    title.toggleClass('correct', !!isCorrect);

    optionEle.toggleClass('correct-option', !!isCorrect);

    if (questionType === 'true-false' || (questionType === 'multi-choice' && !!isCorrect))
    {
        otherOptions.find('.title-group').toggleClass('correct', !isCorrect);
        otherOptions.find('.js-toggle-correct').prop('checked', !isCorrect);
    }
}

$(document).on('click', '.js-publish-btn', function(e)
{
    const classId = $('input[name=classId]').val();
    $.ajax(
    {
        url: '/admin/ajax/publish-class/' + classId,
        type: 'GET',
        dataType: 'json',
        success: function(response)
        {
            if (response.redirectTo)
            {
                if (response.isClassPublishModalViewed === 1 || response.learnerCount > 0)
                {
                    telemetryTrackAndPerformUrlChange(response.redirectTo, 'ep-content-authoring-publish-success');
                }
                else
                {
                    Quitch.modal(
                        {
                            title: trans('content.firstPublishMsg'),
                            message: trans('content.noLearnerMsg'),
                            type: 'confirm_custom',
                            okText: 'content.show',
                            cancelText: 'content.later',
                        },
                        function(modalResponse)
                        {
                            updateClassPublishModalViewedStatus();
                            if (modalResponse === true)
                            {
                                telemetryTrackAndPerformUrlChange(`/class-manager/${classId}/#invitations`, 'ep-publish-invite-yes');
                            }
                            else
                            {
                                telemetryTrackAndPerformUrlChange(response.redirectTo, 'ep-publish-invite-later');
                            }
                        }
                    );

                }
            }
        },
    });
});

$(document).on('click', '#edit-only-desktop-banner .dismiss', function(e)
{
    $('#edit-only-desktop-banner').remove();
    Cookies.set('ca-edit-only-desktop-banner-dismissed', 'true');
});

function updateClassPublishModalViewedStatus()
{
    $.ajax(
    {
        url: '/admin/ajax/class/modal-view-update',
        type: 'GET',
        dataType: 'json',
        success: function(response) {}
    });
}
$(document).on('click', '.js-import-spreadsheet', function(e)
{
    let classId = $('input[name=classId]').val();
    window.location = `/admin/class/${classId}/import`;
});

$(document).on('click', '.content-authoring-feedback-btn', function(e)
{
    showFeedbackModal('New content authoring', trans('feedback.newContentAuthoringFeedback'), false);
});

$(document).on('show.bs.dropdown', '.content-authoring-section-heading.toolbar .dropdown', function(e)
{
    // Increasing z-index to section toolbar so the next sticky section toolbar doesn't cover the dropdown menu
    $(this).parent().css('z-index', 23);
});

$(document).on('hidden.bs.dropdown', '.content-authoring-section-heading.toolbar .dropdown', function(e)
{
    $(this).parent().css('z-index', '');
});