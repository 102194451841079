import { getEmbeddedVideoFromUrl, trans } from '../common';
import { loadExistingImageToImageBox, removeUploadedImageFromModal } from '../form/file-upload';
import { Quitch } from '../quitch';
import { linkify } from './announcements';

$(document).ready(function()
{
    // Side-modal

    const whatsNewBody = $('.js-whats-new-body');
    const whatsNewMain = $('.js-whats-new-main');
    if (whatsNewMain.length) // Login page should not have whatsNewMain
    {
        downloadWhatsNew(true, false);

        whatsNewBody.scroll(function()
        {
            // Pagination
            const xPos = parseInt(whatsNewMain.css('right'), 10);
            if (xPos >= 0) // Side-modal is fully open
            {
                const bScrolledToBottom = Math.abs(this.scrollHeight - this.scrollTop - this.clientHeight) <= 2.0;
                if (bScrolledToBottom)
                {
                    if (!$('.whats-new-has-all-entries').length)
                    {
                        // Re-run the ajax call to append the next batch of paginated results
                        downloadWhatsNew();
                    }
                }
            }

            checkCardsForSeen();
        });
    }

    // Super-user page

    loadWhatsNewDataTable(function(d)
    {
        // This seems to be how data is sent to the controller via ajax - not a callback function
        return {
            _token: $('meta[name="csrf-token"]').attr('content'),
            start: d['start'],
            length: d['length'],
            search: d['search'],
            platformFilter: getPlatformFilter(),
        };
    });

    $('.js-whats-new-checkbox').change(function()
    {
        reloadWhatsNewDataTable();
    });

});

// Check if each card is on screen and add a 'seen' class to those which are
function checkCardsForSeen()
{
    const existingCards = $('.whats-new-card');
    for (let i = 0; i < existingCards.length; ++i)
    {
        if (isElementFullyInViewport(existingCards[i]))
        {
            if (!existingCards.eq(i).hasClass('seen'))
            {
                existingCards.eq(i).addClass('seen');
            }
        }
    }
}

function isElementFullyInViewport(el) // Checks if the item is fully within the viewport
{
    const rect = el.getBoundingClientRect();

    return (rect.top >= 0 && rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth));
}

function getCardHtml(entry)
{
    let cardHtml = '';

    let dateFormatted = '';
    if (entry['publishedAt'] != null)
    {
        dateFormatted = entry['publishedAt'];
    }
    else
    {
        dateFormatted = '[Publish date appears here]';
    }

    cardHtml += `
        <div id="` + entry['gmid'] + `" class="whats-new-card">
            <p class="whats-new-card-date">` + dateFormatted + `</p>
            <p class="whats-new-card-title wrap">` + entry['title'] + `
    `;

    if (entry['globalMessageId'] === null)
    {
        cardHtml += `
                <span class="whats-new-unseen-dot"></span>
        `;
    }

    cardHtml += `
            </p>
            <p class="whats-new-card-text">` + linkify(entry['text']) + `</p>
    `;

    if (entry['image'] != null && entry['image'] !== '')
    {
        cardHtml += `
            <img class="whats-new-card-img" src="` + entry['image'] + `" alt="image">
        `;
    }

    if (entry['video'] != null && entry['video'] !== '')
    {
        cardHtml += `
            <div class="whats-new-card-vid-holder">
                <iframe class="whats-new-card-vid" allowfullscreen
                    src="` + entry['video'] + `">
                </iframe>
            </div>
        `;
    }

    cardHtml += `
        </div>
    `;

    return cardHtml;
}

function downloadWhatsNew(bOpenWhenDone = false, bOpenWithoutUnseen = false)
{
    if ($('.whats-new-load-roll').length)
    {
        return; // Already downloading
    }

    const whatsNewBody = $('.js-whats-new-body');
    const loadRollHtml = `
    <div class="whats-new-load-roll-holder">
        <i class="fa-solid fa-circle-notch fa-pulse whats-new-load-roll adjacent"></i>
    </div>
    `;
    whatsNewBody.append(loadRollHtml);

    let existingCards = $('.whats-new-card');

    $.ajax(
    {
        url: '/whats-new/',
        type: 'GET',
        dataType: 'json',
        data: { offset: existingCards.length, limit: 2, platform: 'ep' },
        success: function(response, status, xhr)
        {
            if (xhr.status === '200' || xhr.status === 200)
            {
                const whatsNewBody = $('.js-whats-new-body');
                const entries = response.entries;
                if (entries.length > 0)
                {
                    let cardsHtml = '';
                    for (let i = 0; i < entries.length; ++i)
                    {
                        cardsHtml += getCardHtml(entries[i]);
                    }

                    whatsNewBody.append(cardsHtml);

                    if (bOpenWhenDone && (response.unseenCount > 0 || bOpenWithoutUnseen))
                    {
                        openSideModal();
                    }

                    checkCardsForSeen();
                }

                existingCards = $('.whats-new-card'); // To count again
                if (existingCards.length > 0)
                {
                    // Remove the empty state if it exists
                    $('.js-whats-new-empty-state').remove();
                }

                if (response.totalEntriesCount === existingCards.length)
                {
                    whatsNewBody.append(`<div class="whats-new-has-all-entries"></div>`);
                }
            }

            // Remove any active load-rolls
            const whatsNewLoadRoll = $('.whats-new-load-roll-holder');
            if (whatsNewLoadRoll.length)
            {
                whatsNewLoadRoll.remove();
            }

            // Recursively load entries until either all are loaded or the page height is filled/overflowing
            if (!$('.whats-new-has-all-entries').length)
            {
                if (whatsNewBody[0].scrollHeight <= whatsNewBody[0].clientHeight)
                {
                    downloadWhatsNew();
                }
            }

        },
    });
}

function previewWhatsNew(id)
{
    if ($('.whats-new-load-roll').length)
    {
        alert('Can\'t preview while what\'s new is still downloading');
        return; // Already downloading
    }

    const data = { 'id': id };
    const token = $('meta[name="csrf-token"]').attr('content');
    $.ajax(
    {
        url: '/manage/whats-new-get-specific/',
        type: 'POST',
        dataType: 'json',
        data: { _token: token, data: data },
        success: function(response, status, xhr)
        {
            if (xhr.status === '200' || xhr.status === 200)
            {
                const entry = response.entry;
                const html = getCardHtml(entry);
                openSideModal(html);
            }
        }
    });

}

$(document).on('click', '#js-whats-new-btn', function()
{
    openSideModal();
});

$(document).on('click', '#js-whats-new-close', function()
{
    closeSideModal();
});

$(document).on('click', '#whats-new-backdrop', function()
{
    const xPos = parseInt($('.js-whats-new-main').css('right'), 10);
    if (xPos >= 0) // Side-modal is fully open
    {
        closeSideModal();
    }
});

function openSideModal(tempHtml = '')
{
    $('html, body').css({ 'overflow-y': 'hidden' }); // Disable main page scrolling

    if (tempHtml !== '')
    {
        // Set the whatsNewBody html to tempHtml and store its current html for retrieval
        const whatsNewBody = $('.js-whats-new-body');
        whatsNewBody.data('data-stored-html', whatsNewBody.html());
        whatsNewBody.html(tempHtml);
    }

    beginAnimateIn();
}

function closeSideModal()
{
    $('html, body').css({ 'overflow-y': 'auto' }); // Enable main page scrolling

    const whatsNewBody = $('.js-whats-new-body');
    const storedHtml = whatsNewBody.data('data-stored-html');

    if (storedHtml !== '' && storedHtml != null)
    {
        // Restore original html - clear the preview
        whatsNewBody.html(storedHtml);
        whatsNewBody.data('data-stored-html', '');

        beginAnimateOut();

        return;
    }


    // Log the currently paginated entries as viewed

    // Get the IDs of the currently paginated cards (they correspond to the id column in globalMessages)
    const existingCards = $('.whats-new-card');
    let existingCardIds = [];
    for (let i = 0; i < existingCards.length; ++i)
    {
        if (existingCards.eq(i).hasClass('seen'))
        {
            existingCardIds.push(existingCards.eq(i).attr('id'));
        }
    }

    const token = $('meta[name="csrf-token"]').attr('content');
    $.ajax(
    {
        url: '/whats-new-log-viewed/',
        type: 'POST',
        dataType: 'json',
        data: { _token: token, viewedEntryIds: existingCardIds },
        success: function(response) {}
    });

    beginAnimateOut();

    $('.whats-new-unseen-dot').remove(); // Remove blue dots from any loaded entries

    existingCards.removeClass('seen');
}

function beginAnimateIn()
{
    const elem = $('.js-whats-new-main');
    const bgElem = $('.whats-new-backdrop');

    bgElem.removeClass('click-through');

    elem.show();
    bgElem.show();

    animate(elem, bgElem);
}

function beginAnimateOut()
{
    animate($('.js-whats-new-main'), $('.whats-new-backdrop'), true);

    $('.whats-new-backdrop').addClass('click-through');
}

function animate(elem, bgElem, bOut = false, animRate = 6, fadeRate = 0.01)
{
    const xPos = parseInt(elem.css('right'), 10);

    let bIsComplete = xPos >= 0;
    if (bOut) // End point is -600 (fully off-screen)
    {
        const diff = Math.abs(-600 - xPos);
        if (diff < animRate)
        {
            animRate = diff; // Prevent overshoot
        }
        elem.css('right', xPos - animRate);
        bIsComplete = xPos <= -600;

        const bgOpacity = parseFloat(bgElem.css('opacity'));
        if (bgOpacity > 0.0)
        {
            bgElem.css('opacity', bgOpacity - fadeRate);
        }
    }
    else // End point is 0 (fully on-screen)
    {
        if (Math.abs(xPos) < animRate)
        {
            animRate = Math.abs(xPos); // Prevent overshoot
        }
        elem.css('right', xPos + animRate);

        const bgOpacity = parseFloat(bgElem.css('opacity'));
        if (bgOpacity < 0.5)
        {
            bgElem.css('opacity', bgOpacity + fadeRate);
        }
    }

    if (!bIsComplete)
    {
        window.setTimeout(function()
        {
            animate(elem, bgElem, bOut, animRate, fadeRate);
        }, 0);
    }
    else
    {
        if (bOut)
        {
            onSideModalClosed(elem, bgElem);
        }
        else
        {
            onSideModalOpened();
        }
    }
}

function onSideModalOpened()
{
    checkCardsForSeen();
}

function onSideModalClosed(elem, bgElem)
{
    elem.hide();
    bgElem.hide();
}



function reloadWhatsNewDataTable()
{
    if ($.fn.dataTable.isDataTable('.js_whats-new_datatable'))
    {
        $('.js_whats-new_datatable').DataTable().ajax.reload(null, false); // callback, resetPaging
        return true;
    }
    return false;
}

function loadWhatsNewDataTable(data)
{
    if (reloadWhatsNewDataTable())
    {
        return;
    }

    const url = '/manage/whats-new-data';
    const platforms = { 'ep': 'Educator portal', 'app': 'App' };

    $('.js_whats-new_datatable').DataTable(
    {
        ajax:
        {
            url: url,
            type: 'POST',
            data: data,
        },
        language:
        {
            info: trans('invites.tableEntries', { start: '_START_', end: '_END_', total: '_TOTAL_' }),
            search: '',
            searchPlaceholder: trans('base.search'),
            lengthMenu: trans('reporting.lengthMenuOther', { menu: '_MENU_' }),
            processing: trans('reporting.processing'),
            zeroRecords: trans('reporting.empty'),
            infoEmpty: '',
            infoFiltered: '',
            paginate:
            {
                next: '<i class="fa fa-angle-right"></i>',
                previous: '<i class="fa fa-angle-left"></i>',
            },
        },
        serverSide: true,
        searching: true,
        dom: `frtlpi`,
        pagingType: 'simple',
        targets: 'no-sort',
        order: [],
        pageLength: 10,
        columnDefs: [
        {
            targets: [0],
            visible: false
        },
        {
            targets: '_all',
            orderable: false,
            visible: true,
        }, ],
        columns: [
        {
            data: 'id',
            title: 'ID'
        },
        {
            data: 'title',
            title: 'Title'
        },
        {
            data: 'author',
            title: 'Author',
        },
        {
            data: 'platform',
            title: 'Platform',
            render: function(data)
            {
                return `<span class="whats-new-lozenge whats-new-lozenge-square">${platforms[data]}</span>`;
            },
        },
        {
            data: 'version',
            title: 'Version'
        },
        {
            data: 'publishedAt',
            title: 'Status',
            render: function(data, type, row)
            {
                if (data != null)
                {
                    return `<span class="whats-new-lozenge whats-new-lozenge-blue" data-bs-toggle="tooltip" title="${data}">Published</span>`;
                }
                return `<span class="whats-new-lozenge">Draft</span>`;
            },
        },
        {
            data: 'views',
            title: 'Views'
        },
        {
            data: 'actions',
            title: '',
            render: function(data, type, row)
            {
                let html = `
                    <div class="dropdown table-action-dropdown">
                        <i class="fa-solid fa-ellipsis" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="true" aria-hidden="true"></i>
                        <div class="dropdown-menu dropdown-menu-end custom-dropdown-list">
                `;

                if (row['publishedAt'] == null)
                {
                    html += `
                            <a data-item-id="${row.id}" class="dropdown-item js-whats-new-publish">Publish</a>
                    `;

                    if (row['platform'] === 'ep')
                    {
                        html += `
                            <a data-item-id="${row.id}" class="dropdown-item js-whats-new-preview">Preview</a>
                        `;
                    }
                }
                else
                {
                    html += `
                            <a data-item-id="${row.id}" class="dropdown-item js-whats-new-unpublish">Revert to draft</a>
                    `;
                }

                html += `
                            <a data-item-id="${row.id}" class="dropdown-item js-whats-new-edit">Edit</a>
                            <a data-item-id="${row.id}" class="dropdown-item js-whats-new-duplicate">Duplicate</a>
                            <a data-item-id="${row.id}" class="dropdown-item js-whats-new-delete">Delete</a>
                        </div>
                    </div>
                `;

                return html;
            },
        }, ],
    });
}

$(document).on('click', '.js-whats-new-publish', function()
{
    publishWhatsNew($(this).data('item-id'));
});

$(document).on('click', '.js-whats-new-unpublish', function()
{
    publishWhatsNew($(this).data('item-id'), true);
});

$(document).on('click', '.js-whats-new-preview', function()
{
    previewWhatsNew($(this).data('item-id'));
});

$(document).on('click', '.js-whats-new-edit', function()
{
    editWhatsNewStart($(this).data('item-id'));
});

$(document).on('click', '.js-whats-new-duplicate', function()
{
    duplicateWhatsNew($(this).data('item-id'));
});

$(document).on('click', '.js-whats-new-delete', function()
{
    const itemId = $(this).data('item-id');
    Quitch.modal(
        {
            title: 'Delete what\'s new post',
            message: `<p>Are you sure you want to delete this post?</p>`,
            type: 'confirm_custom',
            cssClass: 'modal-dialog-centered',
            okText: 'Delete post',
            cancelText: 'Cancel',
        },
        function(response)
        {
            if (response === true)
            {
                deleteWhatsNew(itemId);
            }
        }
    );

});

$(document).on('click', '.js-whats-new-save-publish', function()
{
    addWhatsNew(true);
});

$(document).on('click', '.js-whats-new-save-draft', function()
{
    addWhatsNew(false);
});

$(document).on('click', '.js-whats-new-save-edit', function()
{
    editWhatsNewFinish();
});

function getPlatformFilter()
{
    let filters = [];

    if ($('#platform-option-ep #plat-1').prop('checked') === true)
    {
        filters.push('ep');
    }

    if ($('#platform-option-app #plat-2').prop('checked') === true)
    {
        filters.push('app');
    }

    return filters;
}

function resetModal(modalId)
{
    const modal = $('#' + modalId);
    modal.find('#whatsNewPlatform').val(0);
    modal.find('#whatsNewTitle').val('');
    modal.find('#whatsNewDesc').val('');
    modal.find('#whatsNewVersion').val('');
    modal.find('#whatsNewVideo').val('');
}

$(document).on('shown.bs.modal hidden.bs.modal', '#js-add-whats-new-modal', function()
{
    resetModal('js-add-whats-new-modal');
    removeUploadedImageFromModal('js-add-whats-new-modal');
    updateVideoFieldVisibleState();
});

$(document).on('shown.bs.modal hidden.bs.modal', '#js-edit-whats-new-modal', function()
{
    updateVideoFieldVisibleState(true);
});

function updateVideoFieldVisibleState(isEditModal = false)
{
    let modal = $('#js-add-whats-new-modal');
    if (isEditModal)
    {
        modal = $('#js-edit-whats-new-modal');
    }

    const whatsNewVideo = modal.find('#whatsNewVideo').parent();
    const selVal = modal.find('#whatsNewPlatform').find(':selected').val();
    if (selVal == 1)
    {
        whatsNewVideo.hide();
    }
    else if (selVal == 0)
    {
        whatsNewVideo.show();
    }
}

const onPlatformSelected = (window.onPlatformSelected = function(event)
{
    const target = $(event.target);
    const isEditModal = target.parents('#js-edit-whats-new-modal').length > 0;
    updateVideoFieldVisibleState(isEditModal);
});

function getDataFromModal(modalId, publishedAt)
{
    let data = {};

    const modal = $('#' + modalId);

    data['platform'] = modal.find('#whatsNewPlatform').val() == 0 ? 'ep' : 'app';
    data['title'] = modal.find('#whatsNewTitle').val();
    data['desc'] = modal.find('#whatsNewDesc').val();
    data['video'] = getEmbeddedVideoFromUrl(modal.find('#whatsNewVideo').val());

    data['image'] = null; // Default
    const img = modal.find('.image-preview img');
    if (img)
    {
        data['image'] = img.attr('src') === undefined ? null : img.attr('src');
    }

    data['version'] = modal.find('#whatsNewVersion').val();
    data['publishedAt'] = publishedAt;

    return data;
}

function addWhatsNew(bPublish = false)
{
    const data = getDataFromModal('js-add-whats-new-modal', bPublish);
    const token = $('meta[name="csrf-token"]').attr('content');
    $.ajax(
    {
        url: '/manage/whats-new-add/',
        type: 'POST',
        dataType: 'json',
        data: { _token: token, data: data },
        success: function(response, status, xhr)
        {
            if (xhr.status === '200' || xhr.status === 200)
            {
                reloadWhatsNewDataTable();

                Quitch.showMessage('success', 'What\'s new added');
            }
            else
            {
                Quitch.showMessage('danger', response.message);
            }
        }
    });
}

function publishWhatsNew(id, bUnpublish = false)
{
    const data = { 'id': id };
    const token = $('meta[name="csrf-token"]').attr('content');
    $.ajax(
    {
        url: bUnpublish ? '/manage/whats-new-unpublish/' : '/manage/whats-new-publish/',
        type: 'POST',
        dataType: 'json',
        data: { _token: token, data: data },
        success: function(response, status, xhr)
        {
            if (xhr.status === '200' || xhr.status === 200)
            {
                reloadWhatsNewDataTable();

                if (!bUnpublish)
                {
                    Quitch.showMessage('success', 'What\'s new published');
                }
                else
                {
                    Quitch.showMessage('success', 'What\'s new reverted to draft');
                }
            }
            else
            {
                Quitch.showMessage('danger', response.message);
            }
        }
    });
}

function editWhatsNewStart(id)
{
    removeUploadedImageFromModal('js-edit-whats-new-modal');

    const data = { 'id': id };
    const token = $('meta[name="csrf-token"]').attr('content');
    $.ajax(
    {
        url: '/manage/whats-new-edit-retrieve/',
        type: 'POST',
        dataType: 'json',
        data: { _token: token, data: data },
        success: function(response, status, xhr)
        {
            if (xhr.status === '200' || xhr.status === 200)
            {
                const editModal = $('#js-edit-whats-new-modal');

                // Store the original data within the HTML
                editModal.find('.modal-content').data('stored-whats-new-post', response.data);

                editModal.modal({ backdrop: 'static', keyboard: false }, 'show');

                // Populate the modal with the retrieved data
                editModal.find('#whatsNewPlatform').val(response.data['platform'] === 'ep' ? 0 : 1);
                editModal.find('#whatsNewTitle').val(response.data['title']);
                editModal.find('#whatsNewDesc').val(response.data['text']);
                editModal.find('#whatsNewVersion').val(response.data['version']);
                editModal.find('#whatsNewVideo').val(response.data['video']);

                if (response.data['image'] !== '' && response.data['image'] != null)
                {
                    // Put load roller into the image box
                    editModal.find('.image-preview').html(`<div class="image-loader"><i class="fa-solid fa-circle-notch fa-pulse load-roll"></i></div>`);
                }

                loadExistingImageToImageBox(editModal, response.data['image']);
            }
        }
    });

}

function editWhatsNewFinish()
{
    const editModal = $('#js-edit-whats-new-modal');

    const origData = editModal.find('.modal-content').data('stored-whats-new-post');
    const newData = getDataFromModal('js-edit-whats-new-modal', origData['publishedAt']);

    newData['id'] = origData['id'];

    const token = $('meta[name="csrf-token"]').attr('content');
    $.ajax(
    {
        url: '/manage/whats-new-edit/',
        type: 'POST',
        dataType: 'json',
        data: { _token: token, data: newData },
        success: function(response, status, xhr)
        {
            if (xhr.status === '200' || xhr.status === 200)
            {
                reloadWhatsNewDataTable();

                Quitch.showMessage('success', 'What\'s new updated');
            }
            else
            {
                Quitch.showMessage('danger', response.message);
            }
        }
    });

    removeUploadedImageFromModal('js-edit-whats-new-modal');
}

function duplicateWhatsNew(id)
{
    const data = { 'id': id };
    const token = $('meta[name="csrf-token"]').attr('content');
    $.ajax(
    {
        url: '/manage/whats-new-duplicate/',
        type: 'POST',
        dataType: 'json',
        data: { _token: token, data: data },
        success: function(response, status, xhr)
        {
            if (xhr.status === '200' || xhr.status === 200)
            {
                reloadWhatsNewDataTable();

                Quitch.showMessage('success', 'What\'s new duplicated');
            }
            else
            {
                Quitch.showMessage('danger', response.message);
            }
        }
    });
}

function deleteWhatsNew(id)
{
    const data = { 'id': id };
    const token = $('meta[name="csrf-token"]').attr('content');
    $.ajax(
    {
        url: '/manage/whats-new-delete/',
        type: 'POST',
        dataType: 'json',
        data: { _token: token, data: data },
        success: function(response, status, xhr)
        {
            if (xhr.status === '200' || xhr.status === 200)
            {
                reloadWhatsNewDataTable();

                Quitch.showMessage('success', 'What\'s new deleted');
            }
            else
            {
                Quitch.showMessage('danger', response.message);
            }
        }
    });
}