import { getEmbeddedVideoFromUrl, trans, transChoice } from '../../common';
import { selectContent } from './common';
import { getOpenAndCloseBracketIndices } from './drag-drop';
import { renderThumbnail } from '../pdf';

export function loadPreview(selected = false, contentIds = false)
{
    if (!selected)
    {
        selected = $('.content-authoring-content.selected').first();
    }

    if (selected.length === 0)
    {
        $('.preview-content-wrapper > .page-number').html(trans('content.pageNumber', { 'page': 0, 'total': 0 }));
        $('.question-preview').hide();
        $('.resource-preview').hide();
        return;
    }

    const length = selected.siblings('.content-authoring-content').length;
    const pageNumberText = trans('content.pageNumber', { 'page': selected.index() + 1, 'total': length + 1 });
    $('.preview-content-wrapper > .page-number').html(pageNumberText);

    if (selected.hasClass('question'))
    {
        $('.resource-preview').hide();
        $('.question-preview').show();

        loadQuestionPreview(selected);
    }
    else if (selected.hasClass('resource'))
    {
        $('.question-preview').hide();
        $('.resource-preview').show();

        loadResourcePreview(selected);
    }

    updatePreviewArrows(selected, contentIds);
}

function loadQuestionPreview(selected)
{
    const questionPreview = $('.question-preview-question');
    const questionPreviewEmpty = $('.question-preview-question-empty');

    let questionText = selected.find('.input-field').html();

    if (!questionText)
    {
        questionPreviewEmpty.show();
        questionPreview.hide();
    }
    else
    {
        if (selected.data('content-type') === 'fill-in-the-blanks')
        {
            const [openBracketIndices, closeBracketIndices, blanksInFormulas] = getOpenAndCloseBracketIndices(questionText);
            let text = '';
            if (closeBracketIndices.length === 0)
            {
                text = questionText;
            }
            else
            {
                for (let i = 0; i < closeBracketIndices.length; ++i)
                {
                    text += questionText.substring(i === 0 ? 0 : closeBracketIndices[i - 1] + 1, openBracketIndices[i]);
                    text += '<u class="answer-correct">';
                    text += questionText.substring(openBracketIndices[i] + 1, closeBracketIndices[i]);
                    text += '</u>';
                }
                // add text after the final blank
                text += questionText.substring(closeBracketIndices[closeBracketIndices.length - 1] + 1);
            }
            questionText = text;
        }
        questionPreview.html(questionText);
        questionPreview.show();
        questionPreviewEmpty.hide();
    }

    loadQuestionImage(selected);

    const multiChoiceWrapper = $('.question-multi-choice-answer-wrapper');
    const trueFalseWrapper = $('.question-true-false-answer-wrapper');
    const dragDropWrapper = $('.question-drag-drop-answer-wrapper');
    const freeTextWrapper = $('.question-free-text-answer-wrapper');
    const selectAllApplyText = $('.question-select-all-apply-text');
    multiChoiceWrapper.hide();
    trueFalseWrapper.hide();
    dragDropWrapper.hide();
    freeTextWrapper.hide();
    selectAllApplyText.hide();
    const optionList = getOptionList(selected);

    switch (selected.data('content-type'))
    {
        case 'select-all-that-apply':
            selectAllApplyText.show();
            /* falls through */
        case 'multi-choice':
            loadMultiChoicePreview(optionList);
            multiChoiceWrapper.show();
            break;
        case 'fill-in-the-blanks':
            loadDragDropPreview(optionList);
            dragDropWrapper.show();
            break;
        case 'true-false':
            loadTrueFalsePreview(optionList);
            trueFalseWrapper.show();
            break;
        case 'free-text':
            freeTextWrapper.show();
            break;
    }

    MathJax.typeset(['.preview-content-container .question-preview-question.title-medium']);
    MathJax.typeset(['.preview-content-container .body-large.answer-text']);
}

function getOptionList(selected)
{
    const questionPreview = $('.question-preview');
    const shuffle = selected.find('.content-title').data('shuffle');
    let optionList = selected.find('.option-item');
    let optionIds = optionList.map(function()
    {
        return $(this).attr('data-option-id');
    }).get();

    if (shuffle)
    {
        const renderedAnswers = (questionPreview.attr('data-rendered-shuffled-answers') || '').split(',');

        if ([...renderedAnswers].sort().join(',') === [...optionIds].sort().join(','))
        {
            // reorder the optionList to match the already rendered answers
            optionList = optionList.sort(function(a, b)
            {
                return renderedAnswers.indexOf($(a).attr('data-option-id')) - renderedAnswers.indexOf($(b).attr('data-option-id'));
            });
        }
        else
        {
            optionList = shuffleAnswerOrder(optionList);
            optionIds = optionList.map(function()
            {
                return $(this).attr('data-option-id');
            }).get();
            questionPreview.attr('data-rendered-shuffled-answers', optionIds.join(','));
        }
    }
    else
    {
        questionPreview.attr('data-rendered-shuffled-answers', '');
    }

    return optionList;
}

function loadMultiChoicePreview(optionList)
{
    const answerWrapper = $('.question-multi-choice-answer-wrapper');
    answerWrapper.find('.question-multi-choice-answer').remove();
    const answerTemplate = $('.question-multi-choice-answer.preview-answer-template');

    loadAnswerCommon(optionList, answerWrapper, answerTemplate);
}

function loadAnswerCommon(optionList, answerWrapper, answerTemplate)
{
    for (let index = 0; index < optionList.length; ++index)
    {
        const option = $(optionList[index]);
        let answer = answerTemplate.clone().addClass('answer-' + index);
        const answerText = option.find('.input-field').html();
        if (answerText)
        {
            answer.find('.answer-text').html(answerText);
        }
        else
        {
            const placeholderText = option.find('.input-field').attr('data-placeholder') || trans('content.answerNo', { 'index': index + 1 });
            answer.find('.answer-text')
                .html(placeholderText)
                .addClass('opacity-60');
        }
        if (option.find('.js-toggle-correct')[0].checked)
        {
            answer.addClass('correct');
        }
        answer.appendTo(answerWrapper);
    }
}

function loadTrueFalsePreview(optionList)
{
    const answerPreviews = $('.question-true-false-answer');
    for (let index = 0; index < optionList.length && index < answerPreviews.length; ++index)
    {
        const option = $(optionList[index]);
        const preview = $(answerPreviews[index]);
        preview.find('.answer-text').html(option.find('.input-field').html());
        if (option.find('.js-toggle-correct')[0].checked)
        {
            preview.addClass('correct');
        }
        else
        {
            preview.removeClass('correct');
        }
    }
}

function loadDragDropPreview(optionList)
{
    const answerWrapper = $('.question-drag-drop-answer-wrapper');
    answerWrapper.find('.question-drag-drop-answer').remove();
    const answerTemplate = $('.question-drag-drop-answer.preview-answer-template');

    loadAnswerCommon(optionList, answerWrapper, answerTemplate);
}

function loadQuestionImage(selected)
{
    const image = selected.find('[name=imageUrl]').val();
    const imagePosition = selected.find('[name=imagePosition]').val();
    let imagePreview = null;

    if (imagePosition === 'cover')
    {
        imagePreview = $('.preview-cover-image');
        $('.question-image-wrapper .question-image').hide();
    }
    else
    {
        imagePreview = $('.question-image-wrapper .question-image');
        $('.preview-cover-image').hide();
    }

    $('.preview-cover-image-empty').hide();
    imagePreview.hide();
    if (image)
    {
        if (image === imagePreview.attr('src'))
        {
            imagePreview.show();
        }
        else
        {
            imagePreview.attr('src', image);
            imagePreview.on('load error', function()
            {
                imagePreview.show();
            });
        }
    }
}

function shuffleAnswerOrder(answers)
{
    return answers.sort(function()
    {
        return Math.round(Math.random()) - 0.5;
    });
}

function loadTextPreview(withEmptyState = true)
{
    $('.resource-text-wrapper').show();
    const textPreview = $('.resource-text-wrapper .resource-description');
    const textPreviewEmpty = $('.resource-text-wrapper .preview-empty-state-wrapper');
    let text = $('.content-authoring-content.selected').find('.resource-description .input-field').html().trim();
    if (!text)
    {
        textPreviewEmpty.toggle(withEmptyState);
        textPreview.hide();
    }
    else
    {
        textPreview.html(text);
        textPreview.show();
        textPreviewEmpty.hide();
    }
}

function loadImagePreview()
{
    $('.resource-image-wrapper .resource-image, .preview-cover-image, .resource-image-wrapper .preview-empty-state-wrapper, .resource-link-wrapper').hide();
    const selectedResource = $('.content-authoring-content.selected');
    const image = selectedResource.find('[name=assetUrl]').val();
    const imagePosition = selectedResource.find('[name=imagePosition]').val();
    const imageWrapper = $('.resource-image-wrapper');
    let imagePreview = null;
    let imagePreviewEmpty = null;

    if (imagePosition === 'cover')
    {
        imageWrapper.hide();
        imagePreview = $('.preview-cover-image');
        imagePreviewEmpty = $('.preview-cover-image-empty');
    }
    else
    {
        imagePreview = $('.resource-image-wrapper .resource-image');
        imagePreviewEmpty = $('.resource-image-wrapper .preview-empty-state-wrapper');
        imageWrapper.show();
    }

    const loader = $('.resource-preview .loader');

    loader.show();

    if (!image)
    {
        loadTextPreview(false);
        loadLinkPreview();
        imagePreviewEmpty.show();
        imagePreview.hide();
        loader.hide();
    }
    if (image)
    {
        imagePreviewEmpty.hide();
        imagePreview.hide();
        if (image === imagePreview.attr('src'))
        {
            loader.hide();
            imagePreview.show();
            loadTextPreview(false);
            loadLinkPreview();
        }
        else
        {
            imagePreview.attr('src', image);
            imagePreview.on('load', function()
            {
                loader.hide();
                imagePreview.show();
                loadTextPreview(false);
                loadLinkPreview();
            });
        }
    }
}

function loadVideoPreview()
{
    $('.resource-video-wrapper .resource-video, .resource-video-wrapper .preview-empty-state-wrapper, .resource-link-wrapper').hide();
    const videoPreview = $('.resource-video-wrapper .resource-video');
    const videoPreviewEmpty = $('.resource-video-wrapper .preview-empty-state-wrapper');
    let video = $('.content-authoring-content.selected [name=filePath]').val();
    const loader = $('.resource-preview .loader');
    loader.show();

    if (!video)
    {
        loadTextPreview(false);
        loadLinkPreview();
        videoPreviewEmpty.show();
        videoPreview.hide();
        loader.hide();
    }
    else
    {
        video = getEmbeddedVideoFromUrl(video);
        if (video === videoPreview.attr('src'))
        {
            loader.hide();
            videoPreview.show();
            loadTextPreview(false);
            loadLinkPreview();
        }
        else
        {
            videoPreview.attr('src', video);
            videoPreview.on('load', function()
            {
                loader.hide();
                videoPreview.show();
                loadTextPreview(false);
                loadLinkPreview();
            });
        }
    }
}

function loadDocumentPreview()
{
    $('.resource-doc-wrapper .resource-document, .resource-doc-wrapper .preview-empty-state-wrapper, .resource-link-wrapper').hide();
    const docPreview = $('.resource-doc-wrapper .resource-document');
    const docPreviewThumbnail = $('.resource-doc-wrapper .resource-document img');
    const docPreviewEmpty = $('.resource-doc-wrapper .preview-empty-state-wrapper');
    let doc = $('.content-authoring-content.selected [name=assetUrl]').val();
    const loader = $('.resource-preview .loader');
    loader.show();

    if (!doc.endsWith('.pdf'))
    {
        docPreviewEmpty.show();
        docPreview.hide();
        loader.hide();
    }
    else
    {
        if (doc === docPreviewThumbnail.attr('data-asset-url'))
        {
            docPreviewThumbnail.show();
            docPreview.show();
            loader.hide();
        }
        else
        {
            renderThumbnail(doc).then(([src, pages]) =>
            {
                docPreviewThumbnail.attr('data-asset-url', doc);
                docPreviewThumbnail.attr('src', src);
                docPreviewThumbnail.on('load', function()
                {
                    docPreview.find('.name').text($('.content-authoring-content.selected [name=originalFileName]').val());
                    docPreview.find('.pages').text(transChoice('content.pages', pages, { 'num': pages }));
                    loader.hide();
                    docPreview.show();
                });
            });
        }
    }
}

function loadLinkPreview()
{
    const linkWrapper = $('.resource-link-wrapper');
    const linkPreview = $('.resource-link-wrapper .resource-link label');
    let link = $('.content-authoring-content.selected [name=url]').val();
    let linkText = $('.content-authoring-content.selected [name=urlText]').val();

    if (!link)
    {
        linkWrapper.hide();
    }
    else
    {
        linkPreview.html(linkText ? linkText : link);
        linkWrapper.show();
    }
}

export function loadResourcePreview(selected)
{
    const resourcePreview = $('.resource-preview-title');
    const resourcePreviewEmpty = $('.resource-preview-title-empty');

    let resourceText = selected.find('.content-title').find('.input-field').html().trim();

    if (!resourceText)
    {
        resourcePreviewEmpty.show();
        resourcePreview.hide();
    }
    else
    {
        resourcePreview.html(resourceText);
        resourcePreview.show();
        resourcePreviewEmpty.hide();
    }

    const textWrapper = $('.resource-text-wrapper');
    const imageWrapper = $('.resource-image-wrapper');
    const videoWrapper = $('.resource-video-wrapper');
    const docWrapper = $('.resource-doc-wrapper');

    textWrapper.hide();
    imageWrapper.hide();
    videoWrapper.hide();
    docWrapper.hide();
    $('.preview-cover-image').hide();
    $('.resource-link-wrapper').hide();

    switch (selected.attr('data-content-type'))
    {
        case 'text':
            loadTextPreview();
            loadLinkPreview();
            break;
        case 'image':
            loadImagePreview();
            break;
        case 'video':
            loadVideoPreview();
            videoWrapper.show();
            break;
        case 'document':
            loadDocumentPreview();
            docWrapper.show();
            break;
    }
}

function getListOfContentIds()
{
    const contents = $('.content-authoring-wrapper .content-authoring-content');
    const contentIds = [];

    for (let index = 0; index < contents.length; ++index)
    {
        contentIds.push($(contents[index]).attr('data-content-id'));
    }

    return contentIds;
}

function updatePreviewArrows(selectedContent, contentIds = false)
{
    if (!contentIds)
    {
        contentIds = getListOfContentIds();
    }

    const selectedId = selectedContent.attr('data-content-id');
    const index = contentIds.indexOf(selectedId);

    if (index === 0)
    {
        $('.preview-wrapper .arrow-btn.left').addClass('disabled');
    }
    else
    {
        $('.preview-wrapper .arrow-btn.left').removeClass('disabled');
    }

    if (index === contentIds.length - 1)
    {
        $('.preview-wrapper .arrow-btn.right').addClass('disabled');
    }
    else
    {
        $('.preview-wrapper .arrow-btn.right').removeClass('disabled');
    }
}

function nextPreview(forwards = true)
{
    const contentIds = getListOfContentIds();
    const selectedId = $('.content-authoring-content.selected').attr('data-content-id');
    const index = contentIds.indexOf(selectedId);
    const nextIndex = index + (forwards ? 1 : -1);

    if (nextIndex < 0 || nextIndex >= contentIds.length)
    {
        return;
    }
    const nextId = contentIds[nextIndex];
    const nextContent = $('.content-authoring-content[data-content-id="' + nextId + '"]');

    $('.preview-content-wrapper .inner-body').fadeOut('fast').promise().done(function()
    {
        selectContent(nextContent, true);
    });
}

$(document).on('input keydown paste change', '.content-authoring .input-field', () =>
{
    loadPreview();
});

$(document).on('change', '.content-authoring .js-toggle-correct', () =>
{
    window.setTimeout(() => { loadPreview(); }, 10);
});

$(document).on('click', '.preview-wrapper .arrow-btn.left:not(.disabled,.locked)', () =>
{
    nextPreview(false);
});

$(document).on('click', '.preview-wrapper .arrow-btn.right:not(.disabled,.locked)', () =>
{
    nextPreview(true);
});