/**
 * Common - functions commonly used across modules
 */

/**
 * Translation function
 * @param key
 * @param replace
 */
export function trans(key, replace = {})
{
    const transKeyRegex = /^[a-zA-Z0-9]*\.[a-zA-Z0-9_]*$/g;
    if (window.i18n && transKeyRegex.test(key))
    {
        let translation = key.split('.').reduce((t, i) => t[i] || null, window.i18n);
        for (let placeholder in replace)
        {
            translation = translation.replace(`:${placeholder}`, replace[placeholder]);
        }
        return translation || key;
    }
    return key;
}

/**
 * Translation with pluralisation
 * @param key
 * @param count
 * @param replace
 */
export function transChoice(key, count, replace = {})
{
    const transKeyRegex = /^[a-zA-Z0-9]*\.[a-zA-Z0-9]*$/g;
    const betweenCurlyRegex = /{(.*)}/;
    const betweenSquareRegex = /\[(.*)\]/;
    replace['count'] = count;

    if (window.i18n && transKeyRegex.test(key))
    {
        let translations = key
            .split('.')
            .reduce((t, i) => t[i] || null, window.i18n)
            .split('|');
        let chosen = null;

        // select translation based on range
        for (let translation of translations)
        {
            // extract number (e.g. {1}) from translation
            let single = translation.match(betweenCurlyRegex);
            single = single ? Number(single[1]) : null;
            if (count === single)
            {
                chosen = translation.replace(betweenCurlyRegex, '');
                break;
            }

            // extract range (e.g. [2,*]) from translation
            let range = betweenSquareRegex.exec(translation);
            range = range ? range[1].split(',') : null;
            if (range && range.length === 2 && count >= range[0] && (range[1] == '*' || count <= range[1]))
            {
                chosen = translation.replace(betweenSquareRegex, '');
                break;
            }
        }

        if (chosen)
        {
            for (let placeholder in replace)
            {
                chosen = chosen.replace(`:${placeholder}`, replace[placeholder]);
            }
            return chosen;
        }
    }
    return key;
}

export function formatTime(time)
{
    let days = Math.floor(time / 86400);
    let hours = Math.floor(time / 3600);
    let minutes = Math.floor((time % 3600) / 60);
    let seconds = Math.floor((time % 3600) % 60);

    if (days > 0)
    {
        return trans('base.dayHourMinute', { days: days, hours: hours });
    }
    else if (hours > 0)
    {
        return trans('base.hourMinuteSecond', { hours: hours, minutes: minutes });
    }
    else if (minutes > 0)
    {
        return trans('base.minuteSecond', { minutes: minutes, seconds: seconds });
    }
    else
    {
        return trans('base.second', { seconds: seconds });
    }
}

//confirm Wrapper
export function confirmI18n(key, param)
{
    return confirm(trans(key, param));
}

/**
 * Helper function to check if a file is uploading
 * @param selector
 * @returns {boolean}
 */
export function fileIsUploading(selector = '[data-file-upload]')
{
    const uploading = $(selector).filter((idx, itm) =>
    {
        return $(itm).data('uploading');
    });
    return uploading.length > 0;
}

export function updateGlobalMessageState(prompt, state)
{
    let token = $('meta[name="csrf-token"]').attr('content');
    let values = {
        _token: token,
        prompt: prompt,
        state: state
    };
    $.ajax(
    {
        url: '/update-global-message-state',
        type: 'POST',
        dataType: 'json',
        data: values,
        success: function(response)
        {
            // Updated global message state for user
        },
    });
}

export function isClassArchived()
{
    return !isClassActive();
}

export function isClassActive()
{
    let isActive = false;
    const $classDetails = $('.js_class_details');
    if ($classDetails.length === 1)
    {
        isActive = $classDetails.data('archived') === false;
    }
    else if ($classDetails.length > 1)
    {
        throw 'found multiple class details: aborting';
    }
    return isActive;
}

export function getEmbeddedVideoFromUrl(normalVideoUrl)
{
    if (normalVideoUrl !== '')
    {
        let videoPlatform = '';
        let videoId = '';

        if (['youtube.com', 'youtu.be'].some(x => normalVideoUrl.includes(x)))
        {
            videoPlatform = 'youtube';
            videoId = normalVideoUrl.match(/(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/|shorts\/)([^"&?\/\s]{11})/i)[1];
        }
        else if (normalVideoUrl.includes('vimeo.com'))
        {
            videoPlatform = 'vimeo';
            videoId = normalVideoUrl.split('/').pop();
        }
        else if (['wistia.com', 'wi.st'].some(x => normalVideoUrl.includes(x)))
        {
            videoPlatform = 'wistia';
            videoId = normalVideoUrl.match(/(?:medias\/|iframe\/)([^"&?\/\s]{10,})/i)[1];
        }

        if (!videoPlatform || !videoId)
        {
            return '';
        }

        if (videoPlatform === 'youtube')
        {
            return `https://www.youtube.com/embed/${videoId}`;
        }
        else if (videoPlatform === 'vimeo')
        {
            return `https://player.vimeo.com/video/${videoId}`;
        }
        else if (videoPlatform === 'wistia')
        {
            return `https://fast.wistia.net/embed/iframe/${videoId}`;
        }
    }
    return '';
}

export function hideModalPopBox(popBoxElement)
{
    let popBoxWrapper = $(popBoxElement).parentsUntil('#pop-box-btn-parent');
    popBoxWrapper.find('#js-pop-box-outer').attr('hidden', true);
    popBoxWrapper.find('#js-filters-btn').removeClass('js-btn-popup-highlight');
}

export function calculateTime(time)
{
    let days = Math.floor(time / 86400);
    let hours = Math.floor((time - days * 86400) / 3600);
    let minutes = Math.floor((time - days * 86400 - hours * 3600) / 60);
    let seconds = Math.floor((time - days * 86400 - hours * 3600 - minutes * 60) % 60);

    if (days > 0)
    {
        return trans('base.dayHourMinute', { days: days, hours: hours });
    }
    else if (hours > 0)
    {
        return trans('base.hourMinuteSecond', { hours: hours, minutes: minutes });
    }
    else if (minutes > 0)
    {
        return trans('base.minuteSecond', { minutes: minutes, seconds: seconds });
    }
    else
    {
        return trans('base.second', { seconds: seconds });
    }
}

export function validateEmail(email)
{
    const emailRegex = /^[a-zA-Z0-9#$'+\/_.-]+@[a-zA-Z0-9]([a-zA-Z0-9-]*[a-zA-Z0-9])?(\.[a-zA-Z0-9]([a-zA-Z0-9-]*[a-zA-Z0-9])*){1,4}$/;
    return email.match(emailRegex);
}

export function getQueryParameter(name)
{
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(name);
}

export function capitalise(text)
{
    if (typeof text === 'string' || text instanceof String)
    {
        return text.charAt(0).toUpperCase() + text.slice(1);
    }

    return null;
}

/**
 * Get the user's timezone from their browser
 * @returns {string}
 */
export function getTimeZone()
{
    // Remap America city timezones to the US timezones
    let timezoneRemap = {
        'America/Anchorage': 'US/Alaska',
        'America/Boise': 'US/Mountain',
        'America/Chicago': 'US/Central',
        'America/Denver': 'US/Mountain',
        'America/Indianapolis': 'US/Eastern',
        'America/Detroit': 'US/Eastern',
        'America/Indiana/Indianapolis': 'US/Eastern',
        'America/Indiana/Knox': 'US/Central',
        'America/Indiana/Marengo': 'US/Eastern',
        'America/Indiana/Petersburg': 'US/Eastern',
        'America/Indiana/Tell_City': 'US/Central',
        'America/Indiana/Vevay': 'US/Eastern',
        'America/Indiana/Vincennes': 'US/Eastern',
        'America/Indiana/Winamac': 'US/Eastern',
        'America/Juneau': 'US/Alaska',
        'America/Kentucky/Louisville': 'US/Eastern',
        'America/Kentucky/Monticello': 'US/Eastern',
        'America/Los_Angeles': 'US/Pacific',
        'America/Menominee': 'US/Central',
        'America/Metlakatla': 'US/Alaska',
        'America/New_York': 'US/Eastern',
        'America/Nome': 'US/Alaska',
        'America/North_Dakota/Beulah': 'US/Central',
        'America/North_Dakota/Center': 'US/Central',
        'America/North_Dakota/New_Salem': 'US/Central',
        'America/Phoenix': 'US/Mountain',
        'America/Shiprock': 'US/Mountain',
        'America/Sitka': 'US/Alaska',
        'America/Yakutat': 'US/Alaska',
    };

    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (Object.prototype.hasOwnProperty.call(timezoneRemap, timezone))
    {
        timezone = timezoneRemap[timezone];
    }

    return timezone;
}

export function timeAgo(secondsAgo)
{
    if (!secondsAgo || secondsAgo < 0)
    {
        return '-';
    }
    // under 15 minutes
    if (secondsAgo < 900)
    {
        return trans('base.justNow');
    }
    // under 30 minutes
    else if (secondsAgo < 1800)
    {
        return transChoice('base.minutesAgo', 15);
    }
    // under 1 hour
    else if (secondsAgo < 3600)
    {
        return transChoice('base.minutesAgo', 30);
    }
    // under 1 day
    else if (secondsAgo < 86400)
    {
        return transChoice('base.hoursAgo', Math.floor(secondsAgo / 3600));
    }
    // under 1 week
    else if (secondsAgo < 604800)
    {
        return transChoice('base.daysAgo', Math.floor(secondsAgo / 86400));
    }
    // under 1 month
    else if (secondsAgo < 2592000)
    {
        return transChoice('base.weeksAgo', Math.floor(secondsAgo / 604800));
    }
    // under 1 year
    else if (secondsAgo < 31536000)
    {
        return transChoice('base.monthsAgo', Math.floor(secondsAgo / 2592000));
    }
    // over 1 year
    else
    {
        return transChoice('base.yearsAgo', Math.floor(secondsAgo / 31536000));
    }
}