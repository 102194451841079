import { Quitch } from '../quitch';
import { getEventNameForElement, telemetryTrackAndPerformUrlChange } from './mixpanel';
import { calculateTime, trans } from '../common';

/**
 * Adding organization users url to organization list
 */
if ($('.organisation-list').length > 0)
{
    const data = d =>
    {
        return {
            _token: $('meta[name="csrf-token"]').attr('content'),
            start: d['start'],
            length: d['length'],
            search: d['search'],
            columns: d['columns'],
            order: d['order'],
        };
    };

    $('.organisation-list').DataTable(
    {
        ajax:
        {
            url: '/organisation/org-list',
            type: 'POST',
            data: data,
        },
        language:
        {
            info: trans('reporting.infoResults2', { start: '_START_', end: '_END_', total: '_TOTAL_' }),
            lengthMenu: trans('reporting.lengthMenuEop', { menu: '_MENU_' }),
            processing: trans('reporting.processing'),
            infoEmpty: trans('reporting.infoLearners', { start: 0, end: 0, total: 0 }),
            paginate:
            {
                next: '<i class="fa fa-angle-right"></i>',
                previous: '<i class="fa fa-angle-left"></i>',
            },
            search: '',
            searchPlaceholder: trans('base.search'),
        },
        paging: true,
        processing: true,
        pagingType: 'simple',
        serverSide: false,
        pageLength: 25,
        dom: 'ft<"row col-lg-12 ps-0 pe-0 bg-quitch-white sticky-footer pt-1 pb-1"l<"me-4 absolute-right right-65"i><"absolute-right"p>>',
        columns: [
            { data: 'name', title: trans('superUser.orgName'), },
            {
                data: 'plan',
                title: trans('superUser.plan'),
                render: function(data)
                {
                    return `<span class="lozenge">${data}</span>`;
                },
            },
            {
                data: 'created',
                title: trans('superUser.created'),
                render: data =>
                {
                    let date = new Date(data);
                    return date.toGMTString().substring(5, 16); //substring to remove day of week and time
                },
                sort: 'created',
                type: 'date',
                searchable: false,
            },
            { data: 'contactName', title: trans('superUser.contact'), },

            // Org stats
            { data: 'classCount', title: trans('superUser.classCount'), },
            { data: 'activeClassCount', title: trans('superUser.activeClassCount'), },
            { data: 'learnerCount', title: trans('superUser.learnerCount'), },
            { data: 'activeLearnerCount', title: trans('superUser.activeLearnerCount'), },
            { data: 'educatorCount', title: trans('superUser.educatorCount'), },
            { data: 'activeEducatorCount', title: trans('superUser.activeEducatorCount'), },

            {
                title: trans('superUser.actions'),
                render: function(data, type, row)
                {
                    return `<div style="display: flex; display: -webkit-box">
                                <a href="/impersonate/${row['key']}" data-bs-toggle="tooltip" data-bs-placement="top" data-original-title="Impersonate as Site Admin">
                                    <i class="fa fa-exchange fa-lg" aria-hidden="true"></i>
                                </a>
                                <a href="/users/${row['key']}" data-bs-toggle="tooltip" data-bs-placement="top" data-original-title="Organization Users">
                                    <i class="fa fa-users fa-lg" aria-hidden="true"></i>
                                </a>
                                <div class="dropdown">
                                    <button type="button" class="btn btn-clear toolbar-btn btn-dropdown-sm d-flex align-items-center justify-content-center" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="min-height: 20px">
                                        &bull;&bull;&bull;
                                    </button>
                                    <div class="dropdown-menu dropdown-menu-end custom-dropdown-list">
                                        <a href="/organisation/${row['id']}/edit" class="dropdown-item">Edit</a>
                                        <a href="/organization/${row['id']}/confirmDelete" class="dropdown-item">Delete</a>
                                    </div>
                                </div>
                            </div>`;
                },
                bSortable: false,
                searchable: false,
            },
        ],
        order: [
            [2, 'desc']
        ]
    });
}

$(document).on('click', '.js_admin-action', function(e)
{
    e.preventDefault();
    let token = $('meta[name="csrf-token"]').attr('content');
    let action = $(e.target).data('action');
    $.ajax(
    {
        url: action,
        method: 'POST',
        dataType: 'json',
        data: { _token: token },
        success: function(data)
        {
            Quitch.showMessage('success', data.message);
        },
        error: function(xhr, type, error)
        {
            if (error)
            {
                Quitch.showMessage('warning', error);
            }
            else
            {
                Quitch.showMessage('warning', 'Oops, an error occurred.');
            }
        },
    });
});

/**
 * Org delete confirmation
 */
$(document).on('keyup', 'input.js-delete_confirm', e =>
{
    const targetEl = $(e.target).data('bs-target');
    const disabled = 'delete' !== $(e.target).val();
    $(`#${targetEl}`).prop('disabled', disabled);
});

$(document).on('click', 'button.js-delete_confirm', e =>
{
    telemetryTrackAndPerformUrlChange($(e.target).data('href'), getEventNameForElement($(this)));
});