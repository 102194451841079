import
{
    deleteContent,
    getPrevContentId,
    scrollToContent,
    selectContent,
    trimWhitespaceAndBreaks,
    updateTopicIncompleteCounter,
    validateAndUpdateResourceIncompleteFlag,
    validateTitle,
    validInputLength
}
from './common';
import { selectDropdownItem } from '../../form/dropdown';
import { initialiseContentDetails } from './content-details';
import { loadPreview, loadResourcePreview } from './preview';
import { updatePublishBtn } from './schedule-publish';
import { Quitch } from '../../quitch';
import { trans } from '../../common';
import { createTopic } from './topic';

$(document).on('click', '.js-add-resource', function(e)
{
    const parentContent = $(this).closest('.content-authoring-content');
    const prevContentId = getPrevContentId(parentContent, this);
    const resourceType = $(this).attr('data-type');
    const selectedTopic = $('.content-authoring-topic.expanded');
    const selectedTopicId = selectedTopic.attr('data-id');
    const classId = $('[name=classId]').val();

    if (selectedTopicId)
    {
        const addFromDivider = $(this).closest('.add-divider-container').length > 0;

        addResource(classId, selectedTopicId, resourceType, prevContentId, !addFromDivider);
    }
    else
    {
        const classInstanceId = $('input[name=classInstanceId]').val();
        const token = $('meta[name="csrf-token"]').attr('content');

        const firstSection = $(this).closest('.content-authoring-wrapper').find('.content-authoring-section');
        if (firstSection.length)
        {
            const sectionId = firstSection.first().data('id');
            const sectionName = firstSection.first().find('.js-edit-section-topic-name').data('current-val');

            createTopic(trans('base.emptyTopicNamePlaceholder'), sectionId, sectionName, classInstanceId, token, null, resourceType);
        }
        else
        {
            createTopic(trans('base.emptyTopicNamePlaceholder'), null, trans('base.defaultSectionName'), classInstanceId, token, null, resourceType);
        }
    }
});

export function addResource(classId, topicId, resourceType = 'text', prevContentId = null, addToBottom = true)
{
    $('.content-authoring-wrapper').removeClass('expanded-sidebar');

    const newResourceEle = $($('.js-resource-template').first().html());

    selectDropdownItem(newResourceEle.find('.toolbar .js_dropdown-select'), resourceType);
    newResourceEle.attr('data-content-type', resourceType);
    newResourceEle.find('.resource-description .input-field').attr('data-placeholder', trans(resourceType === 'text' ? 'content.resourceDescRequiredPlaceholder' : 'content.resourceDescPlaceholder'));
    newResourceEle.append('<input type="hidden" name="newResourcePath" value="">');
    newResourceEle.append('<input class="filename-input image-filename-input doc-filename-input" type="hidden" name="originalFileName" value="">');
    newResourceEle.addClass('newly-added');
    newResourceEle.find('.resource-description').addClass('newly-added');

    if (resourceType === 'document')
    {
        newResourceEle.find('.resource-description').addClass('hide');
        newResourceEle.find('.content-details-quick-actions').hide();
    }

    const topic = $(`.content-authoring-topic[data-id="${topicId}"]`);
    if (prevContentId)
    {
        topic.find(`.content-authoring-content[data-content-id='${prevContentId}']`).after(newResourceEle);
    }
    else
    {
        if (addToBottom)
        {
            topic.find('.content-authoring-topic-footer').before(newResourceEle);
        }
        else
        {
            topic.find('.content-authoring-topic-body').prepend(newResourceEle);
        }
    }

    selectContent(newResourceEle);

    // watch for change to hidden input by modal
    newResourceEle.find('[name=newResourcePath]').one('change', function()
    {
        const newResourcePath = $(this).val();
        const originalFileName = newResourceEle.find('.filename-input').val() || null;
        const values = {
            _token: $('meta[name="csrf-token"]').attr('content'),
            type: resourceType,
            topicId: topicId,
            classId: classId,
            prevContentId: prevContentId,
            filePath: newResourcePath,
            originalFileName: originalFileName,
            addToBottom: addToBottom
        };

        $.ajax(
        {
            url: '/content/resource/save',
            type: 'POST',
            dataType: 'json',
            data: values,
            success: function(response)
            {
                newResourceEle.attr('data-content-id', response.resource.id);
                newResourceEle.attr('data-topic-content-id', response.topicContentId);
                for (const [key, value] of Object.entries(response.resource))
                {
                    newResourceEle.find(`[name=${key}]`).val(value).change();
                    if (key === 'filePath')
                    {
                        newResourceEle.find('[name=assetUrl]').val(response.resource.assetUrl);
                    }
                }
                initialiseContentDetails(newResourceEle);
                loadResourcePreview(newResourceEle);
                newResourceEle.find('.dropdown-item.js-schedule-publish').show();
                newResourceEle.find('.content-title .input-field').focus();
                updatePublishBtn();

                const footer = topic.find('.content-authoring-topic-footer');
                if (footer.length && footer.hasClass('empty-topic'))
                {
                    footer.removeClass('empty-topic');
                }
            }
        });
    });

    switch (resourceType)
    {
        case 'video':
            addResourceModal(newResourceEle, 'add-video-modal', 'add-video-done-btn', 'data-video-dest');
            break;
        case 'text':
            newResourceEle.find('[name=newResourcePath]').change();
            newResourceEle.find('.content-title .input-field').focus();
            break;
        case 'image':
            addResourceModal(newResourceEle, 'add-image-modal', 'add-image-done-btn', 'data-img-dest');
            break;
        case 'document':
            addResourceModal(newResourceEle, 'add-document-modal', 'add-doc-done-btn', 'data-doc-dest', 'pdf-only');
            break;
    }

    newResourceEle.find('.content-title .input-field').focus();
}

$(document).on('focusout', '.content-authoring-content.resource .content-input .input-field', function(e)
{
    $(this).parents('.content-title').removeClass('active');
    let input = this.innerHTML;
    const oldInput = input;
    const currentValue = $(this).attr('data-current-val');
    const resourceEle = $(this).parents('.content-authoring-content');
    const topicId = $(this).parents('.content-authoring-topic').attr('data-id');

    if (currentValue === input)
    {
        // Put error back if input didn't change
        if ($(this).hasClass('had-error') || $(this).parents('.content-title').hasClass('had-error'))
        {
            validateResource(resourceEle);
        }
        return;
    }
    else if ($(this).text().trim().length === 0)
    {
        input = '';
        $(this).html('').attr('data-current-val', '');
    }
    else
    {
        input = trimWhitespaceAndBreaks($(this));
    }

    if (input !== oldInput)
    {
        loadPreview(resourceEle);
    }

    let values = {
        _token: $('meta[name="csrf-token"]').attr('content'),
        classId: $('[name=classId]').val(),
        topicId: topicId,
        resourceId: resourceEle.attr('data-content-id'),
        [$(this).attr('name')]: input
    };

    $.ajax(
    {
        url: '/content/resource/save',
        type: 'POST',
        dataType: 'json',
        data: values,
        success: function(response)
        {
            resourceEle.find('.input-field').attr('data-current-val', input);
            resourceEle.attr('data-topic-content-id', response.topicContentId);
        },
    });

    validateAndUpdateResourceIncompleteFlag(resourceEle);
    updateTopicIncompleteCounter(resourceEle.parents('.content-authoring-topic'));
});

$(document).on('click', '.js-delete-resource', function(e)
{
    deleteContent($(this).parents('.content-authoring-content'), 'resource');
});

$(document).on('show.bs.modal', '#add-video-modal', function()
{
    $('#add-video-modal').find('[name=video-link]').val('').keyup();
});

$(document).on('click', '.js-duplicate-resource', function(e)
{
    const resourceEle = $(this).parents('.content-authoring-content');
    let values = {
        _token: $('meta[name="csrf-token"]').attr('content'),
        resourceId: resourceEle.attr('data-content-id'),
        classId: $('[name=classId]').val(),
    };

    $.ajax(
    {
        url: '/content/authoring/resource/duplicate',
        type: 'POST',
        dataType: 'json',
        data: values,
        success: function(response)
        {
            const newResource = $(response.resourceHtml);
            resourceEle.after(newResource);
            selectContent(newResource);
            scrollToContent(newResource);
            updatePublishBtn();
        }
    });
});

$(document).on('change', '[name=resource-type-change]', function(e)
{
    const resourceEle = $(this).parents('.content-authoring-content.resource');
    const newResourceType = $(this).val();
    const oldResourceType = resourceEle.attr('data-content-type');
    const dropdownBtn = resourceEle.find('.js-change-resource-type-dropdown .btn-dropdown');
    dropdownBtn.attr('disabled', true);

    if (newResourceType === oldResourceType)
    {
        dropdownBtn.removeAttr('disabled');
        return;
    }

    Quitch.modal(
        {
            title: trans('content.changeResourceTypeTitle'),
            message: trans('content.changeResourceTypeDesc'),
            type: 'confirm_custom',
            cssClass: 'modal-dialog-centered modal-dialog-sm',
            okText: trans('content.changeResourceType'),
        },
        function(response)
        {
            if (response === true)
            {
                changeResourceType(resourceEle, oldResourceType, newResourceType);
            }
            else
            {
                dropdownBtn.removeAttr('disabled');
                selectDropdownItem(dropdownBtn.parent(), oldResourceType);
            }
        });
});

function changeResourceType(resourceEle, oldResourceType, newResourceType)
{
    if ($('.content-authoring-wrapper').hasClass('expanded-sidebar'))
    {
        $('.content-authoring-wrapper').removeClass('expanded-sidebar');
    }

    if (newResourceType === 'text')
    {
        updateResourceAjax(resourceEle, { type: newResourceType, filePath: '', originalFileName: '', thumbnail: '' });
    }
    else
    {
        let modalId = '';
        let modalCss = '';
        let doneBtnId = '';
        let destInputId = '';

        switch (newResourceType)
        {
            case 'video':
                modalId = 'add-video-modal';
                doneBtnId = 'add-video-done-btn';
                destInputId = 'data-video-dest';
                break;
            case 'image':
                modalId = 'add-image-modal';
                doneBtnId = 'add-image-done-btn';
                destInputId = 'data-img-dest';
                break;
            case 'document':
                modalId = 'add-document-modal';
                doneBtnId = 'add-doc-done-btn';
                destInputId = 'data-doc-dest';
                modalCss = 'pdf-only';
                break;
        }

        resourceEle.append('<input type="hidden" name="newResourcePath" value="">');
        resourceEle.append('<input class="filename-input image-filename-input doc-filename-input" type="hidden" name="originalFileName" value="">');
        const newResourcePath = resourceEle.find('[name=newResourcePath]');
        newResourcePath.one('change', function()
        {
            const newResourcePath = $(this).val();
            const originalFileName = resourceEle.find('.filename-input').val() || null;
            updateResourceAjax(resourceEle, { type: newResourceType, filePath: newResourcePath, originalFileName: originalFileName, thumbnail: '' });
        });

        const dropdownBtn = resourceEle.find('.js-change-resource-type-dropdown .btn-dropdown');
        const modal = addResourceModal(resourceEle, modalId, doneBtnId, destInputId, modalCss, false);
        modal.one('hide.bs.modal', function(e)
        {
            if ($(document.activeElement).attr('id') !== doneBtnId)
            {
                dropdownBtn.removeAttr('disabled');
                selectDropdownItem(dropdownBtn.parent(), oldResourceType);
                newResourcePath.off('change');
                newResourcePath.remove();
                resourceEle.find('.filename-input').remove();
            }
        });
    }
}

function updateResourceAjax(resourceEle, newValues)
{
    const values = {
        _token: $('meta[name="csrf-token"]').attr('content'),
        classId: $('[name=classId]').val(),
        resourceId: resourceEle.attr('data-content-id'),
        topicId: resourceEle.parents('.content-authoring-topic').attr('data-id'),
        includeHtml: true
    };

    Object.assign(values, newValues);

    $.ajax(
    {
        url: '/content/resource/save',
        type: 'POST',
        dataType: 'json',
        data: values,
        success: function(response)
        {
            const updatedResource = $(response.resourceHtml);
            updatedResource.removeClass('collapsed').addClass('selected');
            resourceEle.replaceWith(updatedResource);
            loadResourcePreview(updatedResource);
            initialiseContentDetails(updatedResource);
        },
    });
}

function addResourceModal(newResourceEle, modalId, doneBtnId, destInputAttr, modalClass = '', selectPrevContent = true)
{
    const modal = $(`#${modalId}`);
    $(`#${doneBtnId}`).attr(destInputAttr, '[name=newResourcePath]').click(function(e)
    {
        // Focusing as Safari doesn't do it automatically, preventing the document.activeElement from being set to the done button
        $(this).focus();
    });

    modal.addClass(modalClass).modal('show');

    modal.one('hide.bs.modal', function(e)
    {
        // Adding small delay to ensure the click event is fired before the event is unbound
        setTimeout(() =>
        {
            $(`#${doneBtnId}`).unbind('click');
            if (selectPrevContent && $(document.activeElement).attr('id') !== doneBtnId)
            {
                // remove placeholder and select previous content
                const prevContent = newResourceEle.prev('.content-authoring-content');
                newResourceEle.remove();
                if (prevContent.length)
                {
                    selectContent(prevContent);
                }
                else
                {
                    selectContent($('.content-authoring-list .content-authoring-content').first());
                }
            }
        }, 1);
    });

    return modal;
}

export function validateResource(resourceEle)
{
    if (resourceEle.hasClass('newly-added'))
    {
        return true;
    }

    let valid;
    valid = validateTitle(resourceEle.find('.content-title'), 'content.errorTitleRequired', 150);
    valid = validateTitle(resourceEle.find('.resource-description'), resourceEle.attr('data-content-type') === 'text' ? 'content.paragraphRequired' : false, 2000) && valid;

    resourceEle.removeClass('incomplete-settings');
    if ((resourceEle.attr('data-content-type') === 'image' && !validInputLength(resourceEle.find('[name=imageAltText]'), 500)) ||
        !validInputLength(resourceEle.find('[name=urlText]'), 150))
    {
        resourceEle.addClass('incomplete-settings');
        valid = false;
    }

    return valid;
}