import { calculateTime, trans } from '../common';
import { updateStatsValue } from './content-analytics';

$(document).ready(function()
{
    initialiseAnalytics();
});

$(document).on('click', '#class-details-tab', function()
{
    reloadAnalyticsDropdown();
});

$(document).on('click', '.month-previous, .month-next', function(e)
{
    e.preventDefault();
    e.stopImmediatePropagation();
    let selectedTimePeriod = $('.time-range option:selected').attr('data-time-period');
    let timePeriods = JSON.parse(window.localStorage.getItem('timePeriods'));
    let customMonths = timePeriods.months;
    let customMonthsLocale = timePeriods.monthsLocale;

    if (selectedTimePeriod == 'lastMonth')
    {
        switchToCustomTimePeriod(selectedTimePeriod, 'customMonth');
        $('.time-period').attr('data-period', customMonths[customMonths.length - 1]);
    }
    const currentSelectMonth = $('.time-period').attr('data-period');
    let position;
    let length = customMonths.length;
    if (customMonths.includes(currentSelectMonth))
    {
        position = customMonths.indexOf(currentSelectMonth);
    }
    if ($(this).hasClass('month-previous'))
    {
        if (position > 0 && position < length)
        {
            $('.time-period').empty();
            $('.time-period').append(
                '<label><i class="fa fa-caret-left month-previous" aria-hidden="true"></i></label>' +
                customMonthsLocale[position - 1] +
                '<label><i class="fa fa-caret-right month-next" aria-hidden="true"></i></label>'
            );
            $('.time-period').attr('data-period', customMonths[position - 1]);
            if (position - 1 === 0)
            {
                $('.month-previous').addClass('disabled');
            }
            $('.time-period').trigger('change');
        }
        else
        {
            $('.month-previous').addClass('disabled');
        }
    }
    else if ($(this).hasClass('month-next'))
    {
        if (position >= 0 && position < length - 1)
        {
            $('.time-period').empty();
            $('.time-period').append(
                '<label style=""><i class="fa fa-caret-left month-previous" aria-hidden="true"></i></label>' +
                customMonthsLocale[position + 1] +
                '<label><i class="fa fa-caret-right month-next" aria-hidden="true"></i></label>'
            );
            $('.time-period').attr('data-period', customMonths[position + 1]);
            if (position + 1 === length - 1)
            {
                $('.month-next').addClass('disabled');
            }
            $('.time-period').trigger('change');
        }
        else
        {
            $('.month-next').addClass('disabled');
            $('.month-next').removeClass('');
        }
    }
});

$(document).on('click', '.week-previous,.week-next', function(e)
{
    e.preventDefault();
    e.stopImmediatePropagation();
    let timePeriods = JSON.parse(window.localStorage.getItem('timePeriods'));
    let customWeeks = timePeriods.weeks;
    let customWeeksLocale = timePeriods.weeksLocale;

    let selectedTimePeriod = $('.time-range option:selected').attr('data-time-period');
    if (selectedTimePeriod == 'lastWeek')
    {
        switchToCustomTimePeriod(selectedTimePeriod, 'customWeek');
        $('.time-period').attr('data-period', customWeeks[customWeeks.length - 1]);
    }
    const currentSelectWeek = $('.time-period').attr('data-period');
    let length = customWeeks.length;
    let position;
    if (customWeeks.includes(currentSelectWeek))
    {
        position = customWeeks.indexOf(currentSelectWeek);
    }

    if ($(this).hasClass('week-previous'))
    {
        if (position > 0 && position < length)
        {
            $('.time-period').empty();
            $('.time-period').append(
                '<label><i class="fa fa-caret-left week-previous" aria-hidden="true"></i></label>' +
                customWeeksLocale[position - 1] +
                '<label><i class="fa fa-caret-right week-next" aria-hidden="true"></i></label>'
            );
            $('.time-period').attr('data-period', customWeeks[position - 1]);
            if (position - 1 === 0)
            {
                $('.week-previous').addClass('disabled');
            }
            $('.time-period').trigger('change');
        }
        else
        {
            $('.week-previous').addClass('disabled');
        }
    }
    else if ($(this).hasClass('week-next'))
    {
        if (position >= 0 && position < length - 1)
        {
            $('.time-period').empty();
            $('.time-period').append(
                '<label><i class="fa fa-caret-left week-previous" aria-hidden="true"></i></label>' +
                customWeeksLocale[position + 1] +
                '<label><i class="fa fa-caret-right week-next" aria-hidden="true"></i></label>'
            );
            $('.time-period').attr('data-period', customWeeks[position + 1]);
            if (position + 1 === length - 1)
            {
                $('.week-next').addClass('disabled');
            }
            $('.time-period').trigger('change');
        }
        else
        {
            $('.week-next').addClass('disabled');
        }
    }
});

$(document).on('change', '.time-range', function()
{
    reloadAnalyticsDropdown();
});

$(document).on('change', '.time-period', function(event)
{
    const selectedTimeRange = $('.time-range option:selected').attr('data-time-period');
    const classId = $('.js_class-manager').data('class-id');
    if (selectedTimeRange !== 'allTime')
    {
        //This will hide completion column when selecting not viewing all statistics
        reloadAnalytics(false);
    }
    else
    {
        reloadAnalytics(true);
    }

    if ($(event.target).hasClass('time-period'))
    {
        filterActivityLineChart(classId);
    }
});

$(document).on('change', '.js_hide_names', function(event)
{
    if ($.fn.dataTable.isDataTable('.js_analytics_table'))
    {
        const orderJsTable = $('.js_analytics_table').DataTable();

        const hideName = $('.js_hide_names').is(':checked') ? true : false;
        orderJsTable.column(0).visible(!hideName);
        orderJsTable.column(1).visible(hideName);
    }
});

$(document).on('change', '.custom-day', function()
{
    const customDay = $('.custom-day').val();
    const classId = $('.js_class-manager').data('class-id');
    if (customDay)
    {
        reloadAnalytics(false);
        filterActivityLineChart(classId, customDay);
    }
});

function initialiseAnalytics()
{
    const isDashboard = $('#dashboard');
    if (isDashboard.length == 1)
    {
        loadAnalyticsTimePeriods();
    }
}

function filterActivityLineChart(classId, customDay = null)
{
    $('.graph-none').hide();
    $('.noData').remove();
    $('.chart-container').css({ position: 'relative' });
    $('.chart-container').append(`<div class="loading" style="font-size: 25px;font-family:inherit;position: absolute;top: 48%;left: 40%;" >${trans('base.loading')}</div>`);
    let token = $('meta[name="csrf-token"]').attr('content');
    const selectedTimeRange = $('.time-range option:selected').attr('data-time-period');
    let timePeriod;
    if (selectedTimeRange != 'yesterday' && selectedTimeRange != 'customDay')
    {
        timePeriod = $('.time-period')
            .attr('data-period')
            .split(' - ');
    }
    else if (customDay != null)
    {
        timePeriod = customDay;
    }
    else
    {
        timePeriod = $('.time-period').attr('data-period');
    }
    if (!classId || classId == '')
    {
        return;
    }
    $.ajax(
    {
        url: '/get-overview-activity-stats',
        type: 'POST',
        dataType: 'json',
        data: { _token: token, classId: classId, timePeriod: timePeriod, timeRange: selectedTimeRange },
        success: function(response)
        {
            $('.graph-none').hide();
            $('.loading').remove();
            let chart = document.getElementById('chart-legend-top').getContext('2d');
            const chartData = response.activity;
            if (chart)
            {
                // Destroy any existing charts to ensure that only one chart may exist at a time
                // To prevent issues which occur with overlapping charts
                Chart.helpers.each(Chart.instances, function(instance)
                {
                    instance.chart.destroy();
                });

                new Chart(chart,
                {
                    type: 'line',
                    data: chartData,
                    options:
                    {
                        scales: { xAxes: [{ gridLines: { display: false }, ticks: { fontColor: '#2F4858' } }], yAxes: [{ ticks: { suggestedMin: 0, fontColor: '#2F4858' } }] },
                    },
                });
                $('.chart-container').show();
            }
            if (chartData.participation)
            {
                $('.noData').remove();
            }
            else
            {
                $('.chart-container').css({ position: 'relative' });
                $('.chart-container').append(
                    `<div class="noData" style="font-size: 25px;font-family:inherit;position: absolute;top: 48%;width: 100%;text-align:center;" >${trans(
                            'overview.noData'
                        )}</div>`
                );
            }
        },
        error: function(response)
        {
            $('.graph-none').show();
            $('.chart-container').hide();
        },
    });
}

function switchToCustomTimePeriod(selectedTimePeriod, switchPeriod)
{
    $('.time-range option').each(function(index, option)
    {
        if (option.className == switchPeriod)
        {
            $('.time-range option[value=' + option.value + ']').prop('selected', true);
        }
    });
}

function loadAnalyticsTimePeriods()
{
    let classId = $('.js_class-manager').data('class-id');
    const url = `/reporting/timePeriod/${classId}`;
    $.ajax(
    {
        url: url,
        type: 'GET',
        dataType: 'json',
        success: function(response)
        {
            window.localStorage.setItem(
                'timePeriods',
                JSON.stringify(
                {
                    yesterday: response.yesterday,
                    lastWeek: response.lastWeek,
                    lastMonth: response.lastMonth,
                    weeks: response.weeks,
                    months: response.month,
                    allTime: response.allTime,
                    yesterdayLocale: response.yesterdayLocale,
                    lastWeekLocale: response.lastWeekLocale,
                    lastMonthLocale: response.lastMonthLocale,
                    weeksLocale: response.weeksLocale,
                    monthsLocale: response.monthLocale,
                    allTimeLocale: response.allTimeLocale,
                })
            );

            reloadAnalyticsDropdown();
        },
    });
}

function reloadAnalyticsDropdown()
{
    let classId = $('.js_class-manager').data('class-id');
    const selectedTimeRange = $('.time-range option:selected').attr('data-time-period');
    $('.time-period').empty();
    let timePeriods = JSON.parse(window.localStorage.getItem('timePeriods'));

    switch (selectedTimeRange)
    {
        case 'yesterday':
            $('.time-period').append(timePeriods.yesterdayLocale);
            $('.time-period').attr('data-period', timePeriods.yesterday);
            break;
        case 'lastWeek':
            $('.time-period').append(
                `<label><i class="fa fa-caret-left week-previous ${timePeriods.weeks.length < 2 ? 'disabled' : ''}" aria-hidden="true"></i></label>` +
                timePeriods.lastWeekLocale
            );
            $('.time-period').attr('data-period', timePeriods.lastWeek);
            break;
        case 'lastMonth':
            $('.time-period').append(
                `<label><i class="fa fa-caret-left month-previous ${timePeriods.months.length < 2 ? 'disabled' : ''}" aria-hidden="true"></i></label>` +
                timePeriods.lastMonthLocale
            );
            $('.time-period').attr('data-period', timePeriods.lastMonth);
            break;
        case 'customDay':
        {
            $('.time-period').append(`<input type="date" class="custom-day" autocomplete="off" value="${timePeriods.yesterday}">`);
            $('.time-period').attr('data-period', timePeriods.yesterday);
            break;
        }
        case 'customWeek':
            $('.time-period').append(
                `<label><i class="fa fa-caret-left week-previous ${timePeriods.weeks.length === 1 ? 'disabled' : ''}" aria-hidden="true"></i></label>` +
                timePeriods.weeksLocale[timePeriods.weeksLocale.length - 1] +
                '<label><i class="fa fa-caret-right week-next disabled" aria-hidden="true"></i></label>'
            );
            $('.time-period').attr('data-period', timePeriods.weeks[timePeriods.weeks.length - 1]);
            break;
        case 'customMonth':
            $('.time-period').append(
                `<label><i class="fa fa-caret-left month-previous ${timePeriods.months.length === 1 ? 'disabled' : ''}" aria-hidden="true"></i></label>` +
                timePeriods.monthsLocale[timePeriods.monthsLocale.length - 1] +
                '<label><i class="fa fa-caret-right month-next disabled" aria-hidden="true"></i></label>'
            );
            $('.time-period').attr('data-period', timePeriods.months[timePeriods.months.length - 1]);
            break;
        case 'allTime':
            $('.time-period').append(timePeriods.allTimeLocale);
            $('.time-period').attr('data-period', timePeriods.allTime);
            break;
        default:
            break;
    }
    if (selectedTimeRange == 'allTime')
    {
        reloadAnalytics(true);
        filterActivityLineChart(classId);
    }
    else if (selectedTimeRange == 'customDay')
    {
        return false;
    }
    else
    {
        reloadAnalytics(false);
        filterActivityLineChart(classId);
    }
}

//leaderboard Analytics
function reloadAnalytics(isAllTime)
{
    const classId = $('.js_class-manager').data('class-id');
    const url = `/class/${classId}/analytics`;

    const data = d =>
    {
        return {
            _token: $('meta[name="csrf-token"]').attr('content'),
            start: d['start'],
            length: d['length'],
            search: d['search'],
            columns: d['columns'],
            order: d['order'],
            tableName: $('.time-range option:selected').val(),
            customDate: $('.time-period').text() ? $('.time-period').attr('data-period') : $('.custom-day').val(),
        };
    };

    if ($.fn.dataTable.isDataTable('.js_analytics_table'))
    {
        const orderJsTable = $('.js_analytics_table').DataTable();
        //This will hide completion column when selecting not viewing all statistics
        if (!isAllTime)
        {
            orderJsTable.column(2).visible(false);
            orderJsTable.order([
                [5, 'desc']
            ]).draw();
        }
        else
        {
            orderJsTable.column(2).visible(true);
            orderJsTable.order([
                [5, 'desc']
            ]).draw();
        }
        return;
    }

    const jsTable = $('.js_analytics_table').DataTable(
    {
        ajax:
        {
            url: url,
            type: 'POST',
            data: data,
        },
        language:
        {
            info: trans('students.tableEntries', { start: '_START_', end: '_END_', total: '_TOTAL_' }),
            lengthMenu: trans('reporting.lengthMenuLearners', { menu: '_MENU_' }),
            processing: trans('reporting.processing'),
            zeroRecords: trans('reporting.empty'),
            infoEmpty: trans('reporting.infoLearners', { start: 0, end: 0, total: 0 }),
            paginate:
            {
                next: '<i class="fa fa-angle-right"></i>',
                previous: '<i class="fa fa-angle-left"></i>',
            },
            search: '',
            searchPlaceholder: trans('base.search'),
        },
        paging: true,
        processing: true,
        lengthChange: false,
        pagingType: 'simple',
        serverSide: true,
        pageLength: 25,
        dom: '<"js_analytics_table-hide-names">frt<"col-lg-8"i><"col-lg-4"p>',
        columns: [
        {
            data: 'name',
            title: trans('overview.learner'),
            width: '20%',
            className: 'details-control',
            orderable: false,
            render: function(data, type, row)
            {
                return row['name'];
            },
        },
        {
            data: 'screenName',
            title: trans('overview.learner'),
            width: '20%',
            className: 'details-control',
            orderable: false,
            visible: false,
            render: function(data, type, row)
            {
                return row['screenName'];
            },
        },
        {
            data: 'completion',
            width: '20%',
            className: 'details-control',
            orderSequence: ['desc', 'asc'],
            render: data =>
            {
                let bar = `<div class="progress">
                                <div class="progress-bar bg-${getProgressClass(data)}" style="width:${data}%;"
                                role="progressbar" aria-valuenow="${data}" aria-valuemin="0" aria-valuemax="100">`;
                if (data >= 25)
                {
                    bar = bar + data + '%' + '</div></div>';
                }
                else
                {
                    bar = bar + '</div><span style="margin-top: -1px;">' + data + '%' + '</span></div>';
                }
                return bar;
            },
        },
        {
            data: 'accuracy',
            width: '20%',
            className: 'details-control',
            orderSequence: ['desc', 'asc'],
            render: data =>
            {
                let bar = `<div class="progress">
                                <div class="progress-bar bg-${getProgressClass(data)}" style="width:${data}%;"
                                role="progressbar" aria-valuenow="${data}" aria-valuemin="0" aria-valuemax="100">`;
                if (data >= 25)
                {
                    bar = bar + data + '%';
                }
                else
                {
                    bar = bar + '</div><span style="margin-top: -1px;">' + data + '%' + '</span></div>';
                }
                return bar + '</div></div>';
            },
        },
        {
            data: 'avg_duration',
            title: trans('overview.timeSpent'),
            width: '20%',
            className: 'details-control',
            orderSequence: ['desc', 'asc'],
            render: data =>
            {
                if (data)
                {
                    return calculateTime(data);
                }
                else
                {
                    return '-';
                }
            },
        },
        {
            data: 'total_points',
            title: trans('overview.points'),
            width: '20%',
            className: 'details-control',
            orderSequence: ['desc', 'asc'],
        }, ],
        //Order by average completion when the initial ajax is called
        order: [5, 'desc'],
    });

    $('#dashboard .dataTables_filter').append(
        `<span style="vertical-align: text-bottom;"><input id="hideName" class="d-inline-block float-right form-check-input js_hide_names" type="checkbox">&nbsp;<label for="hideName" class="form-check-label hide-names">${trans(
            'overview.hideNames'
        )}</label></span>`
    );
}

function getProgressClass(data)
{
    const percentage = data;
    if (percentage >= 0 && percentage < 40)
    {
        return 'danger';
    }
    else if (percentage >= 40 && percentage < 64)
    {
        return 'warning';
    }
    else if (percentage >= 64 && percentage <= 100)
    {
        return 'success';
    }
}