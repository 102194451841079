import { updateClassPublishStatus } from './index';
import { trans } from '../../common';
import { Quitch } from '../../quitch';
import { displayImagePreview, removeUploadedFile, updateUploadedFileName } from '../../form/file-upload';
import { telemetryTrackEvent } from '../../superuser/mixpanel';

/**
 * Setup add resource modal
 */
$(document).on('click', '.js-add-resource-btn', function(e)
{
    e.preventDefault();
    initResourceModal();
    $('.js-resource-modal-title').text(trans('content.addResource'));

    $('#js-save-resource-btn').prop('disabled', true);
    $('#js-resource-id').val(null);
    $('#js-resource-order-dnd').val(null);
    $('#js-close-resource-btn').hide();
    $('#resource-modal').modal('show');
});

/**
 * Setup edit resource modal
 */
$(document).on('click', '.js-edit-resource-btn', function(e)
{
    e.preventDefault();
    initResourceModal();
    $('.js-resource-modal-title').text(trans('content.editResource'));

    let id = $(this).attr('data-content-id');
    $('#js-resource-id').val(id);
    let orderDnd = $(this).parentsUntil(null, '.js_content-list-item').attr('data-order-dnd');
    $('#js-resource-order-dnd').val(orderDnd);
    $('#js-close-resource-btn').show();
    loadResource(id);
});

function initResourceModal()
{
    hideAllResourceInputs(true);
    $('.resource-type-options input[name="type"]').prop('checked', false);
    const imageUploadSection = $('#resource-image-box .file-upload-section');
    removeUploadedFile('resource', imageUploadSection, imageUploadSection.find('[data-file-upload]'));
    const docUploadSection = $('#resource-doc-box .file-upload-section');
    removeUploadedFile('document', docUploadSection, docUploadSection.find('[data-file-upload]'));
}

function loadResource(resourceId)
{
    let values = {};
    values['_token'] = $('meta[name="csrf-token"]').attr('content');
    values['resourceId'] = resourceId;

    $.ajax(
    {
        url: '/content/resource',
        type: 'GET',
        dataType: 'json',
        data: values,
        success: async function(response)
        {
            let type = response.resource.type;
            $(`#resource-details-card input[name=title]`).val(response.resource.title).keyup();
            $(`#resource-details-card textarea[name=description]`).val(response.resource.description).keyup();
            $(`#resource-details-card input[name=url]`).val(response.resource.url);
            $(`#resource-details-card input[name=urlText]`).val(response.resource.urlText).keyup();

            const imageSection = $('#resource-image-box .file-upload-section');
            const imagePreview = $('#resource-image-box .image-preview');
            const docSection = $('#resource-doc-box .file-upload-section');
            const docPreview = $('#resource-doc-box .image-preview');

            switch (type)
            {
                case 'text':
                    break;
                case 'image':
                    $(`#resource-details-card input[value="${response.resource.imagePosition}"]`).prop('checked', true).change();
                    $(`#resource-image-box input[name=resource-img-path]`).val(response.resource.filePath);
                    displayImagePreview(imageSection, imagePreview, response.resource.originalFileName, response.resource.filePath.split('.').pop(), response.resource.assetUrl, response.resource.filePath);
                    updateUploadedFileName(response.resource.originalFileName, response.resource.fileSize, null, imageSection.parentsUntil(null, '.image-box'), true, false);
                    break;
                case 'video':
                    if (response.resource.filePath)
                    {
                        const vidLinkInput = $(`#resource-details-card input[name=video-link]`);
                        vidLinkInput.val(response.resource.filePath);
                        vidLinkInput.focusout();
                    }
                    break;
                case 'document':
                    $(`#resource-doc-box input[name=resource-doc-path]`).val(response.resource.filePath);
                    displayImagePreview(docSection, docPreview, response.resource.originalFileName, response.resource.filePath.split('.').pop(), response.resource.assetUrl, response.resource.filePath);
                    updateUploadedFileName(response.resource.originalFileName, response.resource.fileSize, response.resource.assetUrl, docSection.parentsUntil(null, '.image-box'), true, false);
                    break;
                default:
            }

            $(`.resource-type-options input[name="type"][value="${type}"]`).prop('checked', true).change();
            $('#resource-modal').modal('show');
        },
        error: function(e, t, s)
        {
            Quitch.showSnackbar(trans('orgManager.errorOccurred'));
        },
    });
}

/*
 Only show urlText field when the url has been filled
 */
$(document).on('input', '#resource-details-card input[name="url"]', function()
{
    if ($(this).val())
    {
        showResourceInputs(['urlText']);
    }
    else
    {
        hideResourceInputs(['urlText']);
    }
});

$(document).on('shown.bs.modal', '#resource-modal', function()
{
    loadAttachQuestions();
});

/*
 Update shown inputs in resource form based on the type of resource selected
 */
$(document).on('change', '.resource-type-options input[name="type"]', function()
{
    hideAllResourceInputs();

    if ($(this).val() === 'text')
    {
        $('label[name="description"]').html(trans('content.descLabel'));
    }
    else
    {
        $('label[name="description"]').html(trans('content.descOptionalLabel'));
    }

    switch ($(this).val())
    {
        case 'text':
            showResourceInputs(['title', 'description', 'url']);
            break;
        case 'image':
            showResourceInputs(['title', 'resource-img-path', 'imagePosition', 'description', 'url']);
            break;
        case 'video':
            showResourceInputs(['title', 'video-link', 'description', 'url']);
            break;
        case 'document':
            showResourceInputs(['title', 'resource-doc-path']);
            break;
        default:
    }
});

function showResourceInputs(inputNames)
{
    $('.default-resource-text').hide();
    $.each(inputNames, function(index, value)
    {
        let ele = $(`#resource-details-card input[name=${value}], #resource-details-card textarea[name=${value}]`);
        $(ele).prop('disabled', false);
        $(ele).siblings('input').prop('disabled', false);
        $(ele).parents('.form-group-depreciated').show();

        //ensure that if any related fields should be shown they are shown (e.g. url and urlText)
        ele.keyup();
    });
}

function hideResourceInputs(inputNames, clearInputs = false)
{
    $.each(inputNames, function(index, value)
    {
        let ele = $(`#resource-details-card input[name=${value}], #resource-details-card textarea[name=${value}]`);
        if (clearInputs && $(ele).val() && ($(ele).attr('type') === 'text' || $(ele).is('textarea')))
        {
            $(ele).val('').change();
        }

        $(ele).prop('disabled', true).keyup();
        $(ele).parents('.form-group-depreciated').hide();
    });
}

function hideAllResourceInputs(clearInputs = false)
{
    let inputNames = $('#resource-details-card input:not([type=hidden]), #resource-details-card textarea').map(function()
    {
        return $(this).attr('name');
    }).get();

    hideResourceInputs(inputNames, clearInputs);
}

function loadAttachQuestions()
{
    let values = {};
    values['_token'] = $('meta[name="csrf-token"]').attr('content');
    values['topicId'] = $('.section-list-item.active').attr('data-section');
    let resourceId = $('#js-resource-id').val();
    values['resourceId'] = resourceId ? resourceId : null;

    $.ajax(
    {
        url: '/content/resource/topic-questions',
        type: 'GET',
        dataType: 'json',
        data: values,
        success: function(response)
        {
            let innerHtml = '';
            $.each(response.questions, function(index, value)
            {
                innerHtml += `<div class="question-btn">
                                        <label>
                                            <input type="checkbox" name="attached-questions" value="${value.id}" ${value.hasResource && !value.isAttached ? 'disabled' : ''}
                                                    ${value.isAttached ? 'checked class="attached"' : ''} >
                                            <i><span>${value.title}</span></i>
                                        </label>
                                    </div> `;
            });

            $('#attach-question-container').html(innerHtml);
        },
        error: function(e, t, s) {

        },
    });
}

$(document).on('click', '#attach-question-container .question-btn', function(e)
{
    let input = $(this).find('input[name="attached-questions"]');
    if (!input.is(':checked') && input.hasClass('attached'))
    {
        input.addClass('detached');
    }
    else if (input.hasClass('detached'))
    {
        input.removeClass('detached');
    }
});

/*
 Submit resource form
 */
$(document).on('click', '#js-save-resource-btn', function(e)
{
    e.preventDefault();

    $(this).prop('disabled', true);

    // Clear old errors
    $('#resource-details-card input, #resource-details-card textarea')
        .removeClass('error-input').closest('.form-group-depreciated')
        .find('.error-msg').remove();

    let values = new FormData($('#resource-details-form')[0]);
    let topicId = $('.section-list-item.active').attr('data-section');
    let resourceType = $('#resource-type-card input[name="type"]').filter(':checked').val();

    values.append('_token', $('meta[name="csrf-token"]').attr('content'));
    values.append('classId', $('.js_class_details').data('class-id'));
    values.append('topicId', topicId);
    values.append('type', resourceType);

    switch (resourceType)
    {
        case 'image':
            values.append('filePath', $('#resource-details-form input[name="resource-img-path"]').val());
            values.append('originalFileName', $('#js-resource-img-file-name').val());
            break;
        case 'video':
            values.append('filePath', $('#resource-details-form input[name="video-link"]').val());
            break;
        case 'document':
            values.append('filePath', $('#resource-details-form input[name="resource-doc-path"]').val());
            values.append('originalFileName', $('#js-resource-doc-file-name').val());
            break;
        default:
            break;
    }

    let attachedQuestions = $('input[name="attached-questions"]:not(.attached):checked').map(function()
    {
        return $(this).val();
    }).get();

    let detachedQuestions = $('input[name="attached-questions"].detached').map(function()
    {
        return $(this).val();
    }).get();

    values.append('attachedQuestions', attachedQuestions);
    values.append('detachedQuestions', detachedQuestions);
    values.append('showFlash', true);
    values.append('validate', true);

    $.ajax(
    {
        url: '/content/resource/save',
        type: 'POST',
        dataType: 'json',
        data: values,
        cache: false,
        processData: false,
        contentType: false,
        success: function(response)
        {
            $('#resource-modal').modal('hide');
            Quitch.updateUrl({ section: topicId }, 'ep-save-resource-success');
            updateClassPublishStatus('modified');
        },
        error: function(xhr, status, error)
        {
            if (xhr.responseJSON.errors)
            {
                // show error message below input
                $.each(xhr.responseJSON.errors, function(inputName, errMsg)
                {
                    $(`input[name="${inputName}"], textarea[name="${inputName}"]`).addClass('error-input').after(`<p class="error-msg">${errMsg}</p>`);
                });
            }
            else
            {
                Quitch.showSnackbar(trans('orgManager.errorOccurred'));
            }

            telemetryTrackEvent('ep-save-resource-error');
        },
    });
});

$(document).on('click', '#js-delete-resource-btn, .resource-del-btn', function(e)
{
    e.preventDefault();

    let topicId = $('.section-list-item.active').attr('data-section');
    let values = {};
    values['_token'] = $('meta[name="csrf-token"]').attr('content');
    values['resourceId'] = $(this).hasClass('resource-del-btn') ? $(this).attr('data-content-id') : $('#js-resource-id').val();

    $.ajax(
    {
        url: '/content/resource/remove',
        type: 'POST',
        dataType: 'json',
        data: values,
        success: function()
        {
            $('#resource-modal').modal('hide');
            Quitch.showSnackbar(trans('content.resourceDeleted'));
            Quitch.updateUrl({ section: topicId }, 'ep-delete-resource-success');

            updateClassPublishStatus('modified');
        },
        error: function(xhr, status, error)
        {
            if (xhr.responseJSON.errors)
            {
                // show error message below input
                $.each(xhr.responseJSON.errors, function(inputName, errMsg)
                {
                    $(`input[name="${inputName}"], textarea[name="${inputName}"]`).addClass('error-input').after(`<p class="error-msg">${errMsg}</p>`);

                    telemetryTrackEvent('ep-delete-resource-error', { error: errMsg ? errMsg.toString() : 'unknown' });
                });
            }
            else
            {
                Quitch.showSnackbar(trans('orgManager.errorOccurred'));
                telemetryTrackEvent('ep-delete-resource-error');
            }
        },
    });
});

/**
 * Enable resource save button if required inputs are filled
 */
$(document).on('keyup paste blur change', '#resource-details-card input, #resource-details-card textarea, .resource-type-options input', function()
{
    let resourceType = $('#resource-type-card input[name="type"]').filter(':checked').val();
    let saveBtnDisabled = false;
    let inputNames = [];

    switch (resourceType)
    {
        case 'text':
            inputNames = ['title', 'description'];
            break;
        case 'image':
            inputNames = ['title', 'resource-img-path', 'imagePosition'];
            break;
        case 'video':
            inputNames = ['title', 'video-link'];
            break;
        case 'document':
            inputNames = ['title', 'resource-doc-path'];
            break;
        default:
            saveBtnDisabled = true;
    }

    $.each(inputNames, function()
    {
        let val = $(`#resource-details-card input[name="${this}"], #resource-details-card textarea[name="${this}"]`).val();
        if (!val || $.trim(val).length === 0)
        {
            saveBtnDisabled = true;
            return false;
        }
        if (this === 'video-link')
        {
            if (!$(`#resource-details-card input[name="${this}"]`).parent().hasClass('valid'))
            {
                saveBtnDisabled = true;
                return false;
            }
        }
    });

    $('#js-save-resource-btn').prop('disabled', saveBtnDisabled);
});

// Remove error message on input on change
$(document).on('keyup', '#resource-details-card input, #resource-details-card textarea', function()
{
    $(this).removeClass('error-input').closest('.form-group-depreciated').find('.error-msg').remove();
});