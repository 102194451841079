import { trans } from '../common';
import { detectOS } from './content-authoring/common';

$(document).on('selectstart', '.wysiwyg-editor', function()
{
    const inputField = $(this);

    if ($('#wysiwyg-editor-balloon').length > 0)
    {
        $('#wysiwyg-editor-balloon').remove();
    }

    if (inputField.attr('contenteditable') === 'false')
    {
        return;
    }

    $(document).one('mouseup', function()
    {
        const windowSelection = window.getSelection();
        if (!windowSelection || windowSelection.rangeCount === 0)
        {
            return;
        }

        const selectionRange = windowSelection.getRangeAt(0);
        const startContainer = selectionRange.startContainer;
        const endContainer = selectionRange.endContainer;

        if (startContainer === endContainer && selectionRange.startOffset === selectionRange.endOffset)
        {
            // No text selected
            return;
        }

        if (!inputField.attr('balloon-open'))
        {
            let activeTags = {
                'b': false,
                'i': false,
                'u': false,
                'ul': false,
                'ol': false
            };

            let clonedSelection = selectionRange.cloneContents();
            for (let tag in activeTags)
            {
                if (startContainer === endContainer)
                {
                    activeTags[tag] = checkNodeInTag(startContainer, tag);
                }
                else
                {
                    activeTags[tag] = checkAllNodesInTag(clonedSelection.childNodes, tag);
                    // If both start and end nodes are in the same tag, set the tag to active
                    if (selectionRange.commonAncestorContainer.localName === tag)
                    {
                        activeTags[tag] = true;
                    }
                }
            }

            let listsEnabled = inputField.attr('data-editor-lists-enabled');
            let formulasEnabled = inputField.attr('data-editor-formulas-enabled');
            let editorWidth = 104;
            const isMac = detectOS() === 'Macintosh';

            let editorHtml = `<div id="wysiwyg-editor-balloon">
                                    <span data-type="bold" class="${activeTags.b ? 'active' : ''}" data-tooltip-title="${trans('content.boldBtn')} ${isMac ? '(⌘B)' : '(Ctrl+B)'}"></span>
                                    <span data-type="italic" class="${activeTags.i ? 'active' : ''}" data-tooltip-title="${trans('content.italicBtn')} ${isMac ? '(⌘I)' : '(Ctrl+I)'}"></span>
                                    <span data-type="underline" class="${activeTags.u ? 'active' : ''}" data-tooltip-title="${trans('content.underlineBtn')} ${isMac ? '(⌘U)' : '(Ctrl+U)'}"></span>`;

            if (listsEnabled === 'true')
            {
                editorHtml += `<div class="separator"></div>
                               <span data-type="uList" class="${activeTags.ul ? 'active' : ''}" data-tooltip-title="${trans('content.unorderedListBtn')}"></span>
                               <span data-type="oList" class="${activeTags.ol ? 'active' : ''}" data-tooltip-title="${trans('content.orderedListBtn')}"></span>`;
                editorWidth += 80;
            }

            if (formulasEnabled === 'true')
            {
                editorHtml += `<div class="separator"></div>
                               <span data-type="math"></span>
                               <span data-type="chem"></span>`;
                editorWidth += 80;
            }

            editorHtml += '</div>';
            const editorObj = $(editorHtml);

            // Position the editor above and centred with the selection
            let domRect = selectionRange.getBoundingClientRect();
            editorObj.css('left', Math.max((domRect.left + domRect.width / 2) - (editorWidth / 2), 8) + 'px');
            editorObj.css('top', domRect.top - 40 + 'px');

            inputField.addClass('balloon-open');
            $('body').append(editorObj);
        }
    });
});

$(document).on('mouseover', '#wysiwyg-editor-balloon span', function(e)
{
    const toolTipObj = $(`<div class="wysiwyg-tooltip"><span class="inner body-small">${$(this).attr('data-tooltip-title')}</span></div>`);

    // Position the tooltip 8px above and centred with the button
    let domRect = $(this).get(0).getBoundingClientRect();
    toolTipObj.css('left', (domRect.left + domRect.width / 2) - 100 + 'px');
    toolTipObj.css('top', domRect.top - 32 + 'px');
    $('body').append(toolTipObj);

    $(document).one('mouseout', function()
    {
        $('.wysiwyg-tooltip').remove();
    });
});

/**
 * Prevent selection disappearing on wysiwyg button click
 */
$(document).on('mousedown', '#wysiwyg-editor-balloon span', function(e)
{
    e = e || window.event;
    e.preventDefault();
});

/**
 * On click of the wysiwyg editor balloon button
 */
$(document).on('click', '#wysiwyg-editor-balloon span', function(e)
{
    let formatType = $(this).attr('data-type');
    let button = $(this);
    let command = null;
    switch (formatType)
    {
        case 'bold':
            command = 'bold';
            break;
        case 'italic':
            command = 'italic';
            break;
        case 'underline':
            command = 'underline';
            break;
        case 'uList':
            command = 'insertUnorderedList';
            break;
        case 'oList':
            command = 'insertOrderedList';
            break;
        case 'math':
            // TODO: Implement math & chemistry formula editor
            break;
        case 'chem':
            break;
        default:
            return;
    }
    // Execute the formatting command
    document.execCommand(command);
    if (document.queryCommandState)
    {
        if (formatType === 'uList' || formatType === 'oList')
        {
            button.parent().find('span[data-type="uList"]').toggleClass('active', document.queryCommandState('insertUnorderedList'));
            button.parent().find('span[data-type="oList"]').toggleClass('active', document.queryCommandState('insertOrderedList'));
        }
        else
        {
            button.toggleClass('active', document.queryCommandState(command));
        }
    }

    cleanUpInput($('.input-field.wysiwyg-editor.balloon-open'));

    //ensure change event gets called
    let selection = document.getSelection();
    if (selection && selection.baseNode)
    {
        $(selection.baseNode).parents('[contenteditable=true]').change();
    }
});

/**
 * Hide wysiwyg editor balloon when focus is lost and no text is selected
 */
$(document).on('focusout keydown mousedown', '.wysiwyg-editor', function(e)
{
    window.setTimeout(() =>
    {
        if (window.getSelection().toString() === '' || (e.type === 'focusout' && document.activeElement != e.currentTarget))
        {
            $(this).removeClass('balloon-open');
            $('#wysiwyg-editor-balloon').remove();
        }
    }, 100);
});

$(document).on('keydown', '.wysiwyg-editor', function(e)
{
    const focusNode = $(window.getSelection().focusNode);
    if (e.key === 'Enter')
    {
        if (focusNode.parents('ul, ol').length > 0)
        {
            // Remove div from wysiwyg editor when hitting enter to exit lists
            const inputField = $(this);
            window.setTimeout(() =>
            {
                inputField.find('div').each(function()
                {
                    $(this).replaceWith(function() { return $(this).contents(); });
                });
            }, 0);
        }
        else if (focusNode.parents('li').length === 0)
        {
            // Simulate Shift + Enter on Enter to prevent div from being created
            document.execCommand('insertLineBreak');
            e.preventDefault();
        }
    }

    // Enable formatting shortcuts on Safari (For some reason Chromium browsers include Safari in userAgent on Mac)
    if (detectOS() === 'Macintosh' && window.navigator.userAgent.includes('Safari') && !window.navigator.userAgent.includes('Chrome'))
    {
        if (e.metaKey && e.key === 'b')
        {
            e.preventDefault();
            document.execCommand('bold');
        }
        else if (e.metaKey && e.key === 'i')
        {
            e.preventDefault();
            document.execCommand('italic');
        }
        else if (e.metaKey && e.key === 'u')
        {
            e.preventDefault();
            document.execCommand('underline');
        }
    }

    // Update wysiwyg editor balloon button state when formatting shortcuts are used
    if ((e.metaKey) || e.ctrlKey && $('#wysiwyg-editor-balloon').length > 0)
    {
        let type = null;
        switch (e.key)
        {
            case 'b':
                type = 'bold';
                break;
            case 'i':
                type = 'italic';
                break;
            case 'u':
                type = 'underline';
                break;
        }

        window.setTimeout(function()
        {
            if (type)
            {
                $(`#wysiwyg-editor-balloon span[data-type="${type}"]`).toggleClass('active', document.queryCommandState(type));
            }
        }, 1);
    }
});

/**
 * Cleanup inline styling that's created when formatting shortcuts are used
 */
$(document).on('keyup', '.wysiwyg-editor', function(e)
{
    const inputField = $(this);
    inputField.find('b, u, i').each(function()
    {
        const style = $(this).attr('style');
        if (style)
        {
            if (style.includes('bold'))
            {
                $(this).contents().filter(function()
                {
                    return this.nodeType !== Node.ELEMENT_NODE;
                }).wrap('<b></b>');
            }

            if (style.includes('underline'))
            {
                $(this).contents().filter(function()
                {
                    return this.nodeType !== Node.ELEMENT_NODE;
                }).wrap('<u></u>');
            }

            if (style.includes('italic'))
            {
                $(this).contents().filter(function()
                {
                    return this.nodeType !== Node.ELEMENT_NODE;
                }).wrap('<i></i>');
            }
        }
    }).removeAttr('style');
});

function checkNodeInTag(node, tag)
{
    return $(node).parents(tag).length > 0;
}

function checkAllNodesInTag(nodeList, tag)
{
    if (nodeList.length > 0)
    {
        for (let i = 0; i < nodeList.length; i++)
        {
            if (nodeList[i].childNodes.length > 0)
            {
                if (!checkAllNodesInTag(nodeList[i].childNodes, tag))
                {
                    return false;
                }
            }
            else
            {
                // Is child of tag
                return checkNodeInTag(nodeList[i], tag);
            }
        }

        return true;
    }
    else
    {
        return false;
    }
}

export function cleanUpInput(inputField)
{
    // Remove empty tags except break
    inputField.find('*:not(br):empty').remove();

    // Remove divs and spans but keep inner html
    inputField.find('div, span').each(function()
    {
        $(this).replaceWith(function() { return $(this).contents(); });
    });

    // Remove all styling
    inputField.find('*').removeAttr('style');
}