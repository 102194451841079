import { trans } from '../common';
import { Quitch } from '../quitch';
import { displayImagePreview, removeUploadedFile, updateProfileImagePlaceholder, updateUploadedFileName } from '../form/file-upload';
import { selectDropdownItem } from '../form/dropdown';

$(document).on('click', '.teacher-details-btn', function()
{
    let teacher_id = $(this).attr('data-teacher-id');
    let activeRow = $(this);
    if (teacher_id.length === 0)
    {
        return false;
    }

    $('.teacher-details-btn').removeClass('active');
    activeRow.addClass('active');

    getTeacherProfile(teacher_id);
});

$(document).on('click', '.edit-active-teacher-profile-btn', function()
{
    let user_id = $(this).val();
    if (user_id.length === 0)
    {
        return false;
    }
    let current = $(this);
    $('#teacher-pending-list')
        .find('.teacher-name')
        .removeClass('teacher-name');
    current
        .parent('td')
        .prev('td')
        .addClass('teacher-name');
    current
        .parent('td')
        .prev('td')
        .prev('td')
        .addClass('teacher-name');

    $.ajax(
    {
        url: '/get-teacher-profile/' + user_id,
        type: 'GET',
        cache: false,
        dataType: 'json',
        success: function(response)
        {
            if (response.status === '200' || response.status === 200)
            {
                let profile = response.profiles;
                const modal = $('#edit-active-teacher-profile-modal');
                $('.edit-teacher-profile-btn').val(profile.id);
                $('.tpfirst_name').val(htmlDecode(profile.first_name));
                $('.tplast_name').val(htmlDecode(profile.last_name));
                $('.tpemail').val(htmlDecode(profile.email));
                $('.tpexternal_reference').val(htmlDecode(profile.external_reference));
                modal.find('[name=title]').val(htmlDecode(profile.title));
                modal.find('[name=description]').val(htmlDecode(profile.description)).keyup();
                modal.find('[name=website]').val(htmlDecode(profile.website));

                if (profile.profileImage)
                {
                    modal.find('input[name=profileImage]').val(profile.profileImage);
                    const fileName = profile.profileImage.split('/').pop();
                    displayImagePreview($('#educator-profile-upload-widget .file-upload-section'), $('#educator-profile-upload-widget .image-preview'), fileName, profile.profileImage.split('.').pop(), profile.imageAssetUrl, profile.profileImage);
                    updateUploadedFileName(fileName, profile.imageFileSize, profile.imageAssetUrl, $('#educator-profile-upload-widget'), true, false);
                }
                else if (modal.find('input[name=profileImage]').val().length > 0)
                {
                    const uploadSection = $('#educator-profile-upload-widget .file-upload-section');
                    removeUploadedFile('profile-image', uploadSection, uploadSection.find('[data-file-upload]'));
                }

                selectDropdownItem($('#edit-active-teacher-profile-modal .status-dropdown'), profile.status);

                let initials = profile.first_name.charAt(0) + profile.last_name.charAt(0);
                updateProfileImagePlaceholder('educator-profile-upload-widget', initials);

                modal.modal('show');
            }
        },
    });
});

function htmlDecode(value)
{
    return $('<div></div>').html(value).text();
}

$(document).on('keyup', '#edit-active-teacher-profile-modal .tpfirst_name, #edit-active-teacher-profile-modal .tplast_name', function()
{
    const firstName = $('#edit-active-teacher-profile-modal .tpfirst_name').val();
    const lastName = $('#edit-active-teacher-profile-modal .tplast_name').val();
    const initials = firstName.charAt(0) + lastName.charAt(0);
    updateProfileImagePlaceholder('educator-profile-upload-widget', initials);
});

$(document).on('click', '.update-teacher-profile-btn', function()
{
    let updateProfileData = $('#activeProfileUpdateForm').serialize();

    $.ajax(
    {
        url: '/update-active-teacher-profile',
        type: 'POST',
        dataType: 'json',
        data: updateProfileData,
        success: function(response)
        {
            $('.tvalidateError').hide();
            if (response.status === '200' || response.status === 200)
            {
                let profile = response.profile;
                let name = profile.first_name + ' ' + profile.last_name;
                let email = profile.email;

                $('#active-teacher-list')
                    .find('.active')
                    .find('td:first')
                    .html(name);
                $('#active-teacher-list')
                    .find('.active')
                    .find('td:last')
                    .html(profile.email);
                $('.tname, .teacher-name').html(name);
                $('.temail').html(email);
                location.reload();
            }
            else
            {
                $('.tvalidateErrorList').empty();
                if (response.status === '422' || response.status === 422)
                {
                    $.each(response.errors, (key, value) =>
                    {
                        $('.tvalidateErrorList').append(`<li>${value}</li>`);
                    });
                    if (response.message)
                    {
                        $('.tvalidateErrorList').append(`<li>${response.message}</li>`);
                    }
                    $('.tvalidateError').show();
                }
                else
                {
                    $('#edit-active-teacher-profile-modal').modal('hide');
                    Quitch.showMessage('danger', response.message);
                }
            }
        },
        error: function()
        {
            $('#edit-active-teacher-profile-modal').modal('hide');
            Quitch.showMessage('danger', 'Educator Profile Not updated');
        },
    });
});

$(document).on('click', '.js_staff-list-filter', function(e)
{
    let link = $('#' + e.target.id);
    let dropdownItems = link.siblings('a.dropdown-item');
    dropdownItems.removeClass('active');
    dropdownItems.attr('aria-expanded', false);
});

function getTeacherProfile(teacher_id)
{
    $.ajax(
    {
        url: '/get-teacher-profile/' + teacher_id,
        type: 'GET',
        dataType: 'json',
        success: function(response)
        {
            let profile = response.profiles;
            $('#teacher-profile-btn').val(profile.id);
            $('#delete-active-teacher-btn').attr('href', `/admin/teacher-manager/${profile.id}/delete`);
            $('#delete-active-teacher-btn').attr(
                'data-message',
                'This will completely remove <b>' + profile.first_name + ' ' + profile.last_name + '</b> from the organisation.<br><br><b>This action is not reversible.</b>'
            );
            $('.tname').html(profile.first_name + ' ' + profile.last_name);
            $('.temail').html(profile.email);
            $('.user-details .profile-box img').attr('src', profile.imageAssetUrl || '').toggle(!!profile.imageAssetUrl);
            $('.user-details .title').html(profile.title);
            $('.user-details .description-wrapper').toggle(!!profile.description);
            $('.user-details .description').html(profile.description);
            $('.user-details .website-wrapper').toggle(!!profile.website);
            $('.user-details .website').html(`<a href="${profile.website}" target="_blank">${profile.website}</a>`);

            let lastActiveDate = new Date(Date.parse(profile.lastActive));
            $('.user-details .last-active').text(lastActiveDate.toLocaleDateString());

            $('tbody.active-classes-list').empty();
            if (response.classes && response.classes.length > 0)
            {
                let classes = response.classes;
                $.each(classes, function(i, item)
                {
                    $('tbody.active-classes-list').append(`
                    <tr class="list-item-block class-details-btn" data-class-id="${item.classId}">
                        <td>${item.name} ${item.code ? `(${item.code})` : ''}</td>
                        <td class="text-end">
                            <a data-class-remove-id="${item.classId}" class="js-delete-confirm no-track-intercept js_track_mixpanel"  data-bs-placement="top"
                            modal-title="${trans('orgManager.deleteTeacher')}"
                            data-message="${trans('orgManager.deleteTeacherFromClass', {
                        'name': profile.first_name + ' ' + profile.last_name,
                        'className': item.name
                    })}" href="/admin/teacher-manager/${profile.id}/${item.classId}/remove">
                                <i data-bs-toggle="tooltip" class="fa fa-trash fa-lg" style="cursor: pointer;" aria-hidden="true" title="${trans('orgManager.removeFromClass')}"></i>
                            </a>
                        </td>
                    </tr>`);
                });
            }
            else
            {
                $('tbody.active-classes-list').append('<td class="col-lg-4 text-center" colspan="2">No Classes Assigned</td>');
            }

            let rolesList = $('tbody.js_user-details-roles-list');
            rolesList.empty();
            if (response.roles && response.roles.length > 0)
            {
                $.each(response.roles, function(i, item)
                {
                    let role = item;
                    if (item === 'teacher')
                    {
                        role = trans('invites.teacherTitle');
                    }
                    else if (item === 'student')
                    {
                        role = trans('invites.studentTitle');
                    }
                    else if (item === 'org-admin')
                    {
                        role = trans('invites.orgAdminTitle');
                    }
                    rolesList.append(`
                            <tr class="list-item-block class-details-btn">
                                <td class="col-lg-10">${role}</td>
                                <td class="col-lg-2 text-end">
                                    <a class="js-delete-confirm no-track-intercept js_track_mixpanel" data-bs-placement="top" modal-title="${trans('orgManager.deleteTeacher')}"
                                        data-message="${trans('orgManager.deleteTeacherPermissions', {'name': profile.first_name + ' ' + profile.last_name, 'permission': item})}"
                                        href="/admin/teacher-manager/${profile.id}/${item}/remove-role">
                                        <i data-bs-toggle="tooltip" class="fa fa-trash fa-lg" style="cursor: pointer;" aria-hidden="true" title="${trans('orgManager.removeFromClass')}"></i>
                                    </a>
                                </td>
                            </tr>`);
                });

                $('#js_teacher-details-role').show();
            }
            else
            {
                $('#js_teacher-details-role').hide();
            }

            $('#user_form').show();
            $('.teacher-details').show();
        },
    });
}