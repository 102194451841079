import { Quitch } from '../quitch';
import { getSpinnerHtml } from '../../system';
import { trans } from '../common';


$(document).ready(function()
{
    initialiseMarkAssessments();
});

/**
 * Assess/Mark Free Text Questions
 */
$(document).on('click', '.get-student-btn', function()
{
    let assessment_id = $(this).attr('data-assessment-id');
    if (assessment_id == '')
    {
        return false;
    }

    let appendPosition = $(this).find('.unmarked-student-list');
    let appendPositionMarked = $(this).find('.marked-student-list');
    $('.selected-answer-section > :not(.empty-state)').hide();
    $('.selected-answer-section .empty-state').show();
    $('.accordion-parent .section-content').removeClass('section-content');
    $(this)
        .find('.asses_question')
        .toggleClass('section-content');
    $('.munmarked-marked-list')
        .find('.selected-question')
        .removeClass('selected-question');
    $(this).addClass('selected-question');
    let type = $(this).attr('id');
    let token = $('meta[name="csrf-token"]').attr('content');

    $.ajax(
    {
        url: '/get-assessment-unmarked-student',
        type: 'POST',
        dataType: 'json',
        data: { assessment_id: assessment_id, type: type, _token: token },
        success: function(response)
        {
            if (response.status === '200' || response.status === 200)
            {
                if (type === 'unmarked')
                {
                    $('.unmarked-student-list').empty();
                    $.each(response.students_list.unmarked_students, function(index, student)
                    {
                        const name = student.first_name + ' ' + student.last_name;
                        const unmarkedStudentList = `
                            <li class="accordion-block clearfix">
                                <div class="accordion-parent">
                                    <h4 class="title answer-person unmarked-student-answer-btn"
                                        data-student-id="${student.id}" data-assessment-result-id="${student.assessment_result_id}">
                                        <span class="accordion-title">${name}</span>
                                    </h4>
                                </div>
                            </li>`;

                        appendPosition.append(unmarkedStudentList);
                    });
                }
                else
                {
                    $('.marked-student-list').empty();
                    $.each(response.students_list.marked_students, function(index, student)
                    {
                        const name = student.first_name + ' ' + student.last_name;
                        const markedStudentList = `
                            <li class="accordion-block clearfix">
                                <div class="accordion-parent">
                                    <h4 class="title answer-person unmarked-student-answer-btn"
                                        data-student-id="${student.student_id}" data-assessment-result-id="${student.id}">
                                        <span class="accordion-title">${name}</span>
                                        <span class="lozenge">${student.point} PTS</span>
                                    </h4>
                                </div>
                            </li>`;

                        appendPositionMarked.append(markedStudentList);
                    });
                }
            }
        },
    });
});

$(document).on('click', '.unmarked-student-answer-btn', function(e)
{
    e.stopPropagation();

    let id = $(this).data('assessment-result-id');
    if (id.length == '')
    {
        return false;
    }

    $('.accordion-parent .section-content').removeClass('section-content');
    $(this).addClass('section-content');

    $.ajax(
    {
        url: '/get-student-answer/' + id,
        type: 'GET',
        dataType: 'json',
        success: function(response)
        {
            if (response.status === '200' || response.status === 200)
            {
                let studentanswer = response.student_answer.student_answer_one;
                $('.selected-question-title').html(studentanswer.title);
                $('.student-answer-details').html(studentanswer.answer_text);

                if (studentanswer.approval_status == 0)
                {
                    $('.assessment-point').val(studentanswer.point);
                }
                else
                {
                    $('.assessment-point').val(studentanswer.result_point);
                }
                $('.question-point').html('/ ' + studentanswer.point);

                $('.teacher-review-btn').val(studentanswer.id);
                $('.selected-answer-section > :not(.empty-state)').show();
                $('.selected-answer-section .empty-state').hide();
            }
            else
            {
                Quitch.showMessage('danger', 'Answer not found');
            }
        },
    });
});

$(document).on('click', '#markquestion-tab', function()
{
    $('.selected-answer-section > :not(.empty-state)').hide();
    $('.selected-answer-section .empty-state').show();
});

$(document).on('click', '#unmarkquestion-tab', function()
{
    $('.selected-answer-section > :not(.empty-state)').hide();
    $('.selected-answer-section .empty-state').show();
    initialiseMarkAssessments();
});

$(document).on('focusout', '.assessment-point', function()
{
    const point = $(this).val();
    if (point > 10)
    {
        $(this).val(10);
    }
    else if (point < 0)
    {
        $(this).val(0);
    }

    $(this).val(Math.round(point));
});

$(document).on('click', '.teacher-review-btn', function(e)
{
    e.stopPropagation();

    let assessment_result_id = $(this).val();
    let required_point = $('.assessment-point').val();
    let class_id = $('.js_class-manager').data('class-id');
    let token = $('meta[name="csrf-token"]').attr('content');
    if (assessment_result_id.length == '')
    {
        return false;
    }

    $.ajax(
    {
        url: '/review-answer-by-teacher',
        type: 'POST',
        dataType: 'json',
        data:
        {
            assessment_result_id: assessment_result_id,
            point: required_point,
            class_id: class_id,
            _token: token,
        },
        success: function(response)
        {
            $('.selected-answer-section > :not(.empty-state)').hide();
            $('.selected-answer-section .empty-state').show();
            if (response.status === '200' || response.status === 200)
            {
                const total_answered = response.reviewed_answer.total_answered;
                const marked_answer_count = response.reviewed_answer.marked_student_list.length;

                const isAssessingMarkedAnswer = $('#markquestion').is('.active, .show');
                const isAssessingUnmarkedAnswer = $('#unmarkquestion').is('.active, .show');

                if (marked_answer_count > 0)
                {
                    $('.unmarked-student-list')
                        .find('.section-content')
                        .parent('li')
                        .remove();

                    const unmarked_qsp = total_answered - marked_answer_count;

                    $('.selected-question')
                        .find('.count-left')
                        .html(marked_answer_count);
                    $('.selected-question')
                        .find('.unmarked-answer')
                        .html(unmarked_qsp);
                    $('.selected-question .asses_question').addClass('section-content');

                    if (isAssessingUnmarkedAnswer)
                    {
                        if (marked_answer_count == total_answered)
                        {
                            $('.selected-question').remove();
                        }
                        else
                        {
                            $(`[data-assessment-result-id=${assessment_result_id}]`)
                                .parentsUntil(null, 'li.accordion-block')
                                .first()
                                .remove();
                        }
                    }
                    else if (isAssessingMarkedAnswer)
                    {
                        const answerElem = $(`[data-assessment-result-id=${assessment_result_id}]`);
                        answerElem.find('.lozenge').html(`${required_point} PTS`);
                        answerElem.removeClass('section-content');
                    }
                }
                Quitch.showMessage('success', 'base.alertAssessmentMarked');
            }
            else
            {
                Quitch.showMessage('danger', 'Assessment Mark Failed.');
            }
        },
    });
});

$(document).on('click', '.js-assess-answers', function()
{
    const targetTab = $('.js_class-manager #assess-answer-tab');
    if (targetTab.length)
    {
        const tabInstance = new window.bootstrap.Tab(targetTab[0]);
        tabInstance.show();
    }
});


$(document).on('click', '#markquestion-tab', function(e)
{
    e.preventDefault();
    let class_id = $('.js_class-manager').data('class-id');

    if ($('.marked-questions').children().length === 0)
    {
        $('.marked-questions').append(getSpinnerHtml());
    }

    $.ajax(
    {
        url: '/get-marked-questions/' + class_id,
        type: 'GET',
        dataType: 'json',
        success: function(response)
        {
            if (response.status === '200' || response.status === 200)
            {
                $('.marked-questions').empty();
                let studentsToReviewCount = 0;
                if (response.marked_question.length > 0)
                {
                    $.each(response.marked_question, function(index, question)
                    {
                        if (question.review_student > 0)
                        {
                            const markedQuestion = `
                                    <li class="accordion-block get-student-btn" id="marked" data-assessment-id="${question.id}">
                                        <div class="accordion-parent">
                                            <div class="asses_question" data-assessment-id="${question.id}">
                                                <h4 class="asses-question-title">
                                                    <span class="question-title line-clamp">${question.title}</span>
                                                    <div class="text-end">
                                                        <span class="total-question">
                                                            <span class="count-left">${question.review_student}</span>
                                                            <span class="count-total">/${question.total_student}</span>
                                                        </span>
                                                   </div>
                                                </h4>
                                            </div>
                                        </div>
                                        <ol class="list-unstyled accordion-child marked-student-list"></ol>
                                    </li>`;

                            $('.marked-questions').append(markedQuestion);
                            studentsToReviewCount++;
                        }
                    });
                }

                if (studentsToReviewCount === 0)
                {
                    $('.marked-questions').append(`<p class="empty-state">${trans('assessAnswers.markedEmptyState')}</p>`);
                }
            }
        },
    });
});

$(document).on('click', '#assess-answer-tab', function(e)
{
    e.preventDefault();
    if ($('.unmarkquestion-student').children().length === 0)
    {
        let class_id = $('.js_class-manager').data('class-id');
        getUnmarkedQuestion(class_id);
        window.scrollTo(0, 0);
    }
});

function initialiseMarkAssessments()
{
    let windowHash = window.location.hash;
    if (windowHash === '#assess-answer')
    {
        let class_id = $('.js_class-manager').data('class-id');
        getUnmarkedQuestion(class_id);
    }
}

function getUnmarkedQuestion(class_id)
{
    $('.unmarkquestion-student').empty();
    $('.unmarkquestion-student').append(getSpinnerHtml());
    $.ajax(
    {
        url: '/get-unmarked-questions/' + class_id,
        type: 'GET',
        dataType: 'json',
        error: function()
        {
            $('.unmarkquestion-student').empty();
        },
        success: function(response)
        {
            $('.unmarkquestion-student').empty();
            if (response.status === '200' || response.status === 200)
            {
                let studentsToMarkCount = 0;
                if (response.unmarked_question.length > 0)
                {
                    $.each(response.unmarked_question, function(index, question)
                    {
                        if (question.total_student != question.review_student)
                        {
                            const unmarkedQuestion = `
                                    <li class="accordion-block get-student-btn" id="unmarked" data-assessment-id="${question.id}">
                                        <div class="accordion-parent">
                                            <div class="asses_question" data-assessment-id="${question.id}">
                                                <h4 class="asses-question-title">
                                                    <span class="question-title line-clamp">${question.title}</span>
                                                    <div class="text-end">
                                                        <span class="total-question">
                                                            <span class="count-left point">${question.not_review_student}</span>
                                                            <span class="count-total-all">/${question.total_student}</span>
                                                        </span>
                                                   </div>
                                                </h4>
                                            </div>
                                        </div>
                                        <ol class="list-unstyled accordion-child unmarked-student-list"></ol>
                                    </li>`;

                            $('.unmarkquestion-student').append(unmarkedQuestion);
                            studentsToMarkCount++;
                        }
                    });
                }

                if (studentsToMarkCount === 0)
                {
                    $('.unmarkquestion-student').append(`<p class="empty-state">${trans('assessAnswers.unmarkedEmptyState')}</p>`);
                }
            }
        },
    });
}