import { Quitch } from '../quitch';
import { initialiseCanvas } from './integrations/canvas';
import { trans, transChoice, validateEmail } from '../common';
import { telemetryTrackAndPerformUrlChange, telemetryTrackEvent } from '../superuser/mixpanel';
import { removeUploadedFile } from '../form/file-upload';

$(document).ready(function()
{
    initialiseInvitations();
    //Code to prevent tooltips from remaining after deletion of invitation:
    //Check if the invitations table exists before adding the observer
    const table = document.querySelector('.js_invites_datatable');
    if (table)
    {
        //Adding a mutationObserver to check for changes to the invitations table children in the DOM
        const observer = new MutationObserver(function(mutations)
        {
            mutations.forEach(function(mutation)
            {
                if (mutation.type === 'childList')
                {
                    //if child is removed, remove lingering tooltips
                    $('.tooltip').remove();
                }
            });
        });
        //Observe the invitations table for changes
        observer.observe(table, { childList: true, subtree: true });
    }
});

$(document).on('click', '#all-invitations-tab', function()
{
    loadAllInvitations();
});

$(document).on('change', '.js_pending-invites-only, .js_learner-invites, .js_archived-invites', function()
{
    loadAllInvitations();
});

$(document).on('click', '.js-invitations-filter', function(e)
{
    let $target = $(e.currentTarget);
    $('#js-invitations-filter-class').val($target.data('class'));
    $('#js-invitations-filter-role').val($target.data('role'));
    $('#js-invitations-filter-enabled').val($target.data('filter'));
    $target
        .parent()
        .siblings()
        .removeClass('active');
    $target.parent().addClass('active');

    loadAllInvitations();
});

$('.js_invites_datatable').on('click', '.js_send-invite', function(e)
{
    e.preventDefault();
    let classId = $('.js_class-manager').data('class-id');
    if (classId == null)
    {
        classId = $(e.currentTarget).data('class');
        if (classId == null)
        {
            //org-admin invites have no classes
            classId = 'no-class';
        }
    }
    const inviteId = $(this).data('invite-id');
    $.ajax(
    {
        url: `/class/${classId}/invites/${inviteId}/send`,
        type: 'GET',
        dataType: 'json',
        success: function(response)
        {
            $('.student-loader').empty();
            if (response.status === 200)
            {
                Quitch.showSnackbar(trans('invites.inviteSingle', { email: response.invite.email }));
                reloadInvitationsTable();
            }
            else if (response.status === 412)
            {
                Quitch.showSnackbar(response.message);
                reloadInvitationsTable();
            }
            else
            {
                Quitch.showSnackbar(trans('invites.inviteFailed'));
            }
        },
        error: function(xhr, status, error)
        {
            Quitch.showSnackbar(xhr.responseJSON && xhr.responseJSON.message ? xhr.responseJSON.message : trans('invites.inviteFailed'));
        },
    });
});

$('.js_invites_datatable').on('click', '.js_send-delete-invite', function(e)
{
    e.preventDefault();
    let classId = $('.js_class-manager').data('class-id');
    if (classId == null)
    {
        classId = $(e.currentTarget).data('class');
        if (classId == null || classId === '')
        {
            //org-admin invites have no classes
            classId = 'no-class';
        }
    }
    const inviteId = $(this).data('invite-id');
    const inviteEmail = $(this).data('invite-email');
    Quitch.modal(
        {
            title: trans('invites.inviteDeleteTitle'),
            message: `<p>${trans('invites.inviteDeleteDesc')}</p>
                          <p><b>${inviteEmail}</b></p>
                          <br>
                          <p>${trans('invites.inviteDeleteContinue')}</p>`,
            type: 'confirm',
        },
        function(response)
        {
            if (response === true)
            {
                $.ajax(
                {
                    url: `/class/${classId}/invites/${inviteId}/delete`,
                    type: 'GET',
                    dataType: 'json',
                    success: function(response)
                    {
                        $('.student-loader').empty();
                        if (response.status === 200)
                        {
                            Quitch.showSnackbar(trans('invites.inviteDeletedFor', { email: inviteEmail }));
                            reloadInvitationsTable();
                        }
                        else if (response.status === 412)
                        {
                            Quitch.showSnackbar(response.message);
                        }
                        else
                        {
                            Quitch.showSnackbar(trans('invites.inviteFailedDelete'));
                        }
                    },
                    error: function(xhr, status, error)
                    {
                        Quitch.showSnackbar(xhr.responseJSON && xhr.responseJSON.message ? xhr.responseJSON.message : trans('invites.inviteFailedDelete'));
                    },
                });
            }
        }
    );
});

$(document).on('click', '#invites-tab', function()
{
    reloadInvitationsTable();
});

$(document).on('change', '.js_pending-only', function()
{
    reloadInvitationsTable();
});

$(document).on('click', '.js_invite_refresh', function()
{
    reloadInvitationsTable();
});

$(document).on('click', '.js_invitations_click', function()
{
    let inviteTabBtn = $(`.class-nav a[data-bs-target="#invitations"]:visible`);
    if (inviteTabBtn.length === 0)
    {
        inviteTabBtn = $(`.class-nav a[data-bs-target="#invitations"]`);

    }
    inviteTabBtn.tab('show');
    reloadInvitationsTable();
});

$('#invite-user-modal').on('show.bs.modal', function()
{
    const $modal = $(this);
    // reset form errors and ui
    $modal.find('.help-block').remove();
    $modal.find('.error-message').remove();
    $modal.find('.has-error').removeClass('has-error');
    $modal.find('select[name="slug_name"]').prop('selectedIndex', 0);
    $modal.find('.plan-usage-info-container').show();
    $modal.find('.js-educator-email-message').hide();
    $modal.find('.js-learner-email-message').show();
    $modal.find('.js-educator-invite-btn').hide();
    $modal.find('.js-learner-invite-btn').show();
});

$(document).on('change', 'select[name=slug_name]', function()
{
    $('.plan-usage-info-container').toggle($(this).val() === 'student');
});

$('#invite-import-modal').on('show.bs.modal', function()
{
    // Update slug name in form based on event button pressed
    const $modal = $(this);

    let classId = $('.js_class-manager').data('class-id');
    $modal.find('input[name="class_id"]').val(classId);

    // reset form errors and ui
    $modal.find('#invitationImportForm')[0].reset();
    $modal.find('.help-block').remove();
    $modal.find('.error-message').remove();
    $modal.find('.has-error').removeClass('has-error');
    $modal
        .find('.resource-filename')
        .text('')
        .hide();
    $modal.find('.js-csv-file-format-warning').hide();
    $modal.find('select[name="slug_name"]').prop('selectedIndex', 0);
    $modal.find('.js-educator-email-message').hide();
    $modal.find('.js-learner-email-message').show();
    $modal.find('.js-educator-invite-btn').hide();
    $modal.find('.js-learner-invite-btn').show();

    removeUploadedFile('csv', $modal.find('.file-upload-section'), $modal.find('[data-file-upload]'));
});

$(document).on('click', '.js_invite_save', function()
{
    $(this).prop('disabled', true);

    const inviteEmailEle = $('.js-invite-email');
    inviteEmailEle.val(inviteEmailEle.val().trim());

    if (!validateEmail(inviteEmailEle.val()))
    {
        inviteEmailEle.addClass('has-error');
        inviteEmailEle.after(`<div class="error-text body-small">${trans('invites.invalidEmail')}</div>`);
        return;
    }

    const $form = $('#invitationForm');
    const studentData = $form.serialize();
    const classId = $('.js_class-manager').data('class-id');

    $form.find('.help-block').remove();
    $form.find('.error-message').remove();
    $form.find('.has-error').removeClass('has-error');

    $.ajax(
    {
        url: `/class/${classId}/invite`,
        type: 'POST',
        dataType: 'json',
        data: studentData,
        error: function(xhr, text, error)
        {
            $('.js_invite_save').prop('disabled', false);

            $form.find('.help-block').remove();
            $form.find('.error-message').remove();
            $form.find('.has-error').removeClass('has-error');

            if (xhr.responseJSON && xhr.responseJSON.errors)
            {
                $.each(xhr.responseJSON.errors, function(key, value)
                {
                    const $input = $form.find(`input[name=${key}]`);
                    if ($input.length)
                    {
                        $input
                            .parent()
                            .addClass('has-error')
                            .append(`<span style="font-size: 10px" class="help-block">${value[0]}</span>`);
                    }
                    else
                    {
                        $form.prepend(`<div style="font-size: 12px" class="alert alert-danger error-message">${value[0]}</div>`);
                    }
                });
            }
            else
            {
                $('#invite-user-modal').modal('hide');
                Quitch.showErrors(error);
            }
        },
        success: function(response)
        {
            reloadInvitationsTable();
            $('#invitationForm')[0].reset();
            $('#invite-user-modal').modal('hide');
            Quitch.showSnackbar(trans('invites.inviteCreated'));
        },
    });
});

$(document).on('click', '.js_invite_import', function()
{
    const $form = $('#invitationImportForm');
    let form = $form[0];
    let formData = new FormData(form);
    let classId = $('.js_class-manager').data('class-id');

    $form.find('.help-block').remove();
    $form.find('.error-message').remove();
    $form.find('.has-error').removeClass('has-error');

    $.ajax(
    {
        url: `/class/${classId}/invites/import`,
        enctype: 'multipart/form-data',
        type: 'POST',
        data: formData,
        processData: false,
        contentType: false,
        cache: false,
        error: function(xhr, text, error)
        {
            if (xhr.responseJSON && xhr.responseJSON.errors)
            {
                $.each(xhr.responseJSON.errors, function(key, value)
                {
                    let $input = $form.find(`input[name=${key}]`);
                    if ($input.length)
                    {
                        $input
                            .parent()
                            .addClass('has-error')
                            .append(`<span style="font-size: 10px" class="help-block">${value[0]}</span>`);
                    }
                    else
                    {
                        $form.prepend(`<div style="font-size: 12px" class="alert alert-danger error-message">${value[0]}</div>`);
                    }
                });
            }
            else
            {
                Quitch.showErrors(error);
            }
        },
        success: function(response)
        {
            reloadInvitationsTable();
            $('#invitationImportForm')[0].reset();
            $('#invite-import-modal').modal('hide');
            Quitch.showSnackbar(trans('invites.inviteUploaded', { inviteCount: response.import.rows }));
        },
    });
});

/**
 * Prepare bulk send email invitation modal
 */
$(document).on('click', '.js_send_invitation', function(e)
{
    e.preventDefault();
    const classId = $('.js_class-manager').data('class-id');
    $.ajax(
    {
        url: `/class/${classId}/invites/send`,
        type: 'GET',
        dataType: 'json',
        success: function(response)
        {
            $('.student-loader').empty();
            if (response.invitesCount > 0 || response.resendCount > 0)
            {
                $('#js-send-invitation-emails').prop('disabled', response.invitesCount == 0);
                $('#resendChk').prop('checked', false);
                $('#resendChk').parent().toggle(response.resendCount > 0);
                $(`label[for='resendChk']`).text(transChoice('invites.resendInvitesAmount', response.resendCount));
                $('#js-invite-count-msg').html(transChoice('invites.sendInvitesAmount', response.invitesCount));
                $('#js-send-invitation-emails').attr('data-invite-count', response.invitesCount).attr('data-resend-count', response.resendCount);
                $('#bulk-send-invite-email-modal').modal('show');
            }
            else
            {
                Quitch.showSnackbar(trans('invites.sendInvitesNone'));
            }
        },
        error: function(xhr, status, error)
        {
            if (xhr.status == 412)
            {
                Quitch.showSnackbar(trans(xhr.responseJSON.message));
            }
            else
            {
                Quitch.showSnackbar(trans('invites.sendInvitesError'));
            }
        },
    });
});

$(document).on('click', '.js_show-bulk-invites-detail', function(e)
{
    e.preventDefault();

    // Mark row as selected
    $(this)
        .siblings()
        .removeClass('active');
    $(this).addClass('active');

    let jobId = $(this).data('job-id');
    let classId = $(this).data('class-id');
    let table = $('.js_bulk_invites_datatable');
    table.data('job-id', jobId);
    table.data('class-id', classId);

    let datatable = table.DataTable(
    {
        ajax:
        {
            url: `/class/${classId}/invite-job/${jobId}/details`,
            type: 'GET',
            datatype: 'json',
        },
        language:
        {
            info: trans('invites.tableEntries', { start: '_START_', end: '_END_', total: '_TOTAL_' }),
            search: trans('invites.tableSearch'),
            lengthMenu: trans('reporting.lengthMenuInvites', { menu: '_MENU_' }),
            processing: trans('reporting.processing'),
            zeroRecords: trans('reporting.empty'),
            infoEmpty: trans('reporting.infoInvites', { start: 0, end: 0, total: 0 }),
            paginate:
            {
                next: '<i class="fa fa-angle-right"></i>',
                previous: '<i class="fa fa-angle-left"></i>',
            },
        },
        serverSide: true,
        searching: false,
        dom: 'frt<"col-lg-8"i><"col-lg-4"p>',
        pagingType: 'simple',
        targets: 'no-sort',
        order: [],
        pageLength: 50,
        columnDefs: [
        {
            targets: '_all',
            orderable: false,
            visible: true,
        }, ],
        columns: [
            { data: 'email', title: trans('invites.tableEmail') },
            { data: 'first_name', title: trans('invites.tableFirstName') },
            { data: 'last_name', title: trans('invites.tableLastName') },
            { data: 'role_slug', title: trans('invites.tableRole') },
            { data: 'status', title: trans('invites.tableStatus') },
        ],
    });

    datatable.ajax.url(`/class/${classId}/invite-job/${jobId}/details`);
    datatable.ajax.reload();

    let sourceButton = $('#js_download-invite-job-source-csv');
    let logButton = $('#js_download-invite-job-log');

    if ($(this).data('has-source'))
    {
        sourceButton.attr('href', `/class/${classId}/job/${jobId}/source`);
        sourceButton.attr('disabled', false);
    }
    else
    {
        sourceButton.attr('href', '#');
        sourceButton.attr('disabled', true);
    }
    if ($(this).data('has-log'))
    {
        logButton.attr('href', `/class/${classId}/job/${jobId}/log`);
        logButton.attr('disabled', false);
    }
    else
    {
        logButton.attr('href', '#');
        logButton.attr('disabled', true);
    }

    $('#js_delete-bulk-imported-invites').data('unaccepted', $(this).data('unaccepted'));
    $('#bulk-invite-details-modal').show();
});

$(document).on('click', '#js_delete-bulk-imported-invites', function()
{
    let inviteCount = $(this).data('unaccepted');
    if (inviteCount === '')
    {
        inviteCount = '0';
    }

    Quitch.modal(
        {
            title: trans('invites.bulkDeleteTitle'),
            message: `<p>${trans('invites.bulkDeleteDesc1')}</p>
                          <p>${trans('invites.bulkDeleteDesc2', {count: inviteCount})}</p>
                          <br>
                          <p>${trans('invites.inviteDeleteContinue')}</p>`,
            type: 'confirm',
        },
        function(response)
        {
            if (response === true)
            {
                let classId = $('.js_bulk_invites_datatable').data('classId');
                let jobId = $('.js_bulk_invites_datatable').data('jobId');
                let token = $('meta[name="csrf-token"]').attr('content');
                $.ajax(
                {
                    url: `/class/${classId}/invites/bulk/delete`,
                    type: 'POST',
                    dataType: 'json',
                    data: { jobId: jobId, _token: token },
                    success: function()
                    {
                        $('#js_delete-bulk-imported-invites').data('unaccepted', 0);
                    },
                });
            }
        }
    );
});

$(document).on('click', '#js_download-invite-job-log', function(e)
{
    e.preventDefault();
    let textarea = $('#js_bulk-import-log-text');
    textarea.val('').addClass('loading');
    $('#bulk-invite-log-modal').modal('show');

    $.ajax(
    {
        url: e.currentTarget.href,
        type: 'GET',
        dataType: 'text',
        success: function(response)
        {
            textarea.val(response).removeClass('loading');
        },
        error: function()
        {
            textarea.removeClass('loading');
        },
    });
});

$(document).on('click', '#js_invite-admin-btn', function()
{
    if (!$('#js_invite-admin-btn').hasClass('disabled'))
    {
        $('#js-admin-import-modal').modal('show');
    }
});

$(document).on('click', '.js_admin-invite-save', function()
{
    let $form = $('#js-admin-invite-form');
    let data = $form.serialize();
    let role = $('#js_invite_role_select').val();

    $form.find('.help-block').remove();
    $form.find('.error-message').remove();
    $form.find('.has-error').removeClass('has-error');

    $.ajax(
    {
        url: `/organisation/user/invite/${role}`,
        type: 'POST',
        dataType: 'json',
        data: data,
        error: function(xhr, text, error)
        {
            if (xhr.responseJSON && xhr.responseJSON.errors)
            {
                $.each(xhr.responseJSON.errors, function(key, value)
                {
                    let $input = $form.find(`input[name=${key}]`);
                    if ($input.length)
                    {
                        $input
                            .parent()
                            .addClass('has-error')
                            .append(`<span style="font-size: 10px" class="help-block">${value[0]}</span>`);
                    }
                    else
                    {
                        $form.prepend(`<div style="font-size: 12px" class="alert alert-danger error-message">${value[0]}</div>`);
                    }
                });
            }
            else
            {
                Quitch.showErrors(error);
            }

            telemetryTrackEvent('ep-admin-invite-created-error', { error: error });
        },
        success: function()
        {
            $('#js-admin-invite-form')[0].reset();
            $('#js_invite_class_select')[0].disabled = false;
            $('#js-admin-import-modal').modal('hide');
            loadAllInvitations();
            Quitch.showSnackbar(trans('invites.inviteCreated'));

            telemetryTrackEvent('ep-admin-invite-created-success');
        },
    });
});

$(document).on('change', '#js_invite_role_select', function(e)
{
    let target = $(e.currentTarget);
    let role = $('#js_invite_role_select').val();
    let educatorText = target.parentsUntil('.modal').find('.js-educator-email-message');
    let adminText = target.parentsUntil('.modal').find('.js-admin-email-message');
    let classSelector = target.parentsUntil('.modal').find('.js-class-selection-div');
    if (role === 'teacher')
    {
        $('#js_invite_class_select')[0].disabled = false;
        educatorText.show();
        adminText.hide();
        classSelector.show();
    }
    else
    {
        $('#js_invite_class_select')[0].disabled = true;
        educatorText.hide();
        adminText.show();
        classSelector.hide();
    }
});

function initialiseInvitations()
{
    let windowHash = window.location.hash;
    if ($('.js_class-manager').length > 0 && windowHash === '#invitations')
    {
        reloadInvitationsTable();
    }
    if ($('#invites').length)
    {
        showInvitesCallToAction();
        initialiseCanvas();
    }
}

function reloadInvitationsTable()
{
    let classId = $('.js_class-manager').data('class-id');
    let url = `/class/${classId}/invites`;
    loadInvitationsByUrl(url, false, function(d)
    {
        // get pending invites checkbox value
        const pendingInvites = $('.js_pending-only').is(':checked') ? true : false;

        // build our own payload for server
        let data = {
            _token: $('meta[name="csrf-token"]').attr('content'),
            start: d['start'],
            length: d['length'],
            search: d['search'],
            pending: pendingInvites,
        };

        return data;
    });
}

function loadInvitationsByUrl(url, showClass, data)
{
    if ($.fn.dataTable.isDataTable('.js_invites_datatable'))
    {
        // just cause it to refresh data from server
        $('.js_invites_datatable')
            .DataTable()
            .ajax.reload(null, false);
        return;
    }

    let jsTable = $('.js_invites_datatable').DataTable(
    {
        ajax:
        {
            url: url,
            type: 'POST',
            data: data,
        },
        drawCallback: function(data)
        {
            if (data.json.filter || data.json.recordsTotal > 0)
            {
                $('.invites-table-container').show();
                $('.invites-empty-state').hide();
                $('.js_send_invitation').show().parent().siblings('.dropdown-divider').show();
            }
            else
            {
                $('.invites-table-container').hide();
                $('.invites-empty-state').show();
                $('.js_send_invitation').hide().parent().siblings('.dropdown-divider').hide();
            }
        },
        language:
        {
            info: trans('invites.tableEntries', { start: '_START_', end: '_END_', total: '_TOTAL_' }),
            search: '',
            searchPlaceholder: trans('base.search'),
            lengthMenu: trans('reporting.lengthMenuInvites', { menu: '_MENU_' }),
            processing: trans('reporting.processing'),
            zeroRecords: trans('reporting.empty'),
            infoEmpty: '',
            infoFiltered: '',
            paginate:
            {
                next: '<i class="fa fa-angle-right"></i>',
                previous: '<i class="fa fa-angle-left"></i>',
            },
        },
        serverSide: true,
        searching: true,
        dom: 'frt<"col-lg-8"i><"col-lg-4"p>',
        pagingType: 'simple',
        targets: 'no-sort',
        order: [],
        pageLength: 50,
        columnDefs: [
            { targets: [0, 1], visible: false },
            {
                targets: '_all',
                orderable: false,
                visible: true,
            },
        ],
        columns: [
            { data: 'id', title: 'id' },
            { data: 'batch_id', title: 'Batch' },
            {
                data: 'email',
                title: trans('invites.tableEmail'),
                render: function(data, type, row)
                {
                    if (row.status === 'created' || row.status === 'invited')
                    {
                        return `${data} <span class="lozenge white">${trans('Pending')}</span>`;
                    }
                    return data;
                }
            },
            {
                data: 'name',
                title: trans('invites.name'),
                render: function(data, type, row)
                {
                    let firstName = row.first_name ? row.first_name : '';
                    let lastName = row.last_name ? row.last_name : '';
                    return `${firstName} ${lastName}`;
                }
            },
            {
                data: 'role_slug',
                title: trans('invites.tableRole'),
                render: function(data)
                {
                    let role = data;

                    if (data === 'teacher')
                    {
                        role = trans('invites.teacherTitle');
                    }
                    else if (data === 'student')
                    {
                        role = trans('invites.studentTitle');
                    }
                    else if (data === 'org-admin')
                    {
                        role = trans('invites.orgAdminTitle');
                    }

                    return `<span class="lozenge">${role}</span>`;
                },
            },
            { data: 'external_reference', title: trans('invites.externalId') },
            {
                data: 'groupNames',
                title: 'Groups',
                render: function(data)
                {
                    return groupsLozenge(data);
                },
            },
            { data: 'className', title: trans('invites.tableClassName'), visible: showClass },
            {
                data: 'archived',
                title: trans('invites.classArchived'),
                visible: showClass,
                render: function(data)
                {
                    if (data == 1)
                    {
                        return trans('base.yes');
                    }
                    else
                    {
                        return trans('base.no');
                    }
                },
            },
            {
                data: 'status',
                title: trans('invites.invitation'),
                render: function(data, type, row)
                {
                    if (data === 'created')
                    {
                        let date = $('.js_invites_datatable').data('class-start-date');
                        if (date)
                        {
                            return `<span class="lozenge" data-bs-toggle="tooltip" title="${trans('invites.scheduledTooltip', {time: date})}">${trans('invites.scheduledPill')}</span>`;
                        }
                        else
                        {
                            return `<span class="lozenge" data-bs-toggle="tooltip" title="${trans('invites.scheduledTooltipNoDate')}">${trans('invites.scheduledPill')}</span>`;
                        }
                    }
                    else if (data === 'invited')
                    {
                        return `<span class="lozenge white" data-bs-toggle="tooltip" title="${row.invitedAt}">${trans('invites.sent')}</span>`;
                    }
                    else if (data === 'blacklist')
                    {
                        return `<span class="lozenge danger" data-bs-toggle="tooltip" title="${trans('invites.blockedTooltip')}">${trans('invites.blockedPill')}</span>`;
                    }
                    else if (data === 'error')
                    {
                        return `<span class="lozenge danger" data-bs-toggle="tooltip" title="${trans('invites.errorTooltip')}">${trans('invites.errorPill')}</span>`;
                    }
                },
            },
            {
                data: 'actions',
                title: '',
                render: function(data, type, row)
                {
                    if (row.status !== 'enrolled')
                    {
                        return `<div class="dropdown table-action-dropdown">
                                    <i class="fa-solid fa-ellipsis" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="true" aria-hidden="true"></i>
                                    <div class="dropdown-menu dropdown-menu-end custom-dropdown-list">
                                        <a data-invite-id="${row.id}" class="dropdown-item js_send-invite" data-class="${row.class_id}">${trans('invites.sendEmailInvitation')}</a>
                                        <a data-invite-id="${row.id}" data-invite-email="${row.email}" class="dropdown-item js_send-delete-invite" data-class="${row.class_id}">${trans('invites.delete')}</a>
                                    </div>
                                </div>`;
                    }
                    else
                    {
                        return '';
                    }
                },
            },
        ],
    });
}

function loadAllInvitations()
{
    loadInvitationsByUrl('/admin/invitations', true, function(d)
    {
        const pendingInvites = $('.js_pending-invites-only').is(':checked') ? 1 : 0;
        const learnerInvites = $('.js_learner-invites').is(':checked') ? 1 : 0;
        const archivedInvites = $('.js_archived-invites').is(':checked') ? 1 : 0;

        let data = {
            _token: $('meta[name="csrf-token"]').attr('content'),
            start: d['start'],
            length: d['length'],
            search: d['search'],
            pending: pendingInvites,
            learners: learnerInvites,
            archived: archivedInvites,
        };

        if ($('#js-invitations-filter-enabled').val() !== 'false')
        {
            data.classId = $('#js-invitations-filter-class').val();
            data.roleSlug = $('#js-invitations-filter-role').val();
        }

        return data;
    });
}

/**
 * Disable send invitation email button if all user have recieved an invitation and the resend email checkbox is unchecked
 */
$(document).on('change', '#resendChk', function(e)
{
    if ($('#js-send-invitation-emails').attr('data-invite-count') == 0)
    {
        $('#js-send-invitation-emails').prop('disabled', !$(this).is(':checked'));
    }
});
/**
 * Ajax to start job that bulk sends email invitations
 */
$(document).on('click', '#js-send-invitation-emails', function(e)
{
    let classId = $('.js_class-manager').data('class-id');
    let token = $('meta[name="csrf-token"]').attr('content');
    let resend = $('#resendChk').is(':checked') ? 1 : 0;
    let invitesCount = parseInt($(this).attr('data-invite-count')) + parseInt($(this).attr('data-resend-count'));

    $.ajax(
    {
        url: `/class/${classId}/invites/send`,
        type: 'POST',
        dataType: 'json',
        data: { _token: token, resend: resend },
        success: function()
        {
            $('.student-loader').empty();
            Quitch.showSnackbar(transChoice('invites.sendInvitesSuccess', invitesCount));
            $('#bulk-send-invite-email-modal').modal('hide');
            reloadInvitationsTable();
        },
        error: function(xhr, status, error)
        {
            if (xhr.status == 412)
            {
                Quitch.showSnackbar(trans(xhr.responseJSON.message));
            }
            else
            {
                Quitch.showSnackbar(trans('invites.sendInvitesError'));
            }
        }
    });
});

$(document).on('change', '.js-invite-role-select', function(e)
{
    let target = $(e.currentTarget);
    let slug = target.val();
    let educatorText = target.parentsUntil('.modal').find('.js-educator-email-message');
    let learnerText = target.parentsUntil('.modal').find('.js-learner-email-message');
    let educatorBtn = target.parentsUntil('.modal').find('.js-educator-invite-btn');
    let learnerBtn = target.parentsUntil('.modal').find('.js-learner-invite-btn');
    if (slug === 'teacher')
    {
        educatorText.show();
        learnerText.hide();
        educatorBtn.show();
        learnerBtn.hide();
    }
    else if (slug === 'student')
    {
        educatorText.hide();
        learnerText.show();
        educatorBtn.hide();
        learnerBtn.show();
    }
});

/**
 * Display call to action showing import in progress
 */
function showInvitesCallToAction()
{
    $.ajax(
    {
        url: '/jobs/get-running-import',
        type: 'GET',
        dataType: 'json',
        success: function(response)
        {
            if (response.jobId)
            {
                Quitch.showCallToAction('#js-invites-cta', 'import-in-progress',
                {
                    type: 'info',
                    title: 'invites.importInProgress',
                    description: 'invites.importInProgressDesc',
                });
                updateInvitesCallToAction(response.jobId, 500);
            }
        },
    });
}

/**
 * Polling job status to update call to action to show import has completed
 */
export function updateInvitesCallToAction(jobId, retryDelay)
{
    $.ajax(
    {
        url: '/jobs/get-status/' + jobId,
        type: 'GET',
        dataType: 'json',
        success: function(response)
        {
            if (response.job.status === 'created' || response.job.status === 'running')
            {
                window.setTimeout(function()
                {
                    updateInvitesCallToAction(jobId, Math.ceil(retryDelay * 1.5));
                }, retryDelay);
            }
            else if (response.job.status === 'completed')
            {
                const classId = $('.classId').attr('class-id');
                Quitch.showCallToAction('#js-invites-cta', 'import-in-progress',
                {
                    type: 'complete',
                    title: 'invites.importComplete',
                    description: 'invites.importCompleteDesc',
                    replace: true,
                    showDismiss: true,
                    dismissCallback: true,
                    actionText: 'invites.viewHistory',
                    actionCallback: () =>
                    {
                        telemetryTrackAndPerformUrlChange(`/class/${classId}/invites/bulk`, 'ep-update-invites-call-to-action-callback');
                    }
                });
                $('.js_invite_refresh').trigger('click');
            }
        },
        error: function(error)
        {
            window.setTimeout(function()
            {
                updateInvitesCallToAction(jobId, Math.ceil(retryDelay));
            }, retryDelay);
        },
    });
}

/**
 * Create lozenge rectangle for a group name
 * @param data
 * @returns {string}
 */
export function groupsLozenge(data)
{
    if (data && data !== '\'\'')
    {
        // split string of groups on commas outside quotes
        let groupsArr = String(data).match(/('.*?'|[^',]+)(?=\s*,|\s*$)/g);
        let groups = '';
        $.each(groupsArr, function(index, group)
        {
            groups += (`<span class="lozenge rectangle">${group.replaceAll(/^'|'$/g, '')}</span>`);
        });
        return `<div class="flex-gap">${groups}</div>`;
    }
    else
    {
        return '';
    }
}

$(document).on('change keyup input paste', '.js-invite-email', function(e)
{
    let input = $(e.target);
    let button = input.parents('.modal').find('.js_invite_save');

    input.removeClass('has-error');
    input.next('.error-text').remove();

    if (input.val().trim() !== '')
    {
        button.attr('disabled', false);
    }
    else
    {
        button.attr('disabled', true);
    }
});

$(document).on('change', '#invite-import-modal [name=import_file]', function(e)
{
    let input = $(e.target);
    let button = input.parents('.modal').find('.js_invite_import');
    if (input.val())
    {
        button.attr('disabled', false);
    }
    else
    {
        button.attr('disabled', true);
    }
});

$(document).on('shown.bs.modal', '#invite-user-modal, #js-admin-import-modal', function(e)
{
    $(e.target).find('.tpemail').focus();
});