import { trans, transChoice } from '../common';
import { Quitch } from '../quitch';
import { selectDropdownItem } from '../form/dropdown';
import { toggleOffAllBadges } from './badges';
import { telemetryTrackEvent } from '../superuser/mixpanel';

$(document).ready(function()
{
    if (!$('#class-settings-outer').length)
    {
        return;
    }

    const previewImage = $('.preview-image').first();
    const previewImageBackground = $('.image-preview-background').first();
    const classImage = $('.class-img .rounded').first();

    previewImageBackground.css('background-color', classImage.css('background-color'));

    if (classImage.attr('src'))
    {
        previewImage.attr('src', classImage.attr('src'));

        $('#js-add-class-img-btn').text(trans('dashboard.changeImage'));
    }
    else
    {
        $('#remove-class-img-btn-holder').hide();
    }

    if ($('#classPresetSelector').first().val() !== 'competition')
    {
        $('#comp-end-date-section').first().hide();
    }
});

$(document).on('change', '#class-settings-outer #class-image-path', e =>
{
    if ($(e.target).val() == '')
    {
        $('#remove-class-img-btn-holder').hide();
    }
    else
    {
        $('#remove-class-img-btn-holder').show();
    }

    updateSettings({ 'classImage': $('#class-image-path').first().val() });
});

$(document).on('change', '.class-name-input', e =>
{
    $('.class-name-input').removeClass('has-error');
    $('.js-settings-class-name-error').hide();

    updateSettings({ 'className': $('.class-name-input').first().val() });
});

$(document).on('change', '#class-archive-date', function(e)
{
    const val = $('#class-archive-date').first().val();
    if (val == '')
    {
        updateSettings({ 'classArchiveDate': val });
        return;
    }

    const dt = new Date(val);
    dt.setDate(dt.getDate() + 1);
    const dateStr = dt.toISOString().split('T')[0];
    updateSettings({ 'classArchiveDate': dateStr });
});

$(document).on('change', '.settings-check-box, #classPresetSelector, #comp-end-date', e =>
{
    $('#save-class-config-btn').removeAttr('disabled');
});

$(document).on('change', '#classPresetSelector', e =>
{
    $('#comp-end-date-section').hide();

    //these are all the most common settings (which happens to be the same as attainment settings)
    $('#shuffleQuestionsCheckbox').prop('checked', false);
    $('#forceTimerCheckbox').prop('checked', true);
    $('#incorrectOnExitCheckbox').prop('checked', true);
    $('#allowSkipCheckbox').prop('checked', false);
    $('#allowPauseCheckbox').prop('checked', false);
    $('#allowSingleQnCheckbox').prop('checked', true);
    $('#allowShowAnsCheckbox').prop('checked', false);
    $('#showFeedbackCheckbox').prop('checked', true);

    switch ($(e.target).val())
    {
        case 'competition':
        {
            $('#comp-end-date-section').show();

            $('#shuffleQuestionsCheckbox').prop('checked', true);
            $('#allowSingleQnCheckbox').prop('checked', false);
            $('#classPointsType').val('competition');
            break;
        }
        case 'practice':
        {
            $('#forceTimerCheckbox').prop('checked', false);
            $('#incorrectOnExitCheckbox').prop('checked', false);
            $('#allowSkipCheckbox').prop('checked', true);
            $('#allowPauseCheckbox').prop('checked', true);
            $('#allowShowAnsCheckbox').prop('checked', true);
            $('#classPointsType').val('growth');
            break;
        }
        case 'assessment':
        {
            //the defaults are all the attainment ones
            $('#classPointsType').val('attainment');
            break;
        }
        case 'nasba':
        {
            $('#allowSingleQnCheckbox').prop('checked', false);
            $('#showFeedbackCheckbox').prop('checked', false);
            $('#classPointsType').val('growth');
            break;
        }
    }
});

$(document).on('change', '.class-config input[type="checkbox"]', function()
{
    $('.js-class-preset-dropdown [data-bs-target=custom]').show();
    selectDropdownItem($('.js-class-preset-dropdown'), 'custom');
});

$(document).on('change', '#class-in-marketplace-toggle', function(e)
{
    updateSaveBtn($('#save-marketplace-settings-btn'));
    const priceTierDropdown = $('#price-tier-dropdown');
    if ($(this).prop('checked'))
    {
        priceTierDropdown.find('button').prop('disabled', false);
        selectDropdownItem(priceTierDropdown, 1);
    }
    else
    {
        priceTierDropdown.find('button').prop('disabled', true);
        selectDropdownItem(priceTierDropdown, 5);
    }
});

$(document).on('change', '#time-zone-selector', e =>
{
    saveTimezoneSettings();
});

$(document).on('change', '#price-tier-selector, #class-accreditation-toggle, #class-hidden-toggle', e =>
{
    updateSaveBtn($('#save-marketplace-settings-btn'));
});

$(document).on('change keyup paste', '.class-tags-text-area', e =>
{
    if ($('.class-tags-text-area').data('initialised') == 'true') // Keyup event is called upon page load
    {
        clearClassTagsError();
    }
    else
    {
        $('.class-tags-text-area').data('initialised', 'true');
    }
});

$(document).on('focusout', '.class-tags-text-area', e =>
{
    clearClassTagsError();
});

$(document).on('change keyup paste', '.marketplace-class-desc-text', e =>
{
    if ($('.marketplace-class-desc-text').data('initialised') === 'true')
    {
        $('.marketplace-class-desc-text').removeClass('has-error');
        $('.js-marketplace-class-desc-error').hide();
        updateSaveBtn($('#save-class-description-settings-btn'));
    }
    else
    {
        $('.marketplace-class-desc-text').data('initialised', 'true');
    }
});

$(document).on('change keyup paste', '.class-tags-text-area', e =>
{
    if ($('.class-tags-text-area').hasClass('not-initialised')) // Prevent initial 'change' event from enabling the save btn
    {
        $('.class-tags-text-area').removeClass('not-initialised');
        return;
    }

    $('#save-class-tags-btn').removeAttr('disabled');
});

function saveClassTags()
{
    const settings = {
        'classTags': $('.class-tags-text-area').first().val(),
    };
    updateSettings(settings);
}

$(document).on('change keyup paste', '.class-faq-text-area, .class-faq-heading-input', e =>
{
    if ($(e.target).data('initialised') == 'true') // Keyup event is called upon page load
    {
        clearClassFaqsError();
    }
    else
    {
        $(e.target).data('initialised', 'true');
    }
});

$(document).on('focusout', '.class-faq-text-area, .class-faq-heading-input', e =>
{
    clearClassFaqsError();
});

$(document).on('change keyup paste', '.class-faq-text-area, .class-faq-heading-input', e =>
{
    if ($(e.target).hasClass('not-initialised')) // Prevent initial 'change' event from enabling the save btn
    {
        $(e.target).removeClass('not-initialised');
        return;
    }

    $('#save-faq-settings-btn').removeAttr('disabled');
});

function saveFaqs()
{
    const faqs = {};
    $('.class-faq-text-area').each(function(key, val)
    {
        const faq = {};
        faq['order'] = key + 1;
        faq['heading'] = $(this).closest('.settings-sub-section').find('.class-faq-heading-input').val();
        faq['body'] = $(this).val();
        faqs[key + 1] = faq;
    });

    const settings = {
        'classFaqs': JSON.stringify(faqs),
    };

    updateSettings(settings);
}

function clearClassTagsError()
{
    $('.class-tags-text-area').removeClass('has-error');
    $('.js-settings-class-tags-error').hide();
}

function clearClassFaqsError()
{
    $('.class-faq-text-area').removeClass('has-error');
    $('.class-faq-heading-input').removeClass('has-error');
    $('.js-settings-class-faq-error').hide();
}

//TODO: Make this percentage input system generic in the future
$(document).on('focusin', '#at-risk-threshold-input-fake', function()
{
    const realInput = $('#at-risk-threshold-input').first();
    const fakeInput = $('#at-risk-threshold-input-fake').first();

    fakeInput.hide();
    realInput.show();
    realInput.focus();

    realInput.attr('type', 'text'); // 'number' type does not allow selection
    $('#at-risk-threshold-input')[0].selectionStart = realInput.val().length;
    $('#at-risk-threshold-input')[0].selectionEnd = realInput.val().length;
    realInput.attr('type', 'number');
});

$(document).on('focusout', '#at-risk-threshold-input', function(e)
{
    const realInput = $('#at-risk-threshold-input').first();
    const fakeInput = $('#at-risk-threshold-input-fake').first();

    realInput.hide();
    fakeInput.show();

    if (realInput.val() == '' || realInput.val() < 0)
    {
        realInput.val(50);
    }
    else if (realInput.val() >= 100)
    {
        realInput.val(99);
    }

    fakeInput.val(realInput.val() + '%');

    saveAtRiskLearnersSettings();
});

$(document).on('click', '#js-remove-class-img-btn', function()
{
    // Restore the org logo's colour

    const previewImageBackground = $('.image-preview-background').first();

    if ($('#org-logo-hidden').length)
    {
        $('#org-logo-hidden').hide();
        $('#org-logo-colour').show();

        previewImageBackground.css('background-color', $('#org-logo-colour').first().css('background-color'));
    }
    else
    {
        $('#org-logo').hide();
        $('#org-logo-colour-hidden').show();

        previewImageBackground.css('background-color', $('#org-logo-colour-hidden').first().css('background-color'));
    }

    $('.preview-image').attr('src', '');
    $('#class-image-path').first().val('');
    $('#remove-class-img-btn-holder').hide();

    updateSettings({ 'classImage': '' });
});

$(document).on('click', '#save-class-config-btn', function()
{
    $(this).attr('disabled', true);
    saveClassConfigSettings();
});

function saveClassConfigSettings()
{
    const settings = {
        'preset': $('#classPresetSelector').val(),
        'classType': $('#classPointsType').val(),
        'compEndDateTime': $('#comp-end-date').first().val(),
        'shuffleQuestions': $('#shuffleQuestionsCheckbox').first().prop('checked'),
        'forceTimer': $('#forceTimerCheckbox').first().prop('checked'),
        'incorrectOnExit': $('#incorrectOnExitCheckbox').first().prop('checked'),
        'allowSkip': $('#allowSkipCheckbox').first().prop('checked'),
        'allowPause': $('#allowPauseCheckbox').first().prop('checked'),
        'allowSingleQuestion': $('#allowSingleQnCheckbox').first().prop('checked'),
        'allowShowAnswer': $('#allowShowAnsCheckbox').first().prop('checked'),
        'showFeedback': $('#showFeedbackCheckbox').prop('checked'),
    };

    if (settings.preset !== 'custom')
    {
        $('.js-class-preset-dropdown [data-bs-target=custom]').hide();
    }

    if (settings.preset === 'nasba')
    {
        Quitch.modal(
            {
                title: trans('settings.changeTypeModalTitle'),
                message: `<p class="wrap">${trans('settings.changeTypeModalBody')}</p>`,
                type: 'confirm_custom',
                cssClass: 'modal-dialog-sm modal-dialog-centered',
                okText: 'settings.yesUpdate',
                cancelText: 'base.cancel',
                showClose: false,
            },
            function(modalResponse)
            {
                if (modalResponse === true)
                {
                    settings.timerDuration = 0;
                    settings.attemptLimit = 1;

                    selectDropdownItem($('#settings-timer-duration-selector').parents('.js_dropdown-select'), 0);
                    selectDropdownItem($('#settings-attempt-limit-selector').parents('.js_dropdown-select'), 1);

                    // toggle off gamification settings
                    $('#settings-leaderboard-toggle').prop('checked', false).change();
                    toggleOffAllBadges();

                    updateSettings(settings);
                }
            }
        );
    }
    else
    {
        updateSettings(settings);
    }
}

$(document).on('click', '#save-at-risk-learners-btn', function()
{
    $(this).attr('disabled', true);
    saveAtRiskLearnersSettings();
});

function saveAtRiskLearnersSettings()
{
    const settings = {
        'atRiskThreshold': $('#at-risk-threshold-input').first().val()
    };
    updateSettings(settings);
}

$(document).on('click', '#save-timezone-btn', function()
{
    $(this).attr('disabled', true);
    saveTimezoneSettings();
});

function saveTimezoneSettings()
{
    const settings = {
        'timeZone': $('#time-zone-selector').first().val()
    };
    updateSettings(settings);
}

$(document).on('click', '#save-marketplace-settings-btn', function()
{
    saveMarketplaceSettings();
});

$(document).on('click', '.add-learningObjective-btn', e =>
{
    const learningObjectives = $('.learningObjectives');
    addLearningObjective(learningObjectives);
});

function addLearningObjective(learningObjectives)
{
    const html = '<li class="learningObjective-item">' + $('.learningObjective-item').html() + '</li>';
    $(learningObjectives).find('.learningObjective-list').append(html);
    const newElem = $(learningObjectives).find('.learningObjective-item').last();
    newElem.find('.input-field').html('').focus();
    updateSaveBtn($('#save-class-description-settings-btn'));
}

$(document).on('change keyup paste', '.learningObjective-item .input-field[contenteditable=true]', function(e)
{
    updateSaveBtn($('#save-class-description-settings-btn'));
});

$(document).on('change', '#classEducatorSelector, #classLabelSelector, .marketplace-class-approxTime, .marketplace-class-name-input', e =>
{
    updateSaveBtn($('#save-class-description-settings-btn'));
});

$(document).on('click', '#save-class-description-settings-btn', function()
{
    let dateStr = $('#class-lastUpdated').first().val();
    if (dateStr != '')
    {
        const dt = new Date(dateStr);
        dt.setDate(dt.getDate() + 1);
        dateStr = dt.toISOString().split('T')[0];
    }
    const learningObjectives = {};
    $('.learningObjective-item').each(function(key, val)
    {
        const title = $(val).find('.input-field').text().trim();
        if (title)
        {
            const learningObjective = { title };
            learningObjectives[key + 1] = learningObjective;
        }
    });

    const settings = {
        'className': $('.marketplace-class-name-input').first().val(),
        'approxTime': $('.marketplace-class-approxTime').first().val(),
        'lastUpdated': dateStr,
        'classDescription': $('.marketplace-class-desc-text').first().html(),
        'label': $('#classLabelSelector').val(),
        'learningObjectives': JSON.stringify(learningObjectives),
        'educatorId': $('#classEducatorSelector').val(),
    };
    updateSettings(settings);
    updateSaveBtn($('#save-class-description-settings-btn'), true);
});

function saveMarketplaceSettings()
{
    const showOnMarketplaceToggle = $('#class-in-marketplace-toggle');
    const showOnMarketplace = showOnMarketplaceToggle.prop('checked');
    const classCategories = $('#class-categories .category-item').map(function() { return $(this).attr('data-category-id'); }).get();

    const settings = {
        'showOnMarketplace': showOnMarketplace,
        'hidden': $('#class-hidden-toggle').prop('checked'),
        'priceTier': $('#price-tier-selector').val(),
        'classCategories': classCategories.length > 0 ? classCategories : null,
        'accreditation': $('#class-accreditation-toggle').prop('checked') ? 'nasba' : null,
        'subtitle': $('#settings-subtitle-input').val(),
    };

    if (showOnMarketplaceToggle.attr('data-current-val') == 1 && !showOnMarketplace)
    {
        Quitch.modal(
            {
                title: trans('settings.removeFromMarketplace'),
                message: `<p class="wrap">${trans('settings.removeFromMarketplaceDescA')}</p>
                          <br>
                          <p class="wrap">${trans('settings.removeFromMarketplaceDescB')}</p>`,
                type: 'confirm_custom',
                cssClass: 'modal-dialog-sm modal-dialog-centered delete-modal',
                okText: 'settings.yesRemove',
                cancelText: 'base.cancel',
                showClose: false,
            },
            function(modalResponse)
            {
                if (modalResponse === true)
                {
                    showOnMarketplaceToggle.attr('data-current-val', 0);
                    updateSaveBtn($('#save-marketplace-settings-btn'), true);
                    updateSettings(settings);
                }
            }
        );
    }
    else
    {
        showOnMarketplaceToggle.attr('data-current-val', showOnMarketplace ? 1 : 0);
        updateSaveBtn($('#save-marketplace-settings-btn'), true);
        updateSettings(settings);
    }
}

$(document).on('change', '#settings-timer-duration-selector', function()
{
    const settings = {
        'timerDuration': $(this).first().val(),
    };
    updateSettings(settings);
});

$(document).on('change', '#settings-attempt-limit-selector', function()
{
    const settings = {
        'attemptLimit': $(this).first().val(),
    };
    updateSettings(settings);
});

$(document).on('change', '#settings-class-code-input', function()
{
    const settings = {
        'classCode': $(this).first().val(),
    };
    updateSettings(settings);
});

$(document).on('change', '#settings-leaderboard-toggle', function()
{
    const leaderboardToggle = $(this).first().prop('checked');
    if (leaderboardToggle)
    {
        $('.hide-if-leaderboard-disabled').show();
    }
    else
    {
        $('.hide-if-leaderboard-disabled').hide();
    }

    const settings = {
        'leaderboard': $(this).first().prop('checked'),
    };
    updateSettings(settings);
});

$(document).on('change', '.settings-radio-btn', function()
{
    $('.settings-radio-btn').prop('checked', false);
    $(this).prop('checked', true);

    const settings = {
        'showAllOnLeaderboard': $('#leaderboardFullClassRadioBtn').first().prop('checked'),
    };
    updateSettings(settings);
});

function updateSettings(settings, reloadAfter = false)
{
    Quitch.showSnackbar(trans('settings.updating'));

    let values = { '_token': $('meta[name="csrf-token"]').attr('content'), 'settings': settings };
    let classId = $('.js_class-manager').data('class-id');
    let url = '/class/settings/update';
    if (!classId)
    {
        url = '/manage/marketplace/class/settings/update';
        classId = $('[data-class-id]').attr('data-class-id');
        values.orgId = $('[data-org-id]').attr('data-org-id');
    }
    values.classId = classId;

    $.ajax(
    {
        url: url,
        type: 'POST',
        dataType: 'json',
        data: values,
        success: function(response)
        {
            Quitch.showSnackbar(trans('settings.updated'));

            if (settings.className)
            {
                $('.js-class-name').text($('.class-name-input').first().val());
            }

            const prevImgSrc = $('.preview-image').attr('src');
            if (prevImgSrc && $('#class-image-path').first().val() != '')
            {
                if ($('#org-logo-hidden').length)
                {
                    // We don't have an existing logo image - show/use the reserve version of the logo image
                    $('#org-logo-colour').hide();
                    $('#org-logo-hidden').show();
                    $('#org-logo-hidden').attr('src', prevImgSrc);
                }
                else
                {
                    // We already have a logo image
                    $('#org-logo-colour-hidden').hide();
                    $('#org-logo').show();
                    $('#org-logo').first().attr('src', prevImgSrc);
                }
            }

            if (reloadAfter)
            {
                window.location.reload();
            }

            if (response.timezones)
            {
                $('#announcement-tz').text('(' + response.timezones + ')');
            }
        },
        error: function(xhr, status, error)
        {
            if (xhr.responseJSON && xhr.responseJSON.errors)
            {
                for (const [key, value] of Object.entries(xhr.responseJSON.errors))
                {
                    if (key === 'className')
                    {
                        $('.js-settings-class-name-error').show().html(value);
                        $('.class-name-input').addClass('has-error');
                    }
                    else if (key === 'classDescription')
                    {
                        $('.js-marketplace-class-desc-error').show().html(value);
                        $('.marketplace-class-desc-text').addClass('has-error');
                    }
                    else if (key === 'classTags')
                    {
                        $('.js-settings-class-tags-error').show().html(value);
                        $('.class-tags-text-area').addClass('has-error');
                    }
                    else if (key === 'classFaqs')
                    {
                        for (let i = 0; i < value.length; ++i)
                        {
                            $('.js-settings-class-faq-error').eq(value[i]['order'] - 1).show().html(value[i]['errorText']);
                            $('.class-faq-text-area').eq(value[i]['order'] - 1).addClass('has-error');
                            $('.class-faq-heading-input').eq(value[i]['order'] - 1).addClass('has-error');
                        }
                    }
                    else
                    {
                        Quitch.showErrors(xhr.responseJSON.errors);
                    }
                }
            }
        }
    });
}

$(document).on('click', '.delete-class-btn', function(e)
{
    e.stopPropagation();

    let target = $(e.target);
    let classId = $('.js_class-manager').data('class-id');
    if (!classId)
    {
        classId = target.data('bs-target-id') ?
            target.data('bs-target-id') :
            $(this)
            .closest('.accordion-parent')
            .data('class-id');
    }

    let className = $('.class-name-input').first().val();
    if (!className)
    {
        if ($('#classes-view-switcher .grid.active').length > 0)
        {
            className = target.parents('.class-card').find('.class-card-desc').text().trim();
        }
        else
        {
            className = target.parents('tr').find('.class-name').text().trim();
        }
    }

    Quitch.modal(
        {
            title: trans('settings.deleteClassHeader'),
            message: `<p class="wrap">${trans('settings.deleteClassBodyA', {className: className})}</p>
                      <p class="wrap mt-2">${trans('settings.deleteClassBodyB')}</p>`,
            type: 'confirm_custom',
            cssClass: 'modal-dialog-sm modal-dialog-centered delete-modal',
            okText: 'settings.yesDelete',
            cancelText: 'base.cancel',
        },
        function(response)
        {
            if (response === true)
            {
                $.ajax(
                {
                    url: '/class/delete/' + classId,
                    type: 'POST',
                    dataType: 'json',
                    data: { _token: $('meta[name="csrf-token"]').attr('content') },
                    success: function(response)
                    {
                        if (window.location.pathname.trim() === '/home' || window.location.pathname.trim() === '/home/')
                        {
                            window.location.reload();
                        }
                        else
                        {
                            window.location.href = '/home';
                        }
                    },
                    error: function()
                    {
                        Quitch.showMessage('danger', trans('settings.deleteClassFailed'));
                    }
                });
            }
        }
    );
});

$(document).on('click', '#generate-class-tags-btn', function()
{
    $(this).attr('disabled', true);
    $('#generate-class-tags-btn-spinner').show();
    $('.class-tags-text-area').attr('disabled', true);

    let url = '/class/tags/ai/generate';
    let values = { '_token': $('meta[name="csrf-token"]').attr('content') };
    let classId = $('.js_class_details').data('class-id');

    if (!classId)
    {
        url = '/manage/marketplace/class/tags/ai/generate';
        classId = $('[data-class-id]').attr('data-class-id');
        values.orgId = $('[data-org-id]').attr('data-org-id');
    }

    values.classId = classId;

    $.ajax(
    {
        url: url,
        type: 'POST',
        dataType: 'json',
        data: values,
        success: function(response)
        {
            const result = response.result;
            let commaSep = '';
            if (result)
            {
                for (let i = 0; i < result.length; ++i)
                {
                    commaSep += result[i];
                    if (i < result.length - 1)
                    {
                        commaSep += ', ';
                    }
                }
            }
            commaSep = commaSep.replace('-', '');
            $('.class-tags-text-area').val(commaSep);

            $('#save-class-tags-btn').removeAttr('disabled');

            $('.class-tags-text-area.js_data-counter').trigger('keyup');

            $('#generate-class-tags-btn').attr('disabled', false);
            $('#generate-class-tags-btn-spinner').hide();
            $('.class-tags-text-area').attr('disabled', false);

            Quitch.showMessage('info', trans('settings.generateClassTagsSuccess'));
        },
        error: function(xhr, status, error)
        {
            $('#generate-class-tags-btn').attr('disabled', false);
            $('#generate-class-tags-btn-spinner').hide();
            $('.class-tags-text-area').attr('disabled', false);

            Quitch.showMessage('danger', trans('settings.generateClassTagsFailed'));
        }
    });

});

$(document).on('click', '#save-class-tags-btn', function()
{
    $(this).attr('disabled', true);
    saveClassTags();
    updateSaveBtn($('#save-class-tags-btn'), true);
});

$(document).on('click', '#save-faq-settings-btn', function()
{
    $(this).attr('disabled', true);
    saveFaqs();
    updateSaveBtn($('#save-faq-settings-btn'), true);
});

$(document).on('submit', '#class-export-form', function(e)
{

    e.preventDefault();

    const form = $(this);
    const actionUrl = form.attr('action');
    toggleExportBtn(false);

    $.ajax(
    {
        type: 'POST',
        url: actionUrl,
        data: form.serialize(),
        success: function(response)
        {
            refreshExportJob(response.jobId);
        },
        error: function(xhr, status, error)
        {
            Quitch.showSnackbar(trans('settings.classExportFailed'));
            toggleExportBtn();
        }
    });
});

function refreshExportJob(jobId, retryDelay = 500)
{
    $.ajax(
    {
        url: `/jobs/get-status/${jobId}`,
        type: 'GET',
        dataType: 'json',
        success: function(response)
        {
            if (response.job.status === 'created' || response.job.status === 'running')
            {
                window.setTimeout(function()
                {
                    refreshExportJob(jobId, Math.ceil(retryDelay * 1.5));
                }, retryDelay);
            }
            else if (response.job.status === 'completed')
            {
                telemetryTrackEvent('classSettings-contentExport', { 'status': 'success', exportType: $('.js-class-content-export-input').val() });
                toggleExportBtn();
                const classId = $('.js_class-manager').data('class-id');
                window.location = `/class/${classId}/${jobId}/export/download`;
            }
            else if (response.job.status === 'failed')
            {
                Quitch.showSnackbar(trans('settings.classExportFailed'));
                toggleExportBtn();
                telemetryTrackEvent('classSettings-contentExport', { 'status': 'failed', exportType: $('.js-class-content-export-input').val() });
            }
        },
        error: function(xhr, status, error)
        {
            Quitch.showSnackbar(trans('settings.classExportFailed'));
            toggleExportBtn();
            telemetryTrackEvent('classSettings-contentExport', { 'status': 'failed', exportType: $('.js-class-content-export-input').val(), 'debug': xhr.responseJSON.message, 'error': error });
        }
    });
}

function toggleExportBtn(enable = true)
{
    if (enable)
    {
        $('.js-class-content-export-dropdown-btn').removeClass('disabled');
        $('#export-download-btn').prop('disabled', false).html(trans('settings.download'));
    }
    else
    {
        $('.js-class-content-export-dropdown-btn').addClass('disabled');
        $('#export-download-btn').prop('disabled', true).html('<span class="me-2"><i class="fa-solid fa-circle-notch fa-pulse"></i></span>' + trans('reporting.processing'));
    }
}

$(document).on('click', '.settings-section.collapsable .settings-section-header', function(e)
{
    let parent = $(this).parents('.settings-section');
    parent.toggleClass('expanded');
    const sectionBody = $(this).siblings('.settings-section-body');

    sectionBody.slideToggle(500, function()
    {
        $(this).removeAttr('style');
        sectionBody.toggleClass('show');
        if (parent.hasClass('expanded') && parent.is('#class-reviews-section'))
        {
            loadClassReviewsTable();
        }
    });
});

function updateSaveBtn(saveBtn, saved = false)
{
    if (saved)
    {
        saveBtn.text(trans('settings.saved'));
        saveBtn.attr('disabled', true);
    }
    else
    {
        saveBtn.text(trans('settings.save'));
        saveBtn.removeAttr('disabled');
    }
}

$(document).on('click', '.js-class-category-select .dropdown-item', function(e)
{
    updateSaveBtn($('#save-marketplace-settings-btn'));
    $('#class-categories').append(`<div class="category-item" data-category-id="${$(this).attr('data-bs-target')}">${$(this).text()}</div>`);
    $(this).attr('hidden', true);
});

$(document).on('click', '#class-categories .category-item', function(e)
{
    updateSaveBtn($('#save-marketplace-settings-btn'));
    $(`.js-class-category-select .dropdown-item[data-bs-target="${$(this).attr('data-category-id')}"]`).removeAttr('hidden');
    $(this).remove();
});


function loadClassReviewsTable()
{
    if ($.fn.dataTable.isDataTable('#class-reviews-table'))
    {
        // just refresh data if table exists
        $('#class-reviews-table')
            .DataTable()
            .ajax.reload(null, false);
        return;
    }

    let jsTable = $('#class-reviews-table').DataTable(
    {
        ajax:
        {
            url: '/class/reviews/list',
            type: 'GET',
            data: function(d)
            {
                return {
                    classInstanceId: $('.classInstanceId').attr('data-class-instance-id'),
                    start: d['start'],
                    length: d['length'],
                };
            }
        },
        drawCallback: function(data)
        {
            updateClassRating(data.json.ratingsCount, data.json.recordsTotal);
            updateClassReviewTagsSummary(data.json.tagsCount);
            $('#class-reviews-table_wrapper').toggle(data.json.recordsTotal > 0);
        },
        language:
        {
            info: trans('invites.tableEntries', { start: '_START_', end: '_END_', total: '_TOTAL_' }),
            infoFiltered: '',
            lengthMenu: trans('settings.reviewLengthMenu', { menu: '_MENU_' }),
            zeroRecords: trans('invites.tableEmpty'),
            infoEmpty: '',
            paginate:
            {
                next: '<i class="fa fa-angle-right"></i>',
                previous: '<i class="fa fa-angle-left"></i>',
            },
        },
        serverSide: true,
        searching: true,
        pagingType: 'simple',
        targets: 'no-sort',
        order: [],
        pageLength: 5,
        lengthMenu: [5, 10, 25, 50, 75, 100],
        dom: 't<"row col-lg-12 ps-0 pe-0 bg-quitch-white pagination pt-1 pb-1"l<"me-4 absolute-right right-65"i><"absolute-right"p>>',
        autoWidth: false,
        columnDefs: [
            { targets: [0], width: '100%', orderable: false, visible: true },
        ],
        columns: [
        {
            render: function(data, type, row)
            {
                let stars = '';
                for (let i = 0; i < row.rating; i++)
                {
                    stars += '<div class="star selected"></div>';
                }
                for (let i = 0; i < 5 - row.rating; i++)
                {
                    stars += '<div class="star"></div>';
                }
                let reviewCardHtml = `
                        <div class="review-card ${row.visible ? 'visible' : 'hidden'}" data-review-id="${row.id}">
                            <div class="header">
                                <div class="stars-group">${stars}</div>
                                <div class="body-small">By ${row.name} • ${row.createdAt}</div>
                            </div>`;
                if (row.review)
                {
                    reviewCardHtml += `<div class="body-large">${row.review}</div>`;

                    if (row.visible)
                    {
                        reviewCardHtml += `<div class="footer"><a href="#" class="toggle-review" data-visible="0">${trans('settings.hideReview')}</a></div>`;
                    }
                    else
                    {
                        reviewCardHtml += `<div class="footer"><span>${trans('settings.hiddenFromMarketplace')}</span> • <a href="#" class="toggle-review" data-visible="1">${trans('settings.showReview')}</a></div>`;
                    }
                }
                reviewCardHtml += '</div>';
                return reviewCardHtml;
            },
        }, ],
    });
}

function updateClassRating(ratingsCount, totalReviewsCount)
{
    let ratingsTotalCount = 0;
    let ratingsTotalSum = 0;
    $.each(ratingsCount, function(key, value)
    {
        ratingsTotalCount += value;
        ratingsTotalSum += (key * value);
    });

    const hiddenReviewsCount = totalReviewsCount - ratingsTotalCount;
    $('.class-reviews-counter .count').text(transChoice('settings.reviewsCount', ratingsTotalCount, { count: ratingsTotalCount }));
    $('.class-reviews-counter .hidden-count').text(`(${trans('settings.hiddenCount',{ count: hiddenReviewsCount })})`).toggle(hiddenReviewsCount > 0);

    if (ratingsTotalCount === 0)
    {
        $('.class-rating .score').text(0);
        $('.class-rating .stars-group.filled').css('width', 0);
        $(`.class-rating .rating-breakdown-item .bar`).css('width', 0);
        $(`.class-rating .rating-breakdown-item .tally`).text(0);
        $('.class-rating').css('opacity', 0.28);
        return;
    }
    else
    {
        $('.class-rating').css('opacity', 1);
    }

    for (let i = 1; i <= 5; i++)
    {
        const ratingBreakdownItem = $(`.class-rating .rating-breakdown-item.${i}`);
        const count = ratingsCount[i] ? ratingsCount[i] : 0;
        ratingBreakdownItem.find('.bar').css('width', ratingsTotalCount > 0 ? (((count / ratingsTotalCount) * 100) + '%') : 0);
        ratingBreakdownItem.find('.tally').text(count);
    }

    let avgRating = ratingsTotalSum / ratingsTotalCount;
    $('.class-rating .score').text(avgRating.toFixed(1));
    // Adjust the width of the yellow stars mask to represent the average rating
    $('.class-rating .stars-group.filled').css('width', (Math.floor(avgRating) * 32) + 4.5 + ((avgRating % 1) * 23) + 'px');
}

function updateClassReviewTagsSummary(tagsCount)
{
    $('.tags-summary .good-tags, .tags-summary .bad-tags').hide();
    $(`.tags-summary .tags-group .lozenge`).remove();
    $.each(tagsCount, function(i, item)
    {
        $(`.tags-summary .${item.type}-tags`).show();
        $(`.tags-summary .${item.type}-tags .tags-group`).append(`<div class="lozenge">${trans(`settings.${item.tag}`)} • ${item.count}</div>`);
    });
}

$(document).on('click', '#class-reviews-table .toggle-review', function(e)
{
    e.preventDefault();
    const reviewId = $(this).closest('.review-card').attr('data-review-id');
    const visible = $(this).attr('data-visible');
    $.ajax(
    {
        url: `/class/review/${reviewId}/toggle`,
        type: 'POST',
        dataType: 'json',
        data:
        {
            _token: $('meta[name="csrf-token"]').attr('content'),
            visible: visible,
        },
        success: function(response)
        {
            loadClassReviewsTable();
        },
    });
});
$(document).on('focusin', '#certificate-accuracy-threshold-input', function(e)
{
    $(this).val($(this).val().replace(/[^0-9]/g, ''));
    $(this).attr('type', 'number');
});

$(document).on('focusout', '#certificate-accuracy-threshold-input', function(e)
{
    $(this).attr('data-value', $(this).val());

    if ($(this).val() > 100 || $(this).val() === '')
    {
        $(this).val(100);
    }
    else if ($(this).val() < 0)
    {
        $(this).val(0);
    }

    $(this).attr('type', 'text');
    $(this).val($(this).val() + '%');
});

$(document).on('change', '#class-accreditation-toggle, #certifying-org-input, #certificate-issue-type-input, #certificate-accuracy-threshold-input, #certificate-points-input, #field-of-study-input', function()
{
    let valid = true;

    $('#certificate-settings-section').find('input[type=text], input[type=hidden]').each(function()
    {
        if ($(this).val() === '')
        {
            valid = false;
            return false;
        }
    });

    updateSaveBtn($('#save-certificate-settings-btn'), !valid);
});

$(document).on('click', '#save-certificate-settings-btn', function()
{
    const settings = {
        'accreditation': $('#class-accreditation-toggle').prop('checked') ? 'nasba' : null,
        'certifyingOrg': $('#certifying-org-input').val(),
        'certificateIssueType': $('#certificate-issue-type-input').val(),
        'certificateAccuracyThreshold': $('#certificate-accuracy-threshold-input').attr('data-value'),
        'certificatePoints': $('#certificate-points-input').val(),
        'fieldOfStudy': $('#field-of-study-input').val(),
    };

    updateSaveBtn($(this), true);
    updateSettings(settings);
});