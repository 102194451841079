import { Quitch } from '../quitch';
import { trans } from '../common';
import { getMessage, getSpinnerHtml } from '../../system';
import { removeUploadedImageFromModal } from '../form/file-upload';
import { mixpanelEventCounter, telemetryTrackAndPerformUrlChange, telemetryTrackEvent } from '../superuser/mixpanel';
import Cookies from 'js-cookie';


$(document).on('click tap touch', '.js-create-class', function(e)
{
    initialiseClassModal();
});

function initialiseClassModal()
{
    const createClassModal = $('#create-class-modal');
    createClassModal.find('.modal-title:not(.first-class)').text(trans('dashboard.createClassTitle'));
    createClassModal.find('.call-to-action.info').hide();
    createClassModal.find('[name=className]').val('').change();
    createClassModal.find('[name=classImage]').val('').change();
    createClassModal.find('.preview-image').attr('src', '').hide();
    createClassModal.find('[name=classArchiveDate]').val('').change();
    createClassModal.find('.js-create-class-btn').attr('data-class-id', '');
    createClassModal.modal('show');
}

$(document).on('shown.bs.modal', '#create-class-modal', function(e)
{
    $('#create-class-modal [name=className]').focus();
});

$(document).on('hidden.bs.modal', '#create-class-modal', function(e)
{
    removeUploadedImageFromModal('add-image-modal');
});

$(document).on('click', '.archive-date-section [type=date].empty', function(e)
{
    $(this)[0].showPicker();
});

$(document).on('click', '.archive-date-section [type=datetime-local].empty', function(e)
{
    $(this)[0].showPicker();
});

$(document).on('change', '.archive-date-section [type=date]', function(e)
{
    updateArchiveDateState(this);
});

$(document).on('change', '.archive-date-section [type=datetime-local]', function(e)
{
    updateArchiveDateState(this);
});

export function updateArchiveDateState(elem)
{
    if (elem)
    {
        if ($(elem).val() === '')
        {
            $(elem).addClass('empty');
        }
        else
        {
            $(elem).removeClass('empty');
        }
    }
}

$(document).on('click', '#js-add-class-img-btn', function()
{
    $('#add-image-modal').modal('show');
    $('#add-image-done-btn').attr('data-img-dest', '#class-image-path');
});

$(document).on('change', '#class-image-path', function()
{
    $('#js-add-class-img-btn').text(trans($(this).val() == '' ? 'dashboard.addImage' : 'dashboard.changeImage'));
});

$(document).on('paste', '#create-class-modal [name=className]', function(e)
{
    //Paste event is called before val() is changed, so call keyup event shortly after to update things.
    window.setTimeout(() => { $(this).keyup(); }, 10);
});

$(document).on('keyup change', '#create-class-modal [name=className]', function(e)
{
    let className = $(this).val().trim();
    $('#create-class-modal .label-small.error-text').remove();
    $(this).removeClass('counter-limit-reached');
    if (className.length > 250)
    {
        $(this).addClass('counter-limit-reached');
        $(this).after(`<span class="label-small error-text text-end">${className.length}/250</span>`);
    }

    $('#js-create-class-btn').attr('disabled', className === '' || className.length > 250);
});

$(document).on('click', '#js-create-class-btn', function()
{
    $('#js-create-class-btn').attr('disabled', true);
    let values = {};
    values['_token'] = $('meta[name="csrf-token"]').attr('content');
    values['name'] = $('#create-class-modal [name=className]').val().trim();
    values['image'] = $('#create-class-modal [name=classImage]').val().trim() || null;
    values['colour'] = $('#create-class-modal [name=classColour]').val();
    values['archiveDate'] = $('#create-class-modal [name=classArchiveDate]').val().trim() || null;

    let url = '/class/create';
    const classId = $(this).attr('data-class-id');
    if (classId)
    {
        values['id'] = classId;
        url = '/class-clone/' + classId;
    }

    $.ajax(
    {
        url: url,
        type: 'POST',
        dataType: 'json',
        data: values,
        success: function(response)
        {
            $('#create-class-modal').modal('hide');
            mixpanelEventCounter('classCreated');

            // Reshow switch editor modal if last used classic
            if (Cookies.get('last-content-authoring-visited') === 'classic')
            {
                Cookies.remove('last-content-authoring-visited');
            }

            telemetryTrackAndPerformUrlChange(`/class-manager/${response.classId}#content`, 'ep-create-class-success');
        },
        error: function(xhr, status, error)
        {
            console.error('Class clone/create error', [url, xhr, status, error]);

            $('#js-create-class-btn').attr('disabled', false);
            $('#create-class-modal').modal('hide');
            Quitch.showErrors(trans('home.createClassErrorTryAgain') + ' (' + error + ')');

            telemetryTrackEvent('ep-class-create-or-clone-error', { 'status': 'failed', 'debug': xhr.responseJSON.message, 'error': error, 'errorUrl': url });
        },
    });
});

$(document).on('change', '.js-is-public-checkbox', function(e)
{
    let target = $(e.currentTarget);
    let form = target.closest('form');
    if (target[0].checked)
    {
        form.find('.js-private-price-tier').attr('hidden', '');
        form.find('.js-public-price-tier').removeAttr('hidden');
        form.find('.price-tier').val(form.find('.js-free-public-price-tier').val());
    }
    else
    {
        form.find('.js-private-price-tier').removeAttr('hidden');
        form.find('.js-public-price-tier').attr('hidden', '');
        form.find('.price-tier').val(form.find('.js-private-price-tier').val());
    }
});

$(document).on('change', '.js-set-soft-end-checkbox', function(e)
{
    let checkbox = $(e.currentTarget);
    if (checkbox.length >= 1)
    {
        let section = checkbox.parents('.modal-body').find('.js-soft-end-section');

        let validateErrorList = checkbox.parents('.modal-body').find('.validateErrorList');
        let validateError = checkbox.parents('.modal-body').find('.validateError');

        if (checkbox[0].checked)
        {
            section.show();

            if (!validateErrorList.innerHtml.isEmpty())
            {
                validateError.show();
            }
        }
        else
        {
            section.hide();

            validateError.hide();
        }
    }
});

$(document).on('change', '.js-class-type-dropdown', function(e)
{
    let dropdown = $(e.currentTarget);
    if (dropdown.length >= 1)
    {
        let modal = dropdown.parents('.modal-body');
        let section = modal.find('.js-soft-end-checkbox-section');
        let checkbox = modal.find('.js-set-soft-end-checkbox');
        let section2 = modal.find('.js-soft-end-section');
        if (dropdown[0].value === 'competition')
        {
            section.show();
        }
        else
        {
            section.hide();
            checkbox[0].checked = false;
            section2.hide();
        }
    }
});

$(document).on('click', '.close-form', function()
{
    $(this)
        .closest('.new-create-form')
        .remove();
    $('#class_create_form').remove();
});

/**
 * Setup class clone modal
 */
$(document).on('click', '.class-clone-btn', function(e)
{
    e.preventDefault();
    e.stopPropagation();

    const createClassModal = $('#create-class-modal');
    const classInfo = $('#classes-view-switcher .grid.active').length > 0 ? $(this).parents('.class-card') : $(this).parents('tr').find('.class-info-container');
    const className = classInfo.find('.class-name').text().trim();
    const classImage = classInfo.find('[name=classImage]').val();

    createClassModal.find('.modal-title').text(trans('home.duplicateClass', { name: className }));
    createClassModal.find('.call-to-action.info').show();
    createClassModal.find('[name=className]').val(className + ' (copy)').change();
    createClassModal.find('[name=classImage]').val(classImage).change();
    createClassModal.find('[name=classColour]').val(classInfo.attr('data-colour'));
    createClassModal.find('.preview-image').attr('src', classInfo.find('.class-image-preview').attr('src')).toggle(!!classImage);
    createClassModal.find('.image-preview-background').css('background-color', classInfo.attr('data-colour'));
    createClassModal.find('[name=classArchiveDate]').val(null).change();
    createClassModal.find('#js-create-class-btn').attr('data-class-id', classInfo.attr('data-class-id'));
    createClassModal.modal('show');
});

/**
 * Submit class clone
 */
$(document).on('click', '.clone-class', function(e)
{
    e.preventDefault();

    let cloneloader = $('.clone-loader');
    cloneloader.html(getSpinnerHtml());

    $('.clone-errors').empty();
    let values = {};
    $('.chosen-container-single-nosearch').hide();
    values['_token'] = $('meta[name="csrf-token"]').attr('content');
    values['id'] = $('.classid').val();
    values['class_name'] = $('.clone-classname').val();
    values['class_code'] = $('.clone-classcode').val();
    values['start_date'] = $('.clone-startdate').val();
    values['end_date'] = $('.clone-enddate').val();

    $.ajax(
    {
        url: '/class-clone/' + values['id'],
        type: 'POST',
        dataType: 'json',
        data: values,
        success: function(response)
        {
            if (response.status === '200' || response.status === 200)
            {
                $('#class-clone-modal').modal('hide');
                Quitch.showMessage('success', 'Class cloned');
                location.reload();
            }
            else if (response.status === '403' || response.status === 403)
            {
                $('.clone-errors').html('<div class="alert text-center alert-danger" style="margin-top: -1px !important;"><ul class="class-clone-validate"></ul></div>');

                $.each(response.errors, function(key, error)
                {
                    $('.class-clone-validate').append('<li>' + error + '</li>');
                });

                $('.errorrs-class');
            }
            else
            {
                $('.clone-errors').html(getMessage(response.message, 'danger'));
            }
            window.scrollTo(0, 0);
            $('.clone-loader').empty();
        },
    });
});

function validateClassEdit(modalName)
{
    let errors = 0;

    let className = $(`${modalName} .class-name`).val();
    if (!className)
    {
        let classNameRequired = $(`${modalName} .class_name_required`);
        classNameRequired.show();
        classNameRequired.css('color', 'red');
        ++errors;
    }
    else
    {
        $(`${modalName} .class_name_required`).hide();
    }

    let classDescError = $(`${modalName} .class-desc-error`);
    if ($(`${modalName} .class-desc`).hasClass('counter-limit-reached'))
    {
        classDescError.show();
        classDescError.css('color', 'red');
        ++errors;
    }
    else
    {
        classDescError.hide();
    }

    return errors;
}