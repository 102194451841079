/**
 * Content Analytics tab JQuery functions
 */

import Cookies from 'js-cookie';
import { calculateTime, getEmbeddedVideoFromUrl, trans, transChoice } from '../common';
import { telemetryTrackAndPerformUrlChange } from '../superuser/mixpanel';


$(document).ready(function()
{
    initialiseTopicsAnalytics();
    initialiseTrafficLights();
});

$(document).on('click', '.js-content-select', e =>
{
    if (!$(e.currentTarget).hasClass('active'))
    {
        let contentType = $(e.currentTarget).data('content-type');
        if (contentType === 'question')
        {
            openContentDetailView(e, '/assessment/', 'question');
        }
        else if (contentType === 'resource')
        {
            openContentDetailView(e, '/content/resource/details/', 'resource');
        }
    }
    else
    {
        closeContentDetailView();
    }
});

$(document).on('click', '.js-detail-view-close', e =>
{
    closeContentDetailView();
});

$(document).on('click', '.js-open-content-authoring', function(e)
{
    window.localStorage.setItem('forceShowContentModal', JSON.stringify($(this).hasClass('force-modal')));
    const lastVisited = Cookies.get('last-content-authoring-visited');
    if (lastVisited === 'new')
    {
        const classInstanceId = $('.classInstanceId').attr('data-class-instance-id');
        telemetryTrackAndPerformUrlChange(`/content-authoring/${classInstanceId}`, 'ep-class-open-content-authoring', { 'version': 'new' });
    }
    else if (lastVisited === 'classic')
    {
        const classId = $('.classId').attr('class-id');
        telemetryTrackAndPerformUrlChange(`/admin/class/${classId}/content`, 'ep-class-open-content-authoring', { 'version': 'classic' });
    }
    else
    {
        $('#editor-select-modal').modal('show');
    }
});

$(document).on('click', '#js-set-content-authoring-new', function(e)
{
    const classInstanceId = $('.classInstanceId').attr('data-class-instance-id');
    telemetryTrackAndPerformUrlChange(`/content-authoring/${classInstanceId}`, 'ep-select-editor-modal-choice', { 'choice': 'new' });
});

$(document).on('click', '#js-set-content-authoring-classic', function(e)
{
    const classId = $('.classId').attr('class-id');
    telemetryTrackAndPerformUrlChange(`/admin/class/${classId}/content`, 'ep-select-editor-modal-choice', { 'choice': 'classic' });
});

$(document).on('click', '.js-topic-heading-expander', function(e)
{
    let $target = $(e.currentTarget);
    let topicId = $target.data('topic-id');
    let questionList = $('.js-questions-topic-' + topicId);
    let topicStats = $('.js-topic-stats-' + topicId);
    let chevron = $target.find('i.js-accordion-chevron');
    if (questionList[0].style['display'] === 'none')
    {
        questionList.show();
        topicStats.show();
        chevron.removeClass('fa-chevron-right');
        chevron.addClass('fa-chevron-down');
    }
    else
    {
        questionList.hide();
        topicStats.hide();
        chevron.removeClass('fa-chevron-down');
        chevron.addClass('fa-chevron-right');
    }
});

function initialiseTopicsAnalytics()
{
    const isContent = $('#content');
    if (isContent.length == 1)
    {
        reloadTopicsAnalytics();
        adjustContentHeight();

        $(window).resize(function()
        {
            adjustContentHeight();
        });
    }
}

function adjustContentHeight()
{
    // Adjust the height of content analytics so that just the analytics scroll instead of the whole page.
    let alertsHeight = 0;
    $('.dashboard-content>.alert, .dashboard-content>.notifications').each(function()
    {
        alertsHeight += $(this).is(':visible') ? $(this).height() : 0;
    });

    const navbarHeight = 112; // Includes paddings/margins as well
    let panelHeight = $(window).height() - alertsHeight - navbarHeight;
    $('#content-analytics .content-list, #content-analytics .detail-view').css({ 'height': `${panelHeight}px` });
    const headerHeight = 56;
    $('#content-analytics .detail-view .scrollable').css({ 'height': `${panelHeight - headerHeight}` });
}

function initialiseTrafficLights()
{
    let classId = $('.js_class-manager').data('class-id');
    let $sectionList = $('.section-name');
    if ($sectionList.length && classId !== undefined)
    {
        getTopicAccuracy(classId);
        getQuestionAccuracy(classId);
        getResourceViews(classId);
    }
}

function getTopicAccuracy(classId)
{
    $.ajax(
    {
        url: '/class/' + classId + '/stats/topic-accuracy',
        type: 'GET',
        dataType: 'json',
        success: function(response)
        {
            updateTopicStats(response);
        },
    });
}

function getQuestionAccuracy(classId)
{
    $.ajax(
    {
        url: '/class/' + classId + '/stats/question-accuracy',
        type: 'GET',
        dataType: 'json',
        success: function(response)
        {
            updateQuestionStats(response);
        },
    });
}

function getResourceViews(classId)
{
    $.ajax(
    {
        url: '/class/' + classId + '/stats/resource-views',
        type: 'GET',
        dataType: 'json',
        success: function(response)
        {
            updateResourceStats(response);
        },
    });
}

function reloadTopicsAnalytics()
{
    let classId = $('.js_class-manager').data('class-id');
    let token = $('meta[name="csrf-token"]').attr('content');
    $.ajax(
    {
        url: `/class/${classId}/topics/analytics`,
        type: 'POST',
        dataType: 'json',
        data: { classId: classId, _token: token },
        success: function(response)
        {
            $.each(response.topics, function(key, res) // Note: This only has topics with questions
                {
                    updateStatsValue('.' + res.topicId + '-topic-completion', res.completion);
                    updateStatsValue('.' + res.topicId + '-topic-accuracy', res.accuracy);
                    updateStatsValue('.' + res.topicId + '-topic-resourceViewed', res.resourcesViewed);
                    updateTimePlayedValue('.' + res.topicId + '-topic-timePlayed', res.timePlayed);
                });
        },
    });
}

/**
 * This animates updates to our circle graphs using jquery
 * @param selector
 * @param value
 */
export function updateStatsValue(selector, value)
{
    $(selector).each(function()
    {
        const $this = $(this);
        if (value === false)
        {
            $this
                .data('value', 0)
                .find('.value')
                .html('0<small>%</small>');
            $this.find('.circle').attr('stroke-dasharray', '0 100');
        }
        else
        {
            $({ countNum: $this.data('value') }).animate({ countNum: value },
            {
                duration: 1000,
                easing: 'swing',
                step: function()
                {
                    let step = Math.round(this.countNum);
                    $this
                        .data('value', step)
                        .find('.value')
                        .html(`${step}<small>%</small>`);
                    $this.find('.circle').attr('stroke-dasharray', `${step} 100`);
                },
                complete: function()
                {
                    $this.find('.value').html(`${this.countNum}<small>%</small>`);
                },
            });
        }
    });
}

/**
 * Updates time played in stats using jquery
 * @param selector
 * @param value
 */
export function updateTimePlayedValue(selector, value)
{
    $(selector)
        .children('.timePlayedValue')
        .text(value);
}

/**
 * Updates accuracy stats displayed for topic
 * @param response
 */
export function updateTopicStats(response)
{
    response.accuracy.forEach(topicDetails =>
    {
        let sectionDot = $('.js-traffic-light-topic-' + topicDetails.topicId);
        updateSectionDot(sectionDot, topicDetails);
        let topicAnswers = $('.topic-total-answers-' + topicDetails.topicId);
        let totalAnswers = topicDetails.totalAnswers ? topicDetails.totalAnswers : 0;
        topicAnswers.text(transChoice('content.answersQty', parseInt(totalAnswers) || 0) + ' •');
        let topicCorrectAnswers = $('.topic-correct-answers-' + topicDetails.topicId);
        let correctAnswers = topicDetails.correctAnswers ? topicDetails.correctAnswers : 0;
        topicCorrectAnswers.text(trans('content.correct', { qty: correctAnswers }));
    });

    response.topicQuestionDetails.forEach(topicDetails =>
    {
        let topicQuestions = $('.topic-count-' + topicDetails.topicId);
        let totalQuestionsAvailable = topicDetails.totalAvailableQuestions ? topicDetails.totalAvailableQuestions : 0;
        let totalQuestions = topicDetails.totalQuestions ? topicDetails.totalQuestions : 0;
        topicQuestions.text(trans('content.amountAvailable', { available: totalQuestionsAvailable, total: totalQuestions }));
    });

    let unansweredDots = $('.js-topic-traffic-light.dot-uninitialised');
    unansweredDots.removeClass('dot-score-0 dot-score-1 dot-score-2 dot-score-3 dot-score-4 dot-uninitialised');
    unansweredDots.addClass('dot-unanswered');

    $('.topic-card').each((idx, elem) =>
    {
        if ($(elem).find('tr.question').length === 0) // No questions
        {
            const topBar = $(elem).find('.js-topic-top-bar');
            topBar.removeClass('dot-score-0 dot-score-1 dot-score-2 dot-score-3 dot-score-4 dot-draft dot-unanswered dot-uninitialised');
            topBar.addClass('dot-draft');
        }
    });
}

/**
 * Updates accuracy stats displayed for questions
 * @param response
 */
export function updateQuestionStats(response)
{
    response.accuracy.forEach(questionDetails =>
    {
        let sectionDot = $('.js-traffic-light-question-' + questionDetails.questionId);
        sectionDot.attr('data-accuracy-percentage', questionDetails.accuracyFraction);
        updateSectionDot(sectionDot, questionDetails);
        if (questionDetails.accuracyFraction)
        {
            let totalAnswers = $('.question-total-answers-' + questionDetails.questionId);
            totalAnswers.text(transChoice('content.answersQty', parseInt(questionDetails.totalAnswers) || 0) + ' •');
            let correctAnswer = $('.question-correct-answers-' + questionDetails.questionId);
            correctAnswer.text(trans('content.correct', { qty: questionDetails.correctAnswers }));
            let accuracy = $('.question-accuracy-' + questionDetails.questionId);
            accuracy.text(trans('content.accuracy', { qty: Math.floor(questionDetails.accuracyFraction * 100) }));
        }
    });
    let unansweredDots = $('.js-question-traffic-light.dot-uninitialised');
    unansweredDots.removeClass('dot-score-0 dot-score-1 dot-score-2 dot-score-3 dot-score-4 dot-uninitialised');
    unansweredDots.addClass('dot-unanswered');
}

/**
 * Updates viewed stats displayed for resources
 * @param response
 */
export function updateResourceStats(response)
{
    response.views.forEach(resource =>
    {
        let avgViewDuration = Math.floor(resource.avgDuration);
        $('.resource-average-duration-' + resource.resourceId).text(calculateTime(avgViewDuration));
        $('.resource-view-' + resource.resourceId).text(transChoice('content.views', resource.views));
    });
}

/**
 * Updates color of traffic light dot to represent accuracy stat
 * @param sectionDot
 * @param stats
 */
function updateSectionDot(sectionDot, stats)
{
    sectionDot.removeClass('dot-score-0 dot-score-1 dot-score-2 dot-score-3 dot-score-4 dot-uninitialised dot-unanswered dot-pending');
    if (stats.accuracyFraction === null)
    {
        if (stats.totalAnswers > 0)
        {
            sectionDot.addClass('dot-pending');
        }
        else
        {
            sectionDot.addClass('dot-unanswered');
        }
    }
    else if (stats.accuracyFraction >= 0.9)
    {
        sectionDot.addClass('dot-score-4');
    }
    else if (stats.accuracyFraction >= 0.65)
    {
        sectionDot.addClass('dot-score-3');
    }
    else if (stats.accuracyFraction >= 0.35)
    {
        sectionDot.addClass('dot-score-2');
    }
    else if (stats.accuracyFraction >= 0.1)
    {
        sectionDot.addClass('dot-score-1');
    }
    else
    {
        sectionDot.addClass('dot-score-0');
    }
}

function updateContentTrafficLight(target, percentage)
{
    target.removeClass('green yellow red');
    if (percentage > 66)
    {
        target.addClass('green');
    }
    else if (percentage > 33)
    {
        target.addClass('yellow');
    }
    else
    {
        target.addClass('red');
    }
}

/**
 * Handles the opening of the Question/Resources details side panel.
 * @param e
 * @param url partial url of the get request that handles fetching assessment details
 * @param contentType
 * @param authToken optional authentication token for use as GET parameter
 */
export function openContentDetailView(e, url, contentType = 'question', authToken = '')
{
    const contentId = $(e.currentTarget).data('content-id');
    const classId = $('.js_class-manager').data('class-id');
    const topicStats = $('.topic-stats').children('div');
    const secondLineStats = $('.second-line-stats');
    $('.js-content-select').removeClass('active');
    $(e.currentTarget).addClass('active');

    toggleLoader(true);

    let contentList = $('.content-list');
    contentList.addClass('content-list-condensed');
    contentList.addClass('content-list-open');
    const detailView = $('.detail-view');

    if (window.matchMedia('(min-width: 992px)').matches)
    {
        contentList.removeClass('col-lg-12');
        contentList.addClass('col-lg-6');
        topicStats.removeClass('col-lg-3');
        topicStats.addClass('col-lg-6');
        secondLineStats.addClass('hide');
        $('.topic-details').hide();
        $('.content-details').hide();
        detailView.show();
    }
    else
    {
        contentList.hide();
        $('.top-nav').hide();
        $('.dashboard-header').hide();
        $('.content-image').hide();
        detailView.addClass('col-md-12');
        detailView.show();
    }

    $.ajax(
    {
        url: url + classId + '/' + contentId + '?authToken=' + authToken,
        type: 'GET',
        dataType: 'json',
        success: function(response)
        {
            if (contentType === 'question')
            {
                createQuestionDetailView(response);
            }
            else if (contentType === 'resource')
            {
                createResourceDetailView(response);
            }

            toggleLoader(false);
        },
    });
}

function toggleLoader(show)
{
    const loader = $('.detail-view .loader');
    const detailContent = $('.detail-view .detail-content');
    if (show)
    {
        hideDetailView();
        detailContent.removeClass('show');
        loader.show();
    }
    else
    {
        loader.hide();
        showDefaultDetailView();
        detailContent.addClass('show');
    }
}

/**
 * Hide all content in detail vie side panel
 */
function hideDetailView()
{
    let selectors = ['.content-title', '.content-stats-bar', '.content-kind', '.content-type', '.content-image',
        '.question-answers', '.question-answers-table', '.question-settings', '.resource-description', '.resource-document',
        '.resource-video', '.resource-url', '.attached-questions', '.content-created-at', '.content-updated-at', '.content-details-traffic-light'
    ];
    selectors.forEach(selector =>
    {
        $(`.detail-view ${selector}`).hide();
    });
}

/**
 * Show content info in detail view that is shared between questions and resources
 */
function showDefaultDetailView()
{
    let selectors = ['.content-title', '.content-stats-bar', '.content-kind',
        '.content-type', '.content-created-at', '.content-updated-at', '.content-details-traffic-light.show'
    ];
    selectors.forEach(selector =>
    {
        $(`.detail-view ${selector}`).show();
    });
}

/**
 * Fill the content info for question in the detail view
 * @param response
 */
function createQuestionDetailView(response)
{
    let assessment = response.assessment[0];
    const learnersAnswered = response.learnersAnswered;
    let totalEnrolled = response.learnersEnrolled;
    let percentageAnswer = Math.floor((learnersAnswered / response.learnersEnrolled) * 100);
    let assessmentOptions = response.assessmentOptions;
    const totalTimedOut = response.totalTimedOut;
    const timedOutPerc = Math.floor(totalTimedOut / response.totalAttempts * 100);

    let image = $('.detail-view .content-image');
    if (assessment.img_question === null || assessment.img_question === '')
    {
        image.hide();
    }
    else
    {
        image.children('img').attr('src', assessment.img_question);
        image.show();
    }

    let accuracyLozenge = $('.detail-view .content-details-traffic-light.lozenge');
    if (learnersAnswered > 0 && assessmentOptions.length > 0)
    {
        const accuracy = (response.accuracy * 100).toFixed(0);

        updateContentTrafficLight(accuracyLozenge, accuracy);
        accuracyLozenge.text(trans('content.accuracy', { qty: accuracy }));
        accuracyLozenge.show();
        accuracyLozenge.addClass('show');
    }
    else
    {
        accuracyLozenge.hide();
        accuracyLozenge.removeClass('show');
    }

    $('.detail-view .content-kind').text(trans('content.questionItem'));
    $('.detail-view .content-type').text(trans('content.' + assessment.type.replace(/-/g, '')));
    $('.detail-view .content-title h2')
        .html(assessment.title)
        .text();
    MathJax.typeset(['.detail-view .content-title h2']);
    $('.question .content-title, .resource .content-title').addClass('title-condensed');

    let $table = $('.question-answers-table');
    let $freeTextAnswerDiv = $('.free-text-answers-link');
    if (assessmentOptions.length === 0)
    {
        $table.hide();
        $freeTextAnswerDiv.show();
    }
    else
    {
        $table.show();
        $freeTextAnswerDiv.hide();

        let table = $table.DataTable(
        {
            retrieve: true,
            paging: false,
            ordering: false,
            info: false,
            header: false,
            footer: false,
            searching: false,
            deferRender: true,
            columnDefs: [
                { targets: [0], className: 'col-7 col-lg-4 answer-title', visible: true },
                { targets: [1], className: 'col-3 col-lg-4 answer-count', visible: true },
                { targets: [2], className: 'col-2 col-lg-4 answer-progress', visible: true },
            ],
        });

        table.clear();

        let maxAnswerPercentage = 0.001;
        assessmentOptions.forEach(option =>
        {
            if (option.answerPercentage > maxAnswerPercentage)
            {
                maxAnswerPercentage = option.answerPercentage;
            }
        });

        if (timedOutPerc > maxAnswerPercentage)
        {
            maxAnswerPercentage = timedOutPerc;
        }

        maxAnswerPercentage /= 100;

        assessmentOptions.forEach(option =>
        {
            table.row.add([
                `<td>${option.img_answer !== null ? `<img class="content-image" src="${option.img_answer}" alt="image preview" onerror="imageErrorHandler(this)">` : ''} ${
                    option.title
                }</td>`,
                `<td>${
                    option.learnersAnswered === 1
                        ? trans('content.answerPercentage', {answers: option.learnersAnswered, percentage: option.answerPercentage})
                        : trans('content.answersPercentage', {answers: option.learnersAnswered, percentage: option.answerPercentage})
                }%</td>`,
                `<td>
                            ${option.is_correct == 1 ? `<i class="answer-check fas fa-circle-check"></i>` : `<i class="answer-check"></i>`}
                            <div class="progress">
                                <div class="progress-bar ${option.is_correct == 1 ? 'success' : 'incorrect'}" style="width:${option.answerPercentage / maxAnswerPercentage}%;"
                                     role="progressbar" aria-valuenow="${option.answerPercentage}" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </td>`,
            ]);
        });

        if (totalTimedOut > 0)
        {
            table.row.add([
                `<td><span class="opacity-60">${trans('content.noAnswer')}</span></td>`,
                `<td>${
                    totalTimedOut === 1
                        ? trans('content.answerPercentage', {answers: totalTimedOut, percentage: timedOutPerc})
                        : trans('content.answersPercentage', {answers: totalTimedOut, percentage: timedOutPerc})
                }%</td>`,
                `<td>
                            <i class="answer-check"></i>
                            <div class="progress">
                                <div class="progress-bar incorrect" style="width:${timedOutPerc / maxAnswerPercentage}%;"
                                     role="progressbar" aria-valuenow="${timedOutPerc}" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </td>`,
            ]);
        }

        table.draw(true);
        $('.question-answers').show();
        $('.question-answers-table tbody tr').addClass('col-lg-12');
        $('.question-answers-table').css('width', '100%');
        MathJax.typeset(['.question-answers-table td.answer-title']);
    }

    let datatableParent = $('.question-answers-table.dataTable').parent('div');
    if (datatableParent.hasClass('col-sm-12'))
    {
        datatableParent.removeClass('col-sm-12');
    }

    // remove empty row above data table
    $table.parents('.row').prev().remove();

    contentStatBar(learnersAnswered, totalEnrolled, percentageAnswer, 'content.learnersAnswered');

    $('.question-settings-timer').text(trans('dashboard.seconds', { seconds: assessment.time_duration }));
    $('.question-settings-hint')
        .html(assessment.question_hints !== '' ? assessment.question_hints : trans('dashboard.noHintExplanation'))
        .text();
    MathJax.typeset(['.question-settings-hint']);
    $('.question-settings-explanation')
        .html(assessment.explanation !== '' ? assessment.explanation : trans('dashboard.noHintExplanation'))
        .text();
    MathJax.typeset(['.question-settings-explanation']);

    $('.question-settings').show();

    $('.content-created-at span').text(assessment.localCreatedAt);
    $('.content-updated-at span').text(assessment.localUpdatedAt);

    $('.question-settings-shuffle').text(assessment.shuffleAnswers === 1 ? trans('content.on') : trans('content.off'));
}

/**
 * Fill the content info for resources in the detail view
 * @param response
 */
function createResourceDetailView(response)
{
    $('.detail-view .content-kind').text(trans('content.resourceItem'));
    $('.detail-view .content-type').text(trans('content.' + response.resource.type));
    $('.detail-view .content-title h2').html(response.resource.title);
    $('.detail-view .content-details-traffic-light').hide().removeClass('show');

    if (response.resource.description)
    {
        $('.detail-view .resource-description p')
            .html(response.resource.description);
        $('.detail-view .resource-description').show();
    }

    if (response.resource.url)
    {
        $('.detail-view .resource-url label').text(response.resource.urlText && response.resource.urlText.trim().length > 0 ? response.resource.urlText : response.resource.url);
        $('.detail-view .resource-url').attr('href', response.resource.url).show();
    }

    if (response.resource.type === 'image')
    {
        let image = $('.detail-view .content-image');
        image.children('img').attr('src', response.resource.assetUrl);
        image.show();
    }
    else if (response.resource.type === 'video')
    {
        let video = $('.detail-view .resource-video');
        let videoUrl = getEmbeddedVideoFromUrl(response.resource.filePath);
        video.children('iframe').attr('src', videoUrl);
        video.show();
    }
    else if (response.resource.type === 'document')
    {
        if (response.resource.assetUrl)
        {
            let document = $('.detail-view .resource-document');
            let src = '';
            if (response.resource.assetUrl.toLowerCase().endsWith('.pdf'))
            {
                src = response.resource.assetUrl + '#view=FitH';
            }
            else
            {
                src = `https://docs.google.com/viewer?url=${response.resource.assetUrl}&embedded=true`;
            }
            document.children('iframe').attr('src', src);
            document.show();
        }
    }

    if (response.attachedQuestions && response.attachedQuestions.length > 0)
    {
        $('.detail-view .attached-questions-container').html('');
        $.each(response.attachedQuestions, (i, questionTitle) =>
        {
            $('.detail-view .attached-questions-container').append(`<div class="question-item"><p>${questionTitle}</p></div>`);
        });
        $('.detail-view .attached-questions').show();

    }

    let learnerCount = response.learnersViewed;
    let totalEnrolled = response.learnersEnrolled;
    let percentageAnswer = Math.floor((learnerCount / totalEnrolled) * 100);
    contentStatBar(learnerCount, totalEnrolled, percentageAnswer, 'content.learnersViewed');

    $('.content-created-at span').text(response.resource.localCreatedAt);
    $('.content-updated-at span').text(response.resource.localUpdatedAt);
}

/**
 * Update horizontal stat bar that shows number of learners that have answered or view the content
 * @param learnerCount
 * @param totalEnrolled
 * @param percentageAnswer
 * @param text
 */
function contentStatBar(learnerCount, totalEnrolled, percentageAnswer, text)
{
    let progressBar = $('.content-stats-bar .progress .progress-bar');
    progressBar.css('transition', 'unset');
    progressBar.css('width', '0%');
    setTimeout(function()
    {
        progressBar.css('transition', '');
        progressBar.css('width', percentageAnswer + '%');
    }, 20);
    progressBar.attr('aria-valuenow', percentageAnswer);
    $('.content-stats-bar span').text(trans(text, { learners: learnerCount, totalLearners: totalEnrolled }));
}

/**
 * Handles the closing of the Question/Resources details side panel.
 */
export function closeContentDetailView()
{
    const contentList = $('.content-list');
    const topicStats = $('.topic-stats').children('div');
    const secondLineStats = $('.second-line-stats');
    const detailView = $('.detail-view');

    $('.detail-view .content-image img').attr('src', '');
    $('.detail-view .resource-video iframe').attr('src', '');
    $('.detail-view .resource-document iframe').attr('src', '');
    $('.js-content-select').removeClass('active');

    if (window.matchMedia('(min-width: 992px)').matches)
    {
        contentList.removeClass('content-list-open');
        let scrollPos = contentList[0].scrollTop + window.scrollY;
        contentList.removeClass('content-list-condensed');
        window.scrollTo(0, scrollPos);

        $('.question .content-title').removeClass('title-condensed');
        contentList.removeClass('col-lg-6');
        contentList.addClass('col-lg-12');
        topicStats.removeClass('col-lg-6');
        topicStats.addClass('col-lg-3');
        secondLineStats.removeClass('hide');
        $('.topic-details').show();
        $('.content-details').show();
    }
    else
    {
        contentList.show();
        $('.top-nav').show();
        $('.dashboard-header').show();
        $('.content-image').show();
        detailView.removeClass('col-md-12');
    }
    detailView.hide();
}