import { selectDropdownItem } from '../../form/dropdown';
import { Quitch } from '../../quitch';
import { trans } from '../../common';
import { validateAllContent, validateAndUpdateQuestionIncompleteFlag, validateAndUpdateResourceIncompleteFlag } from './common';
import { loadPreview } from './preview';

$(document).on('click', '.js-schedule-publish', function()
{
    if ($(this).hasClass('disabled'))
    {
        return;
    }

    const schedulePublishModal = $('#schedule-publish-modal');
    const context = $(this).attr('data-context');
    let scheduled = 0;
    schedulePublishModal.attr('data-context', context);
    schedulePublishModal.find('.js-schedule-topic-msg').toggle(context !== 'content');
    $('#js-back-schedule-publish-btn').hide();
    $('#js-cancel-schedule-publish-btn').show();
    // Ensure content is valid before allowing to publish
    $('.content-authoring-content .newly-added').removeClass('newly-added');

    switch (context)
    {
        case 'topic':
        {
            schedulePublishModal.find('.schedule-selection').show();
            schedulePublishModal.find('.topic-selection').hide();
            $('#js-next-schedule-publish-btn').hide();
            $('#js-schedule-publish-btn').show();
            const topicEle = $(this).parents('.content-authoring-topic');
            schedulePublishModal.attr('data-topic-id', topicEle.attr('data-id'));
            validateAllContent();
            scheduled = topicEle.find('[data-schedule-status=scheduled]').length;
            schedulePublishModal.find('.incomplete-content-warning').toggle(topicEle.find('.js-topic-incomplete-counter').attr('data-count') > 0);
            break;
        }
        case 'content':
        {
            const contentEle = $(this).parents('.content-authoring-content');
            if (contentEle.hasClass('question') && !validateAndUpdateQuestionIncompleteFlag(contentEle))
            {
                return;
            }
            else if (contentEle.hasClass('resource') && !validateAndUpdateResourceIncompleteFlag(contentEle))
            {
                return;
            }
            schedulePublishModal.find('.schedule-selection').show();
            schedulePublishModal.find('.topic-selection').hide();
            $('#js-next-schedule-publish-btn').hide();
            $('#js-schedule-publish-btn').show();
            schedulePublishModal.attr('data-topic-content-id', contentEle.attr('data-topic-content-id'));
            schedulePublishModal.find('.incomplete-content-warning').hide();
            scheduled = false;
            break;
        }
        case 'class':
        {
            schedulePublishModal.find('.schedule-selection').hide();
            schedulePublishModal.find('.topic-selection').show();
            $('#js-schedule-publish-btn').hide();
            $('#js-next-schedule-publish-btn').show();
            validateAllContent();
            setupTopicCheckboxes(schedulePublishModal);
        }
    }

    selectDropdownItem($('#schedule-publish-modal .js-schedule-when-dropdown'), 'now', true);

    const timezone = $('#js-class-timezone').val();
    const now = new Date((new Date()).toLocaleString('en-US', { timeZone: timezone }));
    // initialize date to today and time to nearest 30 minute interval in the future
    now.setMinutes(now.getMinutes() + 30 - now.getMinutes() % 30);
    const nowDate = now.toISOString().split('T')[0];
    // get now time in HH:mm format
    let nowTime = now.toTimeString().split(' ')[0].split(':').slice(0, 2).join(':');
    schedulePublishModal.find('[name=scheduleDate]').val(nowDate).attr('min', nowDate);
    schedulePublishModal.find('[name=scheduleTime]').val(nowTime).change();

    schedulePublishModal.find('[name=overrideSchedule]').prop('checked', scheduled > 0);
    schedulePublishModal.find('.js-override-schedule-check').toggle(scheduled > 0);
    schedulePublishModal.modal('show');
});

$(document).on('change', '#schedule-publish-modal .js-select-all-topics', function()
{
    // on change of select all topics checkbox, check/uncheck all topic checkboxes
    const checked = $(this).prop('checked');
    $('#schedule-publish-modal .topic-selection-checkbox-group input[type=checkbox]').prop('checked', checked);
    $('#js-next-schedule-publish-btn').prop('disabled', !checked);
});

$(document).on('change', '#schedule-publish-modal .topic-selection-checkbox-group input[type=checkbox]', function()
{
    // on change of any topic checkbox, check/uncheck select all checkbox
    const checkboxesSelected = $('#schedule-publish-modal .topic-selection-checkbox-group input[type=checkbox]:checked').length;
    const allChecked = $('#schedule-publish-modal .topic-selection-checkbox-group input[type=checkbox]').length === checkboxesSelected;
    $('#schedule-publish-modal .js-select-all-topics').prop('checked', allChecked);
    $('#js-next-schedule-publish-btn').prop('disabled', checkboxesSelected === 0);
});

$(document).on('click', '#js-next-schedule-publish-btn', function()
{
    const schedulePublishModal = $('#schedule-publish-modal');
    const selectedTopics = [];
    let errorsExist = false;
    schedulePublishModal.find('.topic-selection-checkbox-group input[type=checkbox]:checked').each(function()
    {
        const topicId = $(this).attr('data-id');
        selectedTopics.push(topicId);
        if (!errorsExist && $(`.content-authoring-topic[data-id=${topicId}] .js-topic-incomplete-counter`).attr('data-count') > 0)
        {
            errorsExist = true;
        }
    });

    // check if any of the selected topics have scheduled content
    const scheduled = selectedTopics.reduce((acc, topicId) =>
    {
        const topicEle = $(`.content-authoring-topic[data-id=${topicId}]`);
        return acc || topicEle.find('[data-schedule-status=scheduled]').length > 0;
    }, false);

    schedulePublishModal.find('[name=overrideSchedule]').prop('checked', scheduled);
    schedulePublishModal.find('.js-override-schedule-check').toggle(scheduled);
    schedulePublishModal.attr('data-topic-ids', selectedTopics.join(','));
    schedulePublishModal.find('.schedule-selection').show();
    schedulePublishModal.find('.topic-selection').hide();
    schedulePublishModal.find('.incomplete-content-warning').toggle(errorsExist);
    $('#js-back-schedule-publish-btn').show();
    $('#js-cancel-schedule-publish-btn').hide();
    $('#js-next-schedule-publish-btn').hide();
    $('#js-schedule-publish-btn').show();
});

$(document).on('click', '#js-back-schedule-publish-btn', function()
{
    const schedulePublishModal = $('#schedule-publish-modal');
    schedulePublishModal.find('.schedule-selection').hide();
    schedulePublishModal.find('.topic-selection').show();
    $('#js-back-schedule-publish-btn').hide();
    $('#js-cancel-schedule-publish-btn').show();
    $('#js-next-schedule-publish-btn').show();
    $('#js-schedule-publish-btn').hide();
});

$(document).on('change', '#schedule-publish-modal [name=schedule-publish-when]', function(e)
{
    if ($(this).val() === 'later')
    {
        $('#schedule-publish-modal [name=scheduleDate]').prop('disabled', false);
        $('#schedule-publish-modal [name=scheduleTime]').prop('disabled', false);
        $('#schedule-publish-modal .js-schedule-publish-later').show();
    }
    else
    {
        $('#schedule-publish-modal [name=scheduleDate]').prop('disabled', true);
        $('#schedule-publish-modal [name=scheduleTime]').prop('disabled', true);
        $('#schedule-publish-modal .js-schedule-publish-later').hide();
    }
});

$(document).on('change', '#schedule-publish-modal [name=scheduleDate], #schedule-publish-modal [name=scheduleTime]', function(e)
{
    let date = $('#schedule-publish-modal [name=scheduleDate]').val();
    let time = $('#schedule-publish-modal [name=scheduleTime]').val();
    const formattedDateHolder = $('#schedule-publish-modal .js-formatted-date-time');

    if (date && time)
    {
        $.ajax(
        {
            url: '/date/format',
            method: 'GET',
            data:
            {
                dateTime: `${date} ${time}:00`,
                timeZone: $('#js-class-timezone').val(),
                format: 'EEE dd MMM, HH:mm zzzz'
            },
            success: function(response)
            {
                formattedDateHolder.text(response.formattedDateTime);
            }
        });
    }
    else
    {
        formattedDateHolder.text('');
    }
});

$(document).on('click', '#js-schedule-publish-btn', function()
{
    const schedulePublishModal = $('#schedule-publish-modal');
    const context = schedulePublishModal.attr('data-context');
    const when = $('#schedule-publish-modal [name=schedule-publish-when]').val();
    let scheduledAt = null;
    if (when === 'later')
    {
        const date = $('#schedule-publish-modal [name=scheduleDate]').val();
        const time = $('#schedule-publish-modal [name=scheduleTime]').val();
        scheduledAt = `${date} ${time}:00`;
    }

    let values = {
        _token: $('meta[name="csrf-token"]').attr('content'),
        classId: $('#js-class-id').val(),
        scheduledAt: scheduledAt,
        override: $('#schedule-publish-modal [name=overrideSchedule]').prop('checked') ? 1 : 0,
        publish: 1
    };

    setPublishBtnState('queued');

    switch (context)
    {
        case 'topic':
            values.topicId = schedulePublishModal.attr('data-topic-id');
            values.target = 'topic';
            values.scheduleType = 'all';
            break;
        case 'class':
            values.topicIds = schedulePublishModal.attr('data-topic-ids');
            values.target = 'topics';
            values.scheduleType = 'all';
            break;
        case 'content':
            values.contentId = schedulePublishModal.attr('data-topic-content-id');
            values.target = 'content';
            values.scheduleType = 'set';
            values.override = $(`.content-authoring-content[data-topic-content-id=${values.contentId}]`).attr('data-schedule-status') === 'scheduled' ? 1 : 0;
            break;
    }

    scheduleAjax(values, when);
});

$(document).on('click', '.js-unschedule-cancel-btn', function()
{
    $('#js-unschedule-confirm-modal').modal('hide');
});

$(document).on('click', '.js-unschedule-ok-btn', function(e)
{
    unscheduleContent($(e.currentTarget).attr('data-topic-content-id'));
    $('#js-unschedule-confirm-modal').modal('hide');
});

$(document).on('click', '.js-content-edit', function(e)
{
    let contentDiv = $(e.currentTarget).parents('.content-authoring-content');
    let topicContentId = contentDiv.attr('data-topic-content-id');

    if (contentDiv.hasClass('question') && contentDiv.attr('data-schedule-status') === 'sent')
    {
        $('#layout-unschedule-modal-ok-btn').attr('data-topic-content-id', topicContentId);
        $('#js-unschedule-confirm-modal').modal('show');
    }
    else if (contentDiv.hasClass('resource') && contentDiv.attr('data-schedule-status') === 'sent')
    {
        unscheduleContent(topicContentId);
    }
    else
    {
        Quitch.modal(
        {
            title: trans('content.unscheduleModalHeading'),
            message: trans('content.unscheduleScheduledWarning'),
            type: 'confirm_custom',
            cssClass: 'modal-dialog-centered custom-width-400',
            okText: 'content.yesEdit',
            cancelText: 'content.noCancel',
        }, function(response)
        {
            if (response === true)
            {
                unscheduleContent(topicContentId);
            }
        });
    }
});

function unscheduleContent(topicContentId)
{
    let values = {
        _token: $('meta[name="csrf-token"]').attr('content'),
        classId: $('#js-class-id').val(),
        contentId: topicContentId,
        target: 'content',
        scheduleType: 'clear',
        publish: 1
    };

    $.ajax(
    {
        url: '/schedule',
        method: 'POST',
        data: values,
        success: function(response)
        {
            if (response.rowsUpdated > 0)
            {
                let item = response.scheduled[0];
                const ele = $(`.content-authoring-content[data-content-id=${item.id}]`);
                ele.attr('data-schedule-status', item.status);
                ele.removeClass('collapsed').addClass('edit');
                ele.find('.content-input .input-field').attr('contenteditable', 'true').focus();
                ele.find('.schedule-status-toolbar').replaceWith(item.statusHtml);

                Quitch.showSnackbar(trans('content.scheduleCleared'));
                updatePublishBtnOnComplete();
                toggleTopicSchedulePublishBtn();
            }
        },
        error: () =>
        {
            Quitch.showSnackbar(trans('content.scheduleError'), true);
        },
    });
}

function setupTopicCheckboxes(schedulePublishModal)
{
    const checkboxGroup = schedulePublishModal.find('.topic-selection-checkbox-group');
    checkboxGroup.empty();
    $('.content-authoring-list .content-authoring-topic').each(function()
    {
        if ($(this).find('[data-schedule-status=draft], [data-schedule-status=scheduled]').length > 0)
        {
            const topicId = $(this).attr('data-id');
            const topicName = $(this).find('.content-authoring-topic-heading .content-title .input-field').text() || trans('base.emptyTopicNamePlaceholder');
            const checkbox = `
            <div class="form-check custom-checkbox">
                <input type="checkbox" id="topic-check-${topicId}" class="settings-check-box form-check-input" name="topicCheckbox${topicId}" data-id="${topicId}" checked="">
                <label for="topic-check-${topicId}" class="form-check-label">${topicName}</label>
            </div>`;
            checkboxGroup.append(checkbox);
        }
    });
}

function scheduleAjax(values, when)
{
    $.ajax(
    {
        url: '/schedule',
        method: 'POST',
        data: values,
        success: function(response)
        {
            $('#schedule-publish-modal').modal('hide');

            response.scheduled.forEach(function(item)
            {
                const ele = $(`.content-authoring-content[data-content-id=${item.id}]`);
                ele.attr('data-schedule-status', item.status);
                ele.removeClass('edit').addClass('collapsed');
                ele.find('.option-item .input-field[data-current-val=""]').parents('.option-item').remove();
                ele.find('.content-input .input-field').attr('contenteditable', 'false');
                ele.find('.schedule-status-toolbar').replaceWith(item.statusHtml);
            });

            if (response.rowsUpdated > 0)
            {
                if (when === 'now')
                {
                    Quitch.showSnackbar(trans(`content.${values.target}Sent`));
                }
                else if (when === 'later')
                {
                    if (values['override'] === 1)
                    {
                        Quitch.showSnackbar(trans('content.contentScheduled'));
                    }
                    else
                    {
                        Quitch.showSnackbar(trans(`content.${values.target}Scheduled`));
                    }
                }

                validateAllContent();
                loadPreview();
                $('.content-authoring-wrapper').removeClass('expanded-sidebar');
            }
            updatePublishBtnOnComplete();
            toggleTopicSchedulePublishBtn();
        },
        error: () =>
        {
            updatePublishBtnOnComplete();
            toggleTopicSchedulePublishBtn();
            Quitch.showSnackbar(trans('content.scheduleError'), true);
        },
    });
}

export function setPublishBtnState(status)
{
    const publishBtn = $('#class-publish-btn');
    publishBtn.removeClass('modified queued published unpublished published-with-errors');
    switch (status)
    {
        case 'modified':
            publishBtn.addClass('modified').text(trans('content.publishBtn'));
            publishBtn.prop('disabled', false);
            break;
        case 'queued':
            publishBtn.addClass('queued').text(trans('content.publishing'));
            publishBtn.prop('disabled', true);
            break;
        case 'published':
            publishBtn.addClass('published').text(trans('content.published'));
            publishBtn.prop('disabled', true);
            break;
    }
}

function updatePublishBtnOnComplete()
{
    const classId = $('#js-class-id').val();
    const schedulePublishBtn = $('#class-publish-btn');
    if (schedulePublishBtn.hasClass('loading'))
    {
        return;
    }
    else
    {
        schedulePublishBtn.addClass('loading');
    }

    const interval = setInterval(async function()
    {
        const status = await getPublishStatus(classId);
        if (status !== 'queued' && status !== 'publishing')
        {
            updatePublishBtn();
            schedulePublishBtn.removeClass('loading');
            clearInterval(interval);
        }
    }, 1000);
}

export function updatePublishBtn()
{
    // keep publish button enabled if there are any drafts
    if ($('.content-authoring-list .content-authoring-content[data-schedule-status=draft]').length > 0)
    {
        setPublishBtnState('modified');
    }
    else
    {
        setPublishBtnState('published');
    }
}

function getPublishStatus(classId)
{
    return new Promise(function(resolve)
    {
        $.ajax(
        {
            url: `/content/authoring/${classId}/publish/status`,
            method: 'GET',
            success: function(response)
            {
                resolve(response.status);
            }
        });
    });
}

export function initialiseSchedulePublish()
{
    initialisePublishBtn();
    toggleTopicSchedulePublishBtn();
}

function initialisePublishBtn()
{
    const publishBtn = $('#class-publish-btn');

    if (publishBtn.hasClass('queued') || publishBtn.hasClass('publishing'))
    {
        updatePublishBtnOnComplete();
    }
    else
    {
        if (publishBtn.hasClass('published'))
        {
            if ($('.content-authoring-list .content-authoring-content[data-schedule-status=draft]').length > 0)
            {
                setPublishBtnState('modified');
            }
            else
            {
                setPublishBtnState('published');
            }
        }
    }
}

/**
 * Show/Hide publish dropdown btn on topic depending on whether the content is sent
 */
export function toggleTopicSchedulePublishBtn()
{
    $('.content-authoring-list .content-authoring-topic').each(function()
    {
        if ($(this).find('[data-schedule-status=draft], [data-schedule-status=scheduled]').length > 0)
        {
            $(this).find('.content-authoring-topic-heading .js-schedule-publish').show();
        }
        else
        {
            $(this).find('.content-authoring-topic-heading .js-schedule-publish').hide();
        }
    });
}