import { timeAgo, trans, transChoice } from './common';
import Cookies from 'js-cookie';
import { debounce } from 'lodash';
import { Quitch } from './quitch';
import { telemetryTrackEvent } from './superuser/mixpanel';

$(document).ready(function()
{
    loadUserPreferenceForClassList();
    loadClassesList();
});

$(document).on('click', '#classes-view-switcher button', function()
{
    const type = $(this).attr('data-type');
    Cookies.set('classes-view-type', type);
    $('#classes-table').attr('data-view-type', type);

    $('#classes-view-switcher button').removeClass('active');
    $(`#classes-view-switcher button[data-type=${type}]`).addClass('active');

    loadClassesList();
    $(this).tooltip('hide');
});

function loadClassesList()
{
    const classesTable = $('#classes-table');
    if (classesTable.length === 0)
    {
        return;
    }

    if ($.fn.dataTable.isDataTable('#classes-table'))
    {
        // just refresh data if table exists via search or reload
        const searchVal = String($('#class-search').val()).toLowerCase();

        if (searchVal.length > 0)
        {
            classesTable.DataTable().search(searchVal).draw();
        }
        else
        {
            classesTable.DataTable().ajax.reload(null, false);
        }
        return;
    }

    const classListDataTable = classesTable.DataTable(
    {
        ajax:
        {
            url: 'classes/list',
            type: 'POST',
            data: function(d)
            {
                let values = {
                    _token: $('meta[name="csrf-token"]').attr('content'),
                    start: d['start'],
                    length: d['length'],
                    search: d['search'],
                    viewType: classesTable.attr('data-view-type'),
                    totalClasses: classesTable.attr('data-total-classes'),
                    sortBy: $('input[name="classesListSortBy"]').val()
                };

                let statusFilters = [];
                $('input[type=checkbox].js-class-status-filter-checkbox:checked').each(function()
                {
                    statusFilters.push($(this).val());
                });
                if (statusFilters.length > 0)
                {
                    values.statusFilters = statusFilters;
                }

                return values;
            },
            dataSrc: function(json)
            {
                if (classesTable.attr('data-view-type') === 'grid')
                {
                    // Don't render the list view
                    return [];
                }
                else
                {
                    return json.data;
                }
            }
        },
        search: { 'search': String($('#class-search').val()).trim().toLowerCase() },
        preDrawCallback: function(data)
        {
            $('#classes-table thead').toggle(classesTable.attr('data-view-type') === 'list');
            $('.tooltip.bs-tooltip-auto').remove();
            showClassListSkeletonLoader(classesTable.attr('data-view-type'));
        },
        drawCallback: function(data)
        {
            if (!data.json.enableFavourite)
            {
                $('#classes-table .js-toggle-favourite-button').hide();
            }

            if (!data.json.allowClassCreate)
            {
                $('#classes-table .class-clone-btn').remove();
            }

            $('.class-section .js-create-class').toggle(!!data.json.allowClassCreate);

            if ($('.classes-table-container .select-wrapper').length === 0) // wrap select to style it
            {
                $('.classes-table-container .dataTables_length select').wrap('<div class="select-wrapper"></div>');
            }

            $('#classes-table_wrapper .pagination-toolbar').toggleClass('show', data.json.recordsFiltered > 20);
            $('#classes-table tbody tr').toggleClass('empty-row', data.json.recordsFiltered === 0);

            if (classesTable.attr('data-view-type') === 'grid' && data.json.recordsFiltered > 0)
            {
                // Render classes grid view
                $('#classes-table tbody').html(`<tr class="grid-view"><td colspan="6">${data.json.gridViewHtml}</td></tr>`);
            }
        },
        language:
        {
            lengthMenu: trans('reporting.lengthMenuEop', { menu: '_MENU_' }),
            info: trans('home.infoResults', { start: '_START_', end: '_END_', total: '_TOTAL_' }),
            infoFiltered: '',
            zeroRecords: trans('home.tableEmpty'),
            infoEmpty: '',
            paginate:
            {
                first: false,
                last: false,
                next: '<i class="fa fa-angle-right"></i>',
                previous: '<i class="fa fa-angle-left"></i>',
            },
        },
        pagingType: 'simple',
        pageLength: 20,
        lengthMenu: [20, 60, 100],
        dom: 't<"pagination-toolbar"<"length-select" l><"page-numbers" i><"arrows" p>>',
        serverSide: true,
        searching: true,
        targets: 'no-sort',
        order: [],
        autoWidth: false,
        columnDefs: [
            { targets: [0], width: '334px' },
            { targets: [1], width: '150px' },
            { targets: [2], width: '100px' },
            { targets: [3], width: '104px' },
            { targets: [4], width: '100px' },
            { targets: [5], width: '52px' },
            { targets: '_all', orderable: false, visible: true },
        ],
        columns: [
        {
            title: trans('home.name'),
            render: function(data, type, row)
            {
                const answerCount = parseInt(row.answerCount || 0);
                const learnerCount = parseInt(row.learnerCount || 0);
                return `<a class="class-table-cell" href="/class-manager/${row.classId}">
                                <div class="class-info-container ${row.ended ? 'archived' : ''}" data-class-id="${row.classId}" data-colour="${row.classColour}">
                                    ${row.classImage && row.imageAssetUrl ?
                    '<img class="class-image-preview" src="' + row.imageAssetUrl + '" style="background-color: ' + row.classColour + ';" alt="" onerror="imageErrorHandler(this)"/>' :
                    '<div class="class-image-preview" style="background-color: ' + row.classColour + ';"></div>'}
                                    <input type="hidden" name="classImage" value="${row.classImage}"/>
                                    <div class="class-info">
                                        <div class="class-name">${row.name}</div>
                                        <div class="body-small">${transChoice('home.answers', answerCount, {value: answerCount.toLocaleString('en-US')})} • ${transChoice('home.learners', learnerCount, {value: learnerCount.toLocaleString('en-US')})}</div>
                                    </div>
                                    ${row.incompleteContent == 1 ? `<div class="incomplete-status" data-bs-toggle="tooltip" title="${trans('home.incompleteContent')}"></div>` : ''}
                                </div>
                            </a>`;
            },
        },
        {
            title: trans('home.status'),
            render: function(data, type, row)
            {
                return `<a class="class-table-cell" href="/class-manager/${row.classId}" tabindex="-1">
                                <span class="lozenge status ${row.active ? 'active' : ''}">${row.status}</span>
                            </a>`;
            }
        },
        {
            title: trans('home.lastActivity'),
            render: function(data, type, row)
            {
                return `<a class="class-table-cell" href="/class-manager/${row.classId}" tabindex="-1">
                                ${timeAgo(row.lastActivitySecondsAgo)}
                            </a>`;
            }
        },
        {
            title: trans('home.educators'),
            render: function(data, type, row)
            {
                let rowHtml = '';
                if (row.educators)
                {
                    const LIMIT = 5;
                    let additionalCount = 0;
                    rowHtml = '<div class="educator-circle-group">';

                    if (row.educators.length > LIMIT)
                    {
                        additionalCount = row.educators.length - LIMIT + 1;
                    }

                    for (const [index, educator] of row.educators.entries())
                    {
                        if (additionalCount && index === LIMIT - 1)
                        {
                            rowHtml += `<div class="circle additional">+${additionalCount}</div>`;
                            break;
                        }

                        rowHtml += `<div class="circle">${educator}</div>`;
                    }

                    rowHtml = rowHtml + '</div>';
                }
                else
                {
                    rowHtml = '-';
                }

                return `<a class="class-table-cell" href="/class-manager/${row.classId}" tabindex="-1">${rowHtml}</a>`;
            }
        },
        {
            title: trans('home.dateCreated'),
            render: function(data, type, row)
            {
                return `<a class="class-table-cell" href="/class-manager/${row.classId}" tabindex="-1">${row.createdAt}</a>`;
            }
        },
        {
            data: 'actions',
            title: '',
            render: function(data, type, row)
            {
                const archiveHtml = row.allowArchive ? `<a href="#" class="dropdown-item js-archive-class" data-bs-target-id="${row.classId}">${trans('home.archive')}</a>` : '';
                const deleteHtml = row.allowDelete ? `<a href="#" class="dropdown-item delete-class-btn" style="color: #d9534f" data-bs-target-id="${row.classId}">${trans('home.deleteButton')}</a>` : '';
                return `<div class="actions">
                                <button type="button" id="toggle-favourite-button" class="js-toggle-favourite-button btn btn-subtle-icon ${row.favourite ? 'star-filled' : 'star'} btn-dropdown-sm"
                                        data-class-id="${row.classId}" data-bs-toggle="tooltip" title="${row.favourite ? trans('home.removeFromFavourites') : trans('home.addToFavourites')}"></button>
                                <div class="dropdown">
                                    <button id="class-list-actions-button" type="button" class="btn btn-subtle-icon dots btn-dropdown-sm" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></button>
                                    <div class="dropdown-menu dropdown-menu-end custom-dropdown-list">
                                        <a href="/class-manager/${row.classId}#settings" class="dropdown-item js_track_mixpanel no-track-intercept" data-class-id="${row.classId}">${trans('home.editButton')}</a>
                                        <a href="#" class="dropdown-item class-clone-btn" data-bs-target-id="${row.classId}">${trans('home.duplicate')}</a>
                                        ${archiveHtml}
                                        ${deleteHtml}
                                    </div>
                                </div>
                            </div>`;
            },
        }, ],
    });
}

function showClassListSkeletonLoader(classesViewType)
{
    let rowsHtml = '';
    if (classesViewType === 'grid')
    {
        rowsHtml = `<tr class="skeleton-row grid-view">
                            <td colspan="6">`;

        for (let i = 0; i < 8; i++)
        {
            rowsHtml += `<div class="accordion-class">
                                <div class="class-card">
                                    <div class="class-card-image skeleton"></div>
                                    <div class="class-card-desc">
                                        <div class="skeleton"></div>
                                        <div class="skeleton"></div>
                                    </div>
                                </div>
                            </div>`;
        }

        rowsHtml += '</td></tr>';
    }
    else
    {
        const skeletonRowHtml = `<tr class="skeleton-row">
                <td>
                    <div class="class-table-cell">
                        <div class="class-info-container skeleton-container">
                            <div class="class-image-preview skeleton"></div>
                            <div class="class-info">
                                <div class="class-name skeleton"></div>
                                <div class="class-stat skeleton"></div>
                            </div>
                        </div>
                    </div>
                </td>
                <td>
                    <div class="class-table-cell">
                        <span class="lozenge status skeleton"></span>
                    </div>
                </td>
                <td>
                    <div class="class-table-cell">
                        <div class="skeleton"></div>
                    </div>
                </td>
                <td>
                    <div class="class-table-cell">
                        <div class="skeleton"></div>
                    </div>
                </td>
                <td>
                    <div class="class-table-cell">
                        <div class="skeleton"></div>
                    </div>
                </td>
                <td></td>
            </tr>`;
        for (let i = 0; i < 10; i++)
        {
            rowsHtml += skeletonRowHtml;
        }
    }

    $('#classes-table tbody').html(rowsHtml);
}

$(document).on('keyup', '.js_filter_classes', function()
{
    const showActiveClasses = !$('.js_class_list_setting').hasClass('archived-content');
    const value = $(this)
        .val()
        .toLowerCase();

    if (showActiveClasses)
    {
        $('#classesList tr td:contains("active")').filter(function()
        {
            const row = $(this).parent();
            row.toggle(
                row
                .text()
                .toLowerCase()
                .indexOf(value) > -1
            );
        });
    }
    else
    {
        $('#classesList tr').filter(function()
        {
            $(this).toggle(
                $(this)
                .text()
                .toLowerCase()
                .indexOf(value) > -1
            );
        });
    }
});

$(document).on('click', '.js_class_list_setting', function()
{
    const showActiveClasses = $('.js_class_list_setting').hasClass('archived-content');
    let token = $('meta[name="csrf-token"]').attr('content');
    $.ajax(
    {
        url: '/class-manager/updateClassListSetting',
        type: 'POST',
        dataType: 'json',
        data: { showActiveClasses: showActiveClasses, _token: token },
        success: function(response)
        {
            if (response.status === '200' || response.status === 200)
            {
                let showArchivedClass = response.showArchivedClass;
                updateClassList(showArchivedClass, false);
            }
        },
    });
});

$(document).on('click', '.js-show-archived', function(e)
{
    e.preventDefault();
    const classSelector = $(this)
        .parent()
        .siblings('select');
    let showActiveClasses = classSelector.hasClass('show-archived');
    let token = $('meta[name="csrf-token"]').attr('content');

    $.ajax(
    {
        url: '/class-manager/updateClassListSetting',
        type: 'POST',
        dataType: 'json',
        data: { showActiveClasses: showActiveClasses, _token: token },
        success: function(response)
        {
            let showArchivedClass = response.showArchivedClass;
            if (response.status === '200' || response.status === 200)
            {
                updateClassDropDownList(showArchivedClass);
            }
        },
    });
});

$(document).on('click', '.js-toggle-favourite-button', function(e)
{
    e.stopPropagation();

    let currentTarget = $(e.currentTarget);
    currentTarget.parent().removeClass('show');
    $(this).tooltip('hide');
    let classId = currentTarget.data('class-id');
    let token = $('meta[name="csrf-token"]').attr('content');
    $.ajax(
    {
        url: '/class/' + classId + '/toggle-favourite',
        type: 'POST',
        dataType: 'json',
        data: { _token: token },
        success: function(response)
        {
            if ($('.organisation-home').length > 0)
            {
                const classInfo = $('#classes-table').attr('data-view-type') === 'grid' ? currentTarget.parents('.class-card') : currentTarget.parents('tr').find('.class-info-container');
                updateFavouritesSideNav(!!response.preferences.favourite, classId, classInfo.find('.class-name').text().trim(), classInfo.find('.class-image-preview').attr('src'), classInfo.attr('data-colour'));
                loadClassesList();
                Quitch.showSnackbar(trans(response.preferences.favourite ? 'home.favouriteAdded' : 'home.favouriteRemoved'));
            }
            else
            {
                window.location.reload();
            }
        },
    });
});

function updateFavouritesSideNav(add, classId, className = null, classImage = null, classColour = null)
{
    const favSubmenu = $('.nav-submenu.favClasses-submenu');
    if (add)
    {
        let newItemEle = `<a class="class-details favourite-${classId} favourite-class" href="/class-manager/${classId}">
                                    ${classImage ? '<img src="' + classImage + '" alt="' + className + '" onerror="imageErrorHandler(this)">' : '<i class="fa fa-square" style="color:' + classColour + ';"></i>'}
                                    <label class="favourite-label">${className}</label>
                                  </a>`;


        if (favSubmenu.find('.class-details').length === 0)
        {
            favSubmenu.find('.add-favourite-help').hide();
            favSubmenu.append(newItemEle);
        }
        else
        {
            // place it alphabetically
            const length = favSubmenu.find('.class-details').length;
            favSubmenu.find('.class-details').each((idx, elem) =>
            {
                let label = $(elem).find('.favourite-label').text();
                if (className.localeCompare(label) <= 0)
                {
                    $(elem).before(newItemEle);
                    return false;
                }
                else if (idx === length - 1)
                {
                    $(elem).after(newItemEle);
                }
            });
        }
    }
    else
    {
        if (favSubmenu.find('.class-details').length === 1)
        {
            favSubmenu.find('.add-favourite-help').show();
        }
        favSubmenu.find('.favourite-' + classId).remove();
    }
}

function loadUserPreferenceForClassList()
{
    const userSetting = $('.js_user_class_setting').val();
    let showArchivedClass = userSetting != '0';
    updateClassList(showArchivedClass, true);
    updateClassDropDownList(showArchivedClass);
}

/**
 * Show class dropdown list on reports pages based on user preference setting
 */
function updateClassDropDownList(showArchivedClass)
{
    const classSelector = $('.js-show-archived')
        .parent()
        .siblings('select');
    const initialValue = classSelector.val();
    if (showArchivedClass)
    {
        classSelector.addClass('show-archived');
        classSelector.children('option[data-archived=true]').show();
        classSelector.append(`<option data-archived=false value="archived" id="archiveTag" disabled>------${trans('reporting.archiveText')}------</option>`);
        $('.js-show-archived').text(trans('reporting.hideArchived'));
    }
    else
    {
        classSelector.removeClass('show-archived');
        classSelector.children('option[id*="archiveTag"]').remove();
        classSelector.children('option[data-archived=true]').hide();
        $('.js-show-archived').text(trans('reporting.classLabelArchiveLink'));
    }
    const options = classSelector.children('option').detach();
    options.sort(function(a, b)
    {
        return $(a).data('archived') - $(b).data('archived');
    });
    classSelector.append(options);
    const style = initialValue ? classSelector.find(`[value=${initialValue}]`).attr('style') : undefined;
    if (style === undefined || !style.includes('display: none'))
    {
        classSelector.val(initialValue);
    }
    else
    {
        classSelector.val(classSelector.find('option:first').val());
    }
}

/**
 * Show class list based on user preference setting
 * @param showArchivedClass
 * @param reload
 */
function updateClassList(showArchivedClass, reload)
{
    const totalClasses = $('#classesList tr').length;
    const totalArchivedClasses = $('#classesList tr td:contains("archived")').length;
    const value = $('.js_filter_classes').val() ?
        $('.js_filter_classes')
        .val()
        .toLowerCase() :
        $('.js_filter_classes').val();
    if (showArchivedClass || (!showArchivedClass && totalClasses == totalArchivedClasses && reload))
    {
        if (value)
        {
            $('#classesList tr').filter(function()
            {
                const filteredRow = $(this).toggle(
                    $(this)
                    .text()
                    .toLowerCase()
                    .indexOf(value) > -1
                );
                filteredRow.show();
            });
        }
        else
        {
            $('#classesList tr td:contains("archived")')
                .parent()
                .show();
        }
        $('.js_class_list_setting').text(trans('reporting.hideArchived'));
        $('.js_class_list_setting').addClass('archived-content');
    }
    else
    {
        $('#classesList tr td:contains("archived")')
            .parent()
            .hide();
        $('.js_class_list_setting').text(trans('reporting.classLabelArchiveLink'));
        $('.js_class_list_setting').removeClass('archived-content');
    }
}

/**
 * Show class list based on search input
 **/
$(document).on('input', '#class-search', debounce(function(e)
{
    let searchVal = String($(this).val()).toLowerCase();
    let closeIcon = $('.clear-icon');

    $('#classes-table').DataTable().search(searchVal).draw();
    closeIcon.toggle(searchVal.length > 0);
}, 300));

$(document).on('click', '#class-search ~.clear-icon', function()
{
    let searchInput = $('#class-search');
    searchInput.val('').focus();
    $(this).hide();
    searchInput.trigger('input');
});

$(document).on('change', 'input[name="classesListSortBy"]', debounce(function(e)
{
    loadClassesList();
    telemetryTrackEvent('classesList-sort-by', { 'value': $(this).val() });
}, 300));

$(document).on('change', '.js-class-status-filter-checkbox', debounce(function(e)
{
    $('#clear-classes-status-filters-btn').toggle($('.js-class-status-filter-checkbox:checked').length > 0);
    loadClassesList();
}, 300));

$(document).on('click', '#clear-classes-status-filters-btn', function(e)
{
    $('.js-class-status-filter-checkbox').prop('checked', false).change();
    $(this).hide();
});

$(document).on('click', '.js-archive-class', function(e)
{
    const classInfo = $('#classes-table').attr('data-view-type') === 'grid' ? $(this).parents('.class-card') : $(this).parents('tr').find('.class-info-container');
    const classId = $(this).attr('data-bs-target-id');
    const className = classInfo.find('.class-name').text().trim();

    Quitch.modal(
        {
            title: trans('home.archiveClassModalHeader'),
            message: `<p class="wrap">${trans('home.archiveClassModalBody1', {className: className})}</p>
                      <p class="wrap mt-2">${trans('home.archiveClassModalBody2')}</p>`,
            type: 'confirm_custom',
            cssClass: 'modal-dialog-sm modal-dialog-centered',
            okText: 'home.archiveClassBtn',
            cancelText: 'base.cancel',
        },
        function(response)
        {
            if (response === true)
            {
                $.ajax(
                {
                    url: '/class/archive',
                    type: 'POST',
                    dataType: 'json',
                    data: { _token: $('meta[name="csrf-token"]').attr('content'), classId: classId },
                    success: function(response)
                    {
                        Quitch.showSnackbar(trans('home.classArchived'));
                        loadClassesList();
                    },
                    error: function()
                    {
                        Quitch.showSnackbar(trans('home.archiveClassFailed'));
                    }
                });
            }
        }
    );
});

$(document).on('show.bs.dropdown', '.accordion-class .class-card .dropdown', function(e)
{
    // Increasing z-index to so dropdown menu isn't covered by other class cards
    $(this).parents('.accordion-class').css('z-index', 1);
});

$(document).on('hidden.bs.dropdown', '.accordion-class .class-card .dropdown', function(e)
{
    $(this).parents('.accordion-class').css('z-index', 0);
});