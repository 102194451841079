import { confirmI18n, fileIsUploading, trans } from '../../common';
import { Quitch } from '../../quitch';
import { removeUploadedFile, resetImagePreview } from '../../form/file-upload';
import { updateClassPublishStatus } from './index';
import { getMessage, getUpdateMessage } from '../../../system';
import { mixpanelEventCounter, telemetryTrackEvent } from '../../superuser/mixpanel';
import { addNewOptionUi } from '../content-authoring';
import { updateEmptyState } from './section-topic';
import { getOpenAndCloseBracketIndices } from '../content-authoring/drag-drop';
import { loadPreview } from '../content-authoring/preview';

const blanksPattern = /([^\\a-zA-Z]{1}[a-zA-Z]*|[^\\a-zA-Z]{1}|^)(\[(.{0}|[^\\]|(([^\]\\]|(\\[a-zA-Z]*[^a-zA-Z]))*))\])/g;
/**
 * Update question modal on question type change
 */
$(document).on('change', '#ques-type-id', function(e)
{
    e.preventDefault();
    let i = 0;
    let questionType = $(this)
        .val()
        .replace(/\s+/g, '')
        .toLowerCase();
    $('#question-modal-save #dragdroptext').hide();
    $('#question-modal-save #js-add-new-incorrect-answer').hide();
    if (questionType)
    {
        $('#question-modal-save #js-add-new-answer').show();
    }
    else
    {
        $('#question-modal-save #js-add-new-answer').hide();
    }
    let answerList = $('#question-modal-save #answer-list');
    answerList.removeClass('true-false');
    answerList.empty();
    let answerId, answeroption;
    let formulaClass = $('#question-modal .enableFormulas')[0].checked ? '' : 'hide-formula';
    $('#question-modal-save #questionBoxElem').attr('placeholder', trans('content.placeholderQuestionTitle'));
    $('#question-modal-save .js-drag-drop-no-answers').hide();
    $('#question-modal-save .js-question-settings-card').show();
    switch (questionType)
    {
        case 'select-all-that-apply':
        case 'multi-choice':
            $('#question-modal-save .js-no-answers').hide();
            $('#question-modal-save .answer-box').css('width', '75%');
            getMcqOption(formulaClass);
            break;
        case 'true-false':
            $('#question-modal-save .js-no-answers').hide();
            $('#question-modal-save #answer-list').addClass('true-false');
            $('#question-modal-save #answer-list div')
                .first()
                .find('.toggle-button label')
                .click();
            $('#question-modal-save #js-add-new-answer').hide();
            $('#question-modal-save .answer-box').css('width', '100%');
            $('#question-modal-save #answer-list').empty();
            for (i = 0; i < 2; i++)
            {
                answerId = 'btn-answer-' + guid();
                let defaultAns = trans(i === 0 ? 'content.true' : 'content.false');
                answeroption = `
                            <div class="row answer">
                                <div class="inner-answer">
                                    <i class="fa fa-ellipsis-v float-left"></i>
                                    <div class="answer-box float-left" style="width: 100%;">
                                        <label class="label-medium">${trans('content.answer')} <span class="txt-grey-primary-70">${trans('content.required')}</span></label>
                                        <span class="float-right txt-grey-primary-70" data-counter="300" data-counter-name="${answerId}">${defaultAns.length}/300</span>
                                        <button class="btn btn-light formula-button js-formula-edit-button js_formula-control ${formulaClass} no-image-answerformula-button"
                                            type="button" data-field="answer_option" data-bs-target-rel=".characterLimit-${answerId}">f(x)</button>
                                        <textarea class="form-control js_data-counter" rows="1" name="answer_option" cols="50"data-rel=".characterLimit-${answerId}" placeholder="${trans('content.placeholderAnswer')}">${defaultAns}</textarea>
                                        <div class="toggle-button toggle-slide float-left">
                                            <input id="${answerId}" name="correct_answer" type="checkbox" data-telemetry-name="correct-answer-toggle"><label for="${answerId}"></label>
                                        </div>
                                        <label for="${answerId}" class="correctAns body-medium">${trans('content.incorrectAnswer')}</label>
                                    </div>
                                </div>
                            </div>`;

                $('#question-modal-save #answer-list').append(answeroption);
                $('#question-modal-save input[type=checkbox][name=correct_answer]').each(function()
                {
                    $('#question-modal-save #answer-list')
                        .find('.toggle-button label')
                        .first()
                        .click();
                });
            }
            break;
        case 'fill-in-the-blanks':
            $('#question-modal-save .js-no-answers').hide();
            $('#question-modal-save .answer-box').css('width', '100%');
            $('#question-modal-save #dragdroptext').show();
            $('#question-modal-save #answer-list').empty();

            $('#question-modal-save #js-add-new-answer').hide();
            $('#question-modal-save #js-add-new-incorrect-answer').show();

            $('#question-modal-save #questionBoxElem').attr('placeholder', trans('content.placeholderQuestionTitleDragDrop'));
            $('#question-modal-save .js-drag-drop-no-answers').show();

            updateAnswerContentFromQuestionBox($('#question-modal-save #questionBoxElem').first()[0]);

            break;
        case 'free-text':
            $('#question-modal-save .js-no-answers').hide();
            $('#question-modal-save #answer-list').empty();
            $('#question-modal-save #js-add-new-answer').hide();
            $('#question-modal-save #answer-list').append(getUpdateMessage('content.freeTextMsg', 'info'));
            $('#question-modal-save .js-question-settings-card').hide();
            break;
        default:
            $('#question-modal-save #answer-list').empty();
            $('#question-modal-save .js-no-answers').show();
            break;
    }
});

$(document).on('input propertychange', '#question-modal-save #questionBoxElem', function(e)
{
    updateAnswerContentFromQuestionBox(this);
});

$(document).on('input propertychange', '#edit-question-modal #questionBoxElem', function(e)
{
    updateAnswerContentFromQuestionBox(this, true);
});

export function updateAnswerContentFromQuestionBox(qnBox, isEditModal = false)
{
    const currentModal = isEditModal ? '#edit-question-modal' : '#question-modal-save';

    let questionType = $(qnBox).closest('.modal').find('#ques-type-id, #ques-type-ids').prop('value');
    if (questionType !== 'fill-in-the-blanks')
    {
        return;
    }

    updateAnswersFromQuestion(qnBox, false, currentModal, isEditModal);
}

export function updateAnswersFromQuestion(questionInput, fakeInputs = false, currentModal = null, isEditModal = false, contAuthQnItem = null)
{
    let qnVal = fakeInputs ? questionInput.innerHTML : questionInput.value;
    let dragDropAnswers;

    if (!fakeInputs)
    {
        dragDropAnswers = $(`${currentModal} .correct-answer textarea`);
    }
    else // Content authoring
    {
        dragDropAnswers = $(contAuthQnItem).find('.option-item').find('.title-group.correct').find('.title.input-field').not('.option-template');
    }

    const [openBracketIndices, closeBracketIndices, blanksInFormulas] = getOpenAndCloseBracketIndices(qnVal);
    if (openBracketIndices.length === closeBracketIndices.length)
    {
        if (openBracketIndices.length > 0)
        {
            for (let i = 0; i < openBracketIndices.length; ++i)
            {
                if (i >= dragDropAnswers.length) // Add extra answer boxes to match the number of square bracket sets
                {
                    if (!fakeInputs)
                    {
                        appendAnswerDragAndDrop(true, false, -1, isEditModal);
                    }
                    else
                    {
                        // Append new drag drop content authoring answer
                        addNewOptionUi(contAuthQnItem);
                    }
                }
            }

            // Get them again with the new additions
            if (!fakeInputs)
            {
                dragDropAnswers = $(`${currentModal} .correct-answer textarea`);
            }
            else
            {
                dragDropAnswers = $(contAuthQnItem).find('.option-item .title.input-field');
            }

            for (let i = 0; i < openBracketIndices.length; ++i)
            {
                if (openBracketIndices[i] < closeBracketIndices[i])
                {
                    // Set the contents of the parallel answer box from what's in the square brackets
                    if (!fakeInputs)
                    {
                        let val = qnVal.substring(openBracketIndices[i] + 1, closeBracketIndices[i]);
                        if (blanksInFormulas[i])
                        {
                            dragDropAnswers[i].value = `\\(${val}\\)`;
                        }
                        else
                        {
                            dragDropAnswers[i].value = val;
                        }
                    }
                    else
                    {
                        let newQnVal = qnVal.substring(openBracketIndices[i] + 1, closeBracketIndices[i]);
                        if (blanksInFormulas[i])
                        {
                            newQnVal = `\\(${newQnVal}\\)`;
                        }

                        dragDropAnswers[i].innerHTML = newQnVal;
                        $(dragDropAnswers[i]).attr('data-current-val', newQnVal);
                    }
                }
            }
        }

        for (let i = 0; i < openBracketIndices.length; ++i) // Correct answers
        {
            if (!fakeInputs)
            {
                dragDropAnswers.eq(i).parent().find('input[type=checkbox][name=correct_answer]').prop('checked', true);
            }
            else
            {
                // Check the boxes
                dragDropAnswers.eq(i).parents('.option-item.correct-option').find('input[type=checkbox][name=optionCheck]').prop('checked', true);
            }
        }

        for (let i = openBracketIndices.length; i < dragDropAnswers.length; ++i) // Excess correct answers
        {
            if (!fakeInputs)
            {
                dragDropAnswers.eq(i).parents('.correct-answer').remove();
            }
            else
            {
                dragDropAnswers.eq(i).parents('.option-item.correct-option').remove();
            }
        }

        correctAnswerCheckBox();

        if ($('.content-authoring.content-preview').length > 0)
        {
            loadPreview();
        }
    }
}

$(document).on('input propertychange', '#question-modal-save .row.answer textarea', function(e)
{
    updateQnBoxContentFromAnsBox(this);
});

$(document).on('input propertychange', '#edit-question-modal .row.answer textarea', function(e)
{
    updateQnBoxContentFromAnsBox(this, true);
});

export function updateQnBoxContentFromAnsBox(ansBox, isEditModal = false)
{
    const currentModal = isEditModal ? '#edit-question-modal' : '#question-modal-save';

    if (ansBox != null)
    {
        let questionType = $(ansBox).closest('.modal').find('#ques-type-id, #ques-type-ids').prop('value');
        if (questionType !== 'fill-in-the-blanks')
        {
            return;
        }
    }

    const questionBoxElem = $(`${currentModal} #questionBoxElem`)[0];
    const dragDropAns = $(`${currentModal} .correct-answer textarea`); // Get all answer boxes for the current modal

    updateQuestionFromAnswers(questionBoxElem, dragDropAns, ansBox);
}

export function updateQuestionFromAnswers(questionInput, answerInputs, ansBox = null, excludeEmpty = false)
{
    for (let i = 0; i < answerInputs.length; ++i)
    {
        if (ansBox == null || ansBox === answerInputs[i]) // If ansBox is null process all answer boxes
        {
            let qnVal = questionInput.innerHTML;

            const [openBracketIndices, closeBracketIndices, blanksInFormulas] = getOpenAndCloseBracketIndices(qnVal, excludeEmpty);
            if (openBracketIndices.length > i && openBracketIndices.length === closeBracketIndices.length && openBracketIndices[i] < closeBracketIndices[i])
            {
                const start = qnVal.substring(0, openBracketIndices[i] + 1);
                const end = qnVal.substring(closeBracketIndices[i], qnVal.length);
                let value = answerInputs[i].innerHTML;

                if (blanksInFormulas[i])
                {
                    value = value.trim();
                    if ((value.startsWith('\\(') && value.endsWith('\\)')) || (value.startsWith('\\[') && value.endsWith('\\]')))
                    {
                        value = value.substring(2, value.length - 2);
                    }
                    if (value.endsWith('\\'))
                    {
                        //add a space so the close blank doesn't look like a close formula
                        value = value + ' ';
                    }
                }

                questionInput.innerHTML = start + value + end;
            }

            if (ansBox === answerInputs[i])
            {
                break;
            }
        }
    }

    if ($('.content-authoring.content-preview').length > 0)
    {
        loadPreview();
    }
}

/**
 * Setup add question modal
 */
$(document).on('click', '.question-create', function(e)
{
    e.preventDefault();

    if ($('#question-modal-save').length === 0)
    {
        Quitch.showMessage('danger', 'content.questionArchivedClass');
        return;
    }

    $('#question-modal-save .resourceError').hide();
    $('#question-modal-save #answer-list').empty();
    $('#question-modal-save #js-add-new-answer').hide();
    $('#question-modal-save .answer-error').hide();
    $('#question-modal-save .ques_required').hide();
    $('#question-modal-save .incorrect-questionType').hide();

    $('#question-modal-save .error-input').removeClass('error-input');

    //reset form
    $('#question-modal-save')[0].reset();
    $('#question-modal-save .js_formula-control').addClass('hide-formula');
    $('#question-modal-save .js-question-type .dropdown-item.js-default-item').click();

    $('#question-modal-save #output').attr('src', '');
    let questionImageSection = $('#question-modal-save .question-image');
    resetImagePreview(questionImageSection, 'question');
    questionImageSection.find('.image-management').hide();
    questionImageSection.find('.unsplash-upload').show();
    resetForm(false);
    $('.answer-img-upload').css('display', 'block');
    $('.answer-img-remove').css('display', 'none');

    $('#question-modal-save .extra-answer').empty();
    $('.js_data-counter').trigger('keyup');
    $('#assess-save').attr('disabled', false);

    $('#question-modal').modal('show');
});

$(document).on('click', '.question-point', function(e)
{
    e.stopPropagation();
    return false;
});

function addNewAnswer()
{
    let questionType = $('#ques-type-id').val();
    let formulasEnabled = $('#question-modal-save .enableFormulas')[0].checked;
    newAnswerOptionAddViaRequest('new', questionType, formulasEnabled);
}

$(document).on('click', '#js-add-new-answer', function(e)
{
    e.preventDefault();
    addNewAnswer();
});

$(document).on('click', '#js-add-new-incorrect-answer', function(e)
{
    e.preventDefault();
    appendAnswerDragAndDrop(true, true);
});

$(document).on('click', '#js-add-new-incorrect-answer-edit', function(e)
{
    e.preventDefault();
    appendAnswerDragAndDrop(true, true, -1, true);
});

$(document).on('click', '#js-add-new-answer-update', function(e)
{
    e.preventDefault();
    let questionType = $('#ques-type-ids').val();
    let formulasEnabled = $('#question-modal-edit .enableFormulas')[0].checked;
    newAnswerOptionAddViaRequest('edit', questionType, formulasEnabled);
});

$(document).on('click', '.deleteAnswerOption', function(e)
{
    e.preventDefault();
    $(this)
        .closest('.row')
        .remove();
});

$(document).on('change', 'input[name=correct_answer]', function(e)
{
    e.preventDefault();
    let questionType = $(this).closest('.modal').find('#ques-type-id, #ques-type-ids').prop('value');

    if (questionType === 'multi-choice' || questionType === 'true-false')
    {
        if ($(this).is(':checked'))
        {
            // Uncheck other answers to only leave one correct answer
            $('input[name=correct_answer]').prop('checked', false);
            $(this).prop('checked', true);
        }
        else if (questionType === 'true-false')
        {
            // Set other tf as correct when unchecking
            $('input[name=correct_answer]').prop('checked', true);
            $(this).prop('checked', false);
        }
    }
    correctAnswerCheckBox();
});

/**
 * Setup edit question modal
 */
$(document).on('click tap touch', '.js-edit-question-btn', function(e)
{
    e.preventDefault();

    $('.edit-content-button').prop('disabled', true);

    let list = $(this).closest('ol');
    let item = $(this).closest('li.js_content-list-item');
    if (!item.length)
    {
        return false;
    }

    list.find('li.del-edit').removeClass('del-edit');
    item.addClass('del-edit');

    let values = [];
    values['question_id'] = $(this).attr('data-content-id');
    values['section_id'] = item.data('section-id');
    values['_token'] = $('meta[name="csrf-token"]').attr('content');

    $.ajax(
    {
        url: '/get-question/' + values['question_id'] + '/' + values['section_id'],
        type: 'GET',
        dataType: 'json',
        data: values,
        success: function(response)
        {
            $('.edit-content-button').prop('disabled', false);

            if (response.status === '200' || response.status === 200)
            {
                $('#edit-question-modal').remove();
                $(response.view).modal('show');

                correctAnswerCheckBox();
            }
            else if (response.message)
            {
                Quitch.showErrors(response.message);
            }
            window.scrollTo(0, 0);
        },
        error: function(jqXHR, textStatus, errorThrown)
        {
            $('.edit-content-button').prop('disabled', false);
        },
    });
});

$(document).on('shown.bs.modal', '#edit-question-modal', function()
{
    $('.js_data-counter').trigger('keyup');

    const questionType = $('#edit-question-modal').find('#ques-type-id, #ques-type-ids').prop('value');
    if (questionType === 'fill-in-the-blanks')
    {
        updateQnBoxContentFromAnsBox(null, true);
    }
});

$(document).on('shown.bs.modal', '#question-modal-save', function()
{
    $('.js_data-counter').trigger('keyup');
});

/**
 * Submit add question form
 */
// TODO: Refactor validation from create_assessment / update_assessment to one method (remove duplication)
$(document).on('click', '.create_assessment', function(e)
{
    e.preventDefault();

    let errors = 0;

    let questionType = $('#question-modal-save #ques-type-id').val();
    if (!questionType)
    {
        $('#question-modal-save .ques_required').show();
    }

    const validationErrors = validateAssessmentForm(errors, questionType, false);
    if (!validationErrors)
    {
        return validationErrors;
    }

    let self = $(this);
    let values = {};
    let answers = {};
    let allTitle = [];
    let countTitle = 0;
    let correctAnswerCount = 0;

    self.closest('.assessment_form')
        .find(':input, select')
        .map(function(index, elm)
        {
            values[elm.name] = $(elm).val();
        });

    // Iterate through each "answer" get values
    $('#question-modal-save #answer-list .inner-answer').each((idx, item) =>
    {
        const $answer = $(item);
        const title = $answer.find('textarea[name="answer_option"]').val();
        const correct = $answer.find('input[name="correct_answer"]').is(':checked');

        const imagePath = $answer.find('.file-upload-section input[type="hidden"]').attr('value');

        allTitle[countTitle] = title.toLowerCase();
        countTitle++;

        if (title)
        {
            if (correct)
            {
                correctAnswerCount++;
                answers['item_' + idx] = { correct: 1, title: title, imagePath: imagePath };
            }
            else
            {
                answers['item_' + idx] = { correct: 0, title: title, imagePath: imagePath };
            }
        }
    });

    const postValidationErrors = validatePostAssessmentForm(correctAnswerCount, countTitle, questionType, allTitle, false);
    if (!postValidationErrors)
    {
        return postValidationErrors;
    }

    // check for file uploads before posting
    if (fileIsUploading())
    {
        if ($('#question-modal-save .modal-footer .upload-error').length === 0)
        {
            $('#question-modal-save .modal-footer').append(`<span class="upload-error float-right" style="color: red">${trans('base.errorFileUpload')}</span>`);
        }
        return false;
    }
    else
    {
        $('#question-modal-save .modal-footer .upload-error').remove();
    }

    const qwtile = $('#question-modal-save .qwtile');

    if (questionType === 'fill-in-the-blanks')
    {
        qwtile.val(qwtile.val().replace('][', '] [').replace(blanksPattern, '$1[......]'));
    }

    let class_id = $('.js_class_details').data('class-id');
    let section_id = $('.section-list-item.active').data('section');
    let form_data = new FormData($('#question-modal-save')[0]);
    form_data.append('class_id', class_id);
    form_data.append('section_id', section_id);
    form_data.append('assessments', JSON.stringify(answers));
    let actionName = $(this).attr('id');

    $('#assess-save').attr('disabled', true);

    $.ajax(
    {
        url: '/assessment',
        type: 'POST',
        dataType: 'json',
        processData: false,
        contentType: false,
        data: form_data,
        success: function(response)
        {
            $('.no-content').remove();
            $('#question-modal-save .answer-error').css('display', 'none');
            if (response.status === '200' || response.status === 200)
            {
                if (actionName === 'assess-save')
                {
                    Quitch.showMessage('success', 'base.alertAssessmentCreated');
                    Quitch.updateUrl({ section: section_id }, 'ep-create-assessment-success');
                }
                else if (actionName === 'assess-save-and-new')
                {
                    $('#question-modal-save .assessmentSuccess').html(getMessage('base.alertAssessmentCreated', 'success'));
                    $('#question-modal-save')[0].reset();
                    $('#question-modal-save .js_formula-control').addClass('hide-formula');
                    $(`#question-modal-save .js-question-type [data-bs-target=${questionType}]`).click();

                    if (questionType === 'true-false')
                    {
                        $('#question-modal-save #answer-list').addClass('true-false');
                        $('#question-modal-save #js-add-new-answer').hide();

                        $('#question-modal-save input[type=checkbox][name=correct_answer]').each(function()
                        {
                            $('#question-modal-save #answer-list')
                                .find('.toggle-button label')
                                .first()
                                .click();
                        });
                    }
                    else if (questionType === 'multi-choice' || questionType === 'select-all-that-apply')
                    {
                        let formulaClass = $('#question-modal .enableFormulas')[0].checked ? '' : 'hide-formula';
                        getMcqOption(formulaClass);
                    }

                    let instructionsDelay = $('.instructions-unlock-time-delay').val();
                    $('#question-modal-save .qss-default-time').val(instructionsDelay);

                    let imageUploaderBtn = $('.js-question-image-upload');
                    removeUploadedFile('question', imageUploaderBtn.parent(), imageUploaderBtn[0]);
                    $('#question-modal').addClass('reload-on-close');
                }
                else if (actionName === 'assess-save-and-duplicate')
                {
                    $('#question-modal-save .assessmentSuccess').html(getMessage('base.alertAssessmentCreated', 'success'));
                    $('#question-modal').addClass('reload-on-close');
                }
                $('#question-modal-save .answer-error').css('display', 'none');
                $('#question-modal-save .ques_required').css('display', 'none');
            }
            else
            {
                $('#question-modal-save .resourceError').html(getMessage(response.message, 'danger'));
                $('#question-modal-save .resourceError').show();
            }
            mixpanelEventCounter('questionCreated');
        },
        error: () =>
        {
            $('#question-modal').modal('hide');
            Quitch.showMessage('danger', 'base.errorAssessmentCreate');
        },
    });
});

$(document).on('hidden.bs.modal', '#question-modal', function(e)
{
    if ($('#question-modal').hasClass('reload-on-close'))
    {
        let section_id = $('.section-list-item.active').data('section');
        Quitch.updateUrl({ section: section_id }, 'ep-hide-question-modal');
    }
});

/**
 * Submit edit question form
 */
// TODO: Refactor validation from create_assessment / update_assessment to one method (remove duplication)
$(document).on('click', '.update_assessment', function(e)
{
    e.preventDefault();

    let errors = 0;

    $('#edit-question-modal .modal-header')
        .find('.error')
        .remove();

    let questionType = $('#edit-question-modal #ques-type-ids').val();
    const validationErrors = validateAssessmentForm(errors, questionType, true);
    if (!validationErrors)
    {
        return validationErrors;
    }

    const self = $(this);
    let values = {};
    let answers = {};
    let allTitle = [];
    let countTitle = 0;
    let correctAnswerCount = 0;

    // TODO: Think this is being done just to get the original question id ??
    self.closest('.edit_assessment_form')
        .find(':input, select')
        .map(function(index, elm)
        {
            values[elm.name] = $(elm).val();
        });

    // Iterate through each "answer" get values
    $('#edit-question-modal #answer-list .inner-answer').each((idx, item) =>
    {
        const $answer = $(item);
        const answer = $answer.find('input[name="answer_ids"]').val() || 'create';
        const title = $answer.find('textarea[name="answer_option"]').val();
        const imagePath = $answer.find('.file-upload-section input[type="hidden"]').attr('value');
        const correct = $answer.find('input[name="correct_answer"]').is(':checked');

        allTitle[countTitle] = title.toLowerCase();
        countTitle++;

        if (title)
        {
            if (correct)
            {
                correctAnswerCount++;
                answers['item_' + idx] = { correct: 1, title: title, imagePath: imagePath, answerId: answer };
            }
            else
            {
                answers['item_' + idx] = { correct: 0, title: title, imagePath: imagePath, answerId: answer };
            }
        }
    });

    const postValidationErrors = validatePostAssessmentForm(correctAnswerCount, countTitle, questionType, allTitle, true);
    if (!postValidationErrors)
    {
        return postValidationErrors;
    }

    // check for file uploads before posting
    if (fileIsUploading())
    {
        if ($('#edit-question-modal .modal-footer .upload-error').length === 0)
        {
            $('#edit-question-modal .modal-footer').append(`<span class="upload-error float-right" style="color: red">${trans('base.errorFileUpload')}</span>`);
        }
        return false;
    }
    else
    {
        $('#edit-question-modal .modal-footer .upload-error').remove();
    }

    const qwtile = $('#edit-question-modal .qwtile');

    if (questionType === 'fill-in-the-blanks')
    {
        qwtile.val(qwtile.val().replace('][', '] [').replace(blanksPattern, '$1[......]'));
    }

    let id = values['id'];
    let class_id = $('.js_class_details').data('class-id');
    let token = $('meta[name="csrf-token"]').attr('content');
    let form_data = new FormData($('#question-modal-edit')[0]);
    let section_id = $('.section-list-item.active').data('section');
    form_data.append('edit_id', id);
    form_data.append('class_id', class_id);
    form_data.append('section_id', section_id);
    form_data.append('assessments', JSON.stringify(answers));
    form_data.append('_token', token);

    if ($('#edit-question-modal .question-image .image-preview img').attr('src'))
    {
        form_data.delete('upload-img-question');
        form_data.append('upload-img-question', $('#edit-question-modal .question-image .image-preview img').attr('src'));
    }
    else
    {
        form_data.delete('upload-img-question');
        form_data.append('upload-img-question', '');
    }

    $('#edit-question-modal-save-btn').attr('disabled', true);

    $.ajax(
    {
        url: '/assessment_update',
        type: 'POST',
        dataType: 'json',
        processData: false,
        contentType: false,
        data: form_data,
        success: response =>
        {
            $('#edit-question-modal .answer-error').css('display', 'none');
            if (response.status === '200' || response.status === 200)
            {
                Quitch.showMessage('success', 'base.assessmentUpdate');
                $('#edit-question-modal').modal('hide');
                updateContentTitle(id, form_data.get('enableFormulas') ? form_data.get('shortTitle') : form_data.get('title'));
                updateClassPublishStatus('modified');
            }
            else
            {
                $('#edit-question-modal-save-btn').attr('disabled', false);
                Quitch.showMessage('danger', response.message);
            }

            $('#upload-img-question-edit').val('');
        },
        error: () =>
        {
            $('#edit-question-modal').modal('hide');
            Quitch.showMessage('danger', 'base.errorAssessmentUpdate');
        },
    });
});

$(document).on('click', '.assessment-del-btn', function(e)
{
    e.preventDefault();

    let id = $(this).hasClass('btn') ? $(this).val() : $(this).attr('data-content-id');
    const listEle = $(this).parents('.js_content-list-item');
    if (id)
    {
        if (confirmI18n('base.alertDeleteInfo'))
        {
            let values = {};
            values['_token'] = $('meta[name="csrf-token"]').attr('content');
            values['classId'] = $('.js-class-id').data('class-id');
            values['questionId'] = id;

            $.ajax(
            {
                url: '/assessment/delete',
                type: 'DELETE',
                dataType: 'json',
                data: values,
                success: function(response)
                {
                    if (response.status === '200' || response.status === 200)
                    {
                        const topicId = listEle.attr('data-section-id');
                        listEle.remove();
                        updateOrder(topicId);
                        updateEmptyState();
                        updateClassPublishStatus('modified');
                        Quitch.showSnackbar(trans('content.questionDeleted'));
                    }
                    else
                    {
                        Quitch.showSnackbar(trans('content.questionDeleteError'));
                    }
                },
            });
        }
    }
    return false;
});

$(document).on('input', '.js-question-details-card textarea, .js-question-details-card input', function(e)
{
    $(this).removeClass('error-input');
    $(this).parent().siblings('.help-block').hide();
});

$(document).on('change', '[name=enableFormulas]', function()
{
    const enableFormulas = $(this).is(':checked');
    $(this).parents('.card-body').find('.js-question-image-content')
        .attr('data-input-class', enableFormulas ? 'question-short-content' : 'question-content')
        .attr('data-error-label', enableFormulas ? 'qshort-title' : 'qtitle');
});


$(document).on('shown.bs.dropdown', '.js-edit-content-dropdown', function()
{
    let dropdownListHtml = '';
    const activeTopic = $('.js_section-content-display.active').attr('data-section');

    $('.js-section-list-item').each(function()
    {
        let sectionEle = $(this);
        let topicsHtml = '';
        sectionEle.find('.js-topic-list-item').each(function()
        {
            let topicId = $(this).attr('topic-id');
            if (topicId !== activeTopic)
            {
                topicsHtml += `<a href="#" id="topic-item-dynamic" class="dropdown-item js-move-to-topic"
                                    data-dest-topic-id="${topicId}">${$(this).attr('data-title')}</a>`;
            }
        });
        if (topicsHtml)
        {
            dropdownListHtml += `<div class="dropdown-header">${sectionEle.attr('data-title')}</div>`;
            dropdownListHtml += topicsHtml;
        }
    });
    const moveToDropdown = $(this).find('.js-move-to-dropdown-menu');
    moveToDropdown.parents('.dropdown-submenu').toggle(dropdownListHtml !== '');
    if (dropdownListHtml === '')
    {
        return;
    }
    moveToDropdown.html(dropdownListHtml);
});

$(document).on('shown.bs.dropdown', '.js-dynamic-dropdown', function()
{
    const listItem = $(this).parents('.js_content-list-item');
    if (listItem.length)
    {
        const dropdownMenu = listItem.find('.js-edit-content-dropdown .dropdown-menu');
        const moveToDropdown = $(this).find('.js-move-to-dropdown-menu');

        const submenuHeight = 300;
        const distance = $('html').height() - (listItem.offset().top + listItem.height());
        // adjust sub menu position when close to bottom of page
        if (distance < submenuHeight && distance > dropdownMenu.height())
        {
            // if the dropdown menu is below the dropdown button;
            moveToDropdown.css('top', '-' + (submenuHeight - distance + 70).toString() + 'px');
        }
        else if (distance < moveToDropdown.height())
        {
            moveToDropdown.css('top', '-' + (submenuHeight - distance - 114).toString() + 'px');
        }
    }
});

$(document).on('click', '.js-update-schedule-click', function()
{
    $(this).parents('.js_content-list-item').find('.js_update-content-date').click();
});

$(document).on('click', '.js-move-to-topic', function()
{
    const listItem = $(this).parents('.js_content-list-item');
    const oldTopicId = listItem.attr('data-section-id');
    const destTopicId = $(this).attr('data-dest-topic-id');
    let values = {
        _token: $('meta[name="csrf-token"]').attr('content'),
        topicContentId: listItem.attr('data-topic-content-id'),
        destTopicId: destTopicId,
    };

    $.ajax(
    {
        url: '/admin/class/content/move',
        type: 'POST',
        dataType: 'json',
        data: values,
        async: false,
        success: function(response)
        {
            listItem.attr('data-section-id', destTopicId).insertAfter(`.js_content-for-${destTopicId} > div`);
            listItem.find('.js_content-list-item').attr('data-section-id', destTopicId);

            updateOrder(destTopicId);
            updateOrder(oldTopicId);

            const contentType = listItem.find('.question-or-instruction').hasClass('question') ? 'question' : 'resource';
            const topicName = $('.js_topic-name-edit[data-section-id=' + destTopicId + ']').val();
            Quitch.showSnackbar(trans(`content.${contentType}MovedToTopic`, { topic: topicName }));
            telemetryTrackEvent('movedContentToTopic', { 'status': 'success' });
        },
        error: function(xhr, status, error)
        {
            telemetryTrackEvent('movedContentToTopic', { 'status': 'failed', 'debug': xhr.responseJSON.message, 'error': error });
            Quitch.showSnackbar(trans('content.failedMoveContentToTopic'));
        },
    });
});

function updateOrder(topicId)
{
    let contentIndex = 1;
    $(`.js_content-list.js_content-for-${topicId} .js_content-list-item`).each((idx, item) =>
    {
        $(item).attr('data-order-dnd', idx);
        if (!$(item).parents('.js_content-list').hasClass('accordion-child'))
        {
            $(item).find('.content-index').text(contentIndex);
            contentIndex++;
        }
    });
}

/**
 * Question form validation
 * @param errors
 * @param questionType
 * @param isEditForm
 * @returns {boolean}
 */
function validateAssessmentForm(errors, questionType, isEditForm)
{
    let currentModel;
    if (isEditForm)
    {
        currentModel = '#edit-question-modal';
    }
    else
    {
        currentModel = '#question-modal-save';
    }

    let questionTypeError = $(`${currentModel} .ques_required`);
    if (!questionType)
    {
        questionTypeError.show();
        questionTypeError.css('color', 'red');
        errors++;
    }
    else
    {
        $(`${currentModel}  .ques_required`).hide();
    }

    let qwtile = $(`${currentModel} .qwtile`);
    let questionError = $(`${currentModel} .qtitle`);
    if (!qwtile.val())
    {
        questionError.text(trans('content.questionTitleRequired'));
        questionError.show();
        questionError.css('color', 'red');
        errors++;
    }
    else if (qwtile.hasClass('counter-limit-reached'))
    {
        questionError.text(trans('content.questionLimitReached'));
        questionError.show();
        questionError.css('color', 'red');
        errors++;
    }
    else
    {
        questionError.hide();
        qwtile = qwtile.val();
        let questionWrongType = $(`${currentModel} .incorrect-questionType`);
        if (qwtile.match(blanksPattern))
        {
            if (questionType !== 'fill-in-the-blanks')
            {
                questionWrongType.show();
                questionWrongType.css('color', 'red');
                errors++;
            }
            else
            {
                questionWrongType.hide();
                qwtile = qwtile.replace('][', '] [').replace(blanksPattern, '$1[......]');
            }
        }
        $(`${currentModel} .qwtile`).val(qwtile);
    }
    let shortTitleError = $(`${currentModel} .qshort-title`);
    if ($(`${currentModel} .enableFormulas`)[0].checked && !$(`${currentModel} #question-short-title`).val())
    {
        shortTitleError.text(trans('content.questionTitleRequired'));
        shortTitleError.show();
        shortTitleError.css('color', 'red');
        errors++;
    }
    else if ($(`${currentModel}  #question-short-title`).hasClass('counter-limit-reached'))
    {
        shortTitleError.text(trans('content.shortLimitReached'));
        shortTitleError.show();
        shortTitleError.css('color', 'red');
        errors++;
    }
    else
    {
        shortTitleError.hide();
    }

    let questionHintError = $(`${currentModel} .question-hint-error`);
    if ($(`${currentModel} .question-hint`).hasClass('counter-limit-reached'))
    {
        questionHintError.text(trans('content.hintLimitReached'));
        questionHintError.show();
        questionHintError.css('color', 'red');
        errors++;
    }
    else
    {
        questionHintError.hide();
    }

    let questionExplanationError = $(`${currentModel}  .question-explanation-error`);
    if ($(`${currentModel} .question-explanation `).hasClass('counter-limit-reached'))
    {
        questionExplanationError.text(trans('content.explanationLimitReached'));
        questionExplanationError.show();
        questionExplanationError.css('color', 'red');
        errors++;
    }
    else
    {
        questionExplanationError.hide();
    }

    let answerErrors = $(`${currentModel} .answer-errors`);
    let answerLimitExceeded = $(`${currentModel} .answer-limit-exceeded`);
    $(`${currentModel} #answer-list .answer`)
        .find('textarea')
        .each(function(index)
        {
            if (questionType === 'true-false')
            {
                if (index === 0 || index === 1)
                {
                    let ansopt = $(this).val();
                    if (ansopt.trim() === '')
                    {
                        answerErrors.css('display', 'block');
                        answerErrors.css('color', 'red');
                        errors++;
                    }
                    else if ($(this).hasClass('counter-limit-reached'))
                    {
                        answerLimitExceeded.css('display', 'block');
                        answerLimitExceeded.css('color', 'red');
                        errors++;
                    }
                }
            }

            if (questionType === 'multi-choice' || questionType === 'select-all-that-apply' || questionType === 'fill-in-the-blanks')
            {
                let ansopt1 = $(this).val();
                if (ansopt1.trim() === '')
                {
                    answerErrors.css('display', 'block');
                    answerErrors.css('color', 'red');
                    errors++;
                }
                else if ($(this).hasClass('counter-limit-reached'))
                {
                    answerLimitExceeded.css('display', 'block');
                    answerLimitExceeded.css('color', 'red');
                    errors++;
                }
            }
        });

    if (errors === 0)
    {
        answerErrors.css('display', 'none');
    }

    let leastOptionError = $(`${currentModel} .least-option-errors`);
    if (questionType === 'multi-choice' || questionType === 'select-all-that-apply' || questionType === 'fill-in-the-blanks' || questionType === 'true-false')
    {
        let has_checked = 0;

        $(`${currentModel} input[type=checkbox][name=correct_answer]`).each(function()
        {
            if ($(this).is(':checked'))
            {
                has_checked++;
                leastOptionError.css('display', 'none');
            }
        });

        if (has_checked === 0)
        {
            errors++;
            leastOptionError.css('display', 'block');
            leastOptionError.css('color', 'red');
        }
        if (has_checked > 1 && (questionType === 'true-false' || questionType === 'multi-choice'))
        {
            errors++;
            let trueFalseError = $(`${currentModel} .true-false-error`);
            trueFalseError.css('display', 'block');
            trueFalseError.css('color', 'red');
        }
    }

    const leastMultiOptionError = $(`${currentModel} .least-multi-option-errors`);
    const allAnswersCurrModal = $(`${currentModel} .row.answer`);
    if (allAnswersCurrModal.length < 2 && (questionType === 'fill-in-the-blanks' || questionType === 'multi-choice' || questionType === 'select-all-that-apply'))
    {
        errors++;
        leastMultiOptionError.css('display', 'block');
        leastMultiOptionError.css('color', 'red');
    }

    if (errors > 0)
    {
        return false;
    }
    else
    {
        return true;
    }
}

/**
 * Question form post checking validation
 * @param correctAnswerCount
 * @param countTitle
 * @param questionType
 * @param allTitle
 * @param isEditForm
 * @returns {boolean}
 */
function validatePostAssessmentForm(correctAnswerCount, countTitle, questionType, allTitle, isEditForm)
{
    let currentModel;

    let errors = 0;
    if (isEditForm)
    {
        currentModel = '#edit-question-modal';
    }
    else
    {
        currentModel = '#question-modal-save';
    }

    let qwtile = $(`${currentModel} .qwtile`).val();
    let correctAnswerCountError = $(`${currentModel} .correct-answer-count-errors`);
    let noBlankError = $(`${currentModel} .no-blank-error`);

    noBlankError.css('display', 'none');
    correctAnswerCountError.css('display', 'none');

    if (questionType === 'fill-in-the-blanks')
    {
        const [openBracketIndices, closeBracketIndices] = getOpenAndCloseBracketIndices(qwtile);
        const ansBracketCount = openBracketIndices.length;

        if (ansBracketCount !== correctAnswerCount)
        {
            correctAnswerCountError.css('display', 'block');
            correctAnswerCountError.css('color', 'red');
            errors = 1;
        }
        else
        {
            correctAnswerCountError.css('display', 'none');
        }
    }

    let duplicateAnswerTitle = $(`${currentModel} .duplicate-title-answers`);
    if (questionType === 'multi-choice' || questionType === 'select-all-that-apply' || questionType === 'fill-in-the-blanks' || questionType === 'true-false')
    {
        let countUniqueTitle = [...new Set(allTitle)];
        if (countTitle !== countUniqueTitle.length)
        {
            duplicateAnswerTitle.css('display', 'block');
            duplicateAnswerTitle.css('color', 'red');
            $(`${currentModel} .loader`).empty();
            errors = 3;
        }
        else
        {
            duplicateAnswerTitle.css('display', 'none');
        }
    }

    if (errors > 0)
    {
        return false;
    }
    else
    {
        return true;
    }
}

function newAnswerOptionAddViaRequest(form, questionType, formulasEnabled)
{
    let str = '';
    $.ajax(
    {
        url: '/get-add-new-answer-option',
        type: 'GET',
        data: { type: questionType || 'multi-choice', formulasEnabled: formulasEnabled ? 1 : 0 },
        dataType: 'json',
        success: function(response)
        {
            if (questionType === 'fill-in-the-blanks')
            {
                str = $(response.view);
                str.find('.image-box').hide();
                str.find('.answer-box').css('width', '100%');
            }
            else
            {
                str = $(response.view);
            }

            if (form == 'edit')
            {
                $('.update_assessment')
                    .closest('.edit_assessment_form')
                    .find('#answer-list')
                    .append(str);
            }
            else
            {
                $('.create_assessment')
                    .closest('.assessment_form')
                    .find('#answer-list')
                    .append(str);

                $('#answer-list')
                    .children()
                    .last()
                    .addClass('extra-answer');

                if (questionType === 'fill-in-the-blanks')
                {
                    $('#answer-list')
                        .children()
                        .last()
                        .addClass('incorrect-answer');
                }
            }
        },
    });
}

function getMcqOption(formulaClass = '')
{
    let answerDropdown = createAnswerDropdown('multi-choice');

    $('#question-modal-save #answer-list').empty();
    for (let i = 0; i < 4; i++)
    {
        let answerId = 'btn-answer-' + guid();
        let answeroption = `
                <div class="row answer" id="answer-form-group-depreciated">
                    <div class="inner-answer">
                        <i class="fa fa-ellipsis-v float-left"></i>
                        <div class="answer-box float-left">
                            <label class="label-medium">${trans('content.answer')} <span class="txt-grey-primary-70">${trans('content.required')}</span></label>
                            <span class="float-right txt-grey-primary-70" data-counter="300" data-counter-name="${answerId}">0/300</span>
                            <button class="btn btn-light formula-button js-formula-edit-button js_formula-control ${formulaClass} answer-formula-button"
                                type="button" data-field="answer_option" data-bs-target-rel=".characterLimit-${answerId}">f(x)</button>
                            <textarea class="form-control answer_option js_data-counter" rows="1" name="answer_option" cols="50" data-rel=".characterLimit-${answerId}" placeholder="${trans('content.placeholderAnswer')}"></textarea>
                            <div><label class="js-answer-error" style="display: none;">${trans('content.fieldRequired')}</label></div>
                            <div class="toggle-button toggle-slide float-left">
                                <input id="${answerId}" name="correct_answer" type="checkbox" data-telemetry-name="correct-answer-toggle">
                                <label for="${answerId}"></label>
                            </div>
                            <label for="${answerId}" class="correctAns body-medium">${trans('content.incorrectAnswer')}</label>
                        </div>
                        <div class="image-box float-left image-file-drag-drop" draggable="true">
                            <div class="image image-preview">
                                <div class="image-preview-empty">
                                    <i class="fa fa-2x fa-picture-o" aria-hidden="true"></i>
                                    <p>${trans('content.addImage')}</p>
                                </div>
                            </div>
                            <div class="file-upload-section">
                                <button data-file-upload-btn="upload" class="btn btn-outline btn-sm">${trans('content.uploadImageBtn')}</button>
                                <input name="file-upload" type="file" accept="image/*" data-file-upload="answer">
                                <input name="answer-img-paths[]" type="hidden">
                            </div>
                        </div>
                    </div>
                    ${answerDropdown}
                </div>`;

        $('#question-modal-save #answer-list').append(answeroption);
    }
}

function correctAnswerCheckBox()
{
    $('input[name=correct_answer]').each(function()
    {
        if ($(this).is(':checked'))
        {
            $(this)
                .parent('div.toggle-button')
                .parent('.answer-box')
                .find('.correctAns')
                .html(trans('content.correctAnswer'));
        }
        else
        {
            $(this)
                .parent('div.toggle-button')
                .parent('.answer-box')
                .find('.correctAns')
                .html(trans('content.incorrectAnswer'));
        }
    });
}

function resetForm(isEditForm)
{
    let currentModel = isEditForm ? '#edit-question-modal' : '#question-modal-save';
    $(`${currentModel} .ques_required`).hide();
    $(`${currentModel} .qtitle`).hide();
    $(`${currentModel} .incorrect-questionType`).hide();
    $(`${currentModel} .question-hint-error`).hide();
    $(`${currentModel} .question-explanation-error`).hide();
    $(`${currentModel} .qshort-title`).hide();
    $('.js_data-counter').trigger('keyup');
}

function guid()
{
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
}

function s4()
{
    return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
}

function createAnswerDropdown(questionType)
{
    let deleteAnswer = '';
    let manageAnswer = '';
    if (questionType !== 'free-text' && questionType !== 'true-false')
    {
        if (questionType !== 'fill-in-the-blanks')
        {
            deleteAnswer = '<div class="dropdown-divider"></div>';
        }
        deleteAnswer = deleteAnswer +
            `<a class="dropdown-item deleteAnswerOption" href="#">
                     ${trans('content.removeAnswer')}
                 </a>`;
    }
    if (questionType !== 'fill-in-the-blanks')
    {
        manageAnswer = `
                <a class="dropdown-item js-file-upload-new-btn" href="#">
                    ${trans('content.uploadImage')}
                </a>
                <a class="dropdown-item get-unsplash-img" href="#" data-modal-id="answer-form-group-depreciated" data-input-class="answer_option" data-file-upload="answer" data-error-label="js-answer-error">
                    ${trans('content.generateImage')}
                </a>
                <a class="dropdown-item js-file-upload-remove-btn" href="#" data-file-upload="answer" style="display: none">
                    ${trans('content.removeImage')}
                </a>`;
    }
    return `
            <div class="dropdown">
                <button type="button" class="btn btn-tertiary btn-dropdown"
                        data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    &#8226;&#8226;&#8226;
                </button>
                <div class="dropdown-menu float-right custom-dropdown-list">
                    ${manageAnswer}
                    ${deleteAnswer}
                </div>
            </div>`;
}

function appendAnswerDragAndDrop(isExtraAnswer = false, isIncorrectAnswer = false, atIndex = -1, isEditModal = false)
{
    const currentModal = isEditModal ? '#edit-question-modal' : '#question-modal-save';

    const answerId = 'btn-answer-' + guid();
    let answerDropdown = !isIncorrectAnswer ? '' : createAnswerDropdown('fill-in-the-blanks');
    let formulaClass = $('#question-modal .enableFormulas')[0].checked ? '' : 'hide-formula';
    const answeroption = `
        <div class="row answer${isExtraAnswer ? ' extra-answer' : ''}${isIncorrectAnswer ? ' incorrect-answer' : ' correct-answer'}">
            <div class="inner-answer">
                <i class="fa fa-ellipsis-v float-left"></i>
                <div class="answer-box float-left" style="width: 100%;">
                    <label class="label-medium">${trans('content.answer')} <span class="txt-grey-primary-70">${trans('content.required')}</span></label>
                    <span class="float-right txt-grey-primary-70" data-counter="300" data-counter-name="${answerId}">0/300</span>
                    <button class="btn btn-light formula-button js-formula-edit-button js_formula-control ${formulaClass} no-image-answerformula-button"
                        type="button" data-field="answer_option" data-bs-target-rel=".characterLimit-${answerId}">f(x)</button>
                    <textarea class="form-control js_data-counter" rows="1" name="answer_option" cols="50" data-rel=".characterLimit-${answerId}" placeholder="${trans('content.placeholderAnswer')}"></textarea>
                    <div class="toggle-button toggle-slide toggle-slide-inactive float-left">
                        <input id="${answerId}" name="correct_answer" type="checkbox" disabled="disabled" data-telemetry-name="correct-answer-toggle">
                        <label for="${answerId}"></label>
                    </div>
                    <label for="${answerId}" class="correctAns body-medium">${trans('content.incorrectAnswer')}</label>
                </div>
            </div>
            ${answerDropdown}
        </div>`;

    if (atIndex === -1)
    {
        if (!isIncorrectAnswer)
        {
            const correctAnswers = $(`${currentModal} #answer-list .correct-answer`);
            if (correctAnswers.length > 0)
            {
                correctAnswers.last().after(answeroption);
            }
            else
            {
                const incorrectAnswers = $(`${currentModal} #answer-list .incorrect-answer`);
                if (incorrectAnswers.length > 0)
                {
                    $(`${currentModal} #answer-list`).prepend(answeroption); // Insert before the first incorrect answer
                }
                else
                {
                    $(`${currentModal} #answer-list`).append(answeroption);
                }
            }
        }
        else // Just append new incorrect answers to the bottom
        {
            $(`${currentModal} #answer-list`).append(answeroption);
        }
    }
    else
    {
        const correctAnswers = $(`${currentModal} #answer-list .correct-answer`);
        if (correctAnswers.length > 0 && atIndex < correctAnswers.length)
        {
            correctAnswers.eq(atIndex).after(answeroption);
        }
        else
        {
            $(`${currentModal} #answer-list`).append(answeroption);
        }
    }

    if (!isEditModal)
    {
        $('#question-modal-save .js-drag-drop-no-answers').hide();
    }
}

export function updateContentTitle(id, title)
{
    $(`.question-or-instruction button[value="${id}"]`)
        .siblings('p.title')
        .text(title);
}