$(document).ready(function()
{
    loadPageTutorials();
});

function loadPageTutorials()
{
    if ($('#tutorials-data-holder').length)
    {
        const tutorialsData = $('#tutorials-data-holder').data('tutorials-data');
        loadPageTutorialsFromData(tutorialsData);
    }
}

function loadPageTutorialsFromData(tutorialsData)
{
    if (tutorialsData == '')
    {
        return;
    }

    // For if tutorials have already been loaded on this page before
    let currOpenIdx = 0;
    const visibleCount = $('.tutorial-box-outer:visible').length;
    const previouslyOpenCount = $('.tutorial-box-outer.previously-open').length;
    if (visibleCount)
    {
        currOpenIdx = $('.tutorial-box-outer:visible').data('tutorial-index');
    }
    $('.tutorial-box-outer').remove();

    if (tutorialsData['htmlArray'].length && $('.tutorial-btn').length === 0 && $('.no-tutorial-btn').length === 0)
    {
        if ($('.preview-wrapper').length)
        {
            $('.preview-wrapper').append(tutorialsData['tutBtnHtml']);
        }
        else
        {
            $(document.body).append(tutorialsData['tutBtnHtml']);
        }
    }

    for (let i = 0; i < tutorialsData['htmlArray'].length; ++i)
    {
        if (tutorialsData['data'][i]['elem'] !== '')
        {
            const targets = $(tutorialsData['data'][i]['elem']);
            if (targets.length)
            {
                const newElem = $(tutorialsData['htmlArray'][i]);
                $(document.body).append(newElem);

                const boxPos = tutorialsData['data'][i]['position'];

                setTutorialPos(newElem, tutorialsData['data'][i]['elem'], boxPos);

                if (currOpenIdx > 0) // We already had a tutorial open after the first one at the time of load
                {
                    if (newElem.data('tutorial-index') == currOpenIdx)
                    {
                        newElem.show();
                    }
                    else
                    {
                        newElem.hide();
                    }
                }
                else // Open first tutorial if it hasn't been seen before by this user
                {
                    if (newElem.data('tutorial-index') > 1 || newElem.data('seen-before') == 1)
                    {
                        newElem.hide();
                    }
                    else
                    {
                        newElem.show();
                    }
                }

                if (visibleCount === 0 && previouslyOpenCount > 0)
                {
                    // We don't want to open it again on reload if the user previously closed it manually
                    newElem.hide();
                }
            }
        }
    }
}

export function positionVisibleTutorials()
{
    $('.tutorial-box-outer').each((idx, tutorial) =>
    {
        if ($(tutorial).is(':visible'))
        {
            setTutorialPos($(tutorial), $(tutorial).data('target-selector'), $(tutorial).data('offset-pos'));
        }
    });
}

$(window).resize(function()
{
    positionVisibleTutorials();
});

$(window).scroll(function()
{
    positionVisibleTutorials();
});

function setTutorialPos(elem, targetElemSelector, boxPos = 'left')
{
    const targetElem = $(targetElemSelector);

    const domRectTarget = targetElem.get(0).getBoundingClientRect();
    const domRectElem = elem.find('.tutorial-box').get(0).getBoundingClientRect();

    const dirArrow = elem.find('.dir-arrow');
    const domRectArrow = dirArrow.length ? dirArrow.get(0).getBoundingClientRect() : null;

    if (boxPos === 'right')
    {
        elem.css('left', domRectTarget.right + 18 + 'px');
        elem.css('top', domRectTarget.top - 0 + 'px');

        if (domRectArrow)
        {
            const domRectElemB = elem.find('.tutorial-box').get(0).getBoundingClientRect();
            dirArrow.css('left', (domRectElemB.left - 9) + 'px');
            dirArrow.css('top', (domRectTarget.top + (domRectTarget.height / 2) - (domRectArrow.height / 2)) + 'px');
            dirArrow.css('transform', 'rotate(0deg)');
        }
    }
    else if (boxPos === 'left')
    {
        elem.css('left', (domRectTarget.left - domRectElem.width - 18) + 'px');
        elem.css('top', domRectTarget.top - 0 + 'px');

        if (domRectArrow)
        {
            const domRectElemB = elem.find('.tutorial-box').get(0).getBoundingClientRect();
            dirArrow.css('left', (domRectElemB.right - 5) + 'px');
            dirArrow.css('top', (domRectTarget.top + (domRectTarget.height / 2) - (domRectArrow.height / 2)) + 'px');
            dirArrow.css('transform', 'rotate(180deg)');
        }
    }
    else if (boxPos === 'below')
    {
        elem.css('left', ((domRectTarget.left + domRectTarget.width / 2) - (domRectElem.width / 2)) + 'px');
        elem.css('top', domRectTarget.bottom + 18 + 'px');

        if (domRectArrow)
        {
            const domRectElemB = elem.find('.tutorial-box').get(0).getBoundingClientRect();
            dirArrow.css('left', ((domRectElemB.left + domRectElemB.width / 2) - (domRectArrow.width / 2)) + 'px');
            dirArrow.css('top', (domRectElemB.top - 13) + 'px');
            dirArrow.css('transform', 'rotate(90deg)');
        }
    }
    else if (boxPos === 'below-right')
    {
        elem.css('left', (domRectTarget.right - domRectElem.width) + 'px');
        elem.css('top', domRectTarget.bottom + 18 + 'px');

        if (domRectArrow)
        {
            const domRectElemB = elem.find('.tutorial-box').get(0).getBoundingClientRect();
            dirArrow.css('left', ((domRectTarget.left + domRectTarget.width / 2) - (domRectArrow.width / 2)) + 'px');
            dirArrow.css('top', (domRectElemB.top - 13) + 'px');
            dirArrow.css('transform', 'rotate(90deg)');
        }
    }
    else if (boxPos === 'above-left')
    {
        elem.css('left', (domRectTarget.right - domRectElem.width) + 'px');
        elem.css('top', (domRectTarget.top - domRectElem.height - 8) + 'px');

        dirArrow.hide();
    }

    elem.data('offset-pos', boxPos);
    elem.data('target-selector', targetElemSelector);
}

$(document).on('click', '.js-close-btn', function()
{
    $(this).closest('.tutorial-box-outer').fadeOut('fast').promise().done(function()
    {
        $(this).addClass('previously-open');
        $(this).hide();
        saveTutorialViews();
    });
});

$(document).on('click', '#tutorialNextBtn', function()
{
    $(this).closest('.tutorial-box-outer').fadeOut('fast').promise().done(function()
    {
        const tutIdx = $(this).data('tutorial-index');
        $(this).addClass('previously-open');
        $(this).hide();
        $(`.tutorial-box-outer[data-tutorial-index="${tutIdx + 1}"]`).show();
        saveTutorialViews();

        positionVisibleTutorials();
    });
});

$(document).on('click', '#tutorialBackBtn', function()
{
    $(this).closest('.tutorial-box-outer').fadeOut('fast').promise().done(function()
    {
        const tutIdx = $(this).data('tutorial-index');
        $(this).addClass('previously-open');
        $(this).hide();
        $(`.tutorial-box-outer[data-tutorial-index="${tutIdx - 1}"]`).show();

        positionVisibleTutorials();
    });
});

$(document).on('click', '.tutorial-btn', function()
{
    const visibleCount = $('.tutorial-box-outer:visible').length;
    if (visibleCount > 0)
    {
        $('.tutorial-box-outer').hide();
    }
    else
    {
        $(`.tutorial-box-outer[data-tutorial-index='1']`).show();
    }

    positionVisibleTutorials();
});

function saveTutorialViews()
{
    const visibleCount = $('.tutorial-box-outer:visible').length;
    if (visibleCount === 0)
    {
        const prevOpenIds = $('.tutorial-box-outer.previously-open').map(function() { return $(this).data('id'); }).toArray();
        const token = $('meta[name="csrf-token"]').attr('content');
        $.ajax(
        {
            url: '/tutorials/log-viewed/',
            type: 'POST',
            dataType: 'json',
            data: { _token: token, viewedEntryIds: prevOpenIds },
            success: function(response) {}
        });
    }
}