import { Quitch } from '../quitch';
import { getSpinnerHtml } from '../../system';
import { detectOS } from '../class-manager/content-authoring/common';

/**
 * Marketplace transaction history page js
 */
$(document).on('change', '.js-marketplace-transaction-user-filter', function(e)
{
    let target = $(e.currentTarget);
    let input = $('.js-transactions-user-id-input');
    if (target[0].checked)
    {
        input.val(input.data('user-id'));
    }
    else
    {
        input.val('');
    }
});

$(document).on('click', '.js-transaction-history', function(e)
{
    e.preventDefault();

    let target = $(e.currentTarget.closest('tr'));
    let transactionId = target.data('transaction-id');

    let loadingSpinner = $('.loading-spinner');
    loadingSpinner.html(getSpinnerHtml());
    loadingSpinner.show();

    $('#transaction-history-modal .modal-body').hide();
    $('#transaction-history-modal').modal('toggle');

    $.ajax(
    {
        url: '/manage/marketplace/transaction/' + transactionId + '/history',
        type: 'GET',
        dataType: 'json',
        success: function(response)
        {
            loadingSpinner.hide();
            let historyRowsHtml = '';
            $.each(response.transactionHistory, function(index, row)
            {
                historyRowsHtml += `<tr>
                                            <td>${row.id}</td>
                                            <td>${row.status}</td>
                                            <td>${row.updatedAt}</td>
                                        </tr>`;
            });
            $('.device-uuid').text(target.data('device-uuid'));
            $('.device-uuid').attr('title', target.data('device-uuid'));
            $('.store-type').text(target.data('store-type') || '-');
            $('.store-id').text(target.data('store-transaction-id') || '-');
            $('#transaction-history-modal .modal-body table tbody').html(historyRowsHtml);
            $('#transaction-history-modal .modal-body').show();
        },
        error: function(e, t, s)
        {
            Quitch.showErrors(e.message);
        },
    });
});

$(document).on('click', '.js-transaction-tools', function(e)
{
    e.preventDefault();

    let target = $(e.currentTarget.closest('tr'));
    let transactionId = target.data('transaction-id');
    $('#transaction-tools-modal').data('transaction-id', transactionId);

    $('#transaction-tools-modal .device-uuid').text(target.data('device-uuid'));
    $('#transaction-tools-modal .device-uuid').attr('title', target.data('device-uuid'));
    $('#transaction-tools-modal .user-email').text(target.data('user-email'));
    $('#transaction-tools-modal .store-id').text(target.data('store-transaction-id'));
    $('#transaction-tools-modal').modal('toggle');
});

$(document).on('click', '.js-create-transaction, .js-update-transaction, .js-delete-transaction', function(e)
{
    e.preventDefault();

    let target = $(e.currentTarget);
    let values = { _token: $('meta[name="csrf-token"]').attr('content'), transactionId: $('#transaction-tools-modal').data('transaction-id') };
    let url = '';
    if (target.hasClass('js-create-transaction'))
    {
        url = '/manage/marketplace/transaction/create';
        values['classInstanceId'] = $('.js-select-class').val();
    }
    else if (target.hasClass('js-update-transaction'))
    {
        url = '/manage/marketplace/transaction/update';
        values['status'] = $('.js-select-status').val();
    }
    else if (target.hasClass('js-delete-transaction'))
    {
        if (confirm('Are you sure you want to delete this transaction?'))
        {
            url = '/manage/marketplace/transaction/delete';
        }
        else
        {
            return;
        }
    }
    else
    {
        return;
    }

    $.ajax(
    {
        url: url,
        type: 'POST',
        dataType: 'json',
        data: values,
        success: function(response)
        {
            $('#transaction-tools-modal').modal('toggle');
            Quitch.showSnackbar(response.message);
            window.location.reload();
        },
        error: function(e, t, s)
        {
            $('#transaction-tools-modal').modal('toggle');
            Quitch.showSnackbar(e.responseJSON.message);
        },
    });
});

$(document).on('click', '#js-rebuild-marketplace-search-index', function(e)
{
    e.preventDefault();
    if ($('html').hasClass('production'))
    {
        rebuildAlgoliaSearchIndex();
    }
    else
    {
        Quitch.modal(
        {
            title: 'Rebuild Marketplace Search Index',
            message: '<p class="wrap">This will delete and resend all marketplace classes to Algolia, this can cause searches to be incorrectly empty while it\'s being repopulated.</p><p>Are you certain you want to do this?</p>',
            type: 'confirm_custom',
            cssClass: 'modal-dialog-centered',
            okText: 'Wipe and rebuild',
            cancelText: 'Turn back',
        }, function(response)
        {
            if (response === true)
            {
                rebuildAlgoliaSearchIndex();
            }
        });
    }
});

function rebuildAlgoliaSearchIndex()
{
    const btn = $('#js-rebuild-marketplace-search-index');
    let values = { _token: $('meta[name="csrf-token"]').attr('content') };
    if (btn.hasClass('loading'))
    {
        return;
    }
    else
    {
        btn.addClass('loading');
    }
    btn.find('.complete').hide();
    btn.find('.loader').show();

    $.ajax(
    {
        url: '/manage/marketplace/rebuild-search-index',
        type: 'POST',
        dataType: 'json',
        data: values,
        success: function(response)
        {
            btn.removeClass('loading');
            btn.find('.loader').hide();
            btn.find('.complete').show();

            Quitch.showSnackbar(response.message);
        },
        error: function(e, t, s)
        {
            btn.removeClass('loading');
            btn.find('.loader').hide();
            Quitch.showSnackbar(e.responseJSON.message);
        },
    });
}

$(document).on('click', '#js-update-wix-marketplace', function(e)
{
    e.preventDefault();
    if ($('html').hasClass('production'))
    {
        updateWixMarketplace();
    }
    else
    {
        Quitch.modal(
        {
            title: 'Update Wix Marketplace',
            message: '<p class="wrap">This will replace all classes on Wix with the classes in this environment. Are you certain you want to do this? 🗿</p>',
            type: 'confirm_custom',
            cssClass: 'modal-dialog-centered',
            okText: 'Ship it 🚢',
            cancelText: 'Turn back',
        }, function(response)
        {
            if (response === true)
            {
                updateWixMarketplace();
            }
        });
    }
});

function updateWixMarketplace()
{
    const btn = $('#js-update-wix-marketplace');
    let values = { _token: $('meta[name="csrf-token"]').attr('content') };
    if (btn.hasClass('loading'))
    {
        return;
    }
    else
    {
        btn.addClass('loading');
    }
    btn.find('.complete').hide();
    btn.find('.loader').show();

    $.ajax(
    {
        url: '/manage/marketplace/update-wix-marketplace',
        type: 'POST',
        dataType: 'json',
        data: values,
        success: function(response)
        {
            btn.removeClass('loading');
            btn.find('.loader').hide();
            btn.find('.complete').show();

            Quitch.showSnackbar(response.message);
        },
        error: function(e, t, s)
        {
            btn.removeClass('loading');
            btn.find('.loader').hide();
            Quitch.showSnackbar(e.responseJSON.message);
        },
    });
}

$(document).on('click', '#manage-marketplace .collapsable-section .title', function(e)
{
    let parent = $(this).parents('.collapsable-section');
    parent.toggleClass('expanded');
    const sectionBody = parent.find('.body');

    sectionBody.slideToggle(500, function()
    {
        $(this).removeAttr('style');
        sectionBody.toggleClass('show');
    });
});

$(document).on('click', '#add-category-btn', function(e)
{
    $('#category-details-modal [name="categoryName"]').val('');
    $('#category-details-modal [name="categoryUrlSlug"]').attr('placeholder', 'quitch-picks').val('');
    $('#category-details-modal [name="categoryEmoji"]').val('');
    $('#category-showcase-toggle').prop('checked', true);
    $('#save-category-btn').prop('disabled', true);
    $('#category-details-modal').attr('data-category-id', '').modal('show');
});

$(document).on('click', '.js-edit-category', function(e)
{
    const categoryRow = $(this).parents('tr');
    $('#category-details-modal [name="categoryName"]').val(categoryRow.attr('data-category-name')).keyup();
    $('#category-details-modal [name="categoryUrlSlug"]').val(categoryRow.attr('data-category-url-slug'));
    $('#category-details-modal [name="categoryEmoji"]').val(categoryRow.attr('data-category-emoji'));
    $('#category-showcase-toggle').prop('checked', categoryRow.attr('data-category-showcase') == 1);
    $('#save-category-btn').prop('disabled', false);
    $('#category-details-modal').attr('data-category-id', categoryRow.data('category-id')).modal('show');
});

$(document).on('change', '.js-toggle-category-showcase', function(e)
{
    $('#category-details-modal [name="categoryShowcase"]').val($(this).prop('checked') ? 1 : 0);
    const categoryRow = $(this).parents('tr');
    let values = {
        _token: $('meta[name="csrf-token"]').attr('content'),
        id: categoryRow.attr('data-category-id'),
        showcase: $(this).prop('checked') ? 1 : 0,
    };

    $.ajax(
    {
        url: '/manage/marketplace/category/save',
        type: 'POST',
        dataType: 'json',
        data: values,
        success: function(response)
        {
            Quitch.showSnackbar('Updated category');
        },
    });
});

$(document).on('shown.bs.modal', '#category-details-modal', function(e)
{
    $('#category-details-modal .emoji-title .info').attr('title', `Click input and do ${detectOS() === 'Macintosh' ? 'control + ⌘ + SPACE' : 'Windows key + . (period)'} for emoji picker`);
});

$(document).on('keyup input', '#category-details-modal [name="categoryName"]', function(e)
{
    $('#save-category-btn').prop('disabled', $(this).val().length === 0);
    let urlSlug = $(this).val().replace(/[^a-zA-Z0-9]/g, '-').replace(/-+/g, '-').toLowerCase();
    $('#category-details-modal [name="categoryUrlSlug"]').attr('placeholder', urlSlug === '' ? 'quitch-picks' : urlSlug);
});

$(document).on('click', '#save-category-btn', function(e)
{
    e.preventDefault();
    let urlSlug = $('#category-details-modal [name="categoryUrlSlug"]').val();
    if (urlSlug.length === 0)
    {
        urlSlug = $('#category-details-modal [name="categoryUrlSlug"]').attr('placeholder');
    }

    let values = {
        _token: $('meta[name="csrf-token"]').attr('content'),
        name: $('#category-details-modal [name="categoryName"]').val(),
        urlSlug: urlSlug,
        emoji: $('#category-details-modal [name="categoryEmoji"]').val(),
        showcase: $('#category-details-modal [name="categoryShowcase"]').prop('checked') ? 1 : 0,
        showSnackbar: 1
    };

    const categoryId = $('#category-details-modal').attr('data-category-id');
    if (categoryId)
    {
        values.id = categoryId;
    }

    $.ajax(
    {
        url: '/manage/marketplace/category/save',
        type: 'POST',
        dataType: 'json',
        data: values,
        success: function(response)
        {
            window.location.reload();
        },
    });
});

$(document).on('show.bs.dropdown', '.js-category-dropdown', function(e)
{
    const categoryRow = $(this).parents('tr');
    categoryRow.siblings('tr').removeClass('selected');
    categoryRow.addClass('selected');
});

$(document).on('hide.bs.dropdown', '.js-category-dropdown', function(e)
{
    $(this).parents('tr').removeClass('selected');
});

$(document).on('click', '.js-delete-category', function(e)
{
    const categoryRow = $(this).parents('tr');

    Quitch.modal(
        {
            title: 'Delete Category?',
            message: 'Once you delete a category, you can’t undo it.',
            type: 'confirm_custom',
            cssClass: 'modal-dialog-centered modal-dialog-sm',
            okText: 'Delete',
            cancelText: 'base.cancel',
        },
        function(response)
        {
            if (response === true)
            {
                $.ajax(
                {
                    url: '/manage/marketplace/category/delete',
                    type: 'POST',
                    dataType: 'json',
                    data:
                    {
                        _token: $('meta[name="csrf-token"]').attr('content'),
                        id: categoryRow.attr('data-category-id'),
                    },
                    success: function(response)
                    {
                        window.location.reload();
                    },
                });
            }
        });
});

$(document).on('click', '#manage-marketplace .js-move-item', function(e)
{
    const type = $(this).attr('data-type');
    const categoryRow = $(this).parents('tr');
    const direction = $(this).attr('data-direction');

    if (categoryRow.is(':first-child') && (direction === 'top' || direction === 'up'))
    {
        return;
    }
    else if (categoryRow.is(':last-child') && (direction === 'bottom' || direction === 'down'))
    {
        return;
    }

    let values = {
        _token: $('meta[name="csrf-token"]').attr('content'),
        id: categoryRow.attr(`data-${type}-id`),
        direction: direction,
    };

    $.ajax(
    {
        url: `/manage/marketplace/${type}/reorder`,
        type: 'POST',
        dataType: 'json',
        data: values,
        success: function(response)
        {
            switch (direction)
            {
                case 'top':
                    categoryRow.parent().prepend(categoryRow);
                    break;
                case 'up':
                    categoryRow.prev().before(categoryRow);
                    break;
                case 'down':
                    categoryRow.next().after(categoryRow);
                    break;
                case 'bottom':
                    categoryRow.parent().append(categoryRow);
                    break;
            }

            categoryRow.parent().children('tr').each(function(index)
            {
                $(this).attr('data-category-order', index);
            });
        },
    });
});

$(document).on('click', '.class-categories-section .js-class-settings', function(e)
{
    const classInstanceId = $(this).parents('tr').attr('data-class-instance-id');

    $.ajax(
    {
        url: '/manage/marketplace/class/' + classInstanceId + '/settings',
        type: 'GET',
        dataType: 'json',
        success: function(response)
        {
            $(response.view).modal('show').on('hidden.bs.modal', function()
            {
                if ($(this).hasClass('class-changed'))
                {
                    window.location.reload();
                }
                else
                {
                    $(this).remove();
                }
            });
        }
    });
});

$(document).on('click', '#marketplace-class-settings-modal .toggle-review, #marketplace-class-settings-modal #save-marketplace-settings-btn', function(e)
{
    $('#marketplace-class-settings-modal').addClass('class-changed');
});

$(document).on('input', '#js-marketplace-class-search', function(e)
{
    const searchValue = $(this).val().toLowerCase();

    if (searchValue.length > 0)
    {
        $('.class-categories-section tbody tr').each(function()
        {
            $(this).toggle($(this).text().toLowerCase().indexOf(searchValue) !== -1);
        });

        $('.class-categories-section tbody').each(function()
        {
            $(this).parent().toggle($(this).children('tr:visible').length > 0);
        });
    }
    else
    {
        $('.class-categories-section tbody tr').show();
        $('.class-categories-section table').show();
    }
});